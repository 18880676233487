import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  SelectInput,
  SaveButton,
  FormWithRedirect,
  required,
  email,
  maxLength,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { phoneNumber } from '../../utils';

const useStyles = makeStyles(() => ({
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();

  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];

  return (
    <FormWithRedirect
      save={(data, ...rest) => {
        save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Update External collection agency
                    </Typography>
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[maxLength(60), required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="status" validate={[required()]} choices={statusChoices} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source="phone"
                        fullWidth
                        helperText={false}
                        placeholder={phoneNumber.placeholder}
                        parse={phoneNumber.parser}
                        format={v => phoneNumber.formatter(v)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Email"
                        source="email"
                        type="email"
                        validate={[email('Invalid email')]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput source="details" validate={[maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <BooleanInput
                        source="is_payments_allowed"
                        label="Payments allowed from Outsorcing"
                        helperText={false}
                        className={classes.titleSwitch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <BooleanInput
                        source="is_payments_allowed_after_sold"
                        label="Payments allowed after sold"
                        helperText={false}
                        className={classes.titleSwitch}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    template_key: PropTypes.string,
  }),
};

const ExternalAgenciesEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default ExternalAgenciesEdit;
