import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { timezone } from '../../utils';

const useStyles = makeStyles(theme => ({
  my2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const DateTimeFilterInput = props => {
  const classes = useStyles();
  const {
    input: { value, onChange },
  } = useInput(props);

  return (
    <DateTimePicker
      className={classes.my2}
      label={props.label}
      value={value ? timezone.shift(new Date(value)) : null}
      onChange={value => onChange(timezone.unshift(value).toISOString())}
    />
  );
};

DateTimeFilterInput.propTypes = {
  label: PropTypes.string,
};
