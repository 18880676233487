import React from 'react';

const ImportProcessLoanClose = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/loan_close.csv" download="import_example_loan_close.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>loan_id</b>, required
        </li>
        <li>
          <b>closed_at</b>, required
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessLoanClose;
