import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

const RedMark = ({ phone, title }) => (
  <Box display="flex" alignItems="center">
    <Box>{phone}</Box>
    <Box>
      <Tooltip title={title}>
        <Box pl={1}>
          <CloseIcon color="error" />
        </Box>
      </Tooltip>
    </Box>
  </Box>
);

RedMark.propTypes = {
  phone: PropTypes.string,
  title: PropTypes.string,
};

export default RedMark;
