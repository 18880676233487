import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  TextInput,
  SelectInput,
  SaveButton,
  FormWithRedirect,
  required,
  BooleanInput,
  maxLength,
  useDataProvider,
  SelectArrayInput,
  NumberInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider, Paper, Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import { PHONE_PRIORITY_CHOICES } from '../../constants';
import { time as timeValidator } from '../../utils';
import { Autocomplete } from '../../components';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [transmitters, setTransmitters] = useState({});
  const [transmitter, setTransmitter] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const [variables, setVariables] = useState([]);
  const [dialerId, setDialerId] = useState(null);
  const [frequencyPeriod, setFrequencyPeriod] = useState(null);
  const [contactSource, setContactSource] = useState(null);
  const [contactType, setContactType] = useState(null);
  const [phoneStatus, setPhoneStatus] = useState(null);
  const [phoneValidation, setPhoneValidation] = useState(null);
  const [phoneContact, setPhoneContact] = useState(null);
  const [phonePrefix, setPhonePrefix] = useState(null);

  useEffect(() => {
    dataProvider.query('dialer_transmitters', { method: 'GET' }).then(({ data }) => {
      let newData = {};

      for (const i of data) {
        let params = {};

        for (const j of i.params_description) {
          params = { ...params, [j.id]: j };
        }

        newData = { ...newData, [i.id]: { ...i, params_description: params } };
      }

      const variables_allowed_values = newData?.webitel?.params_description?.variables?.selected_values;
      const weekDays_allowed_values = newData?.webitel?.params_description?.week_days?.selected_values;

      setTransmitters(newData);
      setVariables(Array.isArray(variables_allowed_values) ? variables_allowed_values : []);
      setWeekDays(Array.isArray(weekDays_allowed_values) ? weekDays_allowed_values : []);
    });
  }, [dataProvider]);

  const getAllowedValues = id => {
    const { allowed_values } = transmitters?.[transmitter]?.params_description[id];
    const values = Object.entries(allowed_values).map(item => ({
      id: item[1],
      [id === 'frequency_period' ||
      id === 'contact_source' ||
      id === 'contact_type' ||
      id === 'phone_status' ||
      id === 'phone_validation' ||
      id === 'phone_contact'
        ? 'name'
        : 'label']: item[0],
    }));

    return values;
  };

  const onChange = (id, paramsId) => {
    const params = paramsId === 'week_days' ? weekDays : variables;
    const setParams = paramsId === 'week_days' ? setWeekDays : setVariables;
    const element = params.includes(id);

    if (element) {
      setParams(params.filter(i => i !== id));
    } else {
      setParams([...params, id]);
    }
  };

  const getParams = phone_weight => {
    const params = {};
    if (contactSource) {
      params.contact_source = contactSource;
    }
    if (contactType) {
      params.contact_type = contactType;
    }
    if (dialerId) {
      params.dialer_id = +dialerId;
    }
    if (frequencyPeriod) {
      params.frequency_period = frequencyPeriod;
    }
    if (phoneStatus) {
      params.phone_status = phoneStatus;
    }
    if (phoneValidation) {
      params.phone_validation = phoneValidation;
    }
    if (phoneContact) {
      params.phone_contact = phoneContact;
    }
    if (phonePrefix) {
      params.phone_prefix = phonePrefix;
    }
    if (variables.length) {
      params.variables = variables;
    }
    if (weekDays.length) {
      params.week_days = weekDays;
    }
    if (phone_weight) {
      params.phone_weight = phone_weight;
    }
    return params;
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Create Dialer Base
          </Typography>
          <Divider className={classes.my2} />
          <FormWithRedirect
            save={(
              { name, start_time, end_time, mass_sending_audiences, is_enabled, is_clear_daily, phone_weight },
              ...rest
            ) => {
              const payload = {
                name,
                start_time,
                end_time,
                mass_sending_audiences,
                is_enabled,
                is_clear_daily,
                transmitter_id: transmitter,
                params: getParams(phone_weight),
              };
              save(...[payload, ...rest]);
            }}
            {...props}
            render={formProps => {
              return (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} key="is_enabled">
                      <BooleanInput label="Enabled" source="is_enabled" initialValue={true} />
                    </Grid>
                    <Grid item xs={12} sm={6} key="is_clear_daily">
                      <BooleanInput label="Clear daily" source="is_clear_daily" initialValue={true} />
                    </Grid>
                    <Grid item xs={12} key="name">
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6} key="start_time">
                      <TextInput
                        helperText="Format: hh:mm:ss"
                        source="start_time"
                        validate={[required(), value => timeValidator(value)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="end_time">
                      <TextInput
                        helperText="Format: hh:mm:ss"
                        source="end_time"
                        validate={[required(), value => timeValidator(value)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} key="mass_sending_audiences">
                      <Autocomplete
                        resource="mass_sending_audiences"
                        source="mass_sending_audiences"
                        optionValueProp="id"
                        optionLabelProp="name"
                        required
                        multiple
                        withClickableOptions
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="transmitters">
                      <SelectInput
                        helperText={false}
                        onChange={e => setTransmitter(e.target.value)}
                        source="transmitters"
                        validate={[required()]}
                        choices={Object.keys(transmitters)?.map(key => ({ id: key, name: key }))}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="phone_weight">
                      <SelectArrayInput
                        allowEmpty
                        helperText={false}
                        source="phone_weight"
                        label="Phone priority"
                        choices={PHONE_PRIORITY_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    {transmitter === 'webitel' && (
                      <>
                        <Grid item xs={12} sm={6} key="dialerID">
                          <NumberInput
                            source="dialer_id"
                            onChange={e => setDialerId(e.target.value)}
                            validate={[required(), maxLength(255)]}
                            fullWidth
                            initialValue={dialerId}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="frequency_period">
                          <SelectInput
                            helperText={false}
                            onChange={e => setFrequencyPeriod(e.target.value)}
                            source="frequency_period"
                            validate={[required()]}
                            choices={getAllowedValues('frequency_period')}
                            initialValue={frequencyPeriod}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_status">
                          <SelectInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setPhoneStatus(e.target.value)}
                            source="phone_status"
                            choices={getAllowedValues('phone_status')}
                            initialValue={phoneStatus}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="contact_type">
                          <SelectArrayInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setContactType(e.target.value)}
                            source="contact_type"
                            choices={getAllowedValues('contact_type')}
                            initialValue={contactType}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="contact_source">
                          <SelectArrayInput
                            allowEmpty
                            source="contact_source"
                            helperText={false}
                            onChange={e => setContactSource(e.target.value)}
                            choices={getAllowedValues('contact_source')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_validation">
                          <SelectArrayInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setPhoneValidation(e.target.value)}
                            source="phone_validation"
                            choices={getAllowedValues('phone_validation')}
                            initialValue={phoneValidation}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_contact">
                          <SelectInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setPhoneContact(e.target.value)}
                            source="phone_contact"
                            label="Phone results"
                            choices={getAllowedValues('phone_contact')}
                            initialValue={phoneContact}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_prefix">
                          <TextInput
                            helperText="Prefix with which to send numbers to Webitel (country code)"
                            source="phone_prefix"
                            onChange={e => setPhonePrefix(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} key="week_days">
                          <InputLabel id="week_days_label">Week days</InputLabel>
                          {getAllowedValues('week_days').map(day => (
                            <FormControlLabel
                              key={day.id}
                              control={
                                <Checkbox
                                  onChange={() => onChange(day.id, 'week_days')}
                                  checked={weekDays?.includes(day.id)}
                                  color="primary"
                                />
                              }
                              label={day.label}
                            />
                          ))}
                        </Grid>
                        <Grid item xs={12} sm={12} key="variables">
                          <InputLabel id="week_days_label">Variables</InputLabel>
                          {getAllowedValues('variables').map(variable => (
                            <FormControlLabel
                              key={variable.id}
                              control={
                                <Checkbox
                                  onChange={() => onChange(variable.id, 'variables')}
                                  checked={variables?.includes(variable.id)}
                                  color="primary"
                                />
                              }
                              label={variable.label}
                            />
                          ))}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider className={classes.my2} />
                  <SaveButton
                    label="Submit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </>
              );
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const DialerBasesCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);
export default DialerBasesCreate;
