import React, { useState } from 'react';
import { Toolbar, required, useNotify, useDataProvider } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete, ListFilter } from '../../components';
import { PTP_STATUS_CHOICES } from '../../constants';
import { getUserAttachments } from '../../utils';

const useStyles = makeStyles(theme => ({
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  hidden: {
    display: 'none',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
  removeIcon: {
    padding: 0,
    margin: 0,
  },
}));

const ptpStatusChoices = [{ id: 'without_ptp', name: 'Without PTP' }, ...PTP_STATUS_CHOICES];

const CollectorsReassignment = () => {
  const [loading, setLoading] = useState(false);
  const [loanSource, setLoanSource] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const attachments = getUserAttachments(files);

  const handleAttachFile = async e => {
    try {
      if (e.target.files[0]?.size > 10240000) {
        return notify('Maximum file size is 10 Mb', 'error');
      }
      setLoadingFile(true);
      const formData = new FormData();
      formData.append('file', e.target.files[0], 'file');
      const { data } = await dataProvider.query('files/users', { method: 'POST', body: formData });
      setFiles(prevState => [...prevState, data]);
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    } finally {
      setLoadingFile(false);
    }
  };

  const handleUnattachFile = id => setFiles(prevState => [...prevState.filter(i => i.id !== id)]);

  const handleSubmit = (values, form) => {
    setLoading(true);

    const payload = {
      audience_id: values.audience_id ?? null,
      current_collectors: values.current_collectors ?? null,
      new_collectors: values.new_collectors,
      file_id: files.length > 0 ? files[0].id : null,
      filters: {},
    };

    if (values.loan_source === 'filters') {
      const dpd = {
        dpd: [
          { operation_sign: '>=', number: +values.min_dpd, include: 'include' },
          { operation_sign: '<=', number: +values.max_dpd, include: 'include' },
        ],
      };
      payload.filters = { ...dpd };

      if (
        values.ptp_stage &&
        values.ptp_stage.active?.[0] === 'ptp_stage' &&
        values.ptp_stage.values.every(i => i.value)
      ) {
        const ptp_stage_filters = {
          ptp_stage: values.ptp_stage.values,
        };
        payload.filters = { ...payload.filters, ...ptp_stage_filters };
      }
    }

    dataProvider
      .query('admin/loans/reassign-operator', {
        method: 'PATCH',
        body: JSON.stringify(payload),
      })
      .then(() => {
        notify('Collectors reassigned successfully', 'success');
        setLoading(false);
        setLoanSource(null);
        setFiles([]);
        form.change('max_dpd', null);
        form.change('min_dpd', null);
        form.restart();
      })
      .catch(error => {
        setLoading(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" gutterBottom={false}>
              Reassignment of collectors
            </Typography>
            <Divider className={classes.my2} />
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Field name="loan_source" validate={required('This field is required')}>
                          {({ input, meta }) => (
                            <FormControl fullWidth variant="filled">
                              <InputLabel error={meta.touched && meta.error} required id="loans-source-select-label">
                                Select loans from
                              </InputLabel>
                              <Select
                                id="loans-source-select-label"
                                margin="dense"
                                value={input.value}
                                error={meta.touched && meta.error}
                                onChange={e => {
                                  const value = e.target.value;
                                  input.onChange(value);
                                  setLoanSource(value);
                                  if (value === 'audience') {
                                    form.change('max_dpd', null);
                                    form.change('min_dpd', null);
                                  }
                                }}
                                fullWidth>
                                <MenuItem value="filters">Filters</MenuItem>
                                <MenuItem value="audience">Audience</MenuItem>
                              </Select>
                              {meta.touched && meta.error && (
                                <FormHelperText error={meta.touched && meta.error}>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Grid>

                      {loanSource === 'filters' && (
                        <>
                          <Grid item xs={6}>
                            <Field name="min_dpd" validate={required('This field is required')}>
                              {({ input, meta }) => (
                                <TextField
                                  type="number"
                                  error={meta.touched && meta.error}
                                  helperText={meta.touched && meta.error}
                                  id="min-dpd-input"
                                  label="DPD from"
                                  variant="filled"
                                  margin="dense"
                                  value={input.min_dpd}
                                  onChange={input.onChange}
                                  onKeyPress={event =>
                                    (event?.key === '.' || event?.key === ',') && event.preventDefault()
                                  }
                                  fullWidth
                                  required
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={6}>
                            <Field name="max_dpd" validate={required('This field is required')}>
                              {({ input, meta }) => (
                                <TextField
                                  type="number"
                                  error={meta.touched && meta.error}
                                  helperText={meta.touched && meta.error}
                                  id="max-dpd-input"
                                  label="DPD to"
                                  variant="filled"
                                  margin="dense"
                                  value={input.max_dpd}
                                  onChange={input.onChange}
                                  onKeyPress={event =>
                                    (event?.key === '.' || event?.key === ',') && event.preventDefault()
                                  }
                                  fullWidth
                                  required
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <ListFilter
                              id="ptp_stage"
                              choice={ptpStatusChoices}
                              title="Add PTP stages (optional)"
                              form={form}
                              withPadding={false}
                              withDivider={false}
                            />
                          </Grid>
                        </>
                      )}

                      {loanSource === 'audience' && (
                        <Grid item xs={12}>
                          <Field name="audience_id" validate={required('This field is required')}>
                            {({ input, meta }) => (
                              <Autocomplete
                                resource="mass_sending_audiences"
                                label="Choose audience"
                                source="audience_id"
                                optionValueProp="id"
                                optionLabelProp="name"
                                required
                                requiredMessage="This field is required"
                                margin="none"
                                {...input}
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                              />
                            )}
                          </Field>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Field name="current_collectors">
                          {({ input, meta }) => (
                            <Autocomplete
                              label="Current collectors"
                              margin="none"
                              resource="admins"
                              source="current_collectors"
                              optionValueProp="id"
                              optionLabelProp="username"
                              filter={{
                                role_permission: 'CAN_BE_COLLECTION_SPECIALIST',
                                is_blocked: false,
                              }}
                              multiple
                              {...input}
                              error={meta.touched && meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={12}>
                        <Field name="new_collectors" validate={required('This field is required')}>
                          {({ input, meta }) => (
                            <>
                              <Typography variant="body2" color="textSecondary" gutterBottom>
                                Select the collectors between whom you want to distribute the selected loans
                              </Typography>
                              <Autocomplete
                                label="New collectors"
                                margin="none"
                                resource="admins"
                                source="new_collectors"
                                optionValueProp="id"
                                optionLabelProp="username"
                                required
                                requiredMessage="This field is required"
                                filter={{
                                  role_permission: 'CAN_BE_COLLECTION_SPECIALIST',
                                  is_blocked: false,
                                }}
                                multiple
                                {...input}
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                              />
                            </>
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Exclude loans from reassignment (optional). Example of file you can download{' '}
                          <Link
                            target="_blank"
                            href="/admin/import_example/example_exclude_loans.csv"
                            download="example_exclude_loans.csv">
                            here
                          </Link>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Note that credit numbers should be listed comma-separated in the same column
                        </Typography>
                        {files.length === 0 ? (
                          <Box>
                            <label htmlFor="upload_file">
                              <input
                                className={classes.hidden}
                                type="file"
                                id="upload_file"
                                accept="text/csv"
                                onChange={handleAttachFile}
                                onClick={e => {
                                  e.target.value = null;
                                }}
                              />
                              <Button
                                color="primary"
                                aria-label="upload file"
                                startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                                component="span">
                                Attach file
                              </Button>
                            </label>
                          </Box>
                        ) : (
                          <Box>
                            {attachments.map(file => (
                              <Box key={file.src} className={classes.flex}>
                                <Box className={classes.flex}>
                                  <AttachmentIcon color="primary" fontSize="small" />
                                  <Link target="_blank" href={file.src}>
                                    {file.file_name}
                                  </Link>
                                </Box>
                                <IconButton
                                  color="primary"
                                  aria-label="unattach file"
                                  className={classes.removeIcon}
                                  component="span"
                                  edge="start"
                                  onClick={() => handleUnattachFile(file.id)}>
                                  <CloseIcon color="primary" fontSize="small" />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Toolbar className={classes.toolbar}>
                      <Button
                        onClick={handleSubmit}
                        startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : <SaveIcon />}
                        color="primary"
                        variant="contained"
                        disabled={loading}>
                        Submit
                      </Button>
                    </Toolbar>
                  </form>
                );
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CollectorsReassignment;
