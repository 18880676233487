import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TransactionType, TransactionDirection } from '../../../components';
import { formatCurrency, formatDatetime } from '../../../utils';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  executedTransactionCheckbox: {
    margin: `-${theme.spacing(2)}px `.repeat(3).trim(),
  },
  executedTransactionSwitch: {
    margin: `-12px `.repeat(3).trim(),
  },
  border: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  wrapper: {
    padding: 0,
    width: '100%',
    border: 'none',
  },
  parentRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
}));

const ColapsibleRow = props => {
  const { row, toggle, selected, isActionsDisabled } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const theme = useTheme();

  const zeroStyles = row.grouped_transactions.length % 2 === 0 ? { color: theme.palette.text.secondary } : {};

  React.useLayoutEffect(() => {
    if (open) {
      const setCellsWidths = () => {
        const headers = Array.from(document.getElementsByClassName(`parent_cell_${row.id}`));
        const subTableHeaderRow = document.getElementById(`subTableHeaderRow_${row.id}`);
        const subTableColumns = document.getElementsByClassName(`subTableCell_${row.id}`);
        headers.forEach((header, i) => {
          subTableColumns[i].style.width = `${(header.clientWidth / subTableHeaderRow.clientWidth) * 100}%`;
        });
      };

      setCellsWidths();
      addEventListener('resize', setCellsWidths);
      return () => {
        removeEventListener('resize', setCellsWidths);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <React.Fragment>
      <TableRow key={row.id} className={classes.parentRow}>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`}>
          {row.id}
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`}>
          {formatDatetime(row.executed_at)}
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`}>
          {formatDatetime(row.created_at)}
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`}>
          {row.triggered_by ?? '---'}
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`} align="center">
          <TransactionDirection direction={row.direction} />
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`}>
          <TransactionType type={row.type} direction={row.direction} />
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`} align="right">
          {formatCurrency(row.amount)}
        </TableCell>
        <TableCell style={zeroStyles} className={`parent_cell_${row.id}`} align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={classes.executedTransactionSwitch}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.border}>
        <TableCell style={{ padding: 0, width: '100%', border: 'none' }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow className={classes.parentRow} id={`subTableHeaderRow_${row.id}`}>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                  <TableCell className={`subTableCell_${row.id}`}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.grouped_transactions.map(item => (
                  <TableRow key={item.id} className={classes.parentRow}>
                    <TableCell style={zeroStyles}>{item.id}</TableCell>
                    <TableCell style={zeroStyles}>{formatDatetime(item.executed_at)}</TableCell>
                    <TableCell style={zeroStyles}>{formatDatetime(item.created_at)}</TableCell>
                    <TableCell style={zeroStyles}>{item.triggered_by ?? '---'}</TableCell>
                    <TableCell style={zeroStyles} align="center">
                      <TransactionDirection direction={item.direction} />
                    </TableCell>
                    <TableCell style={zeroStyles}>
                      <TransactionType type={item.type} direction={item.direction} />
                    </TableCell>
                    <TableCell style={zeroStyles} align="right">
                      {formatCurrency(item.amount)}
                    </TableCell>
                    <TableCell style={zeroStyles} align="right">
                      {!item.is_reverted && (
                        <Checkbox
                          className={classes.executedTransactionCheckbox}
                          size="small"
                          checked={selected.indexOf(item.id) !== -1}
                          onChange={() => toggle(item.id)}
                          disabled={isActionsDisabled}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

ColapsibleRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    executed_at: PropTypes.string,
    created_at: PropTypes.string,
    amount: PropTypes.number,
    direction: PropTypes.string,
    type: PropTypes.string,
    triggered_by: PropTypes.string,
    is_reverted: PropTypes.bool,
    grouped_transactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        executed_at: PropTypes.string,
        created_at: PropTypes.string,
        amount: PropTypes.number,
        direction: PropTypes.string,
        type: PropTypes.string,
        triggered_by: PropTypes.string,
        is_reverted: PropTypes.bool,
      }),
    ),
  }),
  toggle: PropTypes.func,
  selected: PropTypes.array,
  isActionsDisabled: PropTypes.bool,
};

export default ColapsibleRow;
