import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

export const QuickFilterInput = ({ label }) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

QuickFilterInput.propTypes = {
  label: PropTypes.string,
};
