import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { ListItemField } from './ListItemField';
import { Grid } from '@material-ui/core';

export const SettingsListItem = ({ setting, canEdit, onChange }) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={8}>
        <Typography>{setting.details.title}</Typography>
      </Grid>
      <Grid item xs={6} sm={4}>
        <ListItemField {...setting} canEdit={canEdit} onChange={onChange} />
      </Grid>
    </Grid>
  );
};

SettingsListItem.propTypes = {
  setting: PropTypes.shape({
    details: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  canEdit: PropTypes.bool,
  onChange: PropTypes.func,
};
