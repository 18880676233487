import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DatePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import { REPORT_PERIOD } from '../../../constants';
import { timezone } from '../../../utils';
import ReportTimezone from '../components/ReportTimezone';

const AccountingLoanReportParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('accounting_loan_report');

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.report_period)} required>
            <InputLabel id="report_period">Period</InputLabel>
            <Select
              labelId="report_period"
              value={value && value.report_period ? value.report_period : ''}
              onChange={e => onChange({ ...(value || {}), report_period: e.target.value })}>
              {REPORT_PERIOD.map(i => (
                <MenuItem value={i} key={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.report_period && <FormHelperText>{error.report_period}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="By date"
            format="MMMM dd, yyyy"
            className={classes.fullWidth}
            value={value && value.report_date ? timezone.shift(value.report_date) : null}
            onChange={v => onChange({ ...(value || {}), report_date: timezone.unshift(v) })}
            error={!!(touched && error && error.report_date)}
            helperText={touched && error && error.report_date}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
      </Grid>
    </>
  );
};

export default AccountingLoanReportParamsInput;
