export const DAYS_MODE_DOW = 'dow';
export const DAYS_MODE_DOM = 'dom';
export const DAYS_MODE_ED = 'ed';
export const WEEK_DAYS = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const MONTH_DAYS = [
  ...[...Array(31).keys()].map(i => ({
    label: i + 1,
    value: i + 1,
  })),
  {
    label: 'Last',
    value: -1,
  },
];

export const KEY_OPENED_ACTION_MODAL = 'opened_action_modal';
