import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { Pagination } from '../../components';
import { marginZeroStyles, WEEK_DAYS } from '../../constants';
import { formatDatetime, formatDate } from '../../utils';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ExternalAgencyCampaignList = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [agencies, setAgencies] = useState([]);
  const [admins, setAdmins] = useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .getList('external_agencies', {
        filter: {},
        pagination: { page: 1, perPage: 300 },
        sort: {},
      })
      .then(({ data }) => setAgencies(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));

    dataProvider
      .getList('admins', {
        filter: {},
        pagination: { page: 1, perPage: 300 },
        sort: {},
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  const getAgencyName = id => (id ? agencies.find(agency => agency.id === id).name : null);
  const getAdminName = id => {
    const admin = admins.find(admin => admin.id === id);
    return admin ? `${admin.username} #${admin.id}` : null;
  };

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField
          label="Allocation by agency"
          render={record =>
            record.params?.external_agencies.map(agency => (
              <div key={agency.id}>{`${getAgencyName(agency.id)}: ${agency.percent}%`}</div>
            ))
          }
        />
        <FunctionField
          label="Audience"
          render={record => {
            if (record.audience_type === 'audience') {
              return <Chip size="small" label={record.audience_id} />;
            }
            if (record.audience_type === 'file') {
              return 'File';
            }
          }}
        />
        <FunctionField label="Created by" source="created_by_id" render={(record, key) => getAdminName(record[key])} />
        <FunctionField label="Updated by" source="updated_by_id" render={(record, key) => getAdminName(record[key])} />
        <FunctionField
          label="Period"
          render={record => {
            const monthDays = Array.isArray(record?.params?.month_days) ? record.params.month_days : [];
            const weekDays = Array.isArray(record?.params?.week_days) ? record.params.week_days : [];

            monthDays.sort((a, b) => {
              a = a < 0 ? a + 100 : a;
              b = b < 0 ? b + 100 : b;

              return Math.sign(a - b);
            });
            weekDays.sort();

            return (
              <>
                {monthDays.length > 0 && (
                  <>
                    <div>
                      <b>Days of month:</b>
                    </div>
                    <div>{monthDays.map(day => (day === -1 ? 'Last' : day)).join(', ')}</div>
                  </>
                )}

                {weekDays.length > 0 && (
                  <>
                    <div>
                      <b>Days of week:</b>
                    </div>
                    <div>{weekDays.map(day => WEEK_DAYS[day - 1] || 'UN').join(', ')}</div>
                  </>
                )}

                {record.params?.one_time_allocations && (
                  <>
                    <div>
                      <b>One-time allocation:</b>
                    </div>
                    <div>{formatDate(record?.params?.one_time_allocations[0])}</div>
                  </>
                )}
              </>
            );
          }}
        />
        <FunctionField
          label="Allocation period"
          render={record => {
            if (!record.params?.allocated_days && !record.params?.allocated_date) {
              return 'Forever';
            }
            if (record.params?.allocated_days) {
              return `For ${record.params.allocated_days} days`;
            }
            if (record.params?.allocated_date) {
              return `Till ${formatDate(record.params?.allocated_date)}`;
            }
          }}
        />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <BooleanField source="is_enabled" />
      </Datagrid>
    </List>
  );
};

export default ExternalAgencyCampaignList;
