import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import RefreshIcon from '@material-ui/icons/Refresh';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const AgreementTable = ({ userId = null, applicationId = null, loanId = null, refreshedAt, onTransition }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [agreements, setAgreements] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    dataProvider
      .getList('agreements', {
        filter: {
          ...(userId ? { 'user.id': userId } : {}),
          ...(applicationId ? { 'application.id': applicationId } : {}),
          ...(loanId ? { 'loan.id': loanId } : {}),
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setAgreements(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, applicationId, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(agreements)) return null;

  const downloadHandler = agreementId => {
    setIsDownloading(true);
    dataProvider
      .downloadFile(`agreements/${agreementId}/download`)
      .then(({ data: objUrl }) => {
        const link = document.createElement('a');
        link.href = objUrl;
        link.download = 'Agreement.pdf';
        link.click();
      })
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setIsDownloading(false));
  };

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>State</TableCell>
            <TableCell>User id</TableCell>
            <TableCell>Appl. id</TableCell>
            <TableCell>Loan id</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agreements.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>{row.user_id}</TableCell>
              <TableCell>{row.application_id}</TableCell>
              <TableCell>{row.loan_id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.template_key} />
              </TableCell>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
              <TableCell>{row.updated_at && formatDatetime(row.updated_at)}</TableCell>
              <TableCell align="right">
                <IconButton
                  color="primary"
                  size="small"
                  disabled={
                    isDownloading ||
                    row.enabled_transitions.indexOf('reset') === -1 ||
                    permissions.indexOf('CAN_AGREEMENT_EDIT') === -1
                  }
                  onClick={() => onTransition(row.id, 'reset')}>
                  <RefreshIcon />
                </IconButton>

                <IconButton
                  color="primary"
                  size="small"
                  disabled={isDownloading || row.state !== 'processed'}
                  onClick={() => downloadHandler(row.id)}>
                  <CloudDownloadIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

AgreementTable.propTypes = {
  userId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func.isRequired,
};
