import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  watermark: {
    width: '60px',
    height: '45px',
    opacity: 0.2,
    background: `linear-gradient(to right, black 1px, transparent 1px) 0 0,
    linear-gradient(to right, black 1px, transparent 2px) 0 100%,
    linear-gradient(to left, black 1px, transparent 1px) 100% 0,
    linear-gradient(to left, black 1px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, black 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, black 1px, transparent 2px) 100% 0,
    linear-gradient(to top, black 1px, transparent 1px) 0 100%,
    linear-gradient(to top, black 1px, transparent 2px) 100% 100%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2px 2px',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    zIndex: 1,
  },
  item: {
    boxSizing: 'border-box',
    width: '15px',
    height: '15px',
    fontSize: '0.5rem',
    textAlign: 'center',
  },
}));

export const Watermark = ({ top, bottom, left, right }) => {
  const getUserId = () => {
    const userJson = localStorage.getItem('admin_fields');
    if (!userJson) {
      return 'Unknown';
    }
    const userObject = JSON.parse(userJson);
    return (userObject || {}).id || 'Unknown';
  };

  const classes = useStyles();
  const userId = getUserId();
  const binary = userId.toString(2).split('');
  const matrix = Array(12).fill('0');
  matrix.splice(-binary.length, binary.length, ...binary);

  return (
    <Box className={classes.watermark} sx={{ top, bottom, left, right }}>
      {matrix.map((item, index) =>
        item === '1' ? (
          <Box key={index} sx={{ backgroundColor: '#CC7986cb' }} className={classes.item}></Box>
        ) : (
          <Box key={index} className={classes.item}></Box>
        ),
      )}
    </Box>
  );
};

Watermark.propTypes = {
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
