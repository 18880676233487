import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '../styles';
import { TransactionType } from '../../../components';
import { formatCurrency, formatDatetime, snakeToHuman } from '../../../utils';

const ScheduledTransactions = ({ loanId, refreshedAt }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query(`loans/${loanId}/scheduled_transactions`, { method: 'GET' })
      .then(({ data }) => setTransactions(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, loanId, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Paper>
      <Typography variant="h6" gutterBottom={false} className={classes.paper}>
        Scheduled transactions
      </Typography>
      <Divider />
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map(row => (
            <TableRow key={row.id}>
              <TableCell>{formatDatetime(row.scheduled_at)}</TableCell>
              <TableCell>{snakeToHuman(row.state)}</TableCell>
              <TableCell>
                <TransactionType type={row.type} />
              </TableCell>
              <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

ScheduledTransactions.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};

export default ScheduledTransactions;
