export const getFormStyles = theme => {
  return {
    paper: {
      padding: theme.spacing(2),
    },
    my2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    hidden: {
      display: 'none',
    },
    uploadIcon: {
      marginBottom: '-20px',
      marginTop: '-24px',
    },
    flex: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '5px',
    },
    removeIcon: {
      padding: 0,
      margin: 0,
    },
    download: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    domList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gridTemplateColumns: 'repeat',
    },
    domItem: {
      flexBasis: '80px',
      flexShrink: 0,
    },
  };
};

export const ALLOCATION_CHOICES = [
  { id: 1, name: 'Forever' },
  { id: 2, name: 'For number of days' },
  { id: 3, name: 'Until the date' },
];

export const AUDIENCE_TYPE_CHOICES = [
  { id: 'audience', name: 'Audience' },
  { id: 'file', name: 'File' },
];
