import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  useDataProvider,
  useNotify,
  CreateButton,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { getAdminsList } from './getAdminsList';

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter, hasCreate, basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const CollectionGroupList = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [admins, setAdmins] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('admins', {
        filter: {},
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  if (loading)
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      bulkActionButtons={false}
      sort={{ field: 'code', order: 'ASC' }}
      actions={<ListActions />}
      perPage={25}
      {...props}>
      <Datagrid rowClick={props.permissions?.includes('ROLE_HEAD_OF_COLLECTION') ? 'edit' : undefined}>
        <TextField label="Group code" source="code" sortable={false} />
        <TextField label="Days past due from" source="dpd_from" sortable={false} />
        <TextField label="Days past due to" source="dpd_to" sortable={false} />
        <TextField label="Max PTP term" source="ptp_term" sortable={false} />
        <FunctionField label="Assigned to admins" render={record => getAdminsList(admins, record.code)} />
      </Datagrid>
    </List>
  );
};

CollectionGroupList.propTypes = {
  permissions: PropTypes.array,
};

export default CollectionGroupList;
