import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BooleanField, Datagrid, List, sanitizeListRestProps, TextField, TopToolbar, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import { CollectionNotPaymentReasonFormDialog } from '../../components';
import { noop } from '../../utils';

const CollectionNoPaymentReasonActions = ({ className, onOpenCreateModal, ...rest }) => {
  const handleClick = e => {
    e.preventDefault();

    onOpenCreateModal();
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button color="primary" size="small" type="button" onClick={handleClick} startIcon={<IconAdd />}>
        Create
      </Button>
    </TopToolbar>
  );
};

CollectionNoPaymentReasonActions.propTypes = {
  className: PropTypes.string,
  onOpenCreateModal: PropTypes.func,
};

CollectionNoPaymentReasonActions.defaultProps = {
  onOpenCreateModal: noop,
};

const CollectionNoPaymentReasonList = props => {
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const refresh = useRefresh();

  const handleOpenCreateModal = () => {
    setIsCreateModalOpened(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpened(false);
  };

  const handleSubmitCreateModal = () => {
    refresh();
    setIsCreateModalOpened(false);
  };

  return (
    <>
      <List
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        actions={<CollectionNoPaymentReasonActions onOpenCreateModal={handleOpenCreateModal} />}
        {...props}>
        <Datagrid rowClick="edit">
          <TextField sortable={false} source="id" />
          <BooleanField sortable={false} source="enabled" />
          <TextField sortable={false} source="name" />
        </Datagrid>
      </List>

      <CollectionNotPaymentReasonFormDialog
        isOpened={isCreateModalOpened}
        onClose={handleCloseCreateModal}
        onSubmit={handleSubmitCreateModal}
      />
    </>
  );
};

export default CollectionNoPaymentReasonList;
