import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useStyles } from '../styles';
import { DraggableDialog } from '../../../components';

const RestartAdeDialog = ({ isOpened, onClose, onSubmit, title, text }) => {
  const classes = useStyles();

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit()} color="primary">
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

RestartAdeDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default RestartAdeDialog;
