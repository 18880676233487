import React from 'react';
import { Datagrid, FunctionField, List, TextField, NumberInput, usePermissions } from 'react-admin';
import { formatPhoneNumber, formatDatetime, snakeToHuman } from '../../utils';
import { Pagination, DateFilterInput } from '../../components';
import { ListActions } from '../user/UserList';
import ListFilter from './components/ListFilter';

const ArchiveClientsListFilter = props => {
  return (
    <ListFilter {...props}>
      <NumberInput label="ID" source="id" alwaysOn />
      <NumberInput label="Duplicate ID" source="duplicate_id" />
      <NumberInput label="Matched user ID" source="matched_user->id" />
      <DateFilterInput label="Duplicate registered before" source="duplicate_registered_at|before" before />
      <DateFilterInput label="Duplicate registered after" source="duplicate_registered_at|after" after />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
    </ListFilter>
  );
};

const ArchiveClientsList = props => {
  const { permissions = [] } = usePermissions();
  const showFullPhone = !permissions.includes('CAN_SEE_FULL_PHONE');

  return (
    <List
      title="Archive Clients"
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ArchiveClientsListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick={null}>
        <TextField source="id" />
        <FunctionField
          label="Phone number"
          source="phone_number"
          render={({ phone_number }) => formatPhoneNumber(phone_number, showFullPhone)}
          sortable={false}
        />
        <FunctionField
          label="Date of duplicate registration"
          source="duplicate_registered_at"
          render={({ duplicate_registered_at }) => formatDatetime(duplicate_registered_at)}
        />
        <FunctionField
          label="Archiving date"
          source="created_at"
          render={({ created_at }) => formatDatetime(created_at)}
        />
        <TextField label="Archiving author" source="created_by_name" sortable={false} />
        <FunctionField
          label="Reason for archiving"
          source="archive_reason"
          render={({ archive_reason }) => snakeToHuman(archive_reason)}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default ArchiveClientsList;
