import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, required, maxLength, minLength } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { phoneNumber } from '../../utils';

const FormWrapper = ({ ...props }) => {
  return (
    <SimpleForm {...props}>
      <Grid container fullWidth spacing={2}>
        <Grid item xs={12} md={3}>
          <NumberInput fullWidth source="user_id" label="User id" disabled />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="curp"
            validate={[
              required('This field is required'),
              maxLength(18, 'CURP length should be 18 symbols'),
              minLength(18, 'CURP length should be 18 symbols'),
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput fullWidth source="first_name" validate={required('This field is required')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput fullWidth source="last_name" validate={required('This field is required')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="phone_number"
            placeholder={phoneNumber.placeholder}
            parse={phoneNumber.parser}
            format={v => phoneNumber.formatter(v)}
            validate={required('This field is required')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput fullWidth source="email" validate={required('This field is required')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="contact_phone_number"
            parse={phoneNumber.parser}
            format={v => phoneNumber.formatter(v)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput fullWidth source="created_at" label="Date" disabled />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
            source="comment"
            validate={required('This field is required')}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const BlackListCreate = props => {
  return (
    <Create {...props}>
      <FormWrapper />
    </Create>
  );
};

export default BlackListCreate;
