import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, Loading } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { formatDatetime } from '../../utils';

export const CallCenterLogTable = ({ filter, refreshedAt, isDialog }) => {
  const dataProvider = useDataProvider();
  const [logData, setLogData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const notify = useNotify();

  useEffect(() => {
    filter &&
      dataProvider
        .getList('phone_calls', {
          filter,
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data, total }) => {
          setLogData(data);
          setTotal(total);
        })
        .catch(error => notify(`Error: ${error.message || 'log not found'}`, 'error'))
        .finally(() => setLoading(false));
    return () => {
      setLogData([]);
    };
  }, [dataProvider, notify, perPage, filter, refreshedAt, page]);

  if (loading && !isDialog) return <Loading />;

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>User id</TableCell>
            <TableCell>Admin id</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Updated at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logData.map((item, key) => {
            return (
              <TableRow key={key + '_log'}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.user_id}</TableCell>
                <TableCell>{item.admin_id}</TableCell>
                <TableCell>
                  <Chip label={item.result} size="small" />
                </TableCell>
                <TableCell>{item.admin_username}</TableCell>
                <TableCell>{formatDatetime(item.created_at)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </>
  );
};

CallCenterLogTable.propTypes = {
  filter: PropTypes.object,
  refreshedAt: PropTypes.string,
  isDialog: PropTypes.bool,
};
