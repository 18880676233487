import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { refreshView } from 'ra-core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export const RefreshButton = ({ icon = defaultIcon, onClick, getUserName, setUser, ...rest }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(
    event => {
      event.preventDefault();
      dispatch(refreshView());
      setUser(getUserName());
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [dispatch, getUserName, onClick, setUser],
  );

  return (
    <Tooltip title="Refresh">
      <IconButton onClick={handleClick} {...rest}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

const defaultIcon = <NavigationRefresh />;

RefreshButton.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func,
  getUserName: PropTypes.func,
  setUser: PropTypes.func,
};
