import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { timezone } from '../../../utils';

const ReportDate = ({
  classes,
  value,
  touched,
  error,
  onChange,
  startLabel = 'Start date',
  endLabel = 'End date',
  required = true,
  notice = null,
}) => {
  const [time, setTime] = useState(false);

  const format = time ? 'MMMM dd, yyyy hh:mm' : 'MMMM dd, yyyy';

  return (
    <>
      <Grid item xs={12} sm={6}>
        {!time ? (
          <DatePicker
            label={startLabel}
            className={classes.fullWidth}
            value={value?.start_date ?? null}
            onChange={v => {
              onChange({ ...(value || {}), start_date: timezone.setTimeWithoutOffset(v, 0, 0, 0) });
            }}
            required={required}
            error={!!(touched && error && error.start_date)}
            helperText={touched && error && error.start_date}
            format={format}
          />
        ) : (
          <DateTimePicker
            label={startLabel}
            className={classes.fullWidth}
            value={value?.start_date ?? null}
            onChange={v => {
              onChange({ ...(value || {}), start_date: v });
            }}
            required={required}
            error={!!(touched && error && error.start_date)}
            helperText={touched && error && error.start_date}
            format={format}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {!time ? (
          <DatePicker
            label={endLabel}
            className={classes.fullWidth}
            value={value?.end_date ?? null}
            onChange={v => {
              onChange({ ...(value || {}), end_date: timezone.setTimeWithoutOffset(v, 23, 59, 59) });
            }}
            required={required}
            error={!!(touched && error && error.end_date)}
            helperText={touched && error && error.end_date}
            format={format}
          />
        ) : (
          <DateTimePicker
            label={endLabel}
            className={classes.fullWidth}
            value={value?.end_date ?? null}
            onChange={v => {
              onChange({ ...(value || {}), end_date: v });
            }}
            required={required}
            error={!!(touched && error && error.end_date)}
            helperText={touched && error && error.end_date}
            format={format}
          />
        )}
      </Grid>
      {notice && (
        <Grid item xs={12} className={classes.py0}>
          <Typography variant="caption" color="textSecondary">
            {notice}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} className={classes.pt0}>
        <FormControlLabel
          label="Show dates with time"
          control={<Checkbox checked={time} onChange={() => setTime(state => !state)} />}
        />
      </Grid>
    </>
  );
};

ReportDate.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  touched: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  required: PropTypes.bool,
  notice: PropTypes.func,
};

export default ReportDate;
