import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from '../../pages/application/styles';
import { DraggableDialog } from './DraggableDialog';
import { useRejectionReasons } from '../../hooks';

export const RejectTransitionDialog = ({ isOpened, onClose, onSubmit, title, text }) => {
  const classes = useStyles();
  const [rejectionReasonCode, setRejectionReasonCode] = useState();

  const rejectionReasons = useRejectionReasons();
  const enabledRejectionReasons = rejectionReasons.filter(reason => reason.is_enabled);

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <Select
          id="rejection-reason-select"
          value={rejectionReasonCode || ''}
          onChange={e => setRejectionReasonCode(e.target.value)}
          className={classes.fullWidth}>
          {enabledRejectionReasons.map(({ code, name }) => (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(rejectionReasonCode)} color="primary" disabled={!rejectionReasonCode}>
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

RejectTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
};
