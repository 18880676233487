import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import {
  TabPanel,
  NotificationTable,
  NoteTable,
  AgreementTable,
  UserContactsTable,
  ApplicationTable,
  LoanTable,
  ChangeHistoryTable,
  SessionsTable,
  SelfieMatchesTable,
  IdCardMatchesTable,
  Kyc,
  UserApiData,
  Ocr,
  CallCenterLogTable,
  FacebookTable,
  CollectionCallTable,
  ShortLinkTable,
  ReportTable,
  AllCallsTable,
  WazzupMessagesTable,
  ReferralProgramTable,
  MailTable,
  BankAccountsTable,
  DirectDebitTable,
  AccountMessageTable,
  RelatedProjectApplicationTable,
  SmsTable,
  AddSmsDialog,
  RelatedProjectLoanTable,
  AddOcrRequestDialog,
  AddNotificationDialog,
  AddUserContactsDialog,
  AddNoteDialog,
  CollectionCallFormDialog,
  AddUserApiDataDialog,
  AddAccountMessageDialog,
} from '../../../components';
import AddEmailDialog from '../../mail/AddEmailDialog';
import TableActionButtonGroup from './TableActionButtonGroup';
import { KEY_OPENED_ACTION_MODAL } from '../../../constants';

const UserEditAdditionalInfo = ({ record }) => {
  const [refreshedAt, setRefreshedAt] = useState(null);
  const [isNotificationDialogOpened, setIsNotificationDialogOpened] = useState(false);
  const [isSMSDialogOpened, setIsSMSDialogOpened] = useState(false);
  const [isEmailDialogOpened, setIsEmailDialogOpened] = useState(false);
  const [isCollectionCallDialogOpened, setIsCollectionCallDialogOpened] = useState(false);
  const [isNoteDialogOpened, setIsNoteDialogOpened] = useState(false);
  const [isOcrRequestDialogOpened, setIsOcrRequestDialogOpened] = useState(false);
  const [isUserContactsDialogOpened, setIsUserContactsDialogOpened] = useState(false);
  const [isUserApiDataDialogOpened, setIsUserApiDataDialogOpened] = useState(false);
  const [isAccountMessageDialogOpened, setIsAccountMessageDialogOpened] = useState(false);
  const [selfieMatches, setSelfieMatches] = useState(0);
  const [idCardMatches, setIdCardMatches] = useState(0);
  const [clickToCallPhone, setClickToCallPhone] = useState(null);
  const [isAutomaticCall, setIsAutomaticCall] = useState(false);
  const [counters, setCounters] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const hasDNDTag = record.tags.length > 0 && record.tags.find(tag => tag.name.includes('not disturb'));
  const loans = counters?.loans ?? 0;

  const canMatchesView = permissions.includes('CAN_MATCHES_VIEW');
  const canEmailsView = permissions.includes('CAN_EMAIL_VIEW');
  const canSmsView = permissions.includes('CAN_SMS_VIEW');
  const canPhoneBookView = permissions.includes('CAN_PHONE_BOOK_VIEW');
  const canPhoneBookEdit = permissions.includes('CAN_PHONE_BOOK_EDIT');
  const canUserView = permissions.includes('CAN_USER_VIEW');
  const canOcrReqView = permissions.includes('CAN_OCR_REQUEST_VIEW');
  const canOcrReqEdit = permissions.includes('CAN_OCR_REQUEST_EDIT');
  const canNotificationsEdit = permissions.includes('CAN_NOTIFICATION_EDIT');
  const canCollectionCallView = permissions.includes('CAN_COLLECTION_CALL_VIEW');
  const canCollectionCallsEdit = permissions.includes('CAN_COLLECTION_CALL_EDIT');
  const canNoteView = permissions.includes('CAN_NOTE_VIEW');
  const canNotesEdit = permissions.includes('CAN_NOTE_EDIT');
  const canUserApiDataView = permissions.includes('CAN_USER_API_DATA_VIEW');
  const canUserApiDataEdit = permissions.includes('CAN_USER_API_DATA_EDIT');
  const canAccountMessagesView = permissions.includes('CAN_ACCOUNT_MESSAGES_VIEW');
  const canAccountMessagesEdit = permissions.includes('CAN_ACCOUNT_MESSAGES_EDIT');
  const canNotificationView = permissions.includes('CAN_NOTIFICATION_VIEW');
  const canWhatsAppMessagesView = permissions.includes('CAN_WAZZUP_MESSAGES_VIEW');
  const canAgreementView = permissions.includes('CAN_AGREEMENT_VIEW');
  const canApplicationView = permissions.includes('CAN_APPLICATION_VIEW');
  const canRelatedProjectAppsView = permissions.includes('CAN_RELATED_PROJECT_APPLICATIONS_VIEW');
  const canRelatedProjectLoansView = permissions.includes('CAN_RELATED_PROJECT_LOANS_VIEW');
  const canLoanView = permissions.includes('CAN_LOAN_VIEW');
  const canDebitsView = permissions.includes('CAN_DEBITS_VIEW');
  const canKycReqView = permissions.includes('CAN_KYC_REQUEST_VIEW');
  const canCreditBureauReportView = permissions.includes('CAN_CREDIT_BUREAU_REPORT_VIEW');
  const canPhoneCallView = permissions.includes('CAN_PHONE_CALL_VIEW');
  const canShortLinkLogView = permissions.includes('CAN_SHORT_LINK_LOG_VIEW');
  const canWebitelCallsView = permissions.includes('CAN_WEBITEL_CALLS_VIEW');

  const showWarningBlock = () =>
    hasDNDTag ? (
      <Alert variant="filled" severity="error">
        <Typography>Note that the client has the DO NOT DISTURB tag!</Typography>
      </Alert>
    ) : null;

  useEffect(() => {
    if (record.id) {
      dataProvider
        .query(`users/${record.id}/relation_counters`, { method: 'GET' })
        .then(({ data }) => setCounters(data))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get(KEY_OPENED_ACTION_MODAL) === '1') {
      setIsCollectionCallDialogOpened(true);
      setIsAutomaticCall(true);
      queryParams.delete(KEY_OPENED_ACTION_MODAL);
      history.replace({ search: queryParams.toString() });
    }
  }, [location, history]);

  useEffect(() => {
    if (canMatchesView) {
      dataProvider
        .getList('aws_rekognition_matches', {
          pagination: {},
          sort: {},
          filter: { user: record.id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setSelfieMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });

      dataProvider
        .getList('aws_rekognition_id_card_matches', {
          pagination: {},
          sort: {},
          filter: { user: record.id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setIdCardMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    }
  }, [canMatchesView, dataProvider, notify, record.id]);

  const handleRefresh = () => setRefreshedAt(Date.now());

  const applyNotificationTransition = (notificationId, name, params = {}) => {
    dataProvider
      .query(`notifications/${notificationId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(handleRefresh)
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyAgreementTransition = (agreementId, name, params = {}) => {
    dataProvider
      .query(`agreements/${agreementId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(handleRefresh)
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const handleCloseCollectionCallDialog = () => {
    setIsCollectionCallDialogOpened(false);
    setIsAutomaticCall(false);
    setClickToCallPhone(null);
  };

  const handleSubmitCollectionCallDialog = () => {
    setRefreshedAt(Date.now());
    setIsCollectionCallDialogOpened(false);
    setClickToCallPhone(null);
    setIsAutomaticCall(false);
  };

  const onCallHandler = phone => {
    setIsCollectionCallDialogOpened(true);
    setClickToCallPhone(phone);
  };

  const getApplicationsHistory = () => {
    dataProvider
      .query(`related_project_data?user_id=${record.id}`, { method: 'GET' })
      .then(() => {
        notify('History requested successfully', 'success');
        setTimeout(() => {
          setRefreshedAt(Date.now());
        }, 1000);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const getLoansHistory = () => {
    dataProvider
      .query(`related_project_loans_data?user_id=${record.id}`, { method: 'GET' })
      .then(() => {
        notify('History requested successfully', 'success');
        setTimeout(() => {
          setRefreshedAt(Date.now());
        }, 1000);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const handleAddOcrRequest = object => {
    setIsOcrRequestDialogOpened(false);
    dataProvider
      .create('ocr_requests', { data: { user: `/api/users/${record.id}`, ...object } })
      .then(handleRefresh)
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const handleAddNotification = (transmitterId, destination, templateId, message, subject, email_source) => {
    dataProvider
      .query(`users/${record.id}/notifications`, {
        body: JSON.stringify({
          transmitter_id: transmitterId,
          destination,
          template_id: templateId,
          message,
          subject,
          channel: email_source,
        }),
      })
      .then(() => {
        setIsNotificationDialogOpened(false);
        setRefreshedAt(Date.now());
      })
      .catch(error => {
        setIsNotificationDialogOpened(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const handleAddSms = (destination, transmitterName, template_id, message) => {
    dataProvider
      .query(`users/${record.id}/notifications`, {
        body: JSON.stringify({
          transmitter_id: transmitterName,
          destination,
          template_id,
          message,
        }),
      })
      .then(() => {
        setIsSMSDialogOpened(false);
        setRefreshedAt(Date.now());
      })
      .catch(error => {
        setIsSMSDialogOpened(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const handleAddEmail = (emailsList, templateId, message, subject, communication_category, email_source, fileList) => {
    dataProvider
      .query(`communication/email`, {
        method: 'POST',
        body: JSON.stringify({
          parentId: null,
          applicationId: null,
          loanId: null,
          userId: record.id,
          templateId,
          content: message ?? '',
          subject,
          categoryId: communication_category,
          fromEmail: email_source,
          toEmails: emailsList,
          files: fileList,
        }),
      })
      .then(() => {
        setIsEmailDialogOpened(false);
        setRefreshedAt(Date.now());
      })
      .catch(error => {
        setIsEmailDialogOpened(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const handleAddUserContact = (phone_number, name, type, note, relation, source, status, validation_state) => {
    setIsUserContactsDialogOpened(false);
    dataProvider
      .create('phone_books', {
        data: {
          user: record.id,
          phone_number,
          name,
          type,
          note: note?.trim() || null,
          relation,
          source,
          status: status || 'active',
          validation_state,
        },
      })
      .then(handleRefresh)
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const handleAddNote = (label, message) => {
    setIsNoteDialogOpened(false);
    dataProvider
      .create('notes', { data: { user_id: record.id, label, message } })
      .then(handleRefresh)
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <TabPanel
        labels={[
          '✖',
          `Notifications|${counters?.notifications ?? 0}`,
          `Mails|${counters?.mails ?? 0}`,
          `SMS|${counters?.sms ?? 0}`,
          `WhatsApp messages|${counters?.whatsapp_messages ?? 0}`,
          `Account messages|${counters?.account_messages ?? 0}`,
          `Actions|${counters?.actions ?? 0}`,
          `Notes|${counters?.notes ?? 0}`,
          `Agreements|${counters?.agreements ?? 0}`,
          `Contacts|${counters?.contacts ?? 0}`,
          `Applications|${counters?.applications ?? 0}`,
          `Other project applications|${counters?.other_project_applications ?? 0}`,
          `Loans|${counters?.loans ?? 0}`,
          `Other project loans|${counters?.other_project_loans ?? 0}`,
          `Direct debits|${counters?.direct_debits ?? 0}`,
          `Referrals|${counters?.referrals ?? 0}`,
          'Changes',
          `Sessions|${counters?.sessions ?? 0}`,
          `Bank accounts|${counters?.bank_accounts ?? 0}`,
          `Selfie matches|${selfieMatches}`,
          `Id card matches|${idCardMatches}`,
          `KYC|${counters?.kyc_requests ?? 0}`,
          `Api data|${counters?.user_api_data ?? 0}`,
          `Credit bureau|${counters?.credit_bureaus ?? 0}`,
          `OCR|${counters?.ocr_requests ?? 0}`,
          `Call center|${counters?.call_center ?? 0}`,
          'Facebook',
          `Short links|${counters?.short_links ?? 0}`,
          `All calls|${counters?.all_calls ?? 0}`,
        ]}
        activeTabIndex={queryParams.get(KEY_OPENED_ACTION_MODAL) === '1' ? 6 : null}>
        <></>
        {canNotificationView && (
          <Fragment key="notifications">
            <NotificationTable
              userId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyNotificationTransition}
              inactive={record.has_sold_loans}
            />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canNotificationsEdit && (
                <Button disabled={record.has_sold_loans} onClick={() => setIsNotificationDialogOpened(true)}>
                  Add
                </Button>
              )}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canEmailsView && (
          <Fragment key="mails">
            <MailTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canNotificationsEdit && <Button onClick={() => setIsEmailDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canSmsView && (
          <Fragment key="sms">
            <SmsTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canNotificationsEdit && <Button onClick={() => setIsSMSDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canWhatsAppMessagesView && (
          <Fragment key="wazzup_messages">
            <WazzupMessagesTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canAccountMessagesView && (
          <Fragment key="account_messages">
            <AccountMessageTable userId={record.id} refreshedAt={refreshedAt} onRefresh={handleRefresh} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canAccountMessagesEdit && <Button onClick={() => setIsAccountMessageDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canCollectionCallView && (
          <Fragment key="calls">
            {showWarningBlock()}
            <CollectionCallTable userId={record.id} refreshedAt={refreshedAt} permissions={permissions} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canCollectionCallsEdit && canPhoneBookView && (
                <Button
                  onClick={() => setIsCollectionCallDialogOpened(true)}
                  disabled={loans === 0 || !record.active_loan_collection_group_id}>
                  Add
                  {loans === 0 ? ' (no loans)' : ''}
                </Button>
              )}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canNoteView && (
          <Fragment key="notes">
            <NoteTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canNotesEdit && <Button onClick={() => setIsNoteDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canAgreementView && (
          <Fragment key="agreements">
            <AgreementTable userId={record.id} refreshedAt={refreshedAt} onTransition={applyAgreementTransition} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canPhoneBookView && (
          <Fragment key="contacts">
            {showWarningBlock()}
            <UserContactsTable
              userId={record.id}
              refreshedAt={refreshedAt}
              setRefreshedAt={setRefreshedAt}
              onCall={onCallHandler}
              hasSoldLoans={record.has_sold_loans}
            />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canPhoneBookEdit && <Button onClick={() => setIsUserContactsDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canApplicationView && (
          <Fragment key="applications">
            <ApplicationTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canRelatedProjectAppsView && (
          <Fragment key="related_project_applications">
            <RelatedProjectApplicationTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={getApplicationsHistory} refreshBtnName="GET HISTORY AVENTUS" />
          </Fragment>
        )}
        {canLoanView && (
          <Fragment key="loans">
            <LoanTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canRelatedProjectLoansView && (
          <Fragment key="related_project_loans">
            <RelatedProjectLoanTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={getLoansHistory} refreshBtnName="GET HISTORY AVENTUS" />
          </Fragment>
        )}
        {canDebitsView && (
          <Fragment key="direct_debits">
            <DirectDebitTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canUserView ? (
          <Fragment key="referral">
            <ReferralProgramTable record={record} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        ) : null}
        {canUserView ? (
          <Fragment key="changes">
            <ChangeHistoryTable
              entityId={record.id}
              entityField="user"
              refreshedAt={refreshedAt}
              endpoint="user_change_histories"
            />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        ) : null}
        {canUserView ? (
          <Fragment key="sessions">
            <SessionsTable userId={record.id} refreshedAt={refreshedAt} returnSimilar={false} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        ) : null}
        {canUserView ? (
          <Fragment key="bank_accounts">
            <BankAccountsTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        ) : null}
        {canMatchesView ? (
          <Fragment key="selfie_matches">
            <SelfieMatchesTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        ) : null}
        {canMatchesView ? (
          <Fragment key="id_card_matches">
            <IdCardMatchesTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        ) : null}
        {canKycReqView && (
          <Fragment key="kyc">
            <Kyc userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canUserApiDataView && (
          <Fragment key="UserApiData">
            <UserApiData userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canUserApiDataEdit && <Button onClick={() => setIsUserApiDataDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canCreditBureauReportView && (
          <Fragment key="UserApiData">
            <ReportTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canOcrReqView && (
          <Fragment key="ocr">
            <Ocr userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh}>
              {canOcrReqEdit && <Button onClick={() => setIsOcrRequestDialogOpened(true)}>Add</Button>}
            </TableActionButtonGroup>
          </Fragment>
        )}
        {canPhoneCallView && (
          <Fragment key="call_center_log">
            <CallCenterLogTable filter={{ 'user.id': record.id }} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canUserView && (
          <Fragment key="facebook">
            <FacebookTable record={record} />
          </Fragment>
        )}
        {canShortLinkLogView && (
          <Fragment key="short_links">
            <ShortLinkTable userId={record.id} refreshedAt={refreshedAt} returnSimilar={false} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
        {canWebitelCallsView && (
          <Fragment key="all_calls">
            <AllCallsTable userId={record.id} refreshedAt={refreshedAt} />
            <TableActionButtonGroup onRefresh={handleRefresh} />
          </Fragment>
        )}
      </TabPanel>

      {canOcrReqView && (
        <AddOcrRequestDialog
          isOpened={isOcrRequestDialogOpened}
          onClose={() => setIsOcrRequestDialogOpened(false)}
          onSubmit={handleAddOcrRequest}
        />
      )}
      {canNotificationsEdit && isNotificationDialogOpened && (
        <AddNotificationDialog
          onClose={() => setIsNotificationDialogOpened(false)}
          onSubmit={handleAddNotification}
          defaultPhoneNumber={record.phone_number}
          defaultEmailAddress={record.email}
        />
      )}
      {canNotificationsEdit && isSMSDialogOpened && (
        <AddSmsDialog
          onClose={() => setIsSMSDialogOpened(false)}
          onSubmit={handleAddSms}
          defaultPhoneNumber={record.phone_number}
        />
      )}
      {canNotificationsEdit && isEmailDialogOpened && (
        <AddEmailDialog
          onClose={() => setIsEmailDialogOpened(false)}
          onSubmit={handleAddEmail}
          defaultEmailAddress={record.email}
        />
      )}
      {canCollectionCallsEdit && canPhoneBookView && isCollectionCallDialogOpened && (
        <CollectionCallFormDialog
          userId={record.id}
          isOpened={isCollectionCallDialogOpened}
          onClose={handleCloseCollectionCallDialog}
          onSubmit={handleSubmitCollectionCallDialog}
          clickToCallPhone={clickToCallPhone}
          isAutomaticCall={isAutomaticCall}
        />
      )}
      {canPhoneBookEdit && isUserContactsDialogOpened && (
        <AddUserContactsDialog
          isOpened={isUserContactsDialogOpened}
          onClose={() => setIsUserContactsDialogOpened(false)}
          onSubmit={handleAddUserContact}
          defaultPhoneNumber={record.phone_number}
        />
      )}
      {canNotesEdit && (
        <AddNoteDialog
          isOpened={isNoteDialogOpened}
          onClose={() => setIsNoteDialogOpened(false)}
          onSubmit={handleAddNote}
        />
      )}
      {canUserApiDataEdit && (
        <AddUserApiDataDialog
          userId={record.id}
          isOpened={isUserApiDataDialogOpened}
          onClose={() => setIsUserApiDataDialogOpened(false)}
          onSubmit={() => {
            setIsUserApiDataDialogOpened(false);
            setRefreshedAt(Date.now());
          }}
        />
      )}
      {canAccountMessagesEdit && isAccountMessageDialogOpened && (
        <AddAccountMessageDialog
          userId={record.id}
          onClose={() => setIsAccountMessageDialogOpened(false)}
          onSubmit={() => {
            setIsAccountMessageDialogOpened(false);
            setRefreshedAt(Date.now());
          }}
        />
      )}
    </>
  );
};

UserEditAdditionalInfo.propTypes = {
  record: PropTypes.object,
};

export default UserEditAdditionalInfo;
