import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import customProvider from '../../dataProvider';
import { timezone } from '../../utils';

export const JuicyScore = ({ applicationId, refreshedAt }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(true);
  const [juicy, setJuicy] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);

  const downloadJuicy = applicationId => {
    setDisabledButton(true);
    customProvider
      .downloadFile(`applications/${applicationId}/juicy_score_log`)
      .then(({ data: objUrl }) => {
        const link = document.createElement('a');
        link.href = objUrl;
        const date = timezone.shift(new Date());
        link.download = `juicy_score_${date}.txt`;
        link.click();
      })
      .catch(error => notify('Warning: '.concat(error.message || 'File not found.'), 'warning'))
      .then(() => setDisabledButton(false));
  };
  const objectTransform = (juicy, result = [], depth = 1) => {
    Object.keys(juicy).forEach(item => {
      if (typeof juicy[item] === 'object' && !Array.isArray(juicy[item])) {
        result.push({ key: item, value: null, depth });
        objectTransform(juicy[item], result, (depth += 1));
      } else {
        if (!Array.isArray(juicy[item])) {
          result.push({ key: item, value: juicy[item], depth });
        } else {
          result.push({ key: item, value: '[...]', depth });
        }
      }
    });
    return result;
  };

  useEffect(() => {
    customProvider
      .query(`applications/${applicationId}/juicy_score_data`, {})
      .then(({ data }) => {
        setJuicy(data);
        setLoading(false);
      })
      .catch(() => {
        notify('Warning: juicy score not found', 'warning');
        setLoading(false);
      });
  }, [applicationId, notify, refreshedAt]);

  if (loading) return <Loading />;

  return (
    <Box>
      <Table size="small">
        <TableBody>
          {objectTransform(juicy).map(i => {
            return (
              <TableRow key={i.key}>
                <TableCell align="left" style={{ paddingLeft: `${i.depth * 16}px` }}>
                  {i.key}
                </TableCell>
                <TableCell align="right">{i.value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box textAlign="right" pt={2} p={1}>
        <ButtonGroup variant="contained" color="primary" size="small">
          <Button disabled={disabledButton} onClick={() => downloadJuicy(applicationId)}>
            Download log
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
JuicyScore.defaultProps = {
  applicationId: 0,
  refreshedAt: undefined,
};
JuicyScore.propTypes = {
  applicationId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};
