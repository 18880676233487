import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, TextInput, usePermissions } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import UserTags from './UserTags';
import { useStyles } from '../styles';
import { useHandbook } from '../../../hooks';
import { RESIDENCE_DURATION } from '../../../constants';
import { formatChoices } from '../../../utils';

const UserResidence = ({ record }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const { choices: employmentTypeChoices } = useHandbook('employments');
  const { choices: residentTypeChoices } = useHandbook('resident_types');

  const isEditable = permissions.includes('CAN_USER_EDIT');
  const canTagsView = permissions.includes('CAN_TAGS_VIEW');
  const canTagsEdit = permissions.includes('CAN_TAGS_EDIT');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Residence
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput disabled label="Country" source="residence_country" fullWidth helperText={false} />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled={!isEditable}
                label="Postal code"
                source="residence_postal_code"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput disabled={!isEditable} label="City" source="residence_city" fullWidth helperText={false} />
            </Grid>
            <Grid item xs={6}>
              <TextInput disabled={!isEditable} label="Region" source="residence_region" fullWidth helperText={false} />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled={!isEditable}
                label="Municipality"
                source="residence_municipality"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput disabled={!isEditable} label="Colony" source="residence_colony" fullWidth helperText={false} />
            </Grid>
            <Grid item xs={6}>
              <TextInput disabled={!isEditable} label="Street" source="residence_street" fullWidth helperText={false} />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                disabled={!isEditable}
                label="External h."
                source="residence_external_house"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                disabled={!isEditable}
                label="Internal h."
                source="residence_internal_house"
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput disabled={!isEditable} label="Flat" source="residence_flat" fullWidth helperText={false} />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                disabled={!isEditable}
                label="Residence time"
                source="residence_duration"
                fullWidth
                helperText={false}
                choices={formatChoices(RESIDENCE_DURATION)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                disabled={!isEditable}
                label="Residence type"
                source="residence_type"
                fullWidth
                helperText={false}
                choices={residentTypeChoices}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Employment
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectInput
                key={`employment-type-input-${employmentTypeChoices.length}`}
                disabled={!isEditable}
                label="Employment type"
                source="employment_type"
                fullWidth
                helperText={false}
                choices={employmentTypeChoices}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            STP
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                key={'stp-account-number-input'}
                disabled
                label="Incoming transactions account number"
                source="stp_number"
                fullWidth
                helperText={false}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {canTagsView && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom={false}>
              User tags
            </Typography>
            <Divider />
            <UserTags userId={record.id} tags={record.tags} editable={canTagsEdit} />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

UserResidence.propTypes = {
  record: PropTypes.object,
};

export default UserResidence;
