import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDataProvider, useNotify } from 'react-admin';
import { debounce, isEqual } from 'lodash';

const titleCase = s => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d));

export const AutocompleteWithoutForm = ({
  resource,
  value,
  defaultValue = null,
  onChange,
  sort,
  page = 1,
  perPage = 20,
  filter = null,
  label,
  variant = 'filled',
  size = 'small',
  optionValueProp = 'key',
  optionLabelProp = 'name',
  margin = 'dense',
  helperText = '',
  onFieldChange = () => {},
  multiple = false,
  withCreateNew = false,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueObject, setValueObject] = React.useState(multiple ? [] : null);
  const [defaultValueObject, setDefaultValueObject] = React.useState(multiple ? [] : null);
  const [defaultOptions, setDefaultOptions] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newItem, setNewItem] = React.useState('');
  const [noResults, setNoResults] = React.useState(false);
  const [isPreselected, setIsPreselected] = React.useState(true);
  const [prevFilter, setPrevFilter] = React.useState(filter);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleAddNewItem = () => {
    dataProvider
      .create(resource, { data: { [optionLabelProp]: newItem } })
      .then(({ data }) => {
        if (multiple) {
          onChange([...(value || []), data[optionValueProp]]);
          setValueObject([...(valueObject || []), data]);
        } else {
          onChange(data[optionValueProp]);
          setValueObject(data);
        }
        setOptions([...options, data]);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setOpenDialog(false);
        setNewItem('');
      });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewItem('');
  };

  const handleChange = (event, newValue, reason) => {
    if (reason === 'select-option' && newValue === `Create new item '${inputValue}'`) {
      setOpenDialog(true);
      setNewItem(inputValue);
      return;
    }

    onFieldChange(event, newValue);

    if (
      reason === 'clear' ||
      newValue === null ||
      newValue === undefined ||
      (Array.isArray(newValue) && newValue.length === 0)
    ) {
      setOptions(defaultOptions);
      setInputValue('');
      if (defaultValue) {
        event.preventDefault();
        onChange(multiple ? defaultValueObject.map(item => item.id) : defaultValueObject[optionValueProp]);
        setValueObject(defaultValueObject);
      } else {
        onChange(null);
        setValueObject(multiple ? [] : null);
      }
    } else if (reason === 'remove-option' && Array.isArray(newValue) && newValue.length === 0) {
      event.preventDefault();
      setValueObject(defaultValueObject);
    } else {
      if (multiple) {
        const selectedValues = newValue.map(item => item[optionValueProp]);
        onChange(selectedValues);
        setValueObject(newValue);
      } else {
        onChange(newValue[optionValueProp]);
        setValueObject(newValue);
      }
      setInputValue('');
    }
  };

  const fetchOptions = async input => {
    const optionsQuery = {
      pagination: { page, perPage },
      sort: sort ?? { field: 'id', order: 'DESC' },
      filter: { ...filter, [optionLabelProp]: input },
    };
    setLoading(true);
    try {
      const { data } = await dataProvider.getList(resource, optionsQuery);
      const fetchedOptions = data.map(item => ({
        ...item,
        [optionLabelProp]: item[optionLabelProp],
      }));
      setOptions(fetchedOptions);
      setNoResults(fetchedOptions.length === 0);
    } catch ({ message }) {
      notify(`Error: ${message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchOptions = React.useMemo(() => debounce(fetchOptions, 1000), [filter]);

  React.useEffect(() => {
    if (inputValue.length >= 2) {
      debouncedFetchOptions(inputValue);
    }
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [inputValue, debouncedFetchOptions]);

  React.useEffect(() => {
    const options = {
      pagination: { page: 1, perPage: 20 },
      sort: sort ?? { field: 'id', order: 'DESC' },
      filter: filter ?? {},
    };
    dataProvider.getList(resource, options).then(({ data }) => {
      const options = data.map(item => ({
        ...item,
        [optionLabelProp]: item[optionLabelProp],
      }));
      setDefaultOptions(options);
      setOptions(options);
    });
    if (defaultValue && isPreselected) {
      const optionsQuery = {
        pagination: { page, perPage },
        sort: sort ?? { field: 'id', order: 'DESC' },
        filter: { ...filter, id: defaultValue },
      };
      dataProvider
        .getList(resource, optionsQuery)
        .then(({ data }) => {
          const preselectedValues = data.map(item => ({
            ...item,
            [optionLabelProp]: item[optionLabelProp],
          }));
          setValueObject(preselectedValues);
          setDefaultValueObject(preselectedValues);
        })
        .catch(error => notify(`Error: ${error.message}`, 'error'))
        .finally(() => setIsPreselected(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isEqual(filter, prevFilter)) {
      const optionsQuery = {
        pagination: { page: 1, perPage: 20 },
        sort: sort ?? { field: 'id', order: 'DESC' },
        filter: filter ?? {},
      };
      dataProvider.getList(resource, optionsQuery).then(({ data }) => {
        const options = data.map(item => ({
          ...item,
          [optionLabelProp]: item[optionLabelProp],
        }));
        setDefaultOptions(options);
        setOptions(options);
        setPrevFilter(filter);
      });
    }
  }, [filter, prevFilter, dataProvider, resource, sort, optionLabelProp]);

  React.useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  React.useEffect(() => {
    if (Array.isArray(value) ? value.length > 0 : value !== null) {
      const optionsQuery = {
        pagination: { page, perPage },
        sort: sort ?? { field: 'id', order: 'DESC' },
        filter: { ...filter, id: value },
      };
      dataProvider.getList(resource, optionsQuery).then(({ data }) => {
        const preselectedValues = data.map(item => ({
          ...item,
          [optionLabelProp]: item[optionLabelProp],
        }));
        setValueObject(preselectedValues);
      });
    } else {
      setValueObject(multiple ? [] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <MuiAutocomplete
        fullWidth
        open={open}
        multiple={multiple}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onInputChange={(_, newValue, reason) => {
          if (reason === 'input') {
            setInputValue(newValue);
          } else if (reason === 'reset') {
            setInputValue('');
            setOptions(defaultOptions);
          }
        }}
        value={multiple ? valueObject || [] : valueObject ? valueObject[optionLabelProp] : null}
        onChange={handleChange}
        options={noResults && withCreateNew ? [...options, `Create new item '${inputValue}'`] : options}
        loading={loading}
        getOptionLabel={option => (typeof option === 'string' ? option : option[optionLabelProp] || '')}
        renderInput={params => (
          <TextField
            {...params}
            margin={margin}
            label={label ?? titleCase(optionLabelProp)}
            variant={variant}
            size={size}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Create new item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="New Item Name"
            value={newItem}
            onChange={e => setNewItem(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleAddNewItem} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AutocompleteWithoutForm.propTypes = {
  resource: PropTypes.string.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  page: PropTypes.number,
  perPage: PropTypes.number,
  filter: PropTypes.object,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  optionValueProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  onFieldChange: PropTypes.func,
  multiple: PropTypes.bool,
  withCreateNew: PropTypes.bool,
};
