import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

const CheckboxListInput = ({ ...props }) => {
  const { value = [], onChange } = useInput(props).input;
  const choices = Array.isArray(props.choices) ? props.choices : [];
  const allCodes = choices.map(choice => choice.code);
  const allSelected = allCodes.every(code => value.includes(code));

  const handleChange = v => {
    if (value.indexOf(v) === -1) {
      onChange([...value, v]);
    } else {
      onChange(value.filter(item => item !== v));
    }
  };

  const handleChangeAll = () => {
    if (allSelected) {
      onChange(value.filter(item => !allCodes.includes(item)));
    } else {
      onChange([...value, ...allCodes]);
    }
  };

  return (
    <List>
      <ListItem dense button onClick={handleChangeAll}>
        <ListItemText primary="SELECT ALL" />
        <ListItemSecondaryAction>
          <Checkbox edge="end" checked={allSelected} onClick={handleChangeAll} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {choices.map((choice, idx) => (
        <ListItem key={idx} dense button onClick={() => handleChange(choice.code)}>
          <ListItemText primary={choice.label} />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={value.indexOf(choice.code) !== -1}
              onClick={() => handleChange(choice.code)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

CheckboxListInput.propTypes = {
  choices: PropTypes.array,
};

export default CheckboxListInput;
