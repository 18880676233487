import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Slider from '@material-ui/core/Slider';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '../styles';
import { DraggableDialog, SelectAutoComplete, withServer } from '../../../components';
import { formatCurrency, formatDate } from '../../../utils';
import { useProducts } from '../../../hooks';

const AutoComplete = withServer(SelectAutoComplete);

const ApproveTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const { requested_principal, requested_tenor, product_id, promo_code, is_repeat, product_code } = record;

  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [priceList, setPriceList] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [maxApprovedPrincipal, setMaxApprovedPrincipal] = useState(requested_principal);
  const [minApprovedPrincipal, setMinApprovedPrincipal] = useState('');
  const [maxApprovedTenor, setMaxApprovedTenor] = useState(requested_tenor);
  const [minApprovedTenor, setMinApprovedTenor] = useState('');
  const [displayedPrincipal, setDisplayedPrincipal] = useState(requested_principal);
  const [displayedTenor, setDisplayedTenor] = useState(requested_tenor);
  const [promoCode, setPromoCode] = useState(promo_code);

  const calculation = priceList && priceList[maxApprovedTenor] && priceList[maxApprovedTenor][maxApprovedPrincipal];
  const { data: products } = useProducts();
  const product = products.find(product => product_id === product.id);

  useEffect(() => {
    setLoading(true);
    dataProvider
      .query(
        `p/product-price-list/${product_code}?${promoCode ? `promo_code=${promoCode}&` : ''}is_repeated_loan=${is_repeat ? '1' : '0'}`,
        { method: 'GET' },
      )
      .then(({ data }) => setPriceList(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, promoCode, is_repeat, product_code]);

  const handleSubmit = () => {
    let payload = {
      max_approved_principal: maxApprovedPrincipal,
      max_approved_tenor: maxApprovedTenor,
      promo_code: promoCode,
    };
    if (minApprovedPrincipal) {
      payload = { ...payload, min_approved_principal: +minApprovedPrincipal };
    }
    if (minApprovedTenor) {
      payload = { ...payload, min_approved_tenor: +minApprovedTenor };
    }

    onSubmit(payload);
  };

  const getDisabledPromocodes = useCallback(
    option => {
      const { max_principal, min_principal, max_tenor, min_tenor, is_repeat_loan } = option.conditions;

      let matchByPrincipal = true;
      if (max_principal !== undefined || min_principal !== undefined) {
        if (minApprovedPrincipal === '') {
          if (min_principal === undefined) {
            matchByPrincipal = max_principal >= maxApprovedPrincipal;
          } else if (max_principal === undefined) {
            matchByPrincipal = maxApprovedPrincipal >= min_principal;
          } else {
            matchByPrincipal = min_principal <= maxApprovedPrincipal && maxApprovedPrincipal <= max_principal;
          }
        } else {
          if (min_principal === undefined) {
            matchByPrincipal = minApprovedPrincipal <= max_principal && max_principal >= maxApprovedPrincipal;
          } else if (max_principal === undefined) {
            matchByPrincipal = maxApprovedPrincipal >= min_principal && min_principal <= minApprovedPrincipal;
          } else {
            matchByPrincipal = max_principal >= maxApprovedPrincipal && minApprovedPrincipal >= min_principal;
          }
        }
      }

      let matchByTenor = true;
      if (max_tenor !== undefined || min_tenor !== undefined) {
        if (minApprovedTenor === '') {
          if (min_tenor === undefined) {
            matchByTenor = max_tenor >= maxApprovedTenor;
          } else if (max_tenor === undefined) {
            matchByTenor = maxApprovedTenor >= min_tenor;
          } else {
            matchByTenor = min_tenor <= maxApprovedTenor && maxApprovedTenor <= max_tenor;
          }
        } else {
          if (min_tenor === undefined) {
            matchByTenor = minApprovedTenor <= max_tenor && max_tenor >= maxApprovedTenor;
          } else if (max_tenor === undefined) {
            matchByTenor = maxApprovedTenor >= min_tenor && min_tenor <= minApprovedTenor;
          } else {
            matchByTenor = max_tenor >= maxApprovedTenor && minApprovedTenor >= min_tenor;
          }
        }
      }

      if (typeof is_repeat_loan === 'boolean') {
        const matchByRepeat = is_repeat_loan === is_repeat;
        return !matchByPrincipal || !matchByTenor || !matchByRepeat;
      } else {
        return !matchByPrincipal || !matchByTenor;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxApprovedPrincipal, minApprovedPrincipal, maxApprovedTenor, minApprovedTenor],
  );

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Approve
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please select conditions</DialogContentText>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Typography color={product?.minApprovedAmountSameAsMax ? 'textSecondary' : 'textPrimary'}>
            Min approved principal, $:
          </Typography>
          <TextField
            className={classes.minValueInput}
            inputProps={{ inputMode: 'numeric' }}
            variant="standard"
            value={minApprovedPrincipal}
            onChange={e => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
              setMinApprovedPrincipal(e.target.value);
              setPromoCode(null);
            }}
            disabled={product?.minApprovedAmountSameAsMax}
          />
        </Box>
        <Typography id="approved-principal-slider" gutterBottom>
          Max approved principal, $: {`${displayedPrincipal}`}
        </Typography>
        <Slider
          defaultValue={requested_principal}
          valueLabelFormat={value => `${value / 1e3}k`}
          aria-labelledby="approved-principal-slider"
          valueLabelDisplay="auto"
          step={product?.principal.step || 1000}
          marks
          min={is_repeat ? product?.principal.min : product?.principal.first_min}
          max={is_repeat ? product?.principal.max : product?.principal.first_max}
          onChangeCommitted={(ev, value) => setMaxApprovedPrincipal(value)}
          onChange={(ev, value) => {
            setDisplayedPrincipal(value);
            setPromoCode(null);
          }}
        />
        <Divider className={classes.my1} />
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Typography color={product?.minApprovedTermSameAsMax ? 'textSecondary' : 'textPrimary'}>
            Min approved tenor, days:
          </Typography>
          <TextField
            className={classes.minValueInput}
            inputProps={{ inputMode: 'numeric' }}
            variant="standard"
            value={minApprovedTenor}
            onChange={e => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
              setMinApprovedTenor(e.target.value);
              setPromoCode(null);
            }}
            disabled={product?.minApprovedTermSameAsMax}
          />
        </Box>
        <Typography id="approved-tenor-slider" gutterBottom>
          Max approved tenor, days: {displayedTenor}
        </Typography>
        <Slider
          defaultValue={requested_tenor}
          aria-labelledby="approved-tenor-slider"
          valueLabelDisplay="auto"
          step={is_repeat ? 5 : product?.tenor.step || 10}
          marks
          min={is_repeat ? product?.tenor.min : product?.tenor.first_min}
          max={is_repeat ? product?.tenor.max : product?.tenor.first_max}
          onChangeCommitted={(ev, value) => setMaxApprovedTenor(value)}
          onChange={(ev, value) => {
            setDisplayedTenor(value);
            setPromoCode(null);
          }}
        />
        <Typography className={classes.mt2} variant="h6" component="div">
          Calculation preview
        </Typography>
        {loading ? <CircularProgress /> : null}
        {error ? (
          <Typography variant="subtitle1" color="error">
            {error.message}
          </Typography>
        ) : null}
        {calculation ? (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Discount</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Loan amount
                  </TableCell>
                  <TableCell align="right">{formatCurrency(calculation.principal)}</TableCell>
                  <TableCell align="right">{formatCurrency(calculation.discount.principal)}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(calculation.principal - calculation.discount.principal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Comission
                  </TableCell>
                  <TableCell align="right">{formatCurrency(calculation.commission)}</TableCell>
                  <TableCell align="right">{formatCurrency(calculation.discount.commission)}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(calculation.commission - calculation.discount.commission)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Interest
                  </TableCell>
                  <TableCell align="right">{formatCurrency(calculation.interest)}</TableCell>
                  <TableCell align="right">{formatCurrency(calculation.discount.interest)}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(calculation.interest - calculation.discount.interest)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Settlement amount
                  </TableCell>
                  <TableCell align="right">{formatCurrency(calculation.settlement_amount)}</TableCell>
                  <TableCell align="right">{formatCurrency(calculation.discount.settlement_amount)}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(calculation.settlement_amount - calculation.discount.settlement_amount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Payment date
                  </TableCell>
                  <TableCell align="right" colSpan={3}>
                    {formatDate(calculation.maturity_date)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        <AutoComplete
          perOnePage={30}
          resource="promo_codes"
          label="Promo codes"
          filter={{ is_enabled: true }}
          value={promoCode}
          setValue={setPromoCode}
          prop={'code'}
          getOptionDisabled={getDisabledPromocodes}
          helperText="If you can not choose any of the promo codes - it means that some conditions for its application are not met"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!maxApprovedPrincipal || !maxApprovedTenor}>
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

ApproveTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    requested_principal: PropTypes.number,
    requested_tenor: PropTypes.number,
    product_id: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    product_code: PropTypes.string,
  }),
};

export default ApproveTransitionDialog;
