import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import PropTypes from 'prop-types';

import { AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES } from '../../constants';
import { useField } from 'react-final-form';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  chip: {
    fontSize: '14px',
    backgroundColor: '#c2c2c1',
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    marginBottom: 10,
    width: '100%',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
  ml: {
    marginLeft: 10,
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
}));

export const ListFilter = props => {
  const { id, choice, title, filter = {}, form, withPadding = true, withDivider = true } = props;
  const [inputList, setInputList] = useState(
    filter?.values
      ? filter?.values?.map((item, index) => ({ include: item.include || 'include', value: item.value, id: index }))
      : [{ include: 'include', value: '', id: 0 }],
  );
  const classes = useStyles();
  const { meta } = useField(id);

  const isError = meta.touched && meta.error;

  useEffect(() => {
    if (form) {
      inputList.forEach((item, index) => {
        form.change(`${id}.values[${index}].include`, item.include);
        form.change(`${id}.values[${index}].value`, item.value);
      });
    }
  }, [form, id, filter?.values, inputList]);

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    form.change(
      `${id}.values`,
      list.map(item => ({ include: item.include, value: item.value })),
    );
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { value: '', id: inputList.length }]);
  };

  return (
    <>
      {withDivider && <Divider />}
      <Box p={withPadding ? 2 : 0} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked={filter?.active}
              onChange={e => form.change(`${id}.active`, e.target.checked ? [id] : [])}
            />
          }
          label={title}
        />
        {inputList.map((x, i) => {
          return (
            <Box key={i} className={classes.inputContainer}>
              <Box className={classes.select}>
                <FormControl style={{ minWidth: 120 }} size="small">
                  <Select
                    onChange={e => {
                      const newInputList = inputList.map((item, index) =>
                        i === index ? { ...item, include: e.target.value } : item,
                      );
                      setInputList(newInputList);
                      form.change(`${id}.values[${i}].include`, e.target.value);
                    }}
                    variant="filled"
                    label="Include"
                    value={x.include}>
                    {AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES.map(i => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* NOTE: empty helper prevents layout shift when second select has an error */}
                  {isError && <FormHelperText> </FormHelperText>}
                </FormControl>
                <FormControl style={{ width: 430 }} size="small" error={!!isError}>
                  <Select
                    onChange={e => {
                      const newInputList = inputList.map((item, index) =>
                        i === index ? { ...item, value: e.target.value } : item,
                      );
                      setInputList(newInputList);
                      form.change(`${id}.values[${i}].value`, e.target.value);
                    }}
                    variant="filled"
                    label="Value"
                    value={x.value}>
                    {choice.map(i => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {isError && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
              </Box>
              <Box className={classes.btnGroup}>
                {inputList.length !== 1 && (
                  <IconButton color="primary" onClick={() => handleRemoveClick(i)}>
                    <RemoveCircleOutlineTwoTone />
                  </IconButton>
                )}
                {inputList.length - 1 === i && (
                  <IconButton color="primary" onClick={handleAddClick}>
                    <AddCircleOutlineTwoTone />
                  </IconButton>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

ListFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  choice: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  withPadding: PropTypes.bool,
  withDivider: PropTypes.bool,
};

ListFilter.defaultProps = {
  filter: {},
};
