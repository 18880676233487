import React, { useEffect, useState } from 'react';
import { useNotify, Loading, useDataProvider } from 'react-admin';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { CirculodecreditoReport } from './CirculodecreditoReport';
import { MoffinReport } from './MoffinReport';

export const ReportTable = ({ userId, refreshedAt }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .getList('user_api_datas', {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { 'user.id': userId, service: ['credit_bureau', 'moffin_credit_bureau'] },
      })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
        setLoading(() => false);
      })
      .catch(error => {
        notify(`Error: ${error.message || 'User api data not found'}`, 'error');
        setLoading(() => false);
      });
  }, [notify, userId, refreshedAt, dataProvider, page, perPage]);

  if (loading) return <Loading />;

  return (
    <Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            {['', 'ID', 'Engine', 'State', 'Error', 'Created at'].map(i => (
              <TableCell key={i}>{i}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(i =>
            i.service === 'credit_bureau' ? (
              <CirculodecreditoReport key={i.id} data={i} />
            ) : (
              <MoffinReport key={i.id} data={i} />
            ),
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </Box>
  );
};

ReportTable.propTypes = {
  userId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};
