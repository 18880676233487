import { useState } from 'react';

export function useEmailSources() {
  const [isEmailSelected, setIsEmailSelected] = useState();

  const handleFormChange = (el, formProps) => {
    if (formProps) {
      formProps.change('email_source', null);
    }
    setIsEmailSelected(el.target.value === 'mailer');
  };

  return {
    isEmailSelected,
    setIsEmailSelected,
    handleFormChange,
  };
}
