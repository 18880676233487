import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  List,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { formatDatetime, formatPhoneNumber, formatChoices } from '../../utils';
import { CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS } from '../../constants';
import { Pagination, Call, CallCenterLogTable, ListFilterHOC, DateFilterInput } from '../../components';
import { ListActions } from '../user/UserList';
import { assignEssence, changeStatus } from './actions';
import CallCenterDropdownDialog from './components/CallCenterDropdownDialog';
import CallCenterLogDialog from './components/CallCenterLogDialog';
import ListFilter from './components/ListFilter';
import { setRowStyles } from './utils/getRowStyles';

const UnsignedListFilter = props => (
  <ListFilter {...props}>
    <TextInput label="User ID" source="user->id" />
    <NumberInput label="Request Id" source="id" />
    <DateFilterInput label="Assign before" source="unsigned_request_phone_call_assigned_at|before" before />
    <DateFilterInput label="Assign after" source="unsigned_request_phone_call_assigned_at|after" after />
    <TextInput label="Document number" source="user->userDocuments->number" />
    <DateFilterInput label="Created before" source="created_at|before" before />
    <DateFilterInput label="Created after" source="created_at|after" after />
    <SelectInput
      label="Last call result"
      source="phone_call_last_result"
      choices={formatChoices(CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS)}
    />
  </ListFilter>
);

const CallCenterListUnsignedRequests = ({
  statefilterchoices = [],
  listfilters: ListFilters,
  permissions,
  ...props
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const theme = useTheme();

  const [refreshedAt, setRefreshedAt] = useState(null);
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [loanIdLog, setLoanIdLog] = useState(0);
  const [loanIdStatus, setLoanIdStatus] = useState(0);

  const canSeePhone = !permissions.includes('CAN_SEE_FULL_PHONE');
  const canEdit = permissions?.includes('CAN_PHONE_CALL_EDIT');

  return (
    <>
      <CallCenterLogDialog open={openLogDialog} close={setOpenLogDialog} title="Request status log">
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'application.id': loanIdLog, context: 'application_unsigned_applications' }}
          isDialog
        />
      </CallCenterLogDialog>
      <CallCenterDropdownDialog
        title="Change request status"
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(userStatus, dataProvider, notify, setOpenDropdownDialog, refresh, 'phone_calls', {
            application_id: loanIdStatus,
            result: userStatus,
            context: 'application_unsigned_applications',
          })
        }
        selectOptions={CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        actions={<ListActions />}
        filters={<ListFilters statefilterchoices={statefilterchoices} />}
        permissions={permissions}
        {...props}>
        <Datagrid rowClick="show" rowStyle={i => setRowStyles(i, theme)}>
          <FunctionField
            label="Request id / Created at"
            render={({ id, created_at }) => `${id} / ${formatDatetime(created_at)}`}
          />
          <FunctionField
            label="Name"
            render={({ user_id, user_first_name = '', user_last_name = '' }) => {
              return (
                <Link
                  onClick={e => e.stopPropagation()}
                  href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>
              );
            }}
          />
          ,
          <FunctionField
            label="Phone number"
            render={({ user_phone_number, user_id }) => (
              <Call userId={user_id} userPhone={user_phone_number}>
                {formatPhoneNumber(user_phone_number, canSeePhone)}{' '}
              </Call>
            )}
          />
          <TextField source="user_id" label="User id" />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.application_unsigned_applications?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={!canEdit}>
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}>
                        log
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(dataProvider, notify, refresh, 'phone_call_application_assigns', {
                        application_id: id,
                        context: 'application_unsigned_applications',
                      });
                    }}
                    disabled={!canEdit}>
                    Assign
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            label="Operator / assigning time"
            render={({ last_phone_calls_by_context }) => {
              return (
                (last_phone_calls_by_context?.application_unsigned_applications?.operator || 'not selected') +
                ' / ' +
                formatDatetime(last_phone_calls_by_context?.application_unsigned_applications?.called_at)
              );
            }}
          />
          <FunctionField
            label="Min PALC"
            render={({ last_phone_calls_by_context }) => {
              const calledAt = last_phone_calls_by_context?.application_unsigned_applications?.called_at;
              const changeTime = new Date(calledAt);
              const nowTime = new Date();
              if (calledAt) {
                return Math.floor((nowTime - changeTime) / 60000) + ' min';
              } else {
                return 0;
              }
            }}
          />
          <FunctionField
            label="Min paseed"
            render={({ resolved_at }) => {
              const smsTime = new Date(resolved_at);
              const nowTime = new Date();
              const min = Math.floor((nowTime - smsTime) / 60000);
              return min + ' min';
            }}
          />
          <FunctionField
            label="Last call result"
            render={({ last_phone_calls_by_context }) =>
              last_phone_calls_by_context?.application_unsigned_applications?.result
            }
          />
          <FunctionField
            label="Loan sum requested / approved"
            render={({ requested_principal, max_approved_principal }) =>
              `${requested_principal} / ${max_approved_principal}`
            }
          />
          <FunctionField
            label="Loan term requested / approved"
            render={({ requested_tenor, max_approved_tenor }) => `${requested_tenor} / ${max_approved_tenor}`}
          />
          <FunctionField
            render={record => (
              <Link
                href={`admin#/unsigned_requests/${record.id}/show`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <IconButton onClick={e => e.stopPropagation()}>
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </Link>
            )}
            key="button_tab"
          />
        </Datagrid>
      </List>
    </>
  );
};

CallCenterListUnsignedRequests.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const CallCenterUnsignedRequestsList = ListFilterHOC(
  CallCenterListUnsignedRequests,
  { state: 'approved' },
  [],
  UnsignedListFilter,
);

export default CallCenterUnsignedRequestsList;
