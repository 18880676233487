import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import { LOAN_STATE_CHOICES } from '../../../constants';

const BalanceLoanReportParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('balance_loan_report');

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.fullWidth} error={!!(touched && error && error.state)}>
          <InputLabel id="last-call-result-select-label">Loan state</InputLabel>
          <Select
            multiple
            fullWidth
            labelId="last-call-result-select-label"
            value={value && value.state ? [...value.state] : []}
            onChange={e => onChange({ ...(value || {}), state: e.target.value })}
            renderValue={selected => (
              <Box className={classes.chipBox}>
                {selected.map(id => (
                  <Chip className={classes.chip} key={id} label={LOAN_STATE_CHOICES.find(i => i.key === id)?.name} />
                ))}
              </Box>
            )}
            MenuProps={{ getContentAnchorEl: () => null }}>
            {LOAN_STATE_CHOICES.map(item => (
              <MenuItem key={item.key} value={item.key}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {touched && error && error.columns && <FormHelperText>{error.columns}</FormHelperText>}
        </FormControl>
      </Grid>
      <br></br>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default BalanceLoanReportParamsInput;
