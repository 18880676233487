import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, TextInput } from 'react-admin';
import MuiTextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../styles';
import { PAYMENT_TYPE } from '../../../constants';
import { clabeValidator, bankNameByAccountNumber } from '../../../utils';

const UserPaymentInfo = ({ record }) => {
  const [bankAccountState, setBankAccountState] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    record.bank_account_number && setBankAccountState(clabeValidator(record.bank_account_number));
  }, [record.bank_account_number]);

  const validateBankAccount = e => setBankAccountState(clabeValidator(e.target.value));

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" gutterBottom={false}>
        Payment info
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput
            disabled
            label="Preferred payment type"
            source="payment_type"
            fullWidth
            helperText={false}
            choices={PAYMENT_TYPE}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiTextField
            variant="outlined"
            size="small"
            label="Bank name"
            value={
              record?.bank_account_number ? bankNameByAccountNumber(record?.bank_account_number, 'Unknown') : 'N/A'
            }
            disabled
            fullWidth
          />
          <TextInput
            disabled
            label="Bank account number"
            source="bank_account_number"
            fullWidth
            helperText={false}
            onBlur={validateBankAccount}
          />
          <FormHelperText error>{bankAccountState}</FormHelperText>
          <TextInput disabled label="Bank account status" source="bank_account_status" fullWidth helperText={false} />
        </Grid>
      </Grid>
    </Paper>
  );
};

UserPaymentInfo.propTypes = {
  record: PropTypes.object,
};

export default UserPaymentInfo;
