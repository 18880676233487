import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  TextInput,
  NumberInput,
  FunctionField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../../components';
import { formatPhoneNumber, formatDatetime } from '../../utils';
import { marginZeroStyles } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="User id" source="user" alwaysOn />
      <TextInput label="CURP" source="curp" />
      <TextInput label="Created by" source="createdBy->username" />
      <TextInput label="First name" source="firstName" />
      <TextInput label="Last name" source="lastName" />
      <TextInput label="Phone number" source="phoneNumber" />
      <TextInput label="Contact phone" source="contactPhoneNumber" />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const BlackList = props => {
  const showFullPhone = !props.permissions?.includes('CAN_SEE_FULL_PHONE');
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="Id" />
        <TextField source="user_id" label="User id" />
        <TextField source="curp" label="CURP" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <FunctionField
          source="phone_number"
          render={(record, key) =>
            record[key] && (
              <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                {formatPhoneNumber(record[key], showFullPhone)}
              </Typography>
            )
          }
        />
        <EmailField source="email" />
        <FunctionField
          source="contact_phone_number"
          render={(record, key) =>
            record[key] && (
              <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                {formatPhoneNumber(record[key], showFullPhone)}
              </Typography>
            )
          }
        />
        <TextField source="comment" />
        <FunctionField source="created_at" render={(record, key) => record[key] && formatDatetime(record[key])} />
        <TextField source="created_by" />
      </Datagrid>
    </List>
  );
};

BlackList.propTypes = {
  permissions: PropTypes.array,
};

export default BlackList;
