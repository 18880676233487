import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  PastDueInterestDiscountDialog,
  AddExecutedTransactionDialog,
  TransactionType,
  TransactionDirection,
  RefundOverpaymentDialog,
  MarkOverpaymentRefundedDialog,
} from '../../../components';
import { useStyles } from '../styles';
import { formatCurrency, formatDatetime } from '../../../utils';
import ColapsibleRow from './ColapsibleRow';
import { transactionsListTransform } from '../utils';

const ExecutedTransactions = ({ record, refreshedAt, refresh, isActionsDisabled }) => {
  const [transactions, setTransactions] = useState([]);
  const [transactionsRaw, setTransactionsRaw] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddExecutedTransactionDialogOpened, setIsAddExecutedTransactionDialogOpened] = useState(false);
  const [isPDIDiscountDialogOpened, setIsPDIDiscountDialogOpened] = useState(false);
  const [isRefundOverpaymentDialogOpened, setIsRefundOverpaymentDialogOpened] = useState(false);
  const [isMarkOverpaymentDialogOpened, setIsMarkOverpaymentDialogOpened] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const isIncomingTransaction = id => {
    const transaction = transactionsRaw.find(t => t.id === id);
    return transaction.direction === 'in';
  };

  const isNotRevertableTransaction = id => {
    const transaction = transactionsRaw.find(t => t.id === id);
    return !transaction || transaction.type.includes('discount') || transaction.type.includes('revert');
  };

  const canRecalc =
    selected.length === 1 && isIncomingTransaction(selected[0]) && !isNotRevertableTransaction(selected[0]);

  const isPDIOutcomingTransaction = ids => {
    const selectedTransactions = transactionsRaw.filter(t => ids.includes(t.id));
    return selectedTransactions.length > 0
      ? selectedTransactions.every(t => t.direction === 'out' && t.type === 'past_due_interest')
      : false;
  };

  const canDiscountPDI = isPDIOutcomingTransaction(selected);

  const toggle = id =>
    setSelected(selected.indexOf(id) === -1 ? selected.concat(id) : selected.filter(item => item !== id));

  useEffect(() => {
    setSelected([]);
    dataProvider
      .query(`loans/${record.id}/executed_transactions`, { method: 'GET' })
      .then(({ data }) => {
        setTransactionsRaw(data);
        setTransactions(transactionsListTransform(data));
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, record, refreshedAt]);

  const deleteExecutedTransactions = ids => {
    setIsDeleting(true);
    dataProvider
      .query(`loans/${record.id}/executed_transactions`, { method: 'DELETE', body: JSON.stringify(ids) })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setIsDeleting(false);
        refresh();
      });
  };

  const recalculateTransaction = id => {
    setLoading(true);
    const body = JSON.stringify({ transaction_id: id });
    dataProvider
      .query(`loans/${record.id}/recalculate_transaction`, { method: 'POST', body })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  const onPdiDiscount = (amount, executedAt) => {
    setIsPDIDiscountDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/executed_transactions`, {
        body: JSON.stringify({
          type: 'past_due_interest_discount',
          direction: 'in',
          amount,
          executed_at: executedAt,
        }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onTransactionAdd = (type, direction, amount, executedAt) => {
    setIsAddExecutedTransactionDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/executed_transactions`, {
        body: JSON.stringify({ type, direction, amount, executed_at: executedAt }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onRefundOverpayment = () => {
    setIsRefundOverpaymentDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/overpayment`, {
        method: 'POST',
      })
      .then(() => {
        notify('Success: Overpayment is in refund progress', 'success');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onMarkOverpaymentAsRefunded = executedAt => {
    setIsMarkOverpaymentDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/mark_overpayment_refund`, {
        method: 'POST',
        body: JSON.stringify({ date: executedAt }),
      })
      .then(() => {
        notify('Success: Overpayment marked as refunded', 'success');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <div style={{ minWidth: '620px' }}>
      <Paper>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom={false} className={classes.paper}>
            Executed transactions
          </Typography>
          <ButtonGroup
            color="primary"
            size="small"
            variant="contained"
            className={classes.mx2}
            disabled={isDeleting || isActionsDisabled}>
            {selected.length !== 0 && <Button onClick={() => deleteExecutedTransactions(selected)}>Delete</Button>}
            <Button onClick={() => setIsAddExecutedTransactionDialogOpened(true)}>Add</Button>
            <Button onClick={() => recalculateTransaction(selected[0])} disabled={!canRecalc}>
              Recalc
            </Button>
            {permissions.includes('CAN_DISCOUNT_PDI') && (
              <Button onClick={() => setIsPDIDiscountDialogOpened(true)} disabled={!canDiscountPDI}>
                PDI discount
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.gap}>
          {permissions.includes('CAN_REFUND_OVERPAYMENT') && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!record.has_unreturned_overpayment}
              onClick={() => setIsRefundOverpaymentDialogOpened(true)}>
              Refund overpayment
            </Button>
          )}
          {permissions.includes('CAN_MARK_OVERPAYMENT_AS_REFUNDED') && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!record.has_unreturned_overpayment}
              onClick={() => setIsMarkOverpaymentDialogOpened(true)}>
              Mark overpayment as refunded
            </Button>
          )}
        </Grid>
        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Executed at</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Triggered by</TableCell>
              <TableCell>Direction</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(row => {
              if (row.grouped_transactions.length === 0) {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{formatDatetime(row.executed_at)}</TableCell>
                    <TableCell>{formatDatetime(row.created_at)}</TableCell>
                    <TableCell>{row.triggered_by ?? '---'}</TableCell>
                    <TableCell align="center">
                      <TransactionDirection direction={row.direction} />
                    </TableCell>
                    <TableCell>
                      <TransactionType type={row.type} direction={row.direction} />
                    </TableCell>
                    <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        className={classes.executedTransactionCheckbox}
                        size="small"
                        checked={selected.indexOf(row.id) !== -1}
                        onChange={() => toggle(row.id)}
                        disabled={isActionsDisabled}
                      />
                    </TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <ColapsibleRow
                    key={row.id}
                    row={row}
                    toggle={toggle}
                    selected={selected}
                    isActionsDisabled={isActionsDisabled}
                  />
                );
              }
            })}
          </TableBody>
        </Table>
      </Paper>
      <AddExecutedTransactionDialog
        record={record}
        isOpened={isAddExecutedTransactionDialogOpened}
        onClose={() => setIsAddExecutedTransactionDialogOpened(false)}
        onSubmit={onTransactionAdd}
      />
      {permissions.includes('CAN_DISCOUNT_PDI') && isPDIDiscountDialogOpened && (
        <PastDueInterestDiscountDialog
          onClose={() => setIsPDIDiscountDialogOpened(false)}
          onSubmit={onPdiDiscount}
          selected={selected}
          transactions={transactions}
        />
      )}
      <RefundOverpaymentDialog
        record={record}
        isOpened={isRefundOverpaymentDialogOpened}
        onClose={() => setIsRefundOverpaymentDialogOpened(false)}
        onSubmit={onRefundOverpayment}
      />
      <MarkOverpaymentRefundedDialog
        isOpened={isMarkOverpaymentDialogOpened}
        onClose={() => setIsMarkOverpaymentDialogOpened(false)}
        onSubmit={onMarkOverpaymentAsRefunded}
      />
    </div>
  );
};

ExecutedTransactions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    has_unreturned_overpayment: PropTypes.bool,
  }),
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
  isActionsDisabled: PropTypes.bool,
};

export default ExecutedTransactions;
