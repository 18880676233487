import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from '../styles';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import ReportDate from '../components/ReportDate';
import { timezone } from '../../../utils';
import PropTypes from 'prop-types';

const SummaryReportParamsInput = ({ field = 'summary_report' }) => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField(field);

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Calculation date"
            format="MMMM dd, yyyy"
            className={classes.fullWidth}
            value={value?.calculation_date ?? null}
            onChange={v =>
              onChange({ ...(value || {}), calculation_date: timezone.setTimeWithoutOffset(v, 23, 59, 59) })
            }
            required
            error={!!(touched && error && error.calculation_date)}
            helperText={touched && error && error.calculation_date}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            label="Short report"
            control={
              <Checkbox
                checked={!!value.short_report}
                onChange={e => onChange({ ...(value || {}), short_report: e.target.checked })}
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

SummaryReportParamsInput.propTypes = {
  field: PropTypes.string.isRequired,
};

export default SummaryReportParamsInput;
