import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ListboxComponent } from './ListBox';

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export const SelectAutoComplete = ({
  result,
  loading,
  maxPage,
  setPage,
  page,
  variant,
  label,
  value,
  setValue,
  onChangeInput,
  prop,
  getOptionDisabled,
  helperText,
}) => {
  const classes = useStyles();
  const [openSelect, setOpenSelect] = useState(false);
  const openClose = () => setOpenSelect(state => !state);
  const Wrapper = (WrappedComponent, page, setPage, maxPage) => {
    return React.forwardRef((props, ref) => {
      return <WrappedComponent {...props} page={page} setPage={setPage} maxPage={maxPage} ref={ref} />;
    });
  };
  const ListBox = Wrapper(ListboxComponent, page, setPage, maxPage);
  return (
    <>
      <Autocomplete
        value={typeof value !== 'undefined' ? { [prop]: value } : undefined}
        ListboxComponent={ListBox}
        id={`select-auto-complite${label}`}
        defaultValue={value}
        open={openSelect}
        classes={classes}
        onChange={(event, newValue, reason) => setValue(reason === 'clear' ? undefined : newValue[prop])}
        onOpen={() => {
          openClose();
        }}
        onClose={() => {
          openClose();
        }}
        getOptionSelected={(option, value) => typeof value !== 'undefined' && option[prop] === value[prop]}
        getOptionLabel={option => option[prop] ?? ''}
        getOptionDisabled={getOptionDisabled}
        options={result.options}
        loading={loading}
        renderInput={params => {
          let options = [];
          if (!loading) {
            options = params.InputProps;
          }
          if (value && !params.inputProps.value) {
            params.inputProps.value = value;
          }

          return (
            <TextField
              {...params}
              role="select-auto-complete-component"
              label={label}
              variant={variant}
              onChange={e => onChangeInput?.(e.target.value)}
              InputProps={{
                ...options,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {!loading ? params.InputProps.endAdornment : null}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};
SelectAutoComplete.propTypes = {
  result: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  maxPage: PropTypes.number,
  setPage: PropTypes.func,
  page: PropTypes.number,
  variant: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  prop: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func,
  getOptionDisabled: PropTypes.any,
  helperText: PropTypes.string,
};
