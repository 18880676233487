import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { DateTimePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import { timezone } from '../../../utils';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import ReportFilterByDate from '../components/ReportFilterByDate';

const SecurityApplicationReportParamsInput = () => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('security_application_report');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <DateTimePicker
          label="Start date"
          format="MMMM dd, yyyy hh:mm"
          className={classes.fullWidth}
          value={value && value.start_date ? timezone.shift(value.start_date) : null}
          onChange={v => onChange({ ...(value || {}), start_date: timezone.unshift(v) })}
          required
          error={!!(touched && error && error.start_date)}
          helperText={touched && error && error.start_date}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateTimePicker
          label="End date"
          format="MMMM dd, yyyy hh:mm"
          className={classes.fullWidth}
          value={value && value.end_date ? timezone.shift(value.end_date) : null}
          onChange={v => onChange({ ...(value || {}), end_date: timezone.unshift(v) })}
          required
          error={!!(touched && error && error.end_date)}
          helperText={touched && error && error.end_date}
        />
      </Grid>
      <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
      <ReportDateFormat classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
      <ReportFilterByDate
        classes={classes}
        value={value}
        touched={touched}
        error={error}
        onChange={onChange}
        required
      />
    </Grid>
  );
};

export default SecurityApplicationReportParamsInput;
