import { differenceInMinutes } from 'date-fns';
import { red, green, orange, yellow, deepPurple, pink, blue } from '@material-ui/core/colors';
import { formatDatetime } from './formatters';
import { getApiEntrypoint } from './env';

export async function copyToClipboard(content = '') {
  try {
    if (!content) {
      return;
    }

    if (typeof document === 'undefined') {
      return;
    }

    if (window?.navigator?.clipboard?.writeText) {
      await window.navigator.clipboard.writeText(content);
    } else {
      const $textarea = document.createElement('textarea');
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

      $textarea.value = ' ';
      $textarea.style.display = 'block';
      $textarea.style.position = 'absolute';
      $textarea.style.left = '-9999px';
      $textarea.style.top = '-9999px';
      $textarea.style.overflow = 'hidden';
      $textarea.style.width = '1px';
      $textarea.style.height = '1px';
      $textarea.style.opacity = '0';
      $textarea.setAttribute('readonly', '');

      document.body.appendChild($textarea);

      $textarea.value = `${content}`;

      $textarea.select();

      document.execCommand('copy');

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }

      setTimeout(() => document.body.removeChild($textarea), 50);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export const getCatalogValue = (catalog, key) => {
  if (!key) {
    return;
  }

  const value = catalog[key];
  return `${key} (${value ?? 'Valor no encontrado'})`;
};

export const getPTPStatusColor = record => {
  switch (record?.status) {
    case 'in_progress':
      return yellow[300];
    case 'kept':
      return green[400];
    case 'partially_kept':
      return green[200];
    case 'broken':
      return orange[300];
    default:
      return '#FFFFFF';
  }
};

export const getRequestDuration = record => {
  const approvedInMinutes = differenceInMinutes(
    new Date(),
    record.resolved_at ? new Date(record.resolved_at) : new Date(),
  );
  // RED: ADE error
  if (record?.aventus_decision_engine_state === 'error') {
    return red[300];
  }

  // GREEN: application confirmed and loan is activated
  if (record?.state === 'confirmed' && record?.loan_id && record?.loan_state === 'active') {
    return green[300];
  }

  // ORANGE: application confirmed and loan is not activated
  if (record?.state === 'confirmed' && record?.loan_id && record?.loan_state === 'pending') {
    return orange[300];
  }

  // YELLOW: application is pending, manual verification and verifier is not assigned
  if (
    ['pending', 'processing'].includes(record?.state) &&
    record?.decision_engine_id === 'manual' &&
    !record?.verifier_id
  ) {
    return yellow[300];
  }

  // VIOLET: application is pending, manual verification and verifier is assigned
  if (
    ['pending', 'processing'].includes(record?.state) &&
    record?.decision_engine_id === 'manual' &&
    record?.verifier_id
  ) {
    return deepPurple[300];
  }

  // PINK: application is approved more than 5 minutes ago but not confirmed
  if (record?.state === 'approved' && approvedInMinutes > 5 && !record?.confirmed_at) {
    return pink[300];
  }

  // BLUE: application is approved more than 5 minutes ago but not confirmed
  if (record?.state === 'verification_control') {
    return blue[300];
  }

  return '#FFFFFF';
};

export const getFileSrc = (fileName, storageName) => {
  const token = localStorage.getItem('admin_access_token');
  const entrypoint = getApiEntrypoint();
  return `${entrypoint}/files/${storageName}/${fileName}?access_token=${token}`;
};

export const getUserImages = data => {
  return data.map(item => {
    return {
      src: getFileSrc(item.file_name, item.storage_name),
      caption: `Created at: ${formatDatetime(item.created_at)}`,
    };
  });
};

export const getUserAttachments = data => {
  return Array.isArray(data)
    ? data.map(item => {
        const fileName = item.file_name.split('/');
        return {
          id: item.id,
          src: getFileSrc(item.file_name, item.storage_name),
          caption: `Created at: ${formatDatetime(item.created_at)}`,
          label: item.label,
          file_name: fileName[fileName.length - 1],
        };
      })
    : {
        id: data.id,
        src: getFileSrc(data.file_name, data.storage_name),
        caption: `Created at: ${formatDatetime(data.created_at)}`,
        label: data.label,
        file_name: data.file_name.split('/')[data.file_name.split('/').length - 1],
      };
};

export function noop() {}

export const openIntoNewTab = (url, downloadName = null) => {
  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  if (downloadName) {
    link.download = downloadName;
  }

  link.click();

  setTimeout(() => link.remove(), 500);
};

export const impersonateUserSession = ({ registrationStep, token, refreshToken }) => {
  const url = `${process.env.REACT_APP_FRONT_HOST}/user/registration/step${registrationStep}?token=${token}&refreshToken=${refreshToken}`;
  window.open(url);
};
