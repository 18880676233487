import { getAdminTimezoneOffset } from './user';

export const phoneNumber = {
  placeholder: '+52 xxx xxx xxxx',
  parser: value => value && value.replace(/\D/g, '').slice(2, 13),
  formatter: (value, masked = false) => {
    if (!value) {
      return '+52';
    }

    if (value.length >= 11) {
      return `+52 ${value.slice(0, 3)} ${masked ? '****' : value.slice(3, 7)} ${value.slice(7)}`;
    } else if (value.length === 10) {
      return `+52 ${value.slice(0, 2)} ${masked ? '****' : value.slice(2, 6)} ${value.slice(6)}`;
    } else if (value.length >= 7) {
      return `+52 ${value.slice(0, 2)} ${masked ? '****' : value.slice(2, 6)} ${value.slice(6)}`;
    } else if (value.length >= 3) {
      return `+52 ${value.slice(0, 2)} ${value.slice(2)}`;
    } else {
      return `+52 ${value}`;
    }
  },
  validator: value => value && [10, 11].includes(value.replace(/\D/g, '').length),
  raValidator:
    (message = 'Invalid phone number') =>
    value =>
      value ? ([10, 11].includes(value.replace(/\D/g, '').length) ? undefined : message) : undefined,
};

const getTimezoneShiftMilliseconds = () => 60000 * (getAdminTimezoneOffset() + new Date().getTimezoneOffset());

export const timezoneOffsetLabel = offset => {
  const sign = offset < 0 ? '-' : '+';
  const minuteOffset = Math.abs(offset) % 60;
  const minuteOffsetLabel = minuteOffset < 10 ? `0${minuteOffset}` : `${minuteOffset}`;
  const hoursOffset = Math.floor(Math.abs(offset / 60));
  const hoursOffsetLabel = hoursOffset < 10 ? `0${hoursOffset}` : `${hoursOffset}`;

  return `${sign}${hoursOffsetLabel}:${minuteOffsetLabel}`;
};

export const timezone = {
  shift: date => new Date(date.valueOf() + getTimezoneShiftMilliseconds()),
  unshift: date => new Date(date.valueOf() - getTimezoneShiftMilliseconds()),
  setTime: (date, h = 0, m = 0, s = 0, ms = 0) => {
    const v = new Date(date);
    v.setHours(0, 0, 0, 0);
    return new Date(v.valueOf() - getTimezoneShiftMilliseconds() + ((h * 60 + m) * 60 + s) * 1000 + ms);
  },
  setTimeWithoutOffset: (date, h = 0, m = 0, s = 0, ms = 0) => {
    const v = new Date(date);
    v.setHours(0, 0, 0, 0);
    return new Date(v.valueOf() + ((h * 60 + m) * 60 + s) * 1000 + ms);
  },
};
