import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Box from '@material-ui/core/Box';
import { formatDatetime, formatPhoneNumber } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  fixedWidth120: {
    width: '120px',
  },
  fixedWidth400: {
    maxWidth: '400px',
  },
  tableHead: {
    '& .MuiTableCell-sizeSmall': {
      padding: '5px',
    },
  },
  tableBody: {
    '& .MuiTableCell-sizeSmall': {
      padding: '5px',
    },
  },
}));

export const SmsTable = ({ userId = null, refreshedAt }) => {
  const [sms, setSms] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('sms', {
        filter: {
          ...(userId ? { 'communication.user.id': userId } : {}),
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setSms(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(sms)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {sms.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flaex-start" gap="5px">
                  {row.communication.direction === 'income' && <CallReceivedIcon color="secondary" fontSize="small" />}
                  {row.communication.direction === 'outcome' && <CallMadeIcon color="secondary" fontSize="small" />}
                  {row.communication.direction}
                </Box>
              </TableCell>
              <TableCell className={classes.fixedWidth120}>
                {row.from_number && /^\d+$/.test(row.from_number)
                  ? formatPhoneNumber(row.from_number)
                  : row.from_number}
              </TableCell>
              <TableCell className={classes.fixedWidth120}>
                {row.to_number && /^\d+$/.test(row.to_number) ? formatPhoneNumber(row.to_number) : row.to_number}
              </TableCell>
              <TableCell className={classes.fixedWidth400}>{row.text}</TableCell>
              <TableCell>
                <Chip size="small" label={row.communication.state} />
              </TableCell>
              <TableCell>{row.communication.admin?.username}</TableCell>
              <TableCell>{row.communication.result?.name}</TableCell>
              <TableCell>{row.communication.category?.name}</TableCell>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

SmsTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
