import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CollectionActionTreeListContactTree } from './CollectionActionTreeListContactTree';

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: 6,
    transition: 'background-color 0.2s',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.075)',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    padding: '6px',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 6,
    flexGrow: 1,
  },
}));

export const CollectionActionTreeListContactTreeItem = ({
  contact,
  types,
  typeValues,
  onClickCreate,
  onClickEdit,
  onClickDelete,
  expandedContacts,
  toggleExpand,
}) => {
  const value = typeValues.find(({ id }) => id === contact.type_value);
  const type = types.find(({ id }) => id === value?.type);

  const classes = useStyles();

  const handleClickCollapse = () => {
    if (contact.children.length > 0) {
      toggleExpand(contact.id);
    }
  };

  const handleClickEdit = () => {
    onClickEdit(contact.id, contact.parent);
  };

  const handleClickCreate = () => {
    onClickCreate(contact.id, contact.children);
  };

  const handleClickDelete = () => {
    onClickDelete(contact.id);
  };

  const isExpanded = expandedContacts.includes(contact.id);

  return (
    <div key={contact.id} className={classes.wrapper}>
      <Box className={classes.row}>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          endIcon={contact.children.length === 0 ? null : isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
          onClick={handleClickCollapse}>
          {value.value}
        </Button>

        <Typography variant="subtitle2" color="textSecondary">
          Type=&quot;{type.name}&quot;, ID={contact.id}, Children={contact.children.length}, Motivators=
          {contact.motivators.length}, NoPaymentReasons={contact.no_payment_reasons.length}
        </Typography>

        <div className={classes.controls}>
          <Button color="secondary" variant="contained" size="small" startIcon={<EditIcon />} onClick={handleClickEdit}>
            Edit
          </Button>
          <Button color="primary" variant="contained" size="small" startIcon={<AddIcon />} onClick={handleClickCreate}>
            Add
          </Button>
          <Button
            // color="default"
            variant="contained"
            size="small"
            style={{ color: '#fff', backgroundColor: '#f44336' }}
            startIcon={<DeleteIcon />}
            onClick={handleClickDelete}>
            Delete
          </Button>
        </div>
      </Box>

      {isExpanded && contact.children.length > 0 && (
        <div style={{ padding: '0 0 12px 24px' }}>
          <CollectionActionTreeListContactTree
            toggleExpand={toggleExpand}
            expandedContacts={expandedContacts}
            contactsTree={contact.children}
            types={types}
            typeValues={typeValues}
            onClickCreate={onClickCreate}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        </div>
      )}
    </div>
  );
};

CollectionActionTreeListContactTreeItem.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    type_value: PropTypes.number,
    children: PropTypes.array,
    motivators: PropTypes.arrayOf(PropTypes.number),
    parent: PropTypes.number,
    no_payment_reasons: PropTypes.arrayOf(PropTypes.string),
  }),
  types: PropTypes.array,
  typeValues: PropTypes.array,
  expandedContacts: PropTypes.array,
  onClickCreate: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  toggleExpand: PropTypes.func,
};
