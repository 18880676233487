import React, { useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import formatISO from 'date-fns/formatISO';
import PropTypes from 'prop-types';
import {
  UserConfirmTransitionDialog,
  UserAddToBlackListDialog,
  UserChangeMoratoriumTransitionDialog,
  UserConfirmationDialog,
} from '../../../components';
import { impersonateUserSession } from '../../../utils';
import { useStyles } from '../styles';

const UserEditTransitions = ({ record, refresh }) => {
  const [disabled, setDisabled] = useState(false);
  const [isConfirmTransitionDialogOpened, setIsConfirmTransitionDialogOpened] = useState(false);
  const [isBlockTransitionDialogOpened, setIsBlockTransitionDialogOpened] = useState(false);
  const [isActivateTransitionDialogOpened, setIsActivateTransitionDialogOpened] = useState(false);
  const [isChangeMoratoriumTransitionDialogOpened, setIsChangeMoratoriumTransitionDialogOpened] = useState(false);
  const [addToBlockListDialogOpened, setAddToBlockListDialogOpened] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_USER_EDIT');
  const canImpersonateUser = permissions.includes('CAN_IMPERSONATION');

  const transitions = record.enabled_transitions;
  const canConfirm = transitions.includes('confirm');
  const canBlock = transitions.includes('block');
  const canActivate = transitions.includes('activate');
  const canChangeMoratorium = transitions.includes('change_moratorium');
  const canRefreshSelfieMatches = transitions.includes('refresh_selfie_matches');
  const canOtpUnblock = transitions.includes('otp_unblock');
  const canAddToBlackList = transitions.includes('add_black_list');
  const canRemoveFromBlackList = transitions.includes('remove_black_list');

  const applyTransition = (name, params = {}) => {
    setDisabled(value => !value);
    dataProvider
      .query(`users/${record.id}/apply_transition`, { method: 'POST', body: JSON.stringify({ name, params }) })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .then(() => setDisabled(value => !value));
  };

  const impersonateUser = e => {
    e.stopPropagation();
    dataProvider
      .query(`users/${record.id}/token`, { method: 'GET' })
      .then(({ data }) => {
        const { token, refresh_token } = data;
        impersonateUserSession({ registrationStep: record.registration_step, token, refreshToken: refresh_token });
      })
      .catch(error => notify(`Error: ${error.message || 'token not found'}`, 'error'));
  };

  if (!transitions) return null;

  return isEditable ? (
    <>
      <Grid container className={classes.mb4} justifyContent="space-between">
        <Grid item xs={6}>
          <ButtonGroup variant="contained" color="primary">
            {canConfirm && (
              <Button onClick={() => setIsConfirmTransitionDialogOpened(true)} disabled={disabled}>
                Confirm
              </Button>
            )}
            {canBlock && (
              <Button onClick={() => setIsBlockTransitionDialogOpened(true)} disabled={disabled}>
                Block
              </Button>
            )}
            {canActivate && (
              <Button onClick={() => setIsActivateTransitionDialogOpened(true)} disabled={disabled}>
                Activate
              </Button>
            )}
            {canChangeMoratorium && (
              <Button onClick={() => setIsChangeMoratoriumTransitionDialogOpened(true)} disabled={disabled}>
                Change Moratorium
              </Button>
            )}
            {canRefreshSelfieMatches && (
              <Button onClick={() => applyTransition('refresh_selfie_matches')} disabled={disabled}>
                Refresh selfie matches
              </Button>
            )}
            {canImpersonateUser && <Button onClick={impersonateUser}>Impersonate</Button>}
          </ButtonGroup>
        </Grid>
        <Grid item xs={6} className={classes.textRight}>
          <ButtonGroup variant="contained" color="primary">
            {canOtpUnblock && (
              <Button className={classes.red} onClick={() => applyTransition('otp_unblock')} disabled={disabled}>
                Unblock OTP
              </Button>
            )}
            {canAddToBlackList && (
              <Button className={classes.red} onClick={() => setAddToBlockListDialogOpened(true)} disabled={disabled}>
                Add to black list
              </Button>
            )}
            {canRemoveFromBlackList && (
              <Button
                className={classes.green}
                onClick={() => applyTransition('remove_black_list')}
                disabled={disabled}>
                Remove from black list
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
      {isConfirmTransitionDialogOpened && (
        <UserConfirmTransitionDialog
          isOpened={isConfirmTransitionDialogOpened}
          onClose={() => setIsConfirmTransitionDialogOpened(false)}
          onSubmit={confirmationCode => {
            setIsConfirmTransitionDialogOpened(false);
            applyTransition('confirm', { confirmation_code: confirmationCode });
          }}
        />
      )}
      {addToBlockListDialogOpened && (
        <UserAddToBlackListDialog
          user={record}
          isOpened={addToBlockListDialogOpened}
          onClose={() => setAddToBlockListDialogOpened(false)}
          applyTransition={applyTransition}
        />
      )}
      {isChangeMoratoriumTransitionDialogOpened && (
        <UserChangeMoratoriumTransitionDialog
          isOpened={isChangeMoratoriumTransitionDialogOpened}
          onClose={() => setIsChangeMoratoriumTransitionDialogOpened(false)}
          onSubmit={moratoriumTillDate => {
            setIsChangeMoratoriumTransitionDialogOpened(false);
            applyTransition('change_moratorium', {
              moratorium_till: moratoriumTillDate ? formatISO(moratoriumTillDate) : null,
            });
          }}
          value={record.moratorium_till}
        />
      )}
      {isBlockTransitionDialogOpened && (
        <UserConfirmationDialog
          title="Block user"
          message="Are you sure you want to block this user?"
          onClose={() => setIsBlockTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsBlockTransitionDialogOpened(false);
            applyTransition('block');
          }}
        />
      )}
      {isActivateTransitionDialogOpened && (
        <UserConfirmationDialog
          title="Activate user"
          message="Are you sure you want to activate this user?"
          onClose={() => setIsActivateTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsActivateTransitionDialogOpened(false);
            applyTransition('activate');
          }}
        />
      )}
    </>
  ) : null;
};

UserEditTransitions.propTypes = {
  record: PropTypes.object,
  refresh: PropTypes.func,
};

export default UserEditTransitions;
