import React, { useState, useEffect } from 'react';
import { usePermissions, useDataProvider, useNotify } from 'react-admin';
import { Card, CardHeader, CardContent, Tabs, Tab } from '@material-ui/core';
import TabPanel from './TabPanel';
import AssignedActionsReport from './AssignedActionsReport';
import CollectionAlertsReport from './CollectionAlertsReport';
import AssignedBucketActions from './AssignedBucketActionsReport';

export default () => {
  const [activeTab, setActiveTab] = useState(0);
  const [collectionGroups, setCollectionGroups] = useState([]);

  const { permissions = [] } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const canFilterByAdminOrGroup = permissions.includes('CAN_FILTER_DASHBOARD_BY_ADMIN_OR_GROUP');

  const handleTabChange = (_event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    if (!canFilterByAdminOrGroup) return;
    dataProvider
      .getList('collection_groups', {
        filter: {},
        pagination: {},
        sort: {},
      })
      .then(({ data }) => setCollectionGroups(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <Card>
      <CardHeader title="Welcome to the administration dashboard" />
      {permissions.includes('CAN_BE_COLLECTION_SPECIALIST') && (
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {permissions.includes('CAN_VIEW_DASHBOARD_ASSIGNED_ACTIONS') && <Tab label="Assigned/Actions today" />}
            {permissions.includes('CAN_VIEW_DASHBOARD_BUCKET_ACTIONS') && <Tab label="Assigned/Actions in bucket" />}
            {permissions.includes('CAN_VIEW_DASHBOARD_COLLECTION_ALERTS') && <Tab label="Collection alerts" />}
          </Tabs>
          {permissions.includes('CAN_VIEW_DASHBOARD_ASSIGNED_ACTIONS') && (
            <TabPanel value={activeTab} index={0}>
              <AssignedActionsReport collectionGroups={collectionGroups} />
            </TabPanel>
          )}
          {permissions.includes('CAN_VIEW_DASHBOARD_BUCKET_ACTIONS') && (
            <TabPanel value={activeTab} index={1}>
              <AssignedBucketActions collectionGroups={collectionGroups} />
            </TabPanel>
          )}
          {permissions.includes('CAN_VIEW_DASHBOARD_COLLECTION_ALERTS') && (
            <TabPanel value={activeTab} index={2}>
              <CollectionAlertsReport collectionGroups={collectionGroups} />
            </TabPanel>
          )}
        </CardContent>
      )}
    </Card>
  );
};
