import React, { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useProductsList } from './useProductsList';
import Button from '@material-ui/core/Button';
import { Box, Typography } from '@material-ui/core';
import { SettingsListItem } from './SettingsListItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';

const useStyles = makeStyles(theme => ({
  paperCard: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingInline: theme.spacing(3),
  },
  innerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  tabLink: {
    minWidth: 100,
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

export default record => {
  const [globalSettings, setGlobalSettings] = useState([]);
  const [changedValues, setChangedValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const globalSettingsOptionsMap = useProductsList();

  const canEdit = permissions.includes('CAN_GLOBAL_SETTINGS_EDIT');

  useEffect(() => {
    if (!permissions.includes('CAN_GLOBAL_SETTINGS_VIEW')) {
      setLoading(false);
      return;
    }

    dataProvider
      .getList('global_settings?items_per_page=100', {
        filter: {},
        pagination: {},
        sort: {},
      })
      .then(({ data }) => setGlobalSettings(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, permissions]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
  }

  const onChangeSetting = (id, value, initialValue) => {
    setChangedValues(prevState => {
      const existingIndex = prevState.findIndex(item => item.id === id);
      if (value === initialValue || (value === null && initialValue === '')) {
        return prevState.filter(item => item.id !== id);
      } else if (existingIndex !== -1) {
        const updatedState = prevState.filter(item => item.id !== id);
        return [...updatedState, { id, value }];
      } else {
        return [...prevState, { id, value }];
      }
    });
  };

  const groupedItems = useMemo(() => {
    const categoryMap = {
      verification: globalSettingsOptionsMap.verification.items,
      finance: globalSettingsOptionsMap.finance.items,
      notification: globalSettingsOptionsMap.notification.items,
      collection: globalSettingsOptionsMap.collection.items,
      compliance: globalSettingsOptionsMap.compliance.items,
      marketing: globalSettingsOptionsMap.marketing.items,
      security: globalSettingsOptionsMap.security.items,
    };
    if (globalSettings) {
      return Object.entries(categoryMap).map(([categoryTitle, items]) => {
        const settingsInCategory = globalSettings
          .filter(item => items[item.name])
          .map(item => ({ name: item.name, details: items[item.name], value: item.value, id: item.id }));
        return {
          category: globalSettingsOptionsMap[categoryTitle].title,
          settings: settingsInCategory,
        };
      });
    }
  }, [globalSettings, globalSettingsOptionsMap]);

  const saveChanges = () => {
    dataProvider
      .update('global_settings_batch', { data: { settings: changedValues } })
      .then(
        ({
          data: {
            data: { data: settings },
          },
        }) => {
          setGlobalSettings(settings.sort((itemA, itemB) => itemA.id - itemB.id));
          setChangedValues([]);
          notify('Success: settings are applied', 'success');
        },
      )
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };
  const isNotReady = loading || error || !Array.isArray(globalSettings);

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item sm={9}>
        {permissions.indexOf('CAN_GLOBAL_SETTINGS_VIEW') !== -1 ? (
          <Paper className={classes.paperCard}>
            <Typography variant="h5">{record.options.label}</Typography>

            <Box className={classes.innerContent}>
              {isNotReady ? (
                <CircularProgress className={classes.paperCard} />
              ) : (
                <Grid container>
                  <Tabs
                    value={activeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(e, value) => setActiveTab(value)}>
                    {groupedItems.map(group => (
                      <Tab key={group.category} label={group.category} className={classes.tabLink} />
                    ))}
                  </Tabs>
                  {groupedItems.map((group, idx) => (
                    <TabPanel key={idx} value={activeTab} index={idx}>
                      {group.settings.map(item => (
                        <SettingsListItem setting={item} canEdit={canEdit} onChange={onChangeSetting} key={item.name} />
                      ))}
                    </TabPanel>
                  ))}
                </Grid>
              )}
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!changedValues.length || isNotReady}
                onClick={saveChanges}>
                Save changes
              </Button>
            </Box>
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
};
