import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  mt2: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}));

export const RefundOverpaymentDialog = ({ record, isOpened, onClose, onSubmit }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Refund overpayment</DialogTitle>
      <DialogContent>
        <Typography>
          {`Important: this action will make a real money transfer to customer account CLABE ${record.user_account_number}`}
        </Typography>
        <Typography className={classes.mt2}>Do you really want to do this?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RefundOverpaymentDialog.propTypes = {
  record: PropTypes.shape({
    user_account_number: PropTypes.string,
  }),
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
