import { makeStyles } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';

export const useReportStyles = makeStyles(theme => ({
  spacing: {
    margin: theme.spacing(1),
  },
  orange: {
    backgroundColor: orange[300],
  },
  green: {
    backgroundColor: green[300],
  },
  red: {
    backgroundColor: red[300],
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      width: '75px',
    },
  },
  tableContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 'none',
    borderRight: 'none',
    marginTop: theme.spacing(2),
  },
  fontBold: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
    },
  },
  maxWidth200: {
    maxWidth: '200px',
  },
}));

export const calculatePercentage = data => {
  const updatedData = [...data];

  const totalIndex = updatedData.findIndex(item => item.date === 'total');

  if (totalIndex !== -1) {
    updatedData.push(updatedData.splice(totalIndex, 1)[0]);
  }

  updatedData.forEach(item => {
    Object.keys(item).forEach(key => {
      if (key !== 'date') {
        const inbox = item[key].inbox;
        const read = item[key].read;
        item[key]['% read'] = inbox > 0 ? ((read / inbox) * 100).toFixed(0) + '%' : '0%';
      }
    });
  });

  return updatedData;
};

export const getCellColorByPercentage = (percentageStr, color) => {
  const percentage = parseInt(percentageStr, 10);

  switch (true) {
    case percentage >= 0 && percentage <= 39:
      return color.red;
    case percentage >= 40 && percentage <= 69:
      return color.orange;
    case percentage >= 70 && percentage <= 100:
      return color.green;
    default:
      return null;
  }
};

export const mapDataToObjects = arr => {
  return arr.map(item => {
    const data = [];

    Object.keys(item).forEach(key => {
      if (key !== 'date') {
        const { inbox, read, '% read': percentRead } = item[key];
        data.push(inbox, read, percentRead); // Push values directly into the array
      }
    });

    return { name: item.date, data };
  });
};

export const generateActionsQuery = (
  collectionGroup,
  collectors,
  endpoint,
  filterQuery,
  filter,
  maxDpd,
  minDpd,
  maxPrincipal,
  minPrincipal,
) => {
  const groupsQuery =
    collectionGroup.length > 0 ? collectionGroup.map(g => `collection_group_list[]=${g}`).join('&') : null;
  const collectorsQuery = collectors.length > 0 ? collectors.map(c => `collector_id_list[]=${c}`).join('&') : null;
  const typeFilter = `${filterQuery}=${filter}`;
  const maxDpdFilter = maxDpd ? `days_past_due[lte]=${+maxDpd}` : null;
  const minDpdFilter = minDpd ? `days_past_due[gte]=${+minDpd}` : null;
  const maxPrincipalFilter = maxPrincipal ? `principal[lte]=${+maxPrincipal}` : null;
  const minPrincipalFilter = minPrincipal ? `principal[gte]=${+minPrincipal}` : null;
  let query = collectorsQuery
    ? `${endpoint}?${typeFilter}&${collectorsQuery}`
    : `${endpoint}?${typeFilter}&${groupsQuery}`;
  if (maxDpdFilter) query = query.concat(`&${maxDpdFilter}`);
  if (minDpdFilter) query = query.concat(`&${minDpdFilter}`);
  if (maxPrincipalFilter) query = query.concat(`&${maxPrincipalFilter}`);
  if (minPrincipalFilter) query = query.concat(`&${minPrincipalFilter}`);

  return query;
};
