import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  TopToolbar,
  useListContext,
  List,
  TextField,
  Datagrid,
  sanitizeListRestProps,
  CreateButton,
  FunctionField,
} from 'react-admin';
import { Pagination } from '../../components';
import { formatDatetime } from '../../utils';

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const DebitStrategiesList = props => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" label="Strategy name" />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField label="Amounts" source="amounts" render={({ amounts }) => amounts.join(', ')} />
      </Datagrid>
    </List>
  );
};

export default DebitStrategiesList;
