import { clabe } from 'clabe-validator';

export const bankNameByAccountNumber = (accountNumber, defaultValue = 'N/A') => {
  if (!accountNumber) {
    return defaultValue;
  }

  if (typeof accountNumber !== 'string' || accountNumber.length !== 18) {
    return defaultValue;
  }

  const clabeCheck = clabe.validate(accountNumber);
  const bank = clabeCheck.ok ? clabeCheck.bank : defaultValue;

  return bank;
};
