import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  const classes = useStyles();

  return (
    <div className={classes.fullWidth} {...other}>
      <Box className={classes.tabPanel} style={{ display: value === index ? 'flex' : 'none' }}>
        {children}
      </Box>
    </div>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  children: PropTypes.node,
};
