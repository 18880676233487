import React from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import { timezone } from '../../../utils';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import { LOAN_STATE_CHOICES } from '../../../constants';

const OutsourcingRegisterReportParamsInput = () => {
  const [agenciesList, setAgenciesList] = React.useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('outsourcing_register_report');

  React.useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => {
        const agencies = data
          .filter(agency => agency.status === 'active')
          .map(item => ({ id: item.id, name: item.name }));
        setAgenciesList(agencies);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.outsourcing_company)}>
            <InputLabel id="last-call-result-select-label">Outsourcing company</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="last-call-result-select-label"
              value={value && value.outsourcing_company ? [...value.outsourcing_company] : []}
              onChange={e => onChange({ ...(value || {}), outsourcing_company: e.target.value })}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                  {selected.map(id => (
                    <Chip
                      className={classes.chip}
                      key={id}
                      label={agenciesList.find(agency => agency.id === id)?.name}
                    />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {agenciesList.map((item, idx) => (
                <MenuItem key={idx} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.outsourcing_company && (
              <FormHelperText>{error.outsourcing_company}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.state)}>
            <InputLabel id="last-call-result-select-label">Loan state</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="last-call-result-select-label"
              value={value && value.state ? [...value.state] : []}
              onChange={e => onChange({ ...(value || {}), state: e.target.value })}
              renderValue={selected => (
                <Box className={classes.chipBox}>
                  {selected.map(id => (
                    <Chip className={classes.chip} key={id} label={LOAN_STATE_CHOICES.find(i => i.key === id)?.name} />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {LOAN_STATE_CHOICES.map(item => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.columns && <FormHelperText>{error.columns}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Calculation date"
            className={classes.fullWidth}
            value={value && value.calculation_date ? timezone.shift(value.calculation_date) : null}
            onChange={v => onChange({ ...(value || {}), calculation_date: timezone.unshift(v) })}
            error={!!(touched && error && error.calculation_date)}
            helperText={touched && error && error.calculation_date}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Assigned date"
            className={classes.fullWidth}
            value={value?.start_date ?? null}
            onChange={v => {
              onChange({
                ...(value || {}),
                start_date: timezone.setTimeWithoutOffset(v, 0, 0, 0),
                end_date: timezone.setTimeWithoutOffset(v, 23, 59, 59),
              });
            }}
            required
            error={!!(touched && error && error.start_date)}
            helperText={touched && error && error.start_date}
            format="MMMM dd, yyyy"
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default OutsourcingRegisterReportParamsInput;
