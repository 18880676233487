import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BlockIcon from '@material-ui/icons/Block';
import PropTypes from 'prop-types';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: '1rem',
  },
  heading: {
    backgroundColor: '#f50057',
    color: '#060606',
  },
  title: {
    color: '#FFFFFF',
  },
  details: {
    padding: theme.spacing(2),
  },
}));

export const BlackList = ({ blackList, isInBlackList }) => {
  const classes = useStyles();

  return isInBlackList ? (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={classes.heading}
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls="black-list-panel-content"
          id="black-list-panel-header">
          <Typography className={classes.title}>User in blacklist</Typography>
          <BlockIcon style={{ color: 'white', paddingLeft: '0.5rem' }} />
        </AccordionSummary>
        <AccordionDetails>
          <Paper variant="outlined" className={classes.details}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Typography variant="body2">
                <b>Comment: </b>
                {blackList.comment}
              </Typography>
              <Typography variant="body2">
                <b>Created at: </b>
                {formatDatetime(blackList.createdAt)}
              </Typography>
              <Typography variant="body2">
                <b>Created by: </b>
                {blackList.createdBy}
              </Typography>
              <Typography variant="body2">
                <b>Reason: </b>
                {blackList.reason}
              </Typography>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
};

BlackList.propTypes = {
  blackList: PropTypes.oneOfType([
    PropTypes.shape({
      createdBy: PropTypes.string,
      createdAt: PropTypes.string,
      comment: PropTypes.string,
      reason: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  isInBlackList: PropTypes.bool,
};
