import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Typography from '@material-ui/core/Typography';
import { formatDatetime, formatPhoneNumber } from '../../utils';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
  },
  card: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: theme.spacing(3),
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: '500px',
  },
  callTitle: {
    marginBottom: theme.spacing(2),
  },
  figure: {
    margin: 0,
  },
  audio: {
    width: '100%',
  },
}));

const AudioPlayerDialog = ({ onClose, record }) => {
  const classes = useStyles();
  const notify = useNotify();

  React.useLayoutEffect(() => {
    function handleMediaError(e) {
      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
        case e.target.error.MEDIA_ERR_NETWORK:
        case e.target.error.MEDIA_ERR_DECODE:
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          onClose();
          notify('Error: The recording could not be played', 'error');
          break;
        default:
          onClose();
          notify('Error: The recording could not be played', 'error');
      }
    }

    document.getElementById('audio-rec')?.addEventListener('error', handleMediaError);
  }, [notify, onClose]);

  return (
    <Paper elevation={24} onClose={onClose} className={classes.card}>
      <Typography
        className={
          classes.callTitle
        }>{`Call ${formatDatetime(record.call_date)} to ${formatPhoneNumber(record.phone)}`}</Typography>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <figure className={classes.figure}>
        <audio
          id="audio-rec"
          className={classes.audio}
          controls
          controlsList="nodownload noplaybackrate"
          src={record.audio_link}></audio>
      </figure>
    </Paper>
  );
};

AudioPlayerDialog.propTypes = {
  onClose: PropTypes.func,
  record: PropTypes.shape({
    call_date: PropTypes.string,
    phone: PropTypes.string,
    audio_link: PropTypes.string,
  }),
};

export const VerificationCallsTable = ({ applicationId, refreshedAt }) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('verification_calls', {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { application: applicationId },
      })
      .then(({ data, total }) => {
        setMatches(data);
        setLoading(false);
        setTotal(total);
      })
      .catch(error => {
        setLoading(false);
        notify(`Error: ${error.message}`, 'error');
      });
  }, [dataProvider, applicationId, refreshedAt, page, perPage, notify]);

  const handleOpenPlayer = record => {
    setRecord(record);
    setOpenPlayer(true);
  };

  const handleClosePlayer = () => {
    setOpenPlayer(false);
    setRecord(null);
  };

  if (loading) return <CircularProgress />;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Application Id</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Audio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{formatPhoneNumber(row.phone)}</TableCell>
              <TableCell>{row.application_id}</TableCell>
              <TableCell>{formatDatetime(row.call_date)}</TableCell>
              <TableCell>{row.result}</TableCell>
              <TableCell>
                <IconButton disabled={!row.audio_link} onClick={() => handleOpenPlayer(row)} color="secondary">
                  <PlayCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      {openPlayer && <AudioPlayerDialog onClose={handleClosePlayer} record={record} />}
    </div>
  );
};

VerificationCallsTable.propTypes = {
  applicationId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
