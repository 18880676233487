import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  diffTable: {
    '& td': {
      padding: '1px 6px !important',
    },
  },
  fieldRow: {
    backgroundColor: '#e0e0e0',
    fontWeight: 700,
  },
  notAvailable: {
    fontSize: 12,
    color: '#a0a0a0',
  },
}));

const Section = ({ title, before, after }) => {
  const classes = useStyles();

  if (Object.keys(before).length === 0) {
    return null;
  }

  function prepareValue(value) {
    return typeof value === 'boolean' ? (value ? 'true' : 'false') : value;
  }

  return (
    <>
      <TableRow>
        <TableCell colSpan={3} align="left" className={classes.fieldRow}>
          {title}
        </TableCell>
      </TableRow>
      {Object.keys(before || {}).map((field, idx) => (
        <TableRow key={idx}>
          <TableCell align="left">{field}</TableCell>
          <TableCell align="right">
            {prepareValue(before[field]) ?? <span className={classes.notAvailable}>N/A</span>}
          </TableCell>
          <TableCell align="right">
            {prepareValue(after[field]) ?? <span className={classes.notAvailable}>N/A</span>}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  before: PropTypes.object,
  after: PropTypes.object,
};

const Diff = ({ entry: { before, after } }) => {
  const classes = useStyles();

  return (
    <>
      <Table size="small" padding="none" className={classes.diffTable}>
        <TableHead>
          <TableRow>
            <TableCell align="left" width="20%">
              &nbsp;
            </TableCell>
            <TableCell align="right" width="40%">
              Before
            </TableCell>
            <TableCell align="right" width="40%">
              After
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(before || {}).map((section, idx) => (
            <Section key={idx} title={section} before={before[section] || {}} after={after[section] || {}} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

Diff.propTypes = {
  entry: PropTypes.shape({
    before: PropTypes.object,
    after: PropTypes.object,
  }),
};

export const ChangeHistoryTable = ({ entityId, entityField, endpoint, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [changeHistory, setChangeHistory] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList(endpoint, {
        filter: entityField ? { [entityField.concat('.id')]: entityId } : {},
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setChangeHistory(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, entityId, entityField, endpoint, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(changeHistory)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Diff</TableCell>
            <TableCell>Initiator</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {changeHistory.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{formatDatetime(row.created_at)}</TableCell>
              <TableCell>
                <Diff entry={row} />
              </TableCell>
              <TableCell>
                {row.initiator_type === 'admin' ? row.initiator_name : ''}
                {row.initiator_id && row.initiator_type === 'admin' ? '#' + row.initiator_id : ''}
                {row.initiator_type ? '#' + row.initiator_type : ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

ChangeHistoryTable.propTypes = {
  entityId: PropTypes.number,
  entityField: PropTypes.string,
  endpoint: PropTypes.string,
  refreshedAt: PropTypes.number,
};
