import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const AddExtensionDialog = ({ isOpened, onClose, onSubmit, extensionTenors = [] }) => {
  const classes = useStyles();
  const [days, setDays] = useState();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Request extention</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <FormControl className={classes.fullWidth}>
          <InputLabel id="label-select-label">In days</InputLabel>
          <Select labelId="label-select-label" value={days || ''} onChange={e => setDays(e.target.value)}>
            {extensionTenors.map(i => (
              <MenuItem key={i + '_days'} value={i}>
                {i} days
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(days)} color="primary" disabled={!days}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddExtensionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  extensionTenors: PropTypes.array,
};
