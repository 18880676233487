import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import red from '@material-ui/core/colors/red';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import { FileCopy } from '@material-ui/icons';
import { formatCurrency, formatPhoneNumber, bankNameByAccountNumber, copyToClipboard } from '../../utils';
import { WebitelButton } from '../webitel_button/WebitelButton';
import { Call } from '../call/Call';
import { TabPanel } from '../hoc/TabPanel';
import { DocumentVerification } from '../document_verification/DocumentVerification';
import { SocialMedia } from '../social_media/SocialMedia';
import { BlackList } from '../black_list/BlackList';
import { BlackListMatch } from '../black_list/BlackListMatch';
import { findImage, ImageZoom } from '../image_zoom/ImageZoom';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  redChip: {
    color: 'white',
    backgroundColor: red[500],
  },
  tagsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  table: {
    tableLayout: 'fixed',
    '& .MuiTableCell-root': {
      wordWrap: 'break-word',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '6px 24px 6px 16px',
    width: 'fit-content',
  },
  breakable: {
    wordBreak: 'break-all',
  },
}));

const rowData = (name, value, style) => ({ name, value, style });

export const TabbedInfoCard = ({ user_id, inactive = false }) => {
  const classes = useStyles();
  const notify = useNotify();

  const copyText = async text => {
    try {
      await copyToClipboard(text);
      notify('Text copied to clipboard!', { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };

  const {
    data: record,
    loading,
    error,
  } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id: user_id },
  });
  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const profileRows = [
    rowData(
      'ID',
      <Box className={classes.flexRow}>
        <WebitelButton userId={record.id} />
        <Link href={'#users/' + record.id}>{record.id}</Link>
      </Box>,
    ),
    rowData('First name', record.first_name),
    rowData('Last name', record.last_name),
    rowData('Father surname', record.father_surname),
    rowData('Mother surname', record.mother_surname),
    rowData(
      'Phone number',
      <Call inactive={inactive} userId={record.id} userPhone={record.phone_number} edge="end">
        {formatPhoneNumber(record.phone_number)}
      </Call>,
      { display: 'flex' },
    ),
    rowData('Is data processing consent given', record.is_data_processing_consent_given ? 'Yes' : 'No'),
    rowData('CURP', record.curp),
    rowData('RFC', record.rfc),
    rowData('Gender', record.gender),
    rowData('Birthday', record.birthday),
    rowData('Email', record.email),
    rowData('Education', record.education),
    rowData('Main source of income', record.main_source_of_income),
    rowData('Monthly income', record.monthly_income && formatCurrency(record.monthly_income)),
    rowData('Marital status', record.marital_status),
    rowData('In blacklist', record.is_in_black_list ? 'true' : 'false'),
    rowData('Has charge back', record.is_charge_back_requested ? 'true' : 'false'),
    rowData(
      'User tags',
      record.tags.length ? (
        <Box className={classes.tagsGrid}>
          {record.tags.map(tag => {
            if (tag.name.includes('not disturb')) {
              return (
                <Chip
                  className={classes.redChip}
                  icon={<ErrorOutlineIcon style={{ color: 'white' }} />}
                  key={tag.id}
                  size="small"
                  label={tag.name}
                />
              );
            } else {
              return <Chip key={tag.id} size="small" label={tag.name} color="primary" />;
            }
          })}
        </Box>
      ) : null,
    ),
  ];

  const residenceRows = [
    rowData('Postal code', record.residence_postal_code),
    rowData('Region', record.residence_region),
    rowData('Municipality', record.residence_municipality),
    rowData('Colony', record.residence_colony),
    rowData('Street', record.residence_street),
    rowData('External house', record.residence_external_house),
    rowData('Internal house', record.residence_internal_house),
    rowData('Flat', record.residence_flat),
    rowData('Duration', record.residence_duration),
  ];

  const employmentRows = [rowData('Type', record.employment_type)];

  const contactRows = [
    rowData('Name', record.contact_name),
    rowData('Relationship', record.contact_relationship),
    rowData(
      'Phone number',
      record.contact_phone_number && (
        <Call inactive={inactive} userId={record.id} userPhone={record.contact_phone_number} edge="end">
          {formatPhoneNumber(record.contact_phone_number)}
        </Call>
      ),
      { display: 'flex' },
    ),
  ];

  const bankRows = [
    rowData(
      'Bank name',
      record.bank_account_number ? bankNameByAccountNumber(record.bank_account_number, 'Unknown') : 'N/A',
    ),
    rowData('Bank account number', record.bank_account_number),
  ];

  return (
    <>
      <BlackListMatch record={record} />
      <BlackList blackList={record.black_list} isInBlackList={record.is_in_black_list} />
      {record.state === 'blocked' && (
        <MuiAlert elevation={6} variant="filled" severity="error" className={classes.mb3}>
          User is blocked!
        </MuiAlert>
      )}
      <Paper>
        <TabPanel
          labels={[
            'Profile',
            'Residence & Employment',
            'Contact & Bank',
            'Photo',
            'Document verification',
            'Social Media',
          ]}>
          <React.Fragment>
            <Table className={classes.table} size="small">
              <TableBody>
                {profileRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right" style={row.style}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box className={classes.flexColumn}>
              <Typography variant="body2" gutterBottom={false}>
                User target URL
              </Typography>
              <Box className={classes.flexRow}>
                {record.target_url ? (
                  <>
                    <Typography className={classes.breakable} variant="body2" gutterBottom={false}>
                      {record.target_url}
                    </Typography>
                    <IconButton onClick={() => copyText(record.target_url)}>
                      <FileCopy />
                    </IconButton>
                  </>
                ) : null}
              </Box>
            </Box>
          </React.Fragment>
          <React.Fragment>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Residence
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {residenceRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Employment
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {employmentRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right" style={row.style}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </React.Fragment>
          <React.Fragment>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Contact person
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {contactRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right" style={row.style}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Bank
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {bankRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              STP
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="left">Incoming transactions account number</TableCell>
                  <TableCell align="right">{record.stp_number}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
          <Box p={2}>
            <Grid container spacing={2}>
              {findImage(record.uploaded_files, 'selfie') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Selfie
                  </Typography>
                  {record.similarity_card_and_selfie !== null ? (
                    <Typography variant="body1">{`Similarity ${record.similarity_card_and_selfie}%`}</Typography>
                  ) : (
                    findImage(record.uploaded_files, 'id_card') &&
                    findImage(record.uploaded_files, 'selfie') && (
                      <Typography variant="caption" color="error">
                        No face is recognized on the ID card
                      </Typography>
                    )
                  )}
                  <Divider />
                  <ImageZoom isExist userId={record.id} label="selfie" />
                </Grid>
              )}
              {findImage(record.uploaded_files, 'id_card') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Id card front
                  </Typography>
                  <Divider />
                  <ImageZoom isExist userId={record.id} label="id_card" />
                </Grid>
              )}
              {findImage(record.uploaded_files, 'id_card_back_side') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Id card back
                  </Typography>
                  <Divider />
                  <ImageZoom isExist userId={record.id} label="id_card_back_side" />
                </Grid>
              )}
              {findImage(record.uploaded_files, 'signature') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Signature
                  </Typography>
                  <Divider />
                  <ImageZoom isExist src={`users/${record.id}/signature`} />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box p={2}>
            <DocumentVerification userId={user_id} />
          </Box>
          <Box p={2}>
            <SocialMedia userId={user_id} />
          </Box>
        </TabPanel>
      </Paper>
    </>
  );
};

TabbedInfoCard.propTypes = {
  user_id: PropTypes.number,
  inactive: PropTypes.bool,
};
