import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LabelIcon from '@material-ui/icons/Label';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const AddUserTagsInput = ({ userId, selectedTags }) => {
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [tags, setTags] = useState([{ id: 0, name: 'Loading...' }]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [dialogValue, setDialogValue] = useState({ name: '' });

  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('user_tags', {
        filter: {},
        pagination: { page: 1, perPage: 100 },
        sort: {},
      })
      .then(({ data }) => {
        const userTagsID = selectedTags.map(tag => tag.id);
        const filteredTags = data.filter(tag => !userTagsID.includes(tag.id));
        setTags(filteredTags);
      })
      .catch(error => setError(error));
  }, [dataProvider, selectedTags]);

  const handleClose = () => {
    setDialogValue({ name: '' });
    toggleOpen(false);
  };

  const handleCreateTag = event => {
    event.preventDefault();
    const newTag = { name: dialogValue.name.toLowerCase() };
    dataProvider
      .query('user_tags', {
        method: 'POST',
        body: JSON.stringify(newTag),
      })
      .then(({ data }) => {
        const tempTags = [...tags, data];
        setTags(tempTags);
        setValue(data);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    handleClose();
  };

  const handleAddUserTag = event => {
    setLoading(true);
    event.preventDefault();
    dataProvider
      .query(`users/${userId}/add_tag`, {
        method: 'POST',
        body: JSON.stringify({ tag_id: value.id }),
      })
      .then(() => {
        setLoading(false);
        refresh();
        notify(`Tag '${value.name}' added to user`, 'success');
      })
      .catch(error => {
        setLoading(false);
        notify(`Error: ${error.message}`, 'error');
      });
    handleClose();
  };

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <>
      <Autocomplete
        disabled={loading}
        value={value}
        onChange={(_event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Create new "${params.inputValue}" tag`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={tags}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={option => option.name}
        style={{ width: 300 }}
        freeSolo
        renderInput={params => <TextField {...params} margin="dense" label="Select tag" variant="filled" />}
      />
      <Button
        startIcon={loading ? <CircularProgress size={20} /> : <LabelIcon />}
        disabled={loading || !value}
        variant="contained"
        color="primary"
        onClick={handleAddUserTag}>
        Add Tag
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
        <form onSubmit={handleCreateTag}>
          <DialogTitle id="form-dialog-title">Create a new tag</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={event => setDialogValue({ ...dialogValue, name: event.target.value })}
              label="Tag name"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

AddUserTagsInput.propTypes = {
  userId: PropTypes.number,
  selectedTags: PropTypes.array,
};

export default AddUserTagsInput;
