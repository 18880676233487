import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { formatCurrency, formatDatetime } from '../../utils';

const useStyles = makeStyles(theme => ({
  noResults: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

const ExpandPanel = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [autoDebitRequests, setAutoDebitRequests] = useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('auto_debit_requests', {
        filter: { 'auto_debit.id': id },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data }) => {
        setAutoDebitRequests(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, notify, id]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return autoDebitRequests.length ? (
    <Table size="small" aria-label="requests">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Income ID</TableCell>
          <TableCell>State</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Created at</TableCell>
          <TableCell>Confirmed at</TableCell>
          <TableCell>Charge back at</TableCell>
          <TableCell>Error</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {autoDebitRequests.map(request => (
          <TableRow key={request.id}>
            <TableCell component="th" scope="row">
              {request.id}
            </TableCell>
            <TableCell>{request.income_id ?? '---'}</TableCell>
            <TableCell>
              <Chip size="small" label={request.state} />
            </TableCell>
            <TableCell>{formatCurrency(request.amount)}</TableCell>
            <TableCell>{formatDatetime(request.created_at)}</TableCell>
            <TableCell>{formatDatetime(request.confirmed_at)}</TableCell>
            <TableCell>{formatDatetime(request.charge_back_at)}</TableCell>
            <TableCell>{request.error ?? '---'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Box className={classes.noResults} display="flex">
      <Typography variant="body2">No requests found</Typography>
    </Box>
  );
};

ExpandPanel.propTypes = {
  id: PropTypes.string,
};

export default ExpandPanel;
