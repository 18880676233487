import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Pagination } from '../../components';
import { marginZeroStyles } from '../../constants';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  ruleTip: {
    marginTop: '0.5rem',
    marginLeft: '0.5rem',
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="Product ID" source="product->id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const LoanRateRulesList = props => {
  const [productList, setProductList] = React.useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  React.useEffect(() => {
    dataProvider
      .query('products?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setProductList(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  return (
    <>
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<ListActions />}
        {...props}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <FunctionField
            source="product_id"
            render={({ product_id }) => `#${product_id} ${productList.find(p => p.id === product_id)?.name ?? ''}`}
            label="Product"
          />
          <TextField sortable={false} source="loan_number_from" />
          <TextField sortable={false} source="loan_number_to" />
          <TextField sortable={false} source="score_from" />
          <TextField sortable={false} source="score_to" />
          <TextField sortable={false} source="rate" />
          <FunctionField
            sortable={false}
            source="created_at"
            render={({ created_at }) => formatDatetime(created_at)}
            label="Created at"
          />
          <TextField sortable={false} label="Created by" source="created_by_username" />
          <FunctionField
            sortable={false}
            source="updated_at"
            render={({ updated_at }) => formatDatetime(updated_at)}
            label="Updated at"
          />
          <TextField sortable={false} label="Updated by" source="updated_by_username" />
        </Datagrid>
      </List>
      <Typography
        className={classes.ruleTip}
        variant="body2">{`Important: Score from < Approved score <= Score to (so the value specified at the beginning of the range  is not subject to the rule)!`}</Typography>
    </>
  );
};

export default LoanRateRulesList;
