import React from 'react';
import {
  Create,
  SaveButton,
  ListButton,
  Toolbar,
  FormWithRedirect,
  SelectInput,
  SelectArrayInput,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useHandbook } from '../../hooks';
import { TRIGGERS_CHOICES } from '../../constants';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  m2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();
  const { data: collectionGroupsResponse } = useHandbook('collection_groups', { items_per_page: 30 });

  const collectionGroups = collectionGroupsResponse.map(group => ({
    id: group.code,
    name: group.code,
  }));

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2} paddingBottom={0}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create collection alert
                  </Typography>
                  <Divider className={classes.m2} />
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <SelectInput
                        source="trigger"
                        label="Event name"
                        resettable
                        helperText={false}
                        choices={TRIGGERS_CHOICES}
                        fullWidth
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid item xs={12} key="banks">
                      <SelectArrayInput
                        allowEmpty
                        label="Collection groups"
                        source="groups"
                        helperText={false}
                        choices={collectionGroups}
                        optionText="name"
                        optionValue="id"
                        fullWidth
                        validate={[required()]}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <ListButton label="Cancel" icon={null} size="large" />
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const CollectionAlertsSettingsCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default CollectionAlertsSettingsCreate;
