import React, { useState } from 'react';
import { Create, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },
}));

const FormWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = React.useState(null);
  const [productRateStrategySettings, setProductRateStrategySettings] = React.useState([
    { rate: null, tenor_from: null, tenor_to: null },
  ]);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChangeName = e => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => name !== 'name')));
    setName(e.target.value || null);
  };

  const handleChangeRow = (e, i, field) => {
    const value = e.target.value;
    const errorsTemp = { ...errors };

    if (errorsTemp?.settings?.[i]?.[field]) {
      delete errorsTemp.settings[i][field];
    }
    if (errorsTemp?.settings?.[i] && Object.keys(errorsTemp.settings[i]).length === 0) {
      delete errorsTemp.settings[i];
    }
    if (errorsTemp?.settings && Object.keys(errorsTemp.settings).length === 0) {
      delete errorsTemp.settings;
    }
    if (field === 'tenor_from' && errorsTemp.settings?.[i]?.other && productRateStrategySettings[i].tenor_to > value) {
      delete errorsTemp.settings?.[i]?.other;
    }
    if (field === 'tenor_to' && errorsTemp.settings?.[i]?.other && productRateStrategySettings[i].tenor_from <= value) {
      delete errorsTemp.settings?.[i]?.other;
    }

    setErrors(errorsTemp);
    setProductRateStrategySettings(prev => {
      const settings = [...prev];
      settings[i][field] = value || null;
      return settings;
    });
  };

  const handleRemoveClick = i => {
    const errorsTemp = { ...errors };
    if (errorsTemp.range?.length > 0) {
      delete errorsTemp.range;
    }
    const settings = [...productRateStrategySettings];
    settings.splice(i, 1);
    setProductRateStrategySettings(settings);
    setErrors(errorsTemp);
  };

  const validate = () => {
    const formErrors = {};

    if (!name) {
      formErrors.name = 'Field is required';
    } else if (name.length > 255) {
      formErrors.name = 'Maximum field length is 255 symbols';
    }

    formErrors.settings = {};
    productRateStrategySettings.forEach((s, i) => {
      if (!s.rate) {
        formErrors.settings[i] = { ...formErrors.settings[i], rate: 'Field is required' };
      }
      if (s.tenor_from === null || s.tenor_from === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], tenor_from: 'Field is required' };
      } else if (+s.tenor_from === 0) {
        formErrors.settings[i] = { ...formErrors.settings[i], tenor_from: 'Tenor from cannot be 0' };
      }
      if (s.tenor_to === null || s.tenor_to === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], tenor_to: 'Field is required' };
      }
      if (s.tenor_from !== null && s.tenor_to !== null && +s.tenor_to < +s.tenor_from) {
        formErrors.settings[i] = { ...formErrors.settings[i], other: 'Tenor from should be less or equal to tenor to' };
      }
    });

    const sortedSettings = [...productRateStrategySettings]
      .filter(s => s.tenor_from !== null && s.tenor_to !== null)
      .sort((a, b) => +a.tenor_from - +b.tenor_from);

    let lastTenorTo = 0;
    const rangeErrors = [];

    sortedSettings.forEach((setting, index) => {
      const { tenor_from, tenor_to } = setting;

      if (index > 0) {
        const prevTenorTo = +sortedSettings[index - 1].tenor_to;
        const prevTenorFrom = +sortedSettings[index - 1].tenor_from;
        if (+tenor_from < prevTenorTo) {
          rangeErrors.push(
            `Overlapping range: 'Tenor from' ${tenor_from} overlaps with the previous range ${prevTenorFrom}-${prevTenorTo}`,
          );
        } else if (+tenor_from === prevTenorTo) {
          rangeErrors.push(
            `'Tenor from' ${tenor_from} is equal to one of 'Tenor to' values - change 'Tenor from' value from ${tenor_from} to ${+tenor_from + 1}`,
          );
        }
      }

      if (+tenor_from > lastTenorTo + 1) {
        rangeErrors.push(`Missing range from ${lastTenorTo + 1} to ${tenor_from - 1}`);
      }

      lastTenorTo = Math.max(lastTenorTo, +tenor_to);
    });

    if (lastTenorTo < 1000) {
      rangeErrors.push(`Missing range from ${lastTenorTo + 1} to 1000`);
    }

    if (rangeErrors.length > 0) {
      formErrors.range = rangeErrors;
    }

    if (formErrors.settings && Object.keys(formErrors.settings).length === 0) {
      delete formErrors.settings;
    }

    const isValid = Object.keys(formErrors).length === 0;
    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const settings = JSON.parse(JSON.stringify(productRateStrategySettings));
      settings.forEach(s => {
        s.rate = parseFloat((+s.rate / 100).toFixed(10));
        s.tenor_from = +s.tenor_from;
        s.tenor_to = +s.tenor_to;
      });
      const payload = { name, product_rate_strategy_settings: settings };

      dataProvider
        .query('product_rate_strategies', { method: 'POST', body: JSON.stringify(payload) })
        .then(() => {
          setLoading(false);
          redirect('/product_rate_strategies');
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>
                  Create product rate strategy
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.my2} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Name"
                    multiline
                    value={name || ''}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  {productRateStrategySettings.map((s, i) => (
                    <>
                      <Grid key={i} container spacing={1} flexDirection="row">
                        <Grid item xs={12} sm={3}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Rate"
                            value={s.rate ?? ''}
                            onChange={e => handleChangeRow(e, i, 'rate')}
                            error={!!errors?.settings?.[i]?.rate}
                            helperText={errors?.settings?.[i]?.rate || '% per day'}
                            size="small"
                            InputProps={{
                              inputProps: { min: 0, max: 10, step: 0.01 },
                            }}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                            className={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Tenor from"
                            value={s.tenor_from ?? ''}
                            onChange={e => handleChangeRow(e, i, 'tenor_from')}
                            error={!!errors?.settings?.[i]?.tenor_from}
                            helperText={errors?.settings?.[i]?.tenor_from || 'This value is included in the range'}
                            size="small"
                            InputProps={{
                              inputProps: { min: 0, max: 1000, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (
                                event?.key === '-' ||
                                event?.key === '+' ||
                                event?.key === '.' ||
                                event?.key === ','
                              ) {
                                event.preventDefault();
                              }
                            }}
                            className={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Tenor to"
                            value={s.tenor_to ?? ''}
                            onChange={e => handleChangeRow(e, i, 'tenor_to')}
                            error={!!errors?.settings?.[i]?.tenor_to}
                            helperText={errors?.settings?.[i]?.tenor_to || 'This value is included in the range'}
                            size="small"
                            InputProps={{
                              inputProps: { min: 0, max: 1000, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (
                                event?.key === '-' ||
                                event?.key === '+' ||
                                event?.key === '.' ||
                                event?.key === ','
                              ) {
                                event.preventDefault();
                              }
                            }}
                            className={classes.formControl}
                          />
                        </Grid>
                        {i !== 0 && (
                          <Grid item xs={12} sm={1}>
                            <IconButton color="primary" onClick={() => handleRemoveClick(i)}>
                              <RemoveCircleOutlineTwoTone />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                      {errors?.settings?.[i]?.other && (
                        <Typography variant="caption" color="error">
                          {errors?.settings?.[i]?.other}
                        </Typography>
                      )}
                    </>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" variant="caption" color="textSecondary">
                    For correctly saving the strategy, it is necessary to fill in all ranges of the terms from 1 to 1000
                  </Typography>
                </Grid>
                {errors.range?.length > 0 && (
                  <Grid item xs={12}>
                    {errors.range.map((error, i) => (
                      <Typography component="p" key={i} variant="caption" color="error">
                        {error}
                      </Typography>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddCircleOutlineTwoTone />}
                    onClick={() => {
                      const errorsTemp = { ...errors };
                      if (errorsTemp.range?.length > 0) {
                        delete errorsTemp.range;
                      }
                      setErrors(errorsTemp);
                      setProductRateStrategySettings(prev => [
                        ...prev,
                        { rate: null, tenor_from: null, tenor_to: null },
                      ]);
                    }}>
                    Add new row
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider className={classes.my2} />
            <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={() => redirect('/product_rate_strategies')}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                type="submit">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

const ProductRateStrategiesCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);

export default ProductRateStrategiesCreate;
