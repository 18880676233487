import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { timezone } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const ActivateExtensionDialog = ({ isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const [extensionDate, setExtensionDate] = useState(new Date());
  const [time, setTime] = useState(false);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Activate extension.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        {time ? (
          <DateTimePicker
            label="Extension date"
            className={classes.fullWidth}
            value={timezone.shift(extensionDate)}
            onChange={value => setExtensionDate(timezone.unshift(value))}
          />
        ) : (
          <DatePicker
            label="Extension date"
            className={classes.fullWidth}
            value={timezone.shift(extensionDate)}
            onChange={value => setExtensionDate(timezone.setTime(timezone.unshift(value), 2, 0, 0))}
          />
        )}
        <FormControlLabel
          control={<Checkbox checked={time} onChange={() => setTime(() => !time)} />}
          label="Change extension time"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(extensionDate)} color="primary" disabled={!extensionDate}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActivateExtensionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
