import React from 'react';
import { useField } from 'react-final-form';
import { useNotify, useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import { AUDIENCE_FILTER_CONDITION_CHOICES } from '../../../constants';

const AffiliateCreditsParamsInput = () => {
  const [affiliatesList, setAffiliatesList] = React.useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('affiliate_credits');

  React.useEffect(() => {
    dataProvider
      .query('affiliates?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => {
        const affiliates = data.map(item => ({ id: item.id, name: item.name, state: item.state }));
        setAffiliatesList(affiliates);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.filter_by_date)} required>
            <InputLabel id="sorton-select-label">Filter by date</InputLabel>
            <Select
              labelId="sorton-select-label"
              value={value && value.filter_by_date ? value.filter_by_date : ''}
              onChange={e => onChange({ ...(value || {}), filter_by_date: e.target.value })}>
              <MenuItem value="loan_disbursed_date">Loan disbursed date</MenuItem>
              <MenuItem value="application_created_date">Application created date</MenuItem>
            </Select>
            {touched && error && error.filter_by_date && <FormHelperText>{error.filter_by_date}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.filter_by_affiliates)}>
            <InputLabel id="affiliates-select-label">Affiliates</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="affiliates-select-label"
              value={value && value.filter_by_affiliates ? [...value.filter_by_affiliates] : []}
              onChange={e => onChange({ ...(value || {}), filter_by_affiliates: e.target.value })}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                  {selected.map(id => (
                    <Chip className={classes.chip} key={id} label={affiliatesList.find(a => a.id === id)?.name} />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {affiliatesList.map((item, idx) => (
                <MenuItem key={idx} value={item.id}>
                  {item.state === 'disabled' ? `${item.name} (${item.state})` : item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.filter_by_affiliates && (
              <FormHelperText>{error.filter_by_affiliates}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <InputLabel error={!!(touched && error && error.filter_by_dpd)} className={classes.mb1}>
            Filter by DPD
          </InputLabel>
          <Box className={classes.select}>
            <FormControl style={{ minWidth: 150 }} error={!!(touched && error && error.filter_by_dpd_sign)}>
              <InputLabel id="dpd-select-label">Sign</InputLabel>
              <Select
                value={value && value.filter_by_dpd ? value.filter_by_dpd.sign : ''}
                onChange={e =>
                  onChange({
                    ...(value || {}),
                    filter_by_dpd: {
                      ...(value?.filter_by_dpd || {}),
                      sign: e.target.value,
                    },
                  })
                }
                labelId="dpd-select-label">
                {AUDIENCE_FILTER_CONDITION_CHOICES.map(i => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
              {touched && error ? (
                <FormHelperText>{error.filter_by_dpd_sign || ' '}</FormHelperText>
              ) : (
                <FormHelperText> </FormHelperText>
              )}
            </FormControl>
            <TextField
              onChange={e =>
                onChange({
                  ...(value || {}),
                  filter_by_dpd: {
                    ...(value?.filter_by_dpd || {}),
                    value: +e.target.value,
                  },
                })
              }
              value={value && value.filter_by_dpd ? value.filter_by_dpd.value : ''}
              className={classes.ml}
              type="number"
              label="Value"
              size="small"
              fullWidth
              error={!!(touched && error && error.filter_by_dpd_value)}
              helperText={touched && error ? error.filter_by_dpd_value || ' ' : ' '}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AffiliateCreditsParamsInput;
