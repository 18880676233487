import React from 'react';
import PropTypes from 'prop-types';
import { Edit, SaveButton, SimpleForm, NumberInput, Toolbar, usePermissions } from 'react-admin';
import { invalidateCache } from '../../hooks';

const DefaultVariablesEditToolbar = ({ isEditable, ...props }) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" disabled={!isEditable} />
  </Toolbar>
);

DefaultVariablesEditToolbar.propTypes = {
  isEditable: PropTypes.bool,
};

const FormWrapper = ({ save, ...props }) => {
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_DEFAULT_VARIABLES_EDIT');

  const handleSave = (data, ...rest) => {
    save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest]);
    invalidateCache('default_variables');
  };

  return (
    <SimpleForm toolbar={<DefaultVariablesEditToolbar isEditable={isEditable} />} save={handleSave} {...props}>
      <NumberInput disabled source="loan_count" />
      <NumberInput disabled={!isEditable} source="default_tenor" />
      <NumberInput disabled={!isEditable} source="default_principal" />
    </SimpleForm>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    default_tenor: PropTypes.number,
    default_principal: PropTypes.number,
  }),
};

const DefaultVariablesEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default DefaultVariablesEdit;
