import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  FormWithRedirect,
  maxLength,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ListIcon from '@material-ui/icons/List';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { AUDIENCE_FILTERS_IDS, CHOICES } from '../../constants';
import { formatChoices, formatFilter } from '../../utils';
import { Filter } from './Filter';
import { audienceFormValidator } from './utils/audienceFormValidator';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btn: {
    fontSize: 11,
    marginRight: 5,
    marginBottom: 5,
  },
  chip: {
    fontSize: '14px',
    backgroundColor: '#C2C2C1',
  },
}));

const FormWrapper = ({ save, filters, ...props }) => {
  const classes = useStyles();
  const [activeType, setActiveType] = useState('');
  const [filterName, setFilterName] = useState([]);

  let filterObject = {};

  AUDIENCE_FILTERS_IDS.forEach(({ id, index, ...rest }) => {
    filterObject = {
      ...filterObject,
      [id]: form => (
        <Filter
          key={index}
          id={id}
          form={form}
          choice={formatChoices(filters.find(i => i.id === id)?.description?.allowed_values || [])}
          {...rest}
        />
      ),
    };
  });

  const handleChange = event => setFilterName(event.target.value);

  const handleChangeActiveType = e => {
    setActiveType(e.target.value);
    setFilterName([]);
  };

  return (
    <FormWithRedirect
      save={(
        {
          registration_date,
          name,
          type,
          user_is_blocked,
          last_application_rejection_reason,
          last_application_state,
          last_call_result_promo,
          last_call_result_unfinished_registration,
          last_call_result_unsigned_request,
          last_loan_state,
          registration_step,
          gender_filter,
          age_filter,
          employment_types,
          education_level,
          closed_loan_number,
          days_since_last_rejected_application,
          days_since_last_user_unlock,
          extension_number,
          last_loan_max_dpd,
          user_days_before_birthday,
          days_without_loan,
          last_moratorium_days,
          user_in_blacklist,
          last_sent_sms_template,
          last_user_activity,
          last_loan_has_active_promo_code,
          last_loan_sequence_number,
          last_loan_term,
          last_loan_type,
          registration_date_from_today,
          last_loan_collection_score_group,
          last_loan_collection_score_priority,
          dpd,
          collection_group,
          last_loan_tenor,
          collector,
          days_from_the_last_call_client,
          days_from_the_last_call_tpc,
          days_from_the_last_call,
          days_from_the_last_action_client,
          days_from_the_last_action_tpc,
          days_from_the_last_action,
          days_from_last_ptp_date,
          days_from_last_ptp_created,
          ptp_stage,
          total,
          give_out_principal,
          outstanding_principal,
          outstanding_commission,
          outstanding_interest,
          outstanding_past_due_interest,
          last_contact_type_client_phone,
          last_contact_type_client_promise_phone,
          days_from_last_payment_date,
          last_logged_into_personal_account_general,
          last_logged_into_short_personal_account,
          last_logged_into_short_extension,
          external_agency,
          user_tag,
          last_approved_loan_amount,
          days_from_last_auto_debt_success_date,
          bank_filter,
          number_of_active_loan_days_filter,
          last_digits_of_loan_id,
          last_digits_of_client_id,
          last_2_digits_of_loan_id,
          last_2_digits_of_client_id,
          creation_of_direct_debit,
          application_risk_score,
          application_has_risk_score,
          user_has_push_available,
          active_discount_offer,
          discount_offer_amount,
          discount_offer_amount_to_pay,
          discount_offer,
          discount_offer_days_active,
          discount_offer_days_before_end,
          discount_offer_ends_at,
          discount_offer_state,
          loan_has_direct_debit,
          client_has_charge_back,
          loan_has_charge_back,
          state_of_last_auto_debit_request,
          last_loan_product_name,
          extension_percentage_of_principal,
          bank_account_closed,
        },
        ...rest
      ) => {
        const filtersIDs = {
          registration_date: formatFilter('date_range', 'registration_date', registration_date),
          user_is_blocked: formatFilter('boolean', 'user_is_blocked', user_is_blocked),
          user_in_blacklist: formatFilter('boolean', 'user_in_blacklist', user_in_blacklist),
          last_application_rejection_reason: formatFilter(
            'list',
            'last_application_rejection_reason',
            last_application_rejection_reason,
          ),
          last_application_state: formatFilter('list', 'last_application_state', last_application_state),
          last_call_result_promo: formatFilter('list', 'last_call_result_promo', last_call_result_promo),
          last_call_result_unfinished_registration: formatFilter(
            'list',
            'last_call_result_unfinished_registration',
            last_call_result_unfinished_registration,
          ),
          last_call_result_unsigned_request: formatFilter(
            'list',
            'last_call_result_unsigned_request',
            last_call_result_unsigned_request,
          ),
          last_loan_state: formatFilter('list', 'last_loan_state', last_loan_state),
          registration_step: formatFilter('list', 'registration_step', registration_step),
          closed_loan_number: formatFilter('sign', 'closed_loan_number', closed_loan_number),
          gender_filter: formatFilter('list', 'gender_filter', gender_filter),
          age_filter: formatFilter('sign', 'age_filter', age_filter),
          employment_types: formatFilter('list', 'employment_types', employment_types),
          education_level: formatFilter('list', 'education_level', education_level),
          days_since_last_rejected_application: formatFilter(
            'sign',
            'days_since_last_rejected_application',
            days_since_last_rejected_application,
          ),
          days_since_last_user_unlock: formatFilter('sign', 'days_since_last_user_unlock', days_since_last_user_unlock),
          extension_number: formatFilter('sign', 'extension_number', extension_number),
          last_loan_max_dpd: formatFilter('sign', 'last_loan_max_dpd', last_loan_max_dpd),
          user_days_before_birthday: formatFilter('sign', 'user_days_before_birthday', user_days_before_birthday),
          days_without_loan: formatFilter('sign', 'days_without_loan', days_without_loan),
          last_moratorium_days: formatFilter('sign', 'last_moratorium_days', last_moratorium_days),
          last_user_activity: formatFilter('sign', 'last_user_activity', last_user_activity),
          last_sent_sms_template: formatFilter('list', 'last_sent_sms_template', last_sent_sms_template),
          last_loan_has_active_promo_code: formatFilter(
            'boolean',
            'last_loan_has_active_promo_code',
            last_loan_has_active_promo_code,
          ),
          last_loan_sequence_number: formatFilter('sign', 'last_loan_sequence_number', last_loan_sequence_number),
          last_loan_term: formatFilter('sign', 'last_loan_term', last_loan_term),
          last_loan_type: formatFilter('list', 'last_loan_type', last_loan_type),
          last_loan_collection_score_group: formatFilter(
            'list',
            'last_loan_collection_score_group',
            last_loan_collection_score_group,
          ),
          last_loan_collection_score_priority: formatFilter(
            'list',
            'last_loan_collection_score_priority',
            last_loan_collection_score_priority,
          ),
          registration_date_from_today: formatFilter(
            'sign',
            'registration_date_from_today',
            registration_date_from_today,
          ),
          dpd: formatFilter('sign', 'dpd', dpd),
          collection_group: formatFilter('list', 'collection_group', collection_group),
          last_loan_tenor: formatFilter('sign', 'last_loan_tenor', last_loan_tenor),
          collector: formatFilter('list', 'collector', collector),
          days_from_the_last_call_client: formatFilter(
            'sign',
            'days_from_the_last_call_client',
            days_from_the_last_call_client,
          ),
          days_from_the_last_call_tpc: formatFilter('sign', 'days_from_the_last_call_tpc', days_from_the_last_call_tpc),
          days_from_the_last_call: formatFilter('sign', 'days_from_the_last_call', days_from_the_last_call),
          days_from_the_last_action_client: formatFilter(
            'sign',
            'days_from_the_last_action_client',
            days_from_the_last_action_client,
          ),
          days_from_the_last_action_tpc: formatFilter(
            'sign',
            'days_from_the_last_action_tpc',
            days_from_the_last_action_tpc,
          ),
          days_from_the_last_action: formatFilter('sign', 'days_from_the_last_action', days_from_the_last_action),
          days_from_last_ptp_date: formatFilter('sign', 'days_from_last_ptp_date', days_from_last_ptp_date),
          days_from_last_ptp_created: formatFilter('sign', 'days_from_last_ptp_created', days_from_last_ptp_created),
          ptp_stage: formatFilter('list', 'ptp_stage', ptp_stage),
          total: formatFilter('sign', 'total', total),
          give_out_principal: formatFilter('sign', 'give_out_principal', give_out_principal),
          outstanding_principal: formatFilter('sign', 'outstanding_principal', outstanding_principal),
          outstanding_commission: formatFilter('sign', 'outstanding_commission', outstanding_commission),
          outstanding_interest: formatFilter('sign', 'outstanding_interest', outstanding_interest),
          outstanding_past_due_interest: formatFilter(
            'sign',
            'outstanding_past_due_interest',
            outstanding_past_due_interest,
          ),
          last_contact_type_client_phone: formatFilter(
            'sign',
            'last_contact_type_client_phone',
            last_contact_type_client_phone,
          ),
          last_contact_type_client_promise_phone: formatFilter(
            'sign',
            'last_contact_type_client_promise_phone',
            last_contact_type_client_promise_phone,
          ),
          days_from_last_payment_date: formatFilter('sign', 'days_from_last_payment_date', days_from_last_payment_date),
          last_logged_into_personal_account_general: formatFilter(
            'sign',
            'last_logged_into_personal_account_general',
            last_logged_into_personal_account_general,
          ),
          creation_of_direct_debit: formatFilter('boolean', 'creation_of_direct_debit', creation_of_direct_debit),
          application_risk_score: formatFilter('sign', 'application_risk_score', application_risk_score),
          application_has_risk_score: formatFilter('boolean', 'application_has_risk_score', application_has_risk_score),
          last_logged_into_short_personal_account: formatFilter(
            'sign',
            'last_logged_into_short_personal_account',
            last_logged_into_short_personal_account,
          ),
          last_logged_into_short_extension: formatFilter(
            'sign',
            'last_logged_into_short_extension',
            last_logged_into_short_extension,
          ),
          external_agency: formatFilter('list', 'external_agency', external_agency),
          user_tag: formatFilter('list', 'user_tag', user_tag),
          last_approved_loan_amount: formatFilter('sign', 'last_approved_loan_amount', last_approved_loan_amount),
          days_from_last_auto_debt_success_date: formatFilter(
            'sign',
            'days_from_last_auto_debt_success_date',
            days_from_last_auto_debt_success_date,
          ),
          bank_filter: formatFilter('list', 'bank_filter', bank_filter),
          number_of_active_loan_days_filter: formatFilter(
            'sign',
            'number_of_active_loan_days_filter',
            number_of_active_loan_days_filter,
          ),
          last_digits_of_loan_id: formatFilter('last_digits', 'last_digits_of_loan_id', last_digits_of_loan_id),
          last_digits_of_client_id: formatFilter('last_digits', 'last_digits_of_client_id', last_digits_of_client_id),
          last_2_digits_of_loan_id: formatFilter('last_digits', 'last_2_digits_of_loan_id', last_2_digits_of_loan_id),
          last_2_digits_of_client_id: formatFilter(
            'last_digits',
            'last_2_digits_of_client_id',
            last_2_digits_of_client_id,
          ),
          user_has_push_available: formatFilter('boolean', 'user_has_push_available', user_has_push_available),
          active_discount_offer: formatFilter('boolean', 'active_discount_offer', active_discount_offer),
          discount_offer_amount: formatFilter('sign', 'discount_offer_amount', discount_offer_amount),
          discount_offer_amount_to_pay: formatFilter(
            'sign',
            'discount_offer_amount_to_pay',
            discount_offer_amount_to_pay,
          ),
          discount_offer: formatFilter('list', 'discount_offer', discount_offer),
          discount_offer_days_active: formatFilter('sign', 'discount_offer_days_active', discount_offer_days_active),
          discount_offer_days_before_end: formatFilter(
            'sign',
            'discount_offer_days_before_end',
            discount_offer_days_before_end,
          ),
          discount_offer_ends_at: formatFilter('date_range', 'discount_offer_ends_at', discount_offer_ends_at),
          discount_offer_state: formatFilter('list', 'discount_offer_state', discount_offer_state),
          loan_has_direct_debit: formatFilter('boolean', 'loan_has_direct_debit', loan_has_direct_debit),
          client_has_charge_back: formatFilter('boolean', 'client_has_charge_back', client_has_charge_back),
          loan_has_charge_back: formatFilter('boolean', 'loan_has_charge_back', loan_has_charge_back),
          state_of_last_auto_debit_request: formatFilter(
            'list',
            'state_of_last_auto_debit_request',
            state_of_last_auto_debit_request,
          ),
          last_loan_product_name: formatFilter('list', 'last_loan_product_name', last_loan_product_name),
          extension_percentage_of_principal: formatFilter(
            'sign',
            'extension_percentage_of_principal',
            extension_percentage_of_principal,
          ),
          bank_account_closed: formatFilter('boolean', 'bank_account_closed', bank_account_closed),
        };
        const newFilters = [];
        filterName.forEach(i => {
          newFilters.push(filtersIDs[i.id]);
        });
        const params = {
          name,
          type,
          filters: newFilters,
        };
        save(...[params, ...rest]);
      }}
      // eslint-disable-next-line unused-imports/no-unused-vars
      validate={({ name, type, ...params }) => {
        return audienceFormValidator(filters, params);
      }}
      {...props}
      render={formProps => (
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6" gutterBottom={false}>
                  Create new audience
                </Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInput className={classes.mt4} source="name" validate={[maxLength(255)]} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInput source="type" choices={CHOICES} onChange={handleChangeActiveType} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel>Filters</InputLabel>
                      <Select
                        multiple
                        value={filterName}
                        onChange={handleChange}
                        input={<FilledInput label="Filters" />}
                        disabled={!activeType}
                        renderValue={selected => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                            {selected.map(({ id }) => (
                              <Chip
                                className={classes.chip}
                                key={id}
                                label={filterObject[id](formProps.form)?.props?.title}
                              />
                            ))}
                          </Box>
                        )}>
                        {filters.map(filter =>
                          filter.supported_audience_types.includes(activeType) ? (
                            filterObject[filter.id] ? (
                              <MenuItem key={filter.id} value={filter}>
                                {filterObject[filter.id](formProps.form)?.props?.title}
                              </MenuItem>
                            ) : null
                          ) : null,
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {filterName.map(filter => (
                <Fragment key={filter.id}>{filterObject[filter.id](formProps.form)}</Fragment>
              ))}
              <Box p={2}>
                <SaveButton
                  className={classes.btn}
                  size="small"
                  icon={<ListIcon />}
                  label="Save and back to list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect="list"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    />
  );
};

FormWrapper.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  save: PropTypes.func,
};

FormWrapper.defaultProps = {
  filters: [],
};

export default ({ ...props }) => {
  const notify = useNotify();
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query('mass_sending_audiences/filters', { method: 'GET' })
      .then(({ data }) => setFilters(data))
      .catch(error => setError(error));

    return () => {
      setFilters([]);
    };
  }, [dataProvider]);

  if (error?.message) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Create component="div" {...props}>
      <FormWrapper filters={filters} />
    </Create>
  );
};
