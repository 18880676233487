import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  List,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { timezone, calculateAge, formatDatetime, formatPhoneNumber, formatChoices } from '../../utils';
import { CALL_CENTER_USER_STATUS_UNCOMPLETED_REG } from '../../constants';
import CallCenterLogDialog from './components/CallCenterLogDialog';
import CallCenterDropdownDialog from './components/CallCenterDropdownDialog';
import {
  Pagination,
  CallCenterLogTable,
  ListFilterHOC,
  DateFilterInput,
  QuickFilterInput,
  AsyncSelectInput,
} from '../../components';
import { ListActions } from '../user/UserList';
import { useHandbook } from '../../hooks';
import { assignEssence, changeStatus } from './actions';
import ListFilter from './components/ListFilter';
import RedMark from './components/RedMark';

const residenceDistrictValues = ['Monaragala', 'Batticaloa', 'Mannar', 'Mullaitivu'];

const UncompletedListFilter = props => {
  const { choices: employmentTypeChoices } = useHandbook('employments');

  return (
    <ListFilter {...props}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="Registration step" source="registration_step" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <TextInput label="First name" source="profile->first_name" />
      <TextInput label="Father surname" source="profile->last_name" />
      <TextInput label="Last name" source="profile->last_name" />
      <TextInput label="Document number" source="userDocuments->number" />
      <NumberInput label="Phone number" source="profile->phone_number" />
      <TextInput label="Email" source="profile->email" />
      <TextInput label="Account number" source="bankAccount->account_number" />
      <SelectInput label="Empl. type" source="employment->type->code" choices={employmentTypeChoices} />
      <QuickFilterInput source="sleeping_clients" label="Sleeping clients" defaultValue={true} />
      <SelectInput
        label="Last call result"
        source="phone_call_last_result"
        choices={formatChoices(CALL_CENTER_USER_STATUS_UNCOMPLETED_REG)}
      />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{
          resource: 'affiliates',
          payload: {
            pagination: { page: 1 },
            sort: { field: 'id', order: 'DESC' },
            filter: { page: 1, items_per_page: 100 },
          },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <AsyncSelectInput
        label="Operator"
        source="phone_call_operator"
        query={{
          resource: 'admins?role_permission=CAN_BE_PHONE_CALL_OPERATOR',
          payload: { pagination: {}, sort: {}, filter: {} },
        }}
        mapper={({ id, username }) => ({ value: id, name: username })}
        type="getList"
        additionalData={[{ id: 'null', username: 'Not assign' }]}
      />
      <NumberInput label="Age less" source="age|lt" />
      <NumberInput label="Age greater" source="age|gt" />
      <DateFilterInput label="Assigned before" source="phone_call_assigned_at|before" before />
      <DateFilterInput label="Assigned after" source="phone_call_assigned_at|after" after />
    </ListFilter>
  );
};

const CallCenterList = ({ permissions, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [refreshedAt, setRefreshedAt] = useState(null);
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [userIdLog, setUserIdLog] = useState(0);
  const [userIdStatus, setUserIdStatus] = useState(0);

  const showFullPhone = !permissions?.includes('CAN_SEE_FULL_PHONE');
  const canEdit = permissions?.includes('CAN_PHONE_CALL_EDIT');

  return (
    <>
      <CallCenterLogDialog open={openLogDialog} close={setOpenLogDialog} title="User status log">
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'user.id': userIdLog, context: 'user_uncompleted_registrations' }}
          isDialog
        />
      </CallCenterLogDialog>
      <CallCenterDropdownDialog
        title="Change user status"
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(userStatus, dataProvider, notify, setOpenDropdownDialog, refresh, 'phone_calls', {
            user_id: userIdStatus,
            result: userStatus,
            context: 'user_uncompleted_registrations',
          })
        }
        selectOptions={CALL_CENTER_USER_STATUS_UNCOMPLETED_REG}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<UncompletedListFilter />}
        actions={<ListActions />}
        permissions={permissions}
        {...props}>
        <Datagrid rowClick="edit">
          <TextField key="id" source="id" />
          <FunctionField
            key="phone_number"
            source="phone_number"
            render={({ phone_number, birthday, employment_type, residence_district }) => {
              const age = calculateAge(
                new Date(birthday).getMonth(),
                new Date(birthday).getDate(),
                new Date(birthday).getFullYear(),
                timezone.shift(new Date()),
              );
              const phone = phone_number && formatPhoneNumber(phone_number, showFullPhone);
              if (age < 20) {
                return <RedMark phone={phone} title={'Age < 20'} />;
              } else if (employment_type === 'Temporarily Unemployed') {
                return <RedMark phone={phone} title={'Employment type is a ' + employment_type} />;
              } else if (residenceDistrictValues.includes(residence_district)) {
                return <RedMark phone={phone} title={'Residence district is a ' + residence_district} />;
              } else {
                return phone;
              }
            }}
          />
          <TextField key="first_name" source="first_name" />
          <TextField key="last_name" source="last_name" />
          <TextField key="gender" source="gender" />
          <TextField key="employment_type" source="employment_type" />
          <FunctionField
            label="Registration date"
            key="created_at"
            render={({ created_at }) => formatDatetime(created_at)}
          />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.user_uncompleted_registrations?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setUserIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={!canEdit}>
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setUserIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}>
                        Log
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(dataProvider, notify, refresh, 'phone_call_user_assigns', {
                        user_id: id,
                        context: 'user_uncompleted_registrations',
                      });
                    }}
                    disabled={!canEdit}>
                    Assign
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            label="Operator"
            render={({ last_phone_calls_by_context }) => (
              <Chip
                label={last_phone_calls_by_context?.user_uncompleted_registrations?.operator || 'none'}
                size="small"
              />
            )}
          />
          <FunctionField
            label="Last call result"
            render={({ last_phone_calls_by_context }) => (
              <Chip
                label={last_phone_calls_by_context?.user_uncompleted_registrations?.result || 'none'}
                size="small"
              />
            )}
          />
          <FunctionField
            label="Last activity"
            render={({ last_phone_calls_by_context }) =>
              formatDatetime(last_phone_calls_by_context?.user_uncompleted_registrations?.called_at)
            }
          />
          <FunctionField
            label="Step"
            source="registration_step"
            render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
          />
          <FunctionField
            label="Affiliate"
            source="affiliate"
            render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
          />
          <FunctionField
            render={record => (
              <Link
                href={`/admin#/uncompleted_registration/${record.id}`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <IconButton onClick={e => e.stopPropagation()}>
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </Link>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

CallCenterList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const CallCenterUncompletedList = ListFilterHOC(CallCenterList, { has_application: false });

export default CallCenterUncompletedList;
