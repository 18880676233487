import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  EmailField,
  usePermissions,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, Call } from '../../components';
import { formatDatetime, formatPhoneNumber } from '../../utils';

const useStyles = makeStyles(() => ({
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
}));

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const LandingLeadsList = props => {
  const { permissions = [] } = usePermissions();
  const classes = useStyles();

  const canSeePhone = !permissions.includes('CAN_SEE_FULL_PHONE');

  return (
    <List
      pagination={<Pagination />}
      actions={<ListActions />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}>
      <Datagrid rowClick={undefined}>
        <TextField source="id" sortable={false} />
        <FunctionField
          label="Name"
          sortable={false}
          render={({ user_id, full_name = '' }) =>
            user_id ? (
              <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
                {`${full_name} #${user_id}`}
              </Link>
            ) : (
              full_name || '---'
            )
          }
        />
        <TextField label="CURP" source="curp" sortable={false} />
        <FunctionField
          source="phone_number"
          sortable={false}
          render={({ phone_number, user_id }) =>
            user_id ? (
              <Call userId={user_id} userPhone={phone_number}>
                {formatPhoneNumber(phone_number, canSeePhone)}
              </Call>
            ) : phone_number ? (
              formatPhoneNumber(phone_number, canSeePhone)
            ) : (
              '---'
            )
          }
        />
        <EmailField source="email" sortable={false} />
        <TextField className={classes.breakableCell} label="Target URL" source="target_url" sortable={false} />
        <FunctionField
          label="Created at"
          source="created_at"
          render={({ created_at }) => formatDatetime(created_at)}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default LandingLeadsList;
