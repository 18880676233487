import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from '@material-ui/core';
import { phoneNumber } from '../../utils';
import {
  DAYS_MODE_DOW,
  DAYS_MODE_DOM,
  WEEK_DAYS,
  MONTH_DAYS,
  USER_NOTIFICATION_MESSAGE_TYPE_CHOICES,
} from '../../constants';
import { Autocomplete } from '../../components';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [daysMode, setDaysMode] = useState(DAYS_MODE_DOW);
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [errors, setErrors] = useState({});

  const classes = useStyles();

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  return (
    <FormWithRedirect
      save={(
        { is_enabled, name, type, text, telegram_link_url, whatsapp_link_url, phone_number, can_be_closed, audience },
        ...rest
      ) => {
        const validationErrors = {};
        if (daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }

        save(
          ...[
            {
              is_enabled: is_enabled || false,
              name,
              type,
              text,
              telegram_link_url: telegram_link_url || null,
              whatsapp_link_url: whatsapp_link_url || null,
              phone_number: phone_number || null,
              can_be_closed,
              audience,
              params: {
                week_days: daysMode === DAYS_MODE_DOW ? weekDays : [],
                month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
              },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => {
        return (
          <form>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} sm={8}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="h6" gutterBottom={false}>
                        Create user notification campaign
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <BooleanInput label="Enabled" source="is_enabled" />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          helperText={false}
                          source="type"
                          validate={[required()]}
                          choices={USER_NOTIFICATION_MESSAGE_TYPE_CHOICES}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          multiline
                          resettable
                          source="text"
                          validate={[required(), maxLength(1000)]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput source="telegram_link_url" fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput source="whatsapp_link_url" fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          source="phone_number"
                          fullWidth
                          placeholder={phoneNumber.placeholder}
                          parse={phoneNumber.parser}
                          format={v => phoneNumber.formatter(v)}
                          validate={[phoneNumber.raValidator()]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <BooleanInput label="Make message closeable?" source="can_be_closed" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="mass_sending_audiences"
                          source="audience"
                          optionValueProp="id"
                          optionLabelProp="name"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup row className={classes.mb2}>
                          <FormControlLabel
                            value={DAYS_MODE_DOW}
                            control={<Radio />}
                            label="Days of week"
                            checked={daysMode === DAYS_MODE_DOW}
                            onChange={() => {
                              setDaysMode(DAYS_MODE_DOW);
                              setMonthDays([]);
                              setErrors({});
                            }}
                          />

                          <FormControlLabel
                            value={DAYS_MODE_DOM}
                            control={<Radio />}
                            label="Days of month"
                            checked={daysMode === DAYS_MODE_DOM}
                            onChange={() => {
                              setDaysMode(DAYS_MODE_DOM);
                              setWeekDays([]);
                              setErrors({});
                            }}
                          />
                        </RadioGroup>

                        {daysMode === DAYS_MODE_DOW && (
                          <>
                            <div>
                              {WEEK_DAYS.map((day, idx) => (
                                <FormControlLabel
                                  key={day}
                                  control={
                                    <Checkbox
                                      onChange={() => onChangeWeekDay(idx + 1)}
                                      checked={weekDays.includes(idx + 1)}
                                    />
                                  }
                                  label={day}
                                />
                              ))}
                            </div>
                            <div>
                              {errors.week_days && (
                                <Typography color="error" variant="caption">
                                  {errors.week_days}
                                </Typography>
                              )}
                            </div>
                          </>
                        )}

                        {daysMode === DAYS_MODE_DOM && (
                          <>
                            <div className={classes.domList}>
                              {MONTH_DAYS.map(({ value, label }) => (
                                <div key={value} className={classes.domItem}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={() => onChangeMonthDay(value)}
                                        checked={monthDays.includes(value)}
                                      />
                                    }
                                    label={label}
                                  />
                                </div>
                              ))}
                            </div>
                            <div>
                              {errors.month_days && (
                                <Typography color="error" variant="caption">
                                  {errors.month_days}
                                </Typography>
                              )}
                            </div>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" gutterBottom={false}>
                          IMPORTANT! Please note that the sending campaign will not work until the next day at the
                          earliest! For example, if today is Monday and the campaign is now set to send on Monday, then
                          it will not send today.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider className={classes.my2} />
                  <SaveButton
                    label="Submit"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Paper>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const UserNotificationCampaignCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
export default UserNotificationCampaignCreate;
