import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  pxy0: {
    padding: 0,
  },
  pr20: {
    paddingRight: theme.spacing(20),
  },
  manualVerificationResultSelect: {
    width: theme.spacing(18),
    right: 0,
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  m2: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  nonClickableBackdrop: {
    pointerEvents: 'none',

    '& .MuiPaper-root': {
      pointerEvents: 'auto',
    },
  },
  modalTitle: {
    cursor: 'move',
  },
  minValueInput: {
    width: theme.spacing(10),
    marginLeft: theme.spacing(1),
  },
  my1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mx2: {
    margin: '0 '.concat(theme.spacing(2)).concat('px'),
  },
  executedTransactionCheckbox: {
    margin: `-${theme.spacing(2)}px `.repeat(3).trim(),
  },
  redChip: {
    color: 'white',
    backgroundColor: red[500],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  gap: {
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));
