import { clabe } from 'clabe-validator';

/* eslint-disable no-undef */
export const time = value => {
  if (typeof value !== 'string' || !/^\d{2}:\d{2}:\d{2}$/.test(value)) return 'Invalid time format.';
  let hour = -1;
  let minute = -1;
  let second = -1;
  [hour, minute, second] = value.split(':').map(v => parseInt(v[0] === '0' ? v[1] : v));
  if (hour < 0 || hour > 23) return 'Invalid hour.';
  if (minute < 0 || minute > 59) return 'Invalid minute.';
  if (second < 0 || second > 59) return 'Invalid second.';
};

export const shortTimeValidator = value => {
  if (typeof value !== 'string' || !/^\d{2}:\d{2}$/.test(value)) return 'Invalid time format.';

  const [hourStr, minuteStr] = value.split(':');
  const hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);

  if (isNaN(hour) || hour < 0 || hour > 23) return 'Invalid hour.';
  if (isNaN(minute) || minute < 0 || minute > 59) return 'Invalid minute.';
};

export const symbolsValidator = (value, min, max) => {
  if (value === undefined) return undefined;
  const regex = new RegExp(`^.{${min},${max}}$`);
  if (!regex.test(value)) {
    return `Min symbols is ${min}, max ${max}`;
  }
};

export const phoneValidator = number => {
  if (typeof number !== 'string') {
    return;
  }

  if (/^\d{10,11}$/.test(number) === false) {
    return 'Phone number should consist of 9 or 10 digits';
  }
};

const bankBlackList = ['042', '059'];
// 042: { tag: 'MIFEL', name: 'Banca Mifel' }
// 059: { tag: 'INVEX', name: 'Banco Invex' }

export const clabeValidator = value => {
  if (value) {
    const clabeCheck = clabe.validate(value);
    if (clabeCheck.ok) {
      return bankBlackList.includes(clabeCheck.code.bank) ? 'Bank is in black list!' : null;
    } else {
      return clabeCheck.message;
    }
  }
  return null;
};

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

export const emailValidator = email => {
  if (typeof email !== 'string') return false;
  if (email === undefined || null) return false;
  if (email.includes(',')) {
    const emails = email.split(',');
    return emails.every(email => EMAIL_REGEX.test(email.trim()));
  } else {
    return EMAIL_REGEX.test(email);
  }
};

export const integerValidator =
  (message = 'Value must be an integer') =>
  value =>
    value && !Number.isInteger(value) ? message : undefined;

export const isValidDateString = dateString => {
  if (!dateString) {
    return undefined;
  }
  // Check if the date string matches the format 'DD.MM.YYYY'
  const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
  if (!dateString.match(datePattern)) {
    return 'Invalid date format. Must be DD.MM.YYYY';
  }

  // Parse the date parts to integers
  const [day, month, year] = dateString.split('.').map(Number);

  // Check if the parsed date is valid
  const date = new Date(year, month - 1, day);
  if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
    return 'Invalid date';
  }

  // Check if the date is in the past
  const today = new Date();
  const isInPast = date < new Date(today.getFullYear(), today.getMonth(), today.getDate());
  if (isInPast) {
    return 'Invalid date. The date should not be in past';
  }

  return undefined;
};

export const selectedValidator = value => (value && value === 1 ? 'Please fill in obligatory field' : undefined);

export const validateCardNumber = value => {
  if (typeof value !== 'string') {
    return false;
  }

  value = value.replace(/\D/g, '');

  if (value.length < 12 || value.length > 19) {
    return false;
  }

  let nCheck = 0;
  let bEven = false;

  for (let n = value.length - 1; n >= 0; n--) {
    let nDigit = parseInt(value.charAt(n), 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};
