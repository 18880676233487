import React from 'react';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

export const getAdminsList = (admins, code) => {
  const list = admins
    .filter(admin => admin.collection_groups.includes(code) && !admin.is_blocked)
    .map(item => item.username);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>
      {list.map(item => (
        <Chip key={item} size="small" label={item} />
      ))}
    </Box>
  );
};
