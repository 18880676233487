import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, DateFilterInput, CollapsedText } from '../../components';
import { formatDatetime, formatPhoneNumber } from '../../utils';
import { marginZeroStyles } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

export const NotificationListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <DateFilterInput label="Sent before" source="sent_at|before" before />
      <DateFilterInput label="Sent after" source="sent_at|after" after />
      <TextInput source="template->key" label="Template" />
      <SelectInput
        source="state"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'sent', name: 'Sent' },
          { id: 'error', name: 'Error' },
        ]}
      />
      <SelectInput
        source="delivery_status"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'cannot_be_known', name: 'Cannot be known' },
          { id: 'not_delivered', name: 'Not delivered' },
          { id: 'delivery_failed', name: 'Delivery failed' },
          { id: 'delivered', name: 'Delivered' },
          { id: 'viewed', name: 'Viewed' },
        ]}
      />
      <SelectInput
        source="transmitter_id"
        choices={[
          { id: 'fake', name: 'Fake' },
          { id: 'amazon_sns', name: 'SMS (Amazon)' },
          { id: 'airmovil', name: 'SMS (Airmovil)' },
          { id: 'wazzup', name: 'Wazzup' },
          { id: 'firebase_cloud_messaging', name: 'Push (FCM)' },
          { id: 'wamm_chat', name: 'Wamm chat' },
          { id: 'mailer', name: 'Mailer' },
        ]}
      />
    </Filter>
  );
};

export const NotificationListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

NotificationListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const NotificationList = props => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<NotificationListFilter />}
      actions={<NotificationListActions />}
      {...props}>
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          label="Created/Sent"
          source="createdAt"
          render={row => (
            <>
              <div>
                <b>Create:</b> {formatDatetime(row.created_at)}
              </div>
              <div>
                <b>Sent at:</b> {formatDatetime(row.sent_at)}
              </div>
            </>
          )}
        />
        <FunctionField
          render={row => (
            <>
              <div>
                <b>User:&nbsp;</b>
                {row.user_id ? (
                  <Link href={`#/users/${row.user_id}`} underline="none" target="_blank" rel="noreferrer">
                    #{row.user_id}
                  </Link>
                ) : (
                  <>---</>
                )}
              </div>
              <div>
                <b>Loan:&nbsp;</b>
                {row.loan_id ? (
                  <Link href={`#/loans/${row.loan_id}/show`} underline="none" target="_blank" rel="noreferrer">
                    #{row.loan_id}
                  </Link>
                ) : (
                  <>---</>
                )}
              </div>
              <div>
                <b>Appl:&nbsp;</b>
                {row.application_id ? (
                  <Link
                    href={`#/applications/${row.application_id}/show`}
                    underline="none"
                    target="_blank"
                    rel="noreferrer">
                    #{row.application_id}
                  </Link>
                ) : (
                  <>---</>
                )}
              </div>
            </>
          )}
        />
        <FunctionField
          label="Statuses"
          render={row => (
            <>
              <div>
                <b>Status:</b> {row.state}
              </div>
              <div>
                <b>Delivery status:</b> {row.delivery_status}
              </div>
            </>
          )}
        />
        <TextField source="transmitter_id" />
        <TextField source="template_key" label="Template" />
        <FunctionField
          label="Destination"
          render={row => (
            <div>
              {/^\d{10,11}$/.test(row.destination) ? formatPhoneNumber(row.destination, true) : row.destination}
            </div>
          )}
        />
        <FunctionField label="Content" render={row => <CollapsedText text={row.message} />} />
      </Datagrid>
    </List>
  );
};
