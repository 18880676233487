import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  uploadIcon: {
    marginBottom: '-20px',
    marginTop: '-24px',
  },
  hidden: {
    display: 'none',
  },
  my20: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  call: {
    textAlign: 'center',
    display: 'flex',
  },
  flexCenteredSpaced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  textRight: {
    textAlign: 'right',
  },
  red: {
    backgroundColor: '#f50057',
    '&:hover': {
      backgroundColor: 'rgb(206 11 81)',
    },
  },
  green: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  tagsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
}));
