import React from 'react';
import PropTypes from 'prop-types';
import { usePermissions } from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import webitelIcon from '../../img/webitel_icon.png';

const useStyles = makeStyles(theme => ({
  webitelLink: {
    width: 'fit-content',
    height: 16,
  },
  webitelImg: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}));

export const WebitelButton = ({ userPhone, loanId, userId }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const webitelDomain = process.env.REACT_APP_WEBITEL_ENTRYPOINT || 'https://webitel.credito-365.mx';
  const now = new Date();
  const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
  const linkParams = `/history/?fields=date&fields=time&fields=direction&fields=answeredAt&fields=bridgedAt&fields=queueBridgedAt&fields=joinedAt&fields=leavingAt&fields=hangupAt&fields=hangupPhrase&fields=reportingAt&fields=user&fields=extension&fields=from&fields=to&fields=destination&fields=gateway&fields=agent&fields=team&fields=queue&fields=member&fields=hangupBy&fields=duration&fields=tags&fields=display&fields=holdSec&fields=waitSec&fields=billSec&fields=talkSec&fields=reportingSec&fields=queueWaitSec&fields=queueDurationSec&fields=result&fields=sipCode&fields=cause&fields=hangupDisposition&fields=amdResult&fields=agentDescription&fields=blindTransfer&fields=grantee&fields=score&fields=ratedBy&fields=memberId&fields=attemptId&fields=contact&fields=variables.loan_id&fields=variables.user_id&fields=variables.dpd&from=${thirtyDaysAgo.getTime()}&page=1&size=300&`;
  let linkToWebitel = '';
  if (loanId) {
    linkToWebitel = `${webitelDomain}${linkParams}variable=loan_id=${loanId}`;
  }
  if (userId) {
    linkToWebitel = `${webitelDomain}${linkParams}variable=user_id=${userId}`;
  }
  if (userPhone) {
    let number = userPhone;
    if (number.startsWith('+52')) {
      number = number.replace('+52', '');
    }
    linkToWebitel = `${webitelDomain}${linkParams}search=${number}`;
  }

  return permissions.includes('CAN_WEBITEL_LINK_VIEW') && linkToWebitel ? (
    <Link href={linkToWebitel} target="_blank" rel="noopener noreferrer">
      <img src={webitelIcon} alt="webitel_button" className={classes.webitelImg} />
    </Link>
  ) : null;
};

WebitelButton.propTypes = {
  userPhone: PropTypes.string,
  loanId: PropTypes.number,
  userId: PropTypes.number,
};

WebitelButton.defaultProps = {
  userPhone: null,
  loanId: null,
  userId: null,
};
