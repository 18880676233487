import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { phoneNumber as phoneNumberValidator, symbolsValidator } from '../../utils';
import { useHandbook } from '../../hooks';
import { CONTACT_STATUS_LIST, CONTACT_VALIDATION_STATE_LIST } from '../../constants';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const EditUserContactsDialog = ({ isOpened, onClose, onSubmit, data }) => {
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberValidator.formatter(data.phone_number));
  const [name, setName] = useState(data.name);
  const [phoneType, setPhoneType] = useState(data.type);
  const [phoneRelation, setPhoneRelation] = useState(data.relation);
  const [phoneSource, setPhoneSource] = useState(data.source);
  const [status, setStatus] = useState(data.status);
  const [validationState, setValidationState] = useState(data.validation_state);
  const [note, setNote] = useState(data.note);
  const [errors, setErrors] = useState({});

  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const { data: contactRelationships } = useHandbook('phone_relations');
  const { data: phoneTypes } = useHandbook('phone_types');
  const { data: phoneSources } = useHandbook('phone_sources');

  const canPhoneBookEditStatus = permissions.includes('CAN_PHONE_BOOK_EDIT_STATUS');

  const validate = useCallback((type, value) => {
    if (type === 'phone' && !phoneNumberValidator.validator(phoneNumberValidator.parser(value))) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: 'Invalid phone number',
        };
      });
    } else if (type === 'name' && symbolsValidator(value, 2, 255) !== undefined) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: symbolsValidator(value, 2, 255),
        };
      });
    } else {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: undefined,
        };
      });
    }
  }, []);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add user contacts.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Phone number"
              value={phoneNumber}
              onChange={e =>
                setPhoneNumber(phoneNumberValidator.formatter(phoneNumberValidator.parser(e.target.value)))
              }
              autoComplete="false"
              onBlur={e => validate('phone', e.target.value)}
              name={'phone'}
              error={errors.phone !== undefined}
              helperText={errors.phone}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              name={'name'}
              error={errors.name !== undefined}
              helperText={errors.name}
              fullWidth
              label="Name"
              value={name || ''}
              onChange={e => setName(e.target.value)}
              onBlur={e => validate('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="locale-select-label">Contact Phone Type</InputLabel>
              <Select
                labelId="locale-select-label"
                value={phoneType}
                disabled
                onChange={e => {
                  setPhoneType(e.target.value);
                  if (e.target.value !== 'contact_person') {
                    setPhoneRelation(null);
                  }
                }}>
                {phoneTypes.map(i => (
                  <MenuItem key={i.code} value={i.code}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="locale-select-label">Contact Phone Source</InputLabel>
              <Select
                labelId="locale-select-label"
                value={phoneSource || ''}
                disabled
                onChange={e => setPhoneSource(e.target.value)}>
                {phoneSources.map(i => (
                  <MenuItem key={i.code} value={i.code}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {phoneType === 'contact_person' ? (
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="locale-select-label">Contact Phone Relation</InputLabel>
                <Select
                  labelId="locale-select-label"
                  value={phoneRelation || ''}
                  onChange={e => setPhoneRelation(e.target.value)}>
                  {contactRelationships.map(i => (
                    <MenuItem key={i.code} value={i.code}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {canPhoneBookEditStatus && (
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="add-user-contact-status-label">Status</InputLabel>
                <Select
                  labelId="add-user-contact-status-label"
                  value={status || ''}
                  onChange={e => setStatus(e.target.value)}>
                  {CONTACT_STATUS_LIST.map(({ code, label }) => (
                    <MenuItem key={code} value={code}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {canPhoneBookEditStatus && (
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="add-user-contact-validation-state-label" shrink>
                  Validation
                </InputLabel>
                <Select
                  labelId="add-user-contact-validation-state-label"
                  value={validationState || ''}
                  onChange={e => setValidationState(e.target.value)}
                  displayEmpty
                  renderValue={value => {
                    return CONTACT_VALIDATION_STATE_LIST.find(({ code }) => code === value)?.label || 'None';
                  }}>
                  <MenuItem value={null}>None</MenuItem>
                  {CONTACT_VALIDATION_STATE_LIST.map(({ code, label }) => (
                    <MenuItem key={code} value={code}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              margin="normal"
              multiline
              rows="3"
              fullWidth
              label="Note"
              value={note || ''}
              onChange={e => setNote(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() =>
            onSubmit(
              phoneNumberValidator.parser(phoneNumber),
              name,
              phoneType,
              note,
              phoneRelation,
              phoneSource,
              status,
              validationState,
            )
          }
          color="primary"
          disabled={
            !phoneNumber ||
            !name ||
            !phoneType ||
            !phoneSource ||
            errors.name ||
            errors.phone ||
            errors.status ||
            errors.validation_state
          }>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditUserContactsDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    phone_number: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    relation: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    validation_state: PropTypes.string,
    note: PropTypes.string,
  }),
};
