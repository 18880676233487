import { TIMEZONES, TIMEZONE_OFFSET } from '../constants';

export const getUserId = () => {
  const user = JSON.parse(localStorage.getItem('admin_fields'));

  if (user?.id) {
    return user.id;
  }

  return null;
};

export const getAdminTimezone = (replaceLocalTimezone = true) => {
  let savedTimezone = localStorage.getItem('admin_timezone');

  if (TIMEZONES.includes(savedTimezone) === false) {
    savedTimezone = TIMEZONES[0];

    localStorage.setItem('admin_timezone', savedTimezone);
  }

  if (replaceLocalTimezone && savedTimezone === 'Local') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return savedTimezone;
};

export const getAdminTimezoneOffset = () => {
  getAdminTimezone();

  const savedTimezone = localStorage.getItem('admin_timezone');

  return TIMEZONE_OFFSET[savedTimezone] ? TIMEZONE_OFFSET[savedTimezone]() : 0;
};
