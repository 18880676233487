import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  FormDataConsumer,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete, DateTimePickerInput } from '../../components';
import { PHONE_CONTACT, TRANSMITTER, AUDIENCE_TYPE_CHOICES } from '../../constants';
import { getUserAttachments } from '../../utils';
import { useHandbook, useEmailSources } from '../../hooks';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
  hidden: {
    display: 'none',
  },
  uploadIcon: {
    marginBottom: '-20px',
    marginTop: '-24px',
  },
  removeIcon: {
    padding: 0,
    margin: 0,
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [params, setParams] = useState([]);
  const [usePhoneBook, setUsePhoneBook] = useState(false);
  const [cmProfilesList, setCmProfilesList] = useState([]);
  const [isCmSelected, setIsCmSelected] = useState(false);
  const [templateFilter, setTemplateFilter] = useState({ is_enabled: true });
  const [scheduledAt] = useState(new Date());
  const [audienceTypeSelected, setAudienceTypeSelected] = useState('audience');
  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFileError, setLoadingFileError] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const attachments = getUserAttachments(files);

  const { isEmailSelected, handleFormChange } = useEmailSources();
  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: phoneTypeChoices } = useHandbook('phone_types');
  const { choices: phoneSourceChoices } = useHandbook('phone_sources');

  useEffect(() => {
    dataProvider
      .query('cm_profiles?page=1&items_per_page=30&is_pending=false', { method: 'GET' })
      .then(({ data }) => setCmProfilesList(data));
  }, [dataProvider]);

  const addItem = () => {
    setParams([
      ...params,
      {
        id: Date.now(),
        type: null,
        relation: null,
        source: null,
      },
    ]);
  };

  const removeItem = id => {
    setParams([...params.filter(item => item.id !== id)]);
  };

  const showOptions = value => {
    setUsePhoneBook(value);
    setParams([]);
  };

  const setSelectValue = (id, type, value) => {
    setParams([
      ...params.map(item => {
        if (item.id === id && type === 'type' && value !== 'contact_person') {
          item[type] = value;
          item.relation = null;
        } else if (item.id === id) {
          item[type] = value;
        }
        return item;
      }),
    ]);
  };

  const handleAttachFile = async e => {
    try {
      if (e.target.files[0]?.size > 10240000) {
        return notify('Maximum file size is 10 Mb', 'error');
      }
      setLoadingFile(true);
      const formData = new FormData();
      formData.append('file', e.target.files[0], 'file');
      const { data } = await dataProvider.query('files/users', { method: 'POST', body: formData });
      setFiles(prevState => [...prevState, data]);
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    } finally {
      setLoadingFile(false);
      loadingFileError && setLoadingFileError(!loadingFileError);
    }
  };

  const handleUnattachFile = id => setFiles(prevState => [...prevState.filter(i => i.id !== id)]);

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Create mass sending process
          </Typography>
          <Divider className={classes.my2} />
          <FormWithRedirect
            save={(
              {
                audience,
                transmitter_id,
                phone_contact,
                scheduled_at,
                name,
                alpha_name,
                template,
                channel,
                template_key,
                email_source,
                audience_type,
              },
              ...rest
            ) => {
              if (audience_type === 'file' && files.length === 0) {
                setLoadingFileError(true);
                return;
              }

              const channelValue = isEmailSelected ? email_source : channel;

              const payload = {
                audience_type,
                name: name ?? null,
                transmitter_id,
                phone_contact: phone_contact ?? null,
                channel: channelValue ?? null,
                scheduled_at,
                params: {
                  use_phone_book: usePhoneBook,
                  phone_book_filters: params,
                  alpha_name: alpha_name ?? null,
                },
              };
              if (audience_type === 'audience') {
                payload.audience = { id: audience };
              } else {
                payload.file = files[0].id;
              }
              if (transmitter_id === 'cm') {
                payload.template_key = template_key;
                payload.template = null;
              } else {
                payload.template = { id: template };
                payload.template_key = null;
              }

              save(...[payload, ...rest]);
            }}
            initialValues={{ audience_type: 'audience' }}
            {...props}
            render={formProps => {
              return (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="audience_type"
                        label="Select from"
                        choices={AUDIENCE_TYPE_CHOICES}
                        onChange={e => {
                          setAudienceTypeSelected(e.target.value);
                        }}
                        validate={[required()]}
                        fullWidth
                        helperText={false}
                      />
                    </Grid>
                    {audienceTypeSelected === 'audience' ? (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="mass_sending_audiences"
                          source="audience"
                          optionValueProp="id"
                          optionLabelProp="name"
                          required
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} sm={6}>
                          <>
                            {files.length === 0 ? (
                              <Box className={classes.mt2}>
                                <label htmlFor="upload_file">
                                  <input
                                    className={classes.hidden}
                                    type="file"
                                    id="upload_file"
                                    accept="text/csv"
                                    onChange={handleAttachFile}
                                    onClick={e => {
                                      e.target.value = null;
                                    }}
                                  />
                                  <Button
                                    color="primary"
                                    aria-label="upload file"
                                    className={classes.uploadIcon}
                                    startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                                    component="span">
                                    Attach file
                                  </Button>
                                </label>
                              </Box>
                            ) : (
                              <Box className={classes.mt3}>
                                {attachments.map(file => (
                                  <Box key={file.src} className={classes.flex}>
                                    <Box className={classes.flex}>
                                      <AttachmentIcon color="primary" fontSize="small" />
                                      {file.file_name}
                                    </Box>
                                    <IconButton
                                      color="primary"
                                      aria-label="unattach file"
                                      className={classes.removeIcon}
                                      component="span"
                                      edge="start"
                                      onClick={() => handleUnattachFile(file.id)}>
                                      <CloseIcon color="primary" fontSize="small" />
                                    </IconButton>
                                  </Box>
                                ))}
                              </Box>
                            )}
                          </>
                          {loadingFileError && (
                            <div>
                              <Typography color="error" variant="caption">
                                Upload a file!
                              </Typography>
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textSecondary">
                            Csv file,{' '}
                            <Link
                              href="/admin/import_example/mass_sending_process.csv"
                              download="mass_sending_process.csv">
                              download example file
                            </Link>
                            . Should be indicated at least 1 column - loan_id or user_id, but also could be indicated
                            both
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        helperText={false}
                        source="transmitter_id"
                        validate={[required()]}
                        choices={TRANSMITTER}
                        fullWidth
                        onChange={e => {
                          const registeredFields = formProps.form.getRegisteredFields();
                          if (registeredFields.includes('template_id')) {
                            formProps.form.change('template_id', null);
                            formProps.form.resetFieldState('template_id');
                          }
                          if (registeredFields.includes('template_key')) {
                            formProps.form.change('template_key', null);
                            formProps.form.resetFieldState('template_key');
                          }
                          if (registeredFields.includes('channel')) {
                            formProps.form.change('channel', null);
                            formProps.form.resetFieldState('channel');
                          }
                          setIsCmSelected(e.target.value === 'cm');
                          handleFormChange(e, formProps.form);
                          if (e.target.value === 'amazon_sns' || e.target.value === 'airmovil') {
                            setTemplateFilter(prev => ({ ...prev, category: 'SMS' }));
                          } else if (e.target.value === 'mailer') {
                            setTemplateFilter(prev => ({ ...prev, category: 'Email' }));
                          } else {
                            setTemplateFilter({ is_enabled: true });
                          }
                        }}
                      />
                    </Grid>
                    {isEmailSelected && (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="email_outbox_addresses"
                          source="email_source"
                          optionValueProp="email"
                          optionLabelProp="email"
                        />
                      </Grid>
                    )}
                    {isCmSelected ? (
                      <FormDataConsumer>
                        {() => (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                resource="cm_templates"
                                source="template_key"
                                optionValueProp="template_id"
                                optionLabelProp="name"
                                label="Template"
                                filter={{ is_active: true, is_pending: false }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <SelectInput
                                helperText={false}
                                source="channel"
                                validate={[required()]}
                                choices={cmProfilesList}
                                optionText="phone_number"
                                optionValue="channel_id"
                                fullWidth
                              />
                            </Grid>
                          </>
                        )}
                      </FormDataConsumer>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="templates"
                          source="template"
                          optionValueProp="id"
                          optionLabelProp="key"
                          label="Template"
                          filter={templateFilter}
                          required
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput
                        label="Scheduled at"
                        source="scheduled_at"
                        inputVariant="filled"
                        fullWidth
                        margin="dense"
                        required
                        initial={scheduledAt}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput helperText={false} source="name" validate={[maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="phone_contact" choices={PHONE_CONTACT} label="Phone results" fullWidth />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <FormControlLabel
                    control={<Checkbox value={usePhoneBook} onChange={() => showOptions(!usePhoneBook)} />}
                    label="Use alternative contacts"
                  />
                  {usePhoneBook ? (
                    <Fragment>
                      <Button onClick={addItem} color="primary" variant="contained">
                        add
                      </Button>
                      {params.map(item => (
                        <Grid container alignItems={'center'} spacing={2} key={item.id}>
                          <Grid item xs={12} sm={4}>
                            <SelectInput
                              key={`contact-sources-input-${phoneSourceChoices.length}`}
                              helperText={false}
                              label={'Contact source'}
                              source={`contact_source->${item.id}`}
                              validate={[required()]}
                              choices={phoneSourceChoices}
                              onChange={e => setSelectValue(item.id, 'source', e.target.value)}
                              value={item.source}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SelectInput
                              key={`contact-types-input-${phoneTypeChoices.length}`}
                              helperText={false}
                              label={'Contact type'}
                              source={`contact_type->${item.id}`}
                              validate={[required()]}
                              choices={phoneTypeChoices}
                              onChange={e => setSelectValue(item.id, 'type', e.target.value)}
                              value={item.type}
                              fullWidth
                            />
                          </Grid>
                          {item.type === 'contact_person' ? (
                            <Grid item xs={12} sm={3}>
                              <SelectInput
                                key={`contact-relationship-input-${contactRelationshipChoices.length}`}
                                emptyValue={null}
                                helperText={false}
                                validate={[required()]}
                                label={'Contact relation'}
                                source={`contact_relation->${item.id}`}
                                choices={contactRelationshipChoices}
                                value={item.relation}
                                onChange={e => setSelectValue(item.id, 'relation', e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={12} sm={2}>
                            <Button onClick={() => removeItem(item.id)} color="primary" variant="contained">
                              <DeleteIcon />
                            </Button>
                          </Grid>
                          <Divider className={classes.my2} />
                        </Grid>
                      ))}
                    </Fragment>
                  ) : null}
                  <Divider className={classes.my2} />

                  <SaveButton
                    label="Submit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </>
              );
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const MassSendingProcessCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);

export default MassSendingProcessCreate;
