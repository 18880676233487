import React from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { RadioButtonGroupInput } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { FormHelperText } from '@material-ui/core';

export const BooleanFilter = ({ id, title, form, hideInputs, filter = {} }) => {
  const { meta } = useField(id);

  const isError = meta.touched && meta.error;

  return (
    <>
      <Divider />
      <Box p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked={filter?.active}
              onChange={e => form.change(`${id}.active`, e.target.checked ? [id] : [])}
            />
          }
          label={title}
        />

        {!hideInputs && (
          <RadioButtonGroupInput
            label={false}
            source={`${id}.values.value`}
            choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
            defaultValue={filter?.values?.[0]?.value}
            helperText={false}
          />
        )}

        {isError && <FormHelperText error={!!isError}>{meta.error}</FormHelperText>}
      </Box>
    </>
  );
};

BooleanFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  hideInputs: PropTypes.bool,
};

BooleanFilter.defaultProps = {
  filter: {},
};
