import React, { useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import CallIcon from '@material-ui/icons/Call';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { DraggableDialog } from '../dialog/DraggableDialog';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  callable: {
    cursor: 'pointer',
  },
  icon: {
    paddingLeft: theme.spacing(0.2),
  },
  modalTitle: {
    cursor: 'move',
  },
}));

export const ClickToCallDialog = ({ isOpened, onClose, onSubmit, phoneNumber }) => {
  const classes = useStyles();

  return (
    <DraggableDialog
      open={isOpened}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      onClick={e => e.stopPropagation()}>
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Call confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you really want to call the number: <b>{phoneNumber}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={() => onSubmit()} color="primary">
          Yes
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

ClickToCallDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  phoneNumber: PropTypes.string,
};

export const Call = ({ children, edge, hidden, withIcon, userId, userPhone, inactive, onCall }) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const disabled = !userPhone || inactive;
  const canCall = permissions.includes('CAN_CLICK_TO_CALL_VIEW');
  const containerStyles = canCall ? [classes.container, classes.callable].join(' ') : classes.container;

  const applyClickToCall = () => {
    dataProvider
      .query('click_to_call', {
        method: 'POST',
        body: JSON.stringify({ user_id: userId, phone: userPhone }),
      })
      .then(() => setIsDialogOpened(false))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <Box
        className={containerStyles}
        onClick={e => {
          if (!disabled && canCall) {
            e.stopPropagation();
            setIsDialogOpened(true);
          }
        }}>
        {!hidden && children}
        {withIcon && canCall && (
          <Box className={classes.icon} onClick={e => e.stopPropagation()}>
            <IconButton
              disabled={disabled}
              color="secondary"
              edge={edge}
              onClick={e => {
                e.stopPropagation();
                setIsDialogOpened(true);
              }}>
              <CallIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {isDialogOpened && (
        <ClickToCallDialog
          isOpened={isDialogOpened}
          onClose={e => {
            e.stopPropagation();
            setIsDialogOpened(false);
          }}
          onSubmit={() => {
            setIsDialogOpened(false);
            applyClickToCall();
            onCall?.(userPhone);
          }}
          phoneNumber={children}
        />
      )}
    </>
  );
};

Call.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  edge: PropTypes.oneOf(['start', 'end', false]),
  hidden: PropTypes.bool,
  withIcon: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userPhone: PropTypes.string,
  inactive: PropTypes.bool,
  onCall: PropTypes.func,
};

Call.defaultProps = {
  children: '',
  hidden: false,
  withIcon: true,
  inactive: false,
};
