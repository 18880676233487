import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatPhoneNumber } from '../../utils';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  offset: {
    paddingBottom: theme.spacing(0.75),
  },
  contentBottom: {
    paddingBottom: theme.spacing(2),
  },
}));

export const BasePreviewDialog = ({ isOpened, onClose, previewList }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle className={classes.offset}>Base preview</DialogTitle>
      <DialogContent className={classes.contentBottom}>
        <Typography variant="subtitle1" className={classes.offset}>
          Total count: {previewList.total}
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Phone number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previewList.items?.map(row => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.phone_number && formatPhoneNumber(row.phone_number)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

BasePreviewDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  previewList: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
};

BasePreviewDialog.defaultProps = {
  previewList: {
    total: 0,
    items: [],
  },
};
