import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { formatDatetime, formatCurrency } from '../../utils';

const useStyles = makeStyles(theme => ({
  p2: {
    padding: theme.spacing(2),
  },
  px2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
}));

export const ReferralProgramTable = ({ record = null, refreshedAt }) => {
  const { id, referral_user_id, referral_wallet_amount, referral_wallet_id } = record;

  const [walletOperations, setWalletOperations] = useState([]);
  const [referralUser, setReferralUser] = useState(null);
  const [referralUsers, setReferralUsers] = useState([]);
  const [total, setTotal] = useState();
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('users', {
        filter: { 'referral_user.id': id },
        pagination: {},
        sort: { field: 'created_at', order: 'DESC' },
      })
      .then(({ data }) => setReferralUsers(data))
      .catch(error => setError(error));

    referral_user_id &&
      dataProvider
        .getOne('users', { id: referral_user_id })
        .then(({ data }) => setReferralUser(data))
        .catch(error => setError(error));
  }, [dataProvider, id, referral_user_id, refreshedAt]);

  useEffect(() => {
    referral_wallet_id &&
      dataProvider
        .getList('referral_wallet_operations', {
          filter: { 'wallet.id': referral_wallet_id },
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data, total }) => {
          setWalletOperations(data);
          setTotal(total);
        })
        .catch(error => {
          setError(error);
        });
  }, [dataProvider, referral_wallet_id, refreshedAt, page, perPage]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <>
      <Box className={classes.p2}>
        <Typography variant="body1">
          Invited by:{' '}
          {referral_user_id ? (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + referral_user_id} target="_blank">
              {referralUser
                ? `${referralUser?.first_name} ${referralUser?.last_name} #${referral_user_id}`
                : `#${referral_user_id}`}
            </Link>
          ) : (
            'N/D'
          )}
        </Typography>
        <Typography variant="body1">
          {`Referral wallet amount: ${referral_wallet_amount ? formatCurrency(referral_wallet_amount) : 'N/D'}`}
        </Typography>
      </Box>
      <Typography className={classes.px2} variant="h6">
        Referrals
      </Typography>
      {referralUsers.length > 0 ? (
        <Table size="small" className={classes.mb3} width="auto">
          <TableHead>
            <TableRow>
              <TableCell>Referral</TableCell>
              <TableCell>Wallet amount</TableCell>
              <TableCell>Referral users</TableCell>
              <TableCell>Referral users with loans</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referralUsers.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link onClick={e => e.stopPropagation()} href={'#users/' + row.id} target="_blank">
                    {row.first_name && row.last_name ? `${row.first_name} ${row.last_name} #${row.id}` : `#${row.id}`}
                  </Link>
                </TableCell>
                <TableCell>{row.referral_wallet_amount ? formatCurrency(row.referral_wallet_amount) : 0}</TableCell>
                <TableCell>{row.referral_users}</TableCell>
                <TableCell>{row.referral_users_with_loans}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body2" className={[classes.px2, classes.mb3].join(' ')}>
          This user has no referrals yet
        </Typography>
      )}
      <Typography className={classes.px2} variant="h6">
        Wallet operations
      </Typography>
      {walletOperations.length > 0 ? (
        <>
          <Table size="small" width="auto">
            <TableHead>
              <TableRow>
                <TableCell>Operation id</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Referral user </TableCell>
                <TableCell>Direction</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletOperations.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.amount && formatCurrency(row.amount)}</TableCell>
                  <TableCell>
                    {row.direction === 'in' && (
                      <Link onClick={e => e.stopPropagation()} href={'#users/' + row.info?.from_user} target="_blank">
                        {`#${row.info?.from_user}`}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>{row.direction}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell align="right">{row.created_at && formatDatetime(row.created_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={total}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={(e, page) => setPage(page + 1)}
            onRowsPerPageChange={e => {
              setPerPage(parseInt(e.target.value, 10));
              setPage(1);
            }}
          />
        </>
      ) : (
        <Typography variant="body2" className={[classes.px2, classes.mb3].join(' ')}>
          No wallet operations history
        </Typography>
      )}
    </>
  );
};

ReferralProgramTable.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    referral_user_id: PropTypes.number,
    referral_wallet_amount: PropTypes.number,
    referral_wallet_id: PropTypes.number,
  }),
  refreshedAt: PropTypes.number,
};
