import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const CollectionScoreTable = ({ loanId, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [scoreCurrent, setScoreCurrent] = useState();
  const [scoreLog, setScoreLog] = useState();
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const tableRows = [
    'Collection score Group',
    'Collection score Priority',
    'Collection score Repay Probability',
    'Collection score Rate',
    'DPD bucket',
    'Report Date',
  ];

  useEffect(() => {
    setLoading(true);
    dataProvider
      .query(`loans/${loanId}/collection_scores`, {})
      .then(({ data }) => {
        setScoreCurrent(data?.[0]);
        setScoreLog(data?.slice(1));
        setLoading(false);
        setTotal(data?.length);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <>
      <div className={classes.fullWidth}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Collection score Group</TableCell>
              <TableCell>Collection score Priority</TableCell>
              <TableCell>Collection score Repay probability</TableCell>
              <TableCell>Collection score Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{scoreCurrent?.score_group}</TableCell>
              <TableCell>{scoreCurrent?.score_priority}</TableCell>
              <TableCell>{scoreCurrent?.score_repay_probability}</TableCell>
              <TableCell>{scoreCurrent?.score_rate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              {tableRows.map(row => (
                <TableCell key={row}>{row}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {scoreLog?.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.score_group}</TableCell>
                <TableCell>{row.score_priority}</TableCell>
                <TableCell>{row.score_repay_probability}</TableCell>
                <TableCell>{row.score_rate}</TableCell>
                <TableCell>{row.dpd_backet}</TableCell>
                <TableCell>{row.report_date && formatDatetime(row.report_date)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={e => {
            setPerPage(+(e.target.value, 10));
            setPage(1);
          }}
        />
      </div>
    </>
  );
};

CollectionScoreTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
