import React from 'react';

const ImportProcessHelperCollectorCompany = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/collector_company.csv" download="import_example_collector_company.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>Loan ID</b>, required
        </li>
        <li>
          <b>Collector agency ID</b>, required, use <b>0</b> for unassign collector agency
        </li>
        <li>
          <b>Assigned to</b>, optional, format <b>2024-01-01</b> or <b>2024-01-01T00:00:00Z</b>
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperCollectorCompany;
