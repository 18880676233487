import React from 'react';
import { useField } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportTimezone from '../components/ReportTimezone';
import ReportDateFormat from '../components/ReportDateFormat';
import { LOAN_STATE_CHOICES } from '../../../constants';

const SalesReportParamsInput = () => {
  const [agenciesList, setAgenciesList] = React.useState([]);
  const [showColumnsInput, setShowColumnsInput] = React.useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('sales_report');

  React.useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => {
        const agencies = data
          .filter(agency => agency.status === 'active')
          .map(item => ({ id: item.id, name: item.name }));
        setAgenciesList(agencies);
      })
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      });
  }, [dataProvider, notify]);

  const reportColumns = [
    { name: 'Loan ID', key: 'loan_id', private: false },
    { name: 'User ID', key: 'user_id', private: false },
    { name: 'Loan Status', key: 'loan_status', private: false },
    { name: 'First Name', key: 'first_name', private: true },
    { name: 'Last Name', key: 'last_name', private: true },
    { name: 'Phone Number', key: 'phone_number', private: true },
    { name: 'Secondary Phone', key: 'secondary_phone', private: true },
    { name: 'Gender', key: 'gender', private: true },
    { name: 'Birthday', key: 'birthday', private: true },
    { name: 'Education', key: 'education', private: true },
    { name: 'Marital Status', key: 'maritial_status', private: true },
    { name: 'Employment Type', key: 'employment_type', private: true },
    { name: 'Email', key: 'email', private: true },
    { name: 'Postal Code', key: 'postal_code', private: true },
    { name: 'Region', key: 'region', private: true },
    { name: 'Municipality', key: 'municipality', private: true },
    { name: 'Colony', key: 'colony', private: true },
    { name: 'Street', key: 'street', private: true },
    { name: 'External House', key: 'external_house', private: true },
    { name: 'Internal House', key: 'internal_house', private: true },
    { name: 'Flat', key: 'flat', private: true },
    { name: 'Disbursed At', key: 'disbursed_at', private: true },
    { name: 'Giveout Principal', key: 'giveout_principal', private: true },
    { name: 'Number of Payments', key: 'num_payments', private: true },
    { name: 'Total Income', key: 'total_income', private: true },
    { name: 'Final Payment Date', key: 'final_payment_date', private: true },
    { name: 'Last Payment Amount', key: 'last_payment_amount', private: true },
    { name: 'Tenor', key: 'tenor', private: true },
    { name: 'Maturity Date', key: 'maturity_date', private: true },
    { name: 'Date Overdue', key: 'date_overdue', private: true },
    { name: 'DPD', key: 'dpd', private: true },
    { name: 'CURP', key: 'curp', private: true },
    { name: 'RFC', key: 'rfc', private: true },
    { name: 'Contact Person Name', key: 'contact_person_name', private: true },
    { name: 'Contact Person Phone Number', key: 'contact_person_phone_number', private: true },
    { name: 'Principal', key: 'principal', private: true },
    { name: 'Interest', key: 'interest', private: true },
    { name: 'Commission', key: 'commission', private: true },
    { name: 'Past Due Interest', key: 'past_due_interest', private: true },
    { name: 'Total', key: 'total', private: true },
    { name: 'Number of Extensions', key: 'number_of_extensions', private: true },
    { name: 'Loan Type', key: 'loan_type', private: true },
    { name: 'Product Type', key: 'product_type', private: true },
    { name: 'Customer Bank Account', key: 'bank_account', private: true },
    { name: 'STP Bank Account (for incomes)', key: 'bank_account_stp', private: true },
    { name: 'CLABE', key: 'clabe', private: true },
    { name: 'Current Outsourcing Company', key: 'current_outsourcing_company', private: true },
    { name: 'Last Outsourcing Company', key: 'last_outsourcing_company', private: true },
    { name: 'Number of Outsourcing Assignments', key: 'number_of_outsourcing_assignments', private: true },
    { name: 'Request Created At', key: 'request_created_at', private: true },
    { name: 'First Loan Disbursed At', key: 'first_loan_disbursed_at', private: true },
    { name: 'Number of Loans Issued', key: 'number_of_loans_issued', private: true },
    { name: 'Salary', key: 'salary', private: true },
    { name: 'Additional Phone Numbers', key: 'additional_phone_numbers', private: true },
    { name: 'Income Month', key: 'income_month', private: true },
    { name: 'Amount paid last 90/180/270/360 days', key: 'amount_paid', private: true },
  ];

  React.useEffect(() => {
    onChange({
      ...(value || {}),
      impersonalized_potfolio: showColumnsInput,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.outsourcing_company_selector)}>
            <InputLabel id="outsorcing-company-selector-select-label">Has a company?</InputLabel>
            <Select
              fullWidth
              labelId="outsorcing-company-selector-select-label"
              value={value?.outsourcing_company_selector || ''}
              onChange={e =>
                onChange({ ...(value || {}), outsourcing_company_selector: e.target.value, outsourcing_company: [] })
              }>
              <MenuItem value="has_company">Has outsourcing company</MenuItem>
              <MenuItem value="doesnt_have_company">Does not have outsourcing company</MenuItem>
            </Select>
            {touched && error && error.outsourcing_company_selector && (
              <FormHelperText>{error.outsourcing_company_selector}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      {value?.outsourcing_company_selector === 'has_company' && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl
              className={classes.fullWidth}
              error={!!(touched && error && error.outsourcing_company)}
              required>
              <InputLabel id="outsourcing-company-select-label">Outsourcing company</InputLabel>
              <Select
                multiple
                fullWidth
                labelId="outsourcing-company-select-label"
                value={value && value.outsourcing_company ? [...value.outsourcing_company] : []}
                onChange={e => onChange({ ...(value || {}), outsourcing_company: e.target.value })}
                renderValue={selected => (
                  <Box className={classes.chipBox}>
                    {selected.map(id => (
                      <Chip
                        className={classes.chip}
                        key={id}
                        label={agenciesList.find(agency => agency.id === id)?.name}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{ getContentAnchorEl: () => null }}>
                {agenciesList.map((item, idx) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {touched && error && error.outsourcing_company && (
                <FormHelperText>{error.outsourcing_company}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min-dpd-number"
            label="Min days past due"
            type="number"
            onChange={e => onChange({ ...(value || {}), min_dpd: e.target.value ? +e.target.value : undefined })}
            fullWidth
            error={!!(touched && error && error.min_dpd)}
            helperText={touched && error && error.min_dpd}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="max-dpd-number"
            label="Max days past due"
            type="number"
            onChange={e => onChange({ ...(value || {}), max_dpd: e.target.value ? +e.target.value : undefined })}
            fullWidth
            error={!!(touched && error && error.max_dpd)}
            helperText={touched && error && error.max_dpd}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportDate
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          startLabel="Start date of issued loans"
          endLabel="End date of issued loans"
          required={false}
        />
      </Grid>
      <Divider className={classes.mt1} />
      <Grid container spacing={4} className={classes.mt1}>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.state)}>
            <InputLabel id="last-call-result-select-label">State</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="last-call-result-select-label"
              value={value && value.state ? [...value.state] : []}
              onChange={e => onChange({ ...(value || {}), state: e.target.value })}
              renderValue={selected => (
                <Box className={classes.chipBox}>
                  {selected.map(id => (
                    <Chip className={classes.chip} key={id} label={LOAN_STATE_CHOICES.find(i => i.key === id)?.name} />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {LOAN_STATE_CHOICES.map(item => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.columns && <FormHelperText>{error.columns}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.mt1}>
        <Grid item xs={12}>
          <FormControlLabel
            label="Masked columns"
            control={
              <Checkbox
                checked={showColumnsInput}
                onChange={e => {
                  setShowColumnsInput(e.target.checked);
                  onChange({
                    ...(value || {}),
                    impersonalized_potfolio: e.target.checked,
                    columns: [],
                  });
                }}
              />
            }
          />
        </Grid>
        {showColumnsInput && (
          <Grid item xs={12}>
            <FormControl className={classes.fullWidth} error={!!(touched && error && error.columns)}>
              <InputLabel id="last-call-result-select-label">Report columns</InputLabel>
              <Select
                multiple
                fullWidth
                labelId="last-call-result-select-label"
                value={value && value.columns ? [...value.columns] : []}
                onChange={e => onChange({ ...(value || {}), columns: e.target.value })}
                renderValue={() => (
                  <Box className={classes.chipBox}>
                    {value.columns.map(key => (
                      <Chip className={classes.chip} key={key} label={reportColumns.find(c => c.key === key)?.name} />
                    ))}
                  </Box>
                )}>
                {reportColumns.map(item => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {touched && error && error.columns && <FormHelperText>{error.columns}</FormHelperText>}
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default SalesReportParamsInput;
