import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { getCatalogValue } from '../../utils';
import {
  CIRCULADECREDITO_TIPO_RESPONSABILIDAD,
  CIRCULADECREDITO_TIPO_CUENTA,
  CIRCULADECREDITO_TIPO_CREDITO,
  CIRCULADECREDITO_FRECUENCIA_PAGO,
  CIRCULADECREDITO_PREVENCION,
  CIRCULADECREDITO_SERVICIOS,
} from '../../constants';

const defaultTheme = createTheme();

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      '& .MuiFormGroup-options': {
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        '& > div': {
          minWidth: 100,
          margin: theme.spacing(2, 2, 2, 0),
        },
      },
    },
    red: {
      backgroundColor: red[300],
      '&.MuiDataGrid-row:hover': {
        backgroundColor: red[300],
      },
    },
    listColumn: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
    },
  }),
  { defaultTheme },
);

const CAN = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();

  return (
    <div>
      <Button aria-describedby={id} color="primary" size="small" onClick={handleClick}>
        Detalles
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box className={classes.listColumn} component="ul">
          <Typography variant="body2" component="li">
            <b>Identificador CAN: </b>
            {data.identificadorCAN ?? '-'}
          </Typography>
          <Typography variant="body2" component="li">
            <b>Prelacion Origen: </b>
            {data.prelacionOrigen ?? '-'}
          </Typography>
          <Typography variant="body2" component="li">
            <b>Prelacion Actual: </b>
            {data.prelacionActual ?? '-'}
          </Typography>
          <Typography variant="body2" component="li">
            <b>FechaApertura CAN: </b>
            {data.fechaAperturaCAN ?? '-'}
          </Typography>
          <Typography variant="body2" component="li">
            <b>Fecha Cancelacion CAN: </b>
            {data.fechaCancelacionCAN ?? '-'}
          </Typography>
          <Typography variant="body2" component="li">
            <b>Historico CAN: </b>
            {data.historicoCAN ?? '-'}
          </Typography>
          <Typography variant="body2" component="li">
            <b>Fecha MRCAN: </b>
            {data.fechaMRCAN ?? '-'}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

CAN.propTypes = {
  data: PropTypes.object,
};

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const getRow = (row, index) => {
  return {
    id: index,
    col1: row.fechaActualizacion,
    col2: row.registroImpugnado,
    col3: row.claveOtorgante,
    col4: row.nombreOtorgante,
    col5: row.cuentaActual,
    col6: getCatalogValue(CIRCULADECREDITO_TIPO_RESPONSABILIDAD, row.tipoResponsabilidad),
    col7: getCatalogValue(CIRCULADECREDITO_TIPO_CUENTA, row.tipoCuenta),
    col8: getCatalogValue(CIRCULADECREDITO_TIPO_CREDITO, row.tipoCredito),
    col9: row.claveUnidadMonetaria,
    col10: row.valorActivoValuacion,
    col11: row.numeroPagos,
    col12: getCatalogValue(CIRCULADECREDITO_FRECUENCIA_PAGO, row.frecuenciaPagos),
    col13: row.montoPagar,
    col14: row.saldoVencido,
    col15: getCatalogValue(CIRCULADECREDITO_PREVENCION, row.clavePrevencion),
    col16: row.fechaAperturaCuenta,
    col17: row.fechaUltimoPago,
    col18: row.fechaUltimaCompra,
    col19: row.fechaCierreCuenta,
    col20: row.fechaReporte,
    col21: row.ultimaFechaSaldoCero,
    col22: row.garantia,
    col23: row.creditoMaximo,
    col24: row.saldoActual,
    col25: row.limiteCredito,
    col26: row.numeroPagosVencidos,
    col27: row.pagoActual,
    col28: row.fechaRecienteHistoricoPagos,
    col29: row.fechaAntiguaHistoricoPagos,
    col30: row.totalPagosReportados,
    col31: row.peorAtraso,
    col32: row.fechaPeorAtraso,
    col33: row.saldoVencidoPeorAtraso,
    col34: row.montoUltimoPago,
    col35: row.idDomicilio,
    col36: getCatalogValue(CIRCULADECREDITO_SERVICIOS, row.servicios),
    col37: row.CAN ?? null,
    col38: row.historicoPagos,
  };
};

const columns = [
  {
    field: 'col1',
    headerName: 'Fecha Actualizacion',
    flex: 1,
    minWidth: 210,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col2',
    headerName: 'Registroimpugnado',
    flex: 1,
    minWidth: 200,
    valueGetter: params => params.row.col2?.toString(),
  },
  { field: 'col3', headerName: 'Clave Otorgante', flex: 1, minWidth: 200 },
  { field: 'col4', headerName: 'Nombre Otorgante', flex: 2, minWidth: 350 },
  { field: 'col5', headerName: 'Cuenta Actual', flex: 1, minWidth: 200 },
  { field: 'col6', headerName: 'Tipo Responsabilidad', flex: 1, minWidth: 220 },
  { field: 'col7', headerName: 'Tipo Cuenta', flex: 1, minWidth: 220 },
  { field: 'col8', headerName: 'Tipo Credito', flex: 1, minWidth: 220 },
  { field: 'col9', headerName: 'Clave Unidadmonetaria', flex: 1, minWidth: 230, sortable: false },
  {
    field: 'col10',
    headerName: 'Valor Activo Valuacion',
    flex: 1,
    minWidth: 220,
    valueGetter: params => params.row.col11?.toString(),
  },
  {
    field: 'col11',
    headerName: 'Numero Pagos',
    flex: 1,
    minWidth: 180,
    valueGetter: params => params.row.col11?.toString(),
  },
  { field: 'col12', headerName: 'Frecuencia Pagos', flex: 1, minWidth: 350 },
  {
    field: 'col13',
    headerName: 'Monto Pagar',
    flex: 1,
    minWidth: 170,
    valueGetter: params => params.row.col13?.toString(),
  },
  {
    field: 'col14',
    headerName: 'Saldo Vencido',
    flex: 1,
    minWidth: 170,
    valueGetter: params => params.row.col14?.toString(),
  },
  { field: 'col15', headerName: 'Clave Prevencion', flex: 1, minWidth: 250, sortable: false },
  {
    field: 'col16',
    headerName: 'Fecha Apertura Cuenta',
    flex: 1,
    minWidth: 230,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col17',
    headerName: 'Fecha Ultimo Pago',
    flex: 1,
    minWidth: 200,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col18',
    headerName: 'Fecha Ultima Compra',
    flex: 1,
    minWidth: 220,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col19',
    headerName: 'Fecha Cierre Cuenta',
    flex: 1,
    minWidth: 220,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col20',
    headerName: 'Fecha Reporte',
    flex: 1,
    minWidth: 180,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col21',
    headerName: 'Ultima Fecha Saldo Cero',
    flex: 1,
    minWidth: 250,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  { field: 'col22', headerName: 'Garantia', flex: 1, minWidth: 220 },
  {
    field: 'col23',
    headerName: 'Credito Maximo',
    flex: 1,
    minWidth: 180,
    valueGetter: params => params.row.col23?.toString(),
  },
  {
    field: 'col24',
    headerName: 'Saldo Actual',
    flex: 1,
    minWidth: 180,
    valueGetter: params => params.row.col24?.toString(),
  },
  {
    field: 'col25',
    headerName: 'Limite Credito',
    flex: 1,
    minWidth: 180,
    valueGetter: params => params.row.col25?.toString(),
  },
  {
    field: 'col26',
    headerName: 'Numero Pagos Vencidos',
    flex: 1,
    minWidth: 220,
    valueGetter: params => params.row.col26?.toString(),
  },
  { field: 'col27', headerName: 'Pago Actual', flex: 1, minWidth: 220, sortable: false },
  {
    field: 'col28',
    headerName: 'Fecha Reciente Historico Pagos',
    flex: 1,
    minWidth: 220,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col29',
    headerName: 'Fecha Antigua Historico Pagos',
    flex: 1,
    minWidth: 220,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col30',
    headerName: 'Total Pagos Reportados',
    flex: 1,
    minWidth: 220,
    valueGetter: params => params.row.col30?.toString(),
  },
  {
    field: 'col31',
    headerName: 'Peor Atraso',
    flex: 1,
    minWidth: 220,
    valueGetter: params => params.row.col31?.toString(),
  },
  {
    field: 'col32',
    headerName: 'Fecha Peor Atraso',
    flex: 1,
    minWidth: 220,
    type: 'date',
    valueGetter: params => new Date(params.row.col1),
    valueFormatter: params => params.value.toLocaleDateString(),
  },
  {
    field: 'col33',
    headerName: 'Saldo Vencido Peor Atraso',
    flex: 1,
    minWidth: 220,
    valueGetter: params => params.row.col33?.toString(),
  },
  { field: 'col34', headerName: 'Monto Ultimo Pago', flex: 1, minWidth: 220 },
  { field: 'col35', headerName: 'Id Domicilio', flex: 1, minWidth: 220 },
  { field: 'col36', headerName: 'Servicios', flex: 1, minWidth: 220 },
  {
    field: 'col37',
    headerName: 'CAN',
    flex: 1,
    minWidth: 120,
    sortable: false,
    filterable: false,
    renderCell: params => (params.value ? <CAN data={params.value} /> : '-'),
  },
  {
    field: 'col38',
    headerName: 'Historico Pagos',
    flex: 200,
    minWidth: 220,
    sortable: false,
  },
];

const CirculoCreditosTable = ({ data }) => {
  const classes = useStyles();

  const rows = data.map((item, index) => {
    return getRow(item, index);
  });
  return (
    <Box className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
        autoHeight
        autoPageSize
        density="compact"
        disableSelectionOnClick
        getRowClassName={params => (params.row.col14 > 10000 ? classes.red : '')}
      />
    </Box>
  );
};

CirculoCreditosTable.propTypes = {
  data: PropTypes.object,
};

export default CirculoCreditosTable;
