import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';

const test = [
  { propname: 'FIXED', year: '1992' },
  { propname: 'PERCENT', year: '1990' },
];
const dictionary = {
  total: 0,
  options: test,
};

export const withDictionary = WrappedComponent => {
  const AutoCompleteDictionary = ({ ...props }) => {
    return <WrappedComponent {...props} result={dictionary} />;
  };
  return AutoCompleteDictionary;
};

export const withServer = WrappedComponent => {
  const AutoCompleteServer = ({ perOnePage, resource, filter, ...props }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [result, setResult] = useState({
      options: [],
      total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const perPage = perOnePage;
    const maxPage = Math.ceil(result.total / perPage);

    useEffect(() => {
      setLoading(state => !state);
      dataProvider
        .getList(resource, {
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
          filter,
        })
        .then(({ data, total }) => setResult({ options: [...result.options, ...data], total }))
        .catch(({ message }) => notify(`Error: ${message}`, 'error'))
        .then(() =>
          setTimeout(() => {
            setLoading(state => !state);
          }, 1000),
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
      <WrappedComponent result={result} loading={loading} maxPage={maxPage} setPage={setPage} page={page} {...props} />
    );
  };

  AutoCompleteServer.propTypes = {
    perOnePage: PropTypes.number,
    resource: PropTypes.string,
    filter: PropTypes.object,
  };

  return AutoCompleteServer;
};

export const withServerAutocomplete = WrappedComponent => {
  const AutoCompleteServer = ({ perOnePage, resource, filter, ...props }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [result, setResult] = useState({
      options: [],
      total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(perOnePage);
    const maxPage = Math.ceil(result.total / perPage);

    useEffect(() => {
      setLoading(state => !state);
      dataProvider
        .getList(resource, {
          pagination: {},
          sort: { field: 'id', order: 'DESC' },
          filter: { ...filter, items_per_page: perPage },
        })
        .then(({ data, total }) => {
          setResult({ options: [...data], total });
          setLoading(false);
        })
        .catch(({ message }) => notify(`Error: ${message}`, 'error'));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
      <WrappedComponent
        result={result}
        loading={loading}
        page={page}
        setPage={() => {
          const newPerPage = perPage + 10;
          setPerPage(prev => prev + 10);

          dataProvider
            .getList(resource, {
              pagination: {},
              sort: { field: 'id', order: 'DESC' },
              filter: { ...filter, items_per_page: newPerPage },
            })
            .then(({ data, total }) => {
              setResult({ options: [...data], total });
              setPerPage(newPerPage);
              setLoading(false);
            })
            .catch(({ message }) => notify(`Error: ${message}`, 'error'));
          return setPage;
        }}
        maxPage={maxPage}
        {...props}
      />
    );
  };

  AutoCompleteServer.propTypes = {
    perOnePage: PropTypes.number,
    resource: PropTypes.string,
    filter: PropTypes.object,
  };

  return AutoCompleteServer;
};
