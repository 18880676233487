import React, { useState } from 'react';
import { MenuItem, Select, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

export const ListItemField = ({ id, name, value, details, canEdit, onChange }) => {
  const [fieldValue, setFieldValue] = useState(value);

  const handleChange = (event, targetType) => {
    if (targetType) {
      setFieldValue(event.target.value?.toString());
      onChange(id, event.target.value?.toString() || null, value);
    } else {
      if (['annual_disbursement_limit', 'client_tracking_disbursement_limit'].includes(name)) {
        event.target.value = event.target.value.replace(/[^0-9.,]/g, '');
      }
      setFieldValue(event.target.value);
      onChange(id, event.target.value || null, value);
    }
  };

  return (
    <>
      {details.options ? (
        <Select
          value={fieldValue || ''}
          fullWidth
          disabled={!canEdit}
          onChange={event => handleChange(event, 'select')}>
          <MenuItem value={''}>-</MenuItem>
          {details.options.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField disabled={!canEdit} value={fieldValue || ''} fullWidth onChange={event => handleChange(event)} />
      )}
    </>
  );
};

ListItemField.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  details: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    ),
  }),
  canEdit: PropTypes.bool,
  onChange: PropTypes.func,
};
