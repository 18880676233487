import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { noop } from '../../utils';

export const DebounceButton = ({ onClick, ...props }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => {
    onClick();
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  return <Button {...props} disabled={disabled} onClick={handleClick} />;
};

DebounceButton.propTypes = {
  onClick: PropTypes.func,
};

DebounceButton.defaultProps = {
  onClick: noop,
};
