import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';

import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportTimezone from '../components/ReportTimezone';
import ReportDateFormat from '../components/ReportDateFormat';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useDataProvider, useNotify } from 'react-admin';

const LandingLeadReportParamsInput = () => {
  const [siteNameList, setSiteNameList] = React.useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('landing_lead_report');

  React.useEffect(() => {
    dataProvider
      .query('p/landing_leads/site_name', { method: 'GET' })
      .then(({ data }) => setSiteNameList(data.map(item => ({ id: item, name: item }))))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.fullWidth} error={!!(touched && error && error.site_name)}>
          <InputLabel id="site-name-select-label">Site name</InputLabel>
          <Select
            multiple
            fullWidth
            labelId="site-name-select-label"
            value={value && value.site_name ? [...value.site_name] : []}
            onChange={e => onChange({ ...(value || {}), site_name: e.target.value })}
            renderValue={selected => (
              <Box className={classes.chipBox}>
                {selected.map(id => (
                  <Chip
                    className={classes.chip}
                    key={id}
                    label={siteNameList.find(siteName => siteName.id === id)?.name}
                  />
                ))}
              </Box>
            )}
            MenuProps={{ getContentAnchorEl: () => null }}>
            {siteNameList.map((item, idx) => (
              <MenuItem key={idx} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {touched && error && error.site_name && <FormHelperText>{error.site_name}</FormHelperText>}
        </FormControl>
      </Grid>

      <br></br>

      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default LandingLeadReportParamsInput;
