import React from 'react';
import PropTypes from 'prop-types';
import { DeleteButton, Edit, SaveButton, SimpleForm, TextInput, Toolbar, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT');
  const redirectTo = `/collection_contact_types/${record.type}`;

  return (
    <SimpleForm
      save={(data, redirectTo, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, redirectTo, ...rest])}
      toolbar={
        <Toolbar className={classes.defaultToolbar}>
          <SaveButton redirect={redirectTo} />
          <DeleteButton redirect="/collection_contact_types" />
        </Toolbar>
      }
      {...props}>
      <TextInput disabled source="id" />
      <TextInput disabled={!isEditable} source="code" helperText="Do not change without real reason" />
      <TextInput disabled={!isEditable} source="value" />
    </SimpleForm>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
  }),
};

const CollectionContactTypeValueEdit = props => {
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <FormWrapper />
    </Edit>
  );
};

export default CollectionContactTypeValueEdit;
