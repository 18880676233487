import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { DateTimePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { timezone } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const alpabeticArraySort = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const optionData = (value, name) => ({ value, name });

const transactionTypes = [
  optionData('principal_discount', 'Principal discount'),
  optionData('principal_revert', 'Principal revert'),
  optionData('overpayment_revert', 'Overpayment revert'),
  optionData('past_due_interest_discount', 'PDI discount'),
  optionData('past_due_interest_revert', 'PDI revert'),
  optionData('interest_discount', 'Interest discount'),
  optionData('interest_revert', 'Interest revert'),
  optionData('commission_discount', 'Commission discount'),
  optionData('commission_revert', 'Commission revert'),
];

export const AddExecutedTransactionDialog = ({ record, isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const [type, setType] = useState(null);
  const [direction, setDirection] = useState(null);
  const [amount, setAmount] = useState(null);
  const [executedAt, setExecutedAt] = useState(null);
  const [errors, setErrors] = useState({});

  const removeError = (...fields) => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => fields.includes(name) === false)));
  };

  const validate = () => {
    const formErrors = {};

    if (!type) {
      formErrors.amount = 'Name field is required';
    } else {
      if (type === 'principal_discount' && +amount > record.accrued_principal) {
        formErrors.amount = `Amount must be less than or equal to ${record.accrued_principal}`;
      }
      if (type === 'interest_discount' && +amount > record.accrued_interest) {
        formErrors.amount = `Amount must be less than or equal to ${record.accrued_interest}`;
      }
      if (type === 'commission_discount' && +amount > record.accrued_commission) {
        formErrors.amount = `Amount must be less than or equal to ${record.accrued_commission}`;
      }
      if (type === 'past_due_interest_discount' && +amount > record.accrued_past_due_interest) {
        formErrors.amount = `Amount must be less than or equal to ${record.accrued_past_due_interest}`;
      }
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const handleCloseDialog = () => {
    setType(null);
    setDirection(null);
    setAmount(null);
    setExecutedAt(null);
    removeError('amount');
    onClose();
  };

  const handleSubmitDialog = () => {
    if (validate()) {
      onSubmit(type, direction, amount, executedAt);
      setType(null);
      setDirection(null);
      setAmount(null);
      setExecutedAt(null);
    }
  };

  useEffect(() => {
    setExecutedAt(new Date());
  }, [isOpened]);

  return (
    <Dialog open={isOpened} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add executed transaction</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                value={type || ''}
                onChange={e => {
                  setType(e.target.value);
                  setDirection(null);
                }}>
                {transactionTypes.sort(alpabeticArraySort).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="direction-select-label">Direction</InputLabel>
              <Select
                labelId="direction-select-label"
                value={direction || ''}
                onChange={e => setDirection(e.target.value)}>
                <MenuItem value="in" disabled={type !== null && type.includes('revert')}>
                  In
                </MenuItem>
                <MenuItem value="out" disabled={type !== null && type.includes('discount')}>
                  Out
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              margin="none"
              fullWidth={true}
              label="Amount"
              value={amount || ''}
              placeholder="0.00"
              error={!!errors.amount}
              helperText={errors.amount}
              onChange={e => {
                removeError('amount');
                setAmount(parseFloat(e.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Executed at"
              className={classes.fullWidth}
              value={executedAt ? timezone.shift(executedAt) : null}
              onChange={value => setExecutedAt(timezone.unshift(value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmitDialog} color="primary" disabled={!type || !direction || !amount || !executedAt}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddExecutedTransactionDialog.propTypes = {
  record: PropTypes.shape({
    accrued_principal: PropTypes.number,
    accrued_interest: PropTypes.number,
    accrued_commission: PropTypes.number,
    accrued_past_due_interest: PropTypes.number,
  }),
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
