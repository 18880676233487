import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { formatDatetime } from '../../utils';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    width: '30%',
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  width40: {
    width: '40%',
  },
}));

export const MoffinReport = ({ data }) => {
  const [open, setOpen] = useState(false);

  const classes = useRowStyles();
  const response =
    data.response?.response?.return?.Personas?.Persona?.length > 0 &&
    data.response?.response?.return?.Personas?.Persona[0];
  const nombre = response.Nombre ? response.Nombre : null;
  const domicilios = response.Domicilios?.Domicilio ?? null;
  const empleos = response.Empleos?.Empleo ?? null;
  const cuentas = response.Cuentas?.Cuenta ?? null;
  const consultas = response.ConsultasEfectuadas?.ConsultaEfectuada ?? null;
  const reports = response.ResumenReporte?.ResumenReporte ?? null;
  const hawkAlertConsultas = response.HawkAlertConsulta?.HawkAlertC ?? null;
  const hawkAlertBD = response.HawkAlertBD?.HawkAlertBD ?? null;
  const scoreBuroCredito = response.ScoreBuroCredito?.ScoreBC ?? null;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.id}</TableCell>
        <TableCell>
          <Chip size="small" label={data.service} />
        </TableCell>
        <TableCell>
          <Chip size="small" label={data.state} />
        </TableCell>
        <TableCell width="40%">{data.error}</TableCell>
        <TableCell>{formatDatetime(data.created_at)}</TableCell>
      </TableRow>
      {response && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                  Persona
                </Typography>
                <Box className={classes.list}>
                  <Box>
                    <Typography variant="body2">
                      <b>Apellido Paterno:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Apellido Materno:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Primer Nombre:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Fecha Nacimiento:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>RFC:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Nacionalidad:</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">{nombre.ApellidoPaterno ?? '-'}</Typography>
                    <Typography variant="body2">{nombre.ApellidoMaterno ?? '-'}</Typography>
                    <Typography variant="body2">{nombre.PrimerNombre ?? '-'}</Typography>
                    <Typography variant="body2">{nombre.FechaNacimiento ?? '-'}</Typography>
                    <Typography variant="body2">{nombre.RFC ?? '-'}</Typography>
                    <Typography variant="body2">{nombre.Nacionalidad ?? '-'}</Typography>
                  </Box>
                </Box>

                <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                  Domicilios
                </Typography>
                <Table size="small" padding="checkbox" className={[classes.table, classes.width40].join(' ')}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Direccion</TableCell>
                      <TableCell>Delegacion Municipio</TableCell>
                      <TableCell>Ciudad</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>CP</TableCell>
                      <TableCell>Cod Pais</TableCell>
                      <TableCell>Fecha Reporte Direccion</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {domicilios.map((row, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{row.Direccion1 ?? '-'}</TableCell>
                        <TableCell>{row.DelegacionMunicipio ?? '-'}</TableCell>
                        <TableCell>{row.Ciudad ?? '-'}</TableCell>
                        <TableCell>{row.Estado ?? '-'}</TableCell>
                        <TableCell>{row.CP ?? '-'}</TableCell>
                        <TableCell>{row.CodPais ?? '-'}</TableCell>
                        <TableCell>{row.FechaReporteDireccion ?? '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {empleos && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      Empleos
                    </Typography>
                    <Table size="small" padding="checkbox" className={[classes.table, classes.width40].join(' ')}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre Empresa</TableCell>
                          <TableCell>Direccion 1</TableCell>
                          <TableCell>Numero Telefono</TableCell>
                          <TableCell>Cargo</TableCell>
                          <TableCell>FechaContratacion</TableCell>
                          <TableCell>Clave Moneda Salario</TableCell>
                          <TableCell>Salario</TableCell>
                          <TableCell>Base Salarial</TableCell>
                          <TableCell>Fecha Reporto Empleo</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {empleos.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.NombreEmpresa ?? '-'}</TableCell>
                            <TableCell>{row.Direccion1 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroTelefono ?? '-'}</TableCell>
                            <TableCell>{row.Cargo ?? '-'}</TableCell>
                            <TableCell>{row.FechaContratacion ?? '-'}</TableCell>
                            <TableCell>{row.ClaveMonedaSalario ?? '-'}</TableCell>
                            <TableCell>{row.Salario ?? '-'}</TableCell>
                            <TableCell>{row.BaseSalarial ?? '-'}</TableCell>
                            <TableCell>{row.FechaReportoEmpleo ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {cuentas && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      Cuentas
                    </Typography>
                    <Table size="small" padding="checkbox" className={[classes.table]}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha Actualizacion</TableCell>
                          <TableCell>Nombre Otorgante</TableCell>
                          <TableCell>Indicador Tipo Responsabilidad</TableCell>
                          <TableCell>Tipo Cuenta</TableCell>
                          <TableCell>Tipo Contrato</TableCell>
                          <TableCell>Clave Unidad Monetaria</TableCell>
                          <TableCell>Frecuencia Pagos</TableCell>
                          <TableCell>Monto Pagar</TableCell>
                          <TableCell>Fecha Apertura Cuenta</TableCell>
                          <TableCell>Fecha Cierre Cuenta</TableCell>
                          <TableCell>Fecha Ultimo Pago</TableCell>
                          <TableCell>Fecha Ultima Compra</TableCell>
                          <TableCell>Fecha Reporte</TableCell>
                          <TableCell>Modo Reportar</TableCell>
                          <TableCell>Ultima Fecha Saldo Cero</TableCell>
                          <TableCell>Credito Maximo</TableCell>
                          <TableCell>Saldo Actual</TableCell>
                          <TableCell>Limite Credito</TableCell>
                          <TableCell>Saldo Vencido</TableCell>
                          <TableCell>Numero Pagos Vencidos</TableCell>
                          <TableCell>Forma Pago Actual</TableCell>
                          <TableCell>Historico Pagos</TableCell>
                          <TableCell>Fecha Mas Reciente Historico Pagos</TableCell>
                          <TableCell>Fecha Mas Antigua Historico Pagos</TableCell>
                          <TableCell>Clave Observacion</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cuentas.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.FechaActualizacion ?? '-'}</TableCell>
                            <TableCell>{row.NombreOtorgante ?? '-'}</TableCell>
                            <TableCell>{row.IndicadorTipoResponsabilidad ?? '-'}</TableCell>
                            <TableCell>{row.TipoCuenta ?? '-'}</TableCell>
                            <TableCell>{row.TipoContrato ?? '-'}</TableCell>
                            <TableCell>{row.ClaveUnidadMonetaria ?? '-'}</TableCell>
                            <TableCell>{row.FrecuenciaPagos ?? '-'}</TableCell>
                            <TableCell>{row.MontoPagar ?? '-'}</TableCell>
                            <TableCell>{row.FechaAperturaCuenta ?? '-'}</TableCell>
                            <TableCell>{row.FechaCierreCuenta ?? '-'}</TableCell>
                            <TableCell>{row.FechaUltimoPago ?? '-'}</TableCell>
                            <TableCell>{row.FechaUltimaCompra ?? '-'}</TableCell>
                            <TableCell>{row.FechaReporte ?? '-'}</TableCell>
                            <TableCell>{row.ModoReportar ?? '-'}</TableCell>
                            <TableCell>{row.UltimaFechaSaldoCero ?? '-'}</TableCell>
                            <TableCell>{row.CreditoMaximo ?? '-'}</TableCell>
                            <TableCell>{row.SaldoActual ?? '-'}</TableCell>
                            <TableCell>{row.LimiteCredito ?? '-'}</TableCell>
                            <TableCell>{row.SaldoVencido ?? '-'}</TableCell>
                            <TableCell>{row.NumeroPagosVencidos ?? '-'}</TableCell>
                            <TableCell>{row.FormaPagoActual ?? '-'}</TableCell>
                            <TableCell>{row.HistoricoPagos ?? '-'}</TableCell>
                            <TableCell>{row.FechaMasRecienteHistoricoPagos ?? '-'}</TableCell>
                            <TableCell>{row.FechaMasAntiguaHistoricoPagos ?? '-'}</TableCell>
                            <TableCell>{row.ClaveObservacion ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {consultas && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      Consultas
                    </Typography>
                    <Table size="small" padding="checkbox" className={[classes.table, classes.width40].join(' ')}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha Consulta</TableCell>
                          <TableCell>Clave Otorgante</TableCell>
                          <TableCell>Nombre Otorgante</TableCell>
                          <TableCell>Tipo Contrato</TableCell>
                          <TableCell>Importe Contrato</TableCell>
                          <TableCell>Indicador Tipo Responsabilidad</TableCell>
                          <TableCell>Consumidor Nuevo</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {consultas.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.FechaConsulta ?? '-'}</TableCell>
                            <TableCell>{row.ClaveOtorgante ?? '-'}</TableCell>
                            <TableCell>{row.NombreOtorgante ?? '-'}</TableCell>
                            <TableCell>{row.TipoContrato ?? '-'}</TableCell>
                            <TableCell>{row.ImporteContrato ?? '-'}</TableCell>
                            <TableCell>{row.IndicadorTipoResponsabilidad ?? '-'}</TableCell>
                            <TableCell>{row.ConsumidorNuevo ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {reports && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      Resumen Reportes
                    </Typography>
                    <Table size="small" padding="checkbox" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha Ingreso BD</TableCell>
                          <TableCell>Numero MOP7</TableCell>
                          <TableCell>Numero MOP6</TableCell>
                          <TableCell>Numero MOP5</TableCell>
                          <TableCell>Numero MOP4</TableCell>
                          <TableCell>Numero MOP3</TableCell>
                          <TableCell>Numero MOP2</TableCell>
                          <TableCell>Numero MOP1</TableCell>
                          <TableCell>Numero MOP0</TableCell>
                          <TableCell>Numero MOPUR</TableCell>
                          <TableCell>Numero Cuentas</TableCell>
                          <TableCell>Cuentas Pagos Fijos Hipotecas</TableCell>
                          <TableCell>Cuentas Revolventes Abiertas</TableCell>
                          <TableCell>Cuentas Cerradas</TableCell>
                          <TableCell>Cuentas Negativas Actuales</TableCell>
                          <TableCell>Cuentas Claves Historia Negativa</TableCell>
                          <TableCell>Cuentas Disputa</TableCell>
                          <TableCell>Numero Solicitudes Ultimos 6 Meses</TableCell>
                          <TableCell>Nueva Direccion Reportada Ultimos 60 Dias</TableCell>
                          <TableCell>Mensajes Alerta</TableCell>
                          <TableCell>Existencia Declaraciones Consumidor</TableCell>
                          <TableCell>Numero MOP96</TableCell>
                          <TableCell>Numero MOP97</TableCell>
                          <TableCell>Numero MOP99</TableCell>
                          <TableCell>Fecha Apertura Cuenta Mas Antigua</TableCell>
                          <TableCell>Fecha Apertura Cuenta Mas Reciente</TableCell>
                          <TableCell>Total Solicitudes Reporte</TableCell>
                          <TableCell>Fecha Solicitud Reporte Mas Reciente</TableCell>
                          <TableCell>Numero Total Cuentas Despacho Cobranza</TableCell>
                          <TableCell>Fecha Apertura Cuenta Mas Reciente Despacho Cobranza</TableCell>
                          <TableCell>Numero Total Solicitudes Despachos Cobranza</TableCell>
                          <TableCell>Fecha Solicitud Mas Reciente Despacho Cobranza</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reports.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.FechaIngresoBD ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP7 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP6 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP5 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP4 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP3 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP2 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP1 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP0 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOPUR ?? '-'}</TableCell>
                            <TableCell>{row.NumeroCuentas ?? '-'}</TableCell>
                            <TableCell>{row.CuentasPagosFijosHipotecas ?? '-'}</TableCell>
                            <TableCell>{row.CuentasRevolventesAbiertas ?? '-'}</TableCell>
                            <TableCell>{row.CuentasCerradas ?? '-'}</TableCell>
                            <TableCell>{row.CuentasNegativasActuales ?? '-'}</TableCell>
                            <TableCell>{row.CuentasClavesHistoriaNegativa ?? '-'}</TableCell>
                            <TableCell>{row.CuentasDisputa ?? '-'}</TableCell>
                            <TableCell>{row.NumeroSolicitudesUltimos6Meses ?? '-'}</TableCell>
                            <TableCell>{row.NuevaDireccionReportadaUltimos60Dias ?? '-'}</TableCell>
                            <TableCell>{row.MensajesAlerta ?? '-'}</TableCell>
                            <TableCell>{row.ExistenciaDeclaracionesConsumidor ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP96 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP97 ?? '-'}</TableCell>
                            <TableCell>{row.NumeroMOP99 ?? '-'}</TableCell>
                            <TableCell>{row.FechaAperturaCuentaMasAntigua ?? '-'}</TableCell>
                            <TableCell>{row.FechaAperturaCuentaMasReciente ?? '-'}</TableCell>
                            <TableCell>{row.TotalSolicitudesReporte ?? '-'}</TableCell>
                            <TableCell>{row.FechaSolicitudReporteMasReciente ?? '-'}</TableCell>
                            <TableCell>{row.NumeroTotalCuentasDespachoCobranza ?? '-'}</TableCell>
                            <TableCell>{row.FechaAperturaCuentaMasRecienteDespachoCobranza ?? '-'}</TableCell>
                            <TableCell>{row.NumeroTotalSolicitudesDespachosCobranza ?? '-'}</TableCell>
                            <TableCell>{row.FechaSolicitudMasRecienteDespachoCobranza ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {hawkAlertConsultas && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      HawkAlert Consultas
                    </Typography>
                    <Table size="small" padding="checkbox" className={[classes.table, classes.width40].join(' ')}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha Reporte</TableCell>
                          <TableCell>Codigo Clave</TableCell>
                          <TableCell>Tipo Institucion</TableCell>
                          <TableCell>Mensaje</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {hawkAlertConsultas.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.FechaReporte ?? '-'}</TableCell>
                            <TableCell>{row.CodigoClave ?? '-'}</TableCell>
                            <TableCell>{row.TipoInstitucion ?? '-'}</TableCell>
                            <TableCell>{row.Mensaje ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {hawkAlertBD && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      HawkAlertBD
                    </Typography>
                    <Table size="small" padding="checkbox" className={[classes.table, classes.width40].join(' ')}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha Reporte</TableCell>
                          <TableCell>Codigo Clave</TableCell>
                          <TableCell>Tipo Institucion</TableCell>
                          <TableCell>Mensaje</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {hawkAlertBD.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.FechaReporte ?? '-'}</TableCell>
                            <TableCell>{row.CodigoClave ?? '-'}</TableCell>
                            <TableCell>{row.TipoInstitucion ?? '-'}</TableCell>
                            <TableCell>{row.Mensaje ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {scoreBuroCredito && (
                  <>
                    <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                      Score Buro Credito
                    </Typography>
                    <Table size="small" padding="checkbox" className={[classes.table, classes.width40].join(' ')}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre Score</TableCell>
                          <TableCell>Codigo Score</TableCell>
                          <TableCell>Valor Score</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {scoreBuroCredito.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.nombreScore ?? '-'}</TableCell>
                            <TableCell>{row.CodigoScore ?? '-'}</TableCell>
                            <TableCell>{row.ValorScore ?? '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {!response && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Typography variant="body2" gutterBottom component="div">
                Informe no encontrado
              </Typography>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

MoffinReport.propTypes = {
  data: PropTypes.object,
};
