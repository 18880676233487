import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

export const FacebookTable = ({ record }) => {
  const { facebook_id, facebook_email, facebook_first_name, facebook_last_name } = record;

  return (
    <Box p={4}>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Paper>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Facebook ID</TableCell>
                  <TableCell>{facebook_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{facebook_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Firts Name</TableCell>
                  <TableCell>{facebook_first_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last name</TableCell>
                  <TableCell>{facebook_last_name}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

FacebookTable.propTypes = {
  record: PropTypes.shape({
    facebook_id: PropTypes.string,
    facebook_email: PropTypes.string,
    facebook_first_name: PropTypes.string,
    facebook_last_name: PropTypes.string,
  }),
};
