import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../../components';
import { marginZeroStyles } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <TextInput label="Holiday" source="title" alwaysOn />
      <NumberInput label="Id" source="id" />
      <DateInput label="Date" source="day" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const HolidayList = props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'day', order: 'ASC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Holiday" source="title" />
      <TextField label="Date" source="day" />
    </Datagrid>
  </List>
);
export default HolidayList;
