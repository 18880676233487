import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const CallCenterDropdownDialog = ({ isOpened, onClose, onSubmit, selectOptions, title, submitDisabled }) => {
  const [userStatus, setUserStatus] = useState('');

  return (
    <Dialog open={isOpened || false} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Status</DialogContentText>
        <FormControl fullWidth>
          <Select
            value={userStatus || ''}
            onChange={e => {
              setUserStatus(() => e.target.value);
            }}>
            {selectOptions.map(i => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onSubmit(userStatus)} color="primary" disabled={submitDisabled}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CallCenterDropdownDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectOptions: PropTypes.array,
  title: PropTypes.string,
  submitDisabled: PropTypes.bool,
};

export default CallCenterDropdownDialog;
