import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  ChipField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  SelectInput,
  NullableBooleanInput,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../../components';
import { marginZeroStyles, USER_EVENT as userEvents, TRANSMITTER as transmitters } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '5px',
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectInput alwaysOn label="Trigger event" source="triggerEvent" choices={userEvents} />
      <SelectInput alwaysOn label="Interrupt event" source="interruptEvent" choices={userEvents} />
      <SelectInput alwaysOn label="Transmitter" source="transmitterId" choices={transmitters} />
      <NullableBooleanInput alwaysOn label="Is enabled" source="isEnabled" />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const UserEventNotificationList = props => {
  const classes = useStyles();
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ChipField source="trigger_event" size="small" />
        <FunctionField
          source="interrupt_event"
          render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
        />
        <ChipField source="transmitter_id" size="small" />
        <TextField source="template_key" />
        <FunctionField
          source="type"
          sortable={false}
          render={(record, key) =>
            record[key] === 'service' ? (
              <Chip size="small" color="primary" label={record[key]} />
            ) : (
              <Chip size="small" color="secondary" label={record[key]} />
            )
          }
        />
        <TextField source="delay" />
        <FunctionField
          label="Filters"
          render={record => {
            return (
              <Box className={classes.flex}>
                {record.filters?.map(filter => (
                  <Chip key={filter.id} size="small" label={filter.id} />
                ))}
              </Box>
            );
          }}
        />
        <BooleanField source="is_enabled" />
      </Datagrid>
    </List>
  );
};

export default UserEventNotificationList;
