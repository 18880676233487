import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { formatDatetime, formatPhoneNumber } from '../../utils';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxWidth: '300px',
  },
  toolbar: {
    paddingX: theme.spacing(2),
  },
}));

export const NotificationTable = ({
  userId = null,
  applicationId = null,
  loanId = null,
  refreshedAt,
  onTransition,
  inactive = false,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const [notifications, setNotifications] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const getFilter = () => {
      switch (filter) {
        case 'push':
          return { transmitter_id: ['firebase_cloud_messaging'] };
        case 'sms':
          return { transmitter_id: ['fake', 'amazon_sns', 'airmovil', 'cm', 'cm_otp'] };
        case 'email':
          return { transmitter_id: ['mailer'] };
        default:
          return null;
      }
    };

    dataProvider
      .getList('notifications', {
        filter: {
          ...(userId ? { 'user.id': userId } : {}),
          ...(applicationId ? { 'application.id': applicationId } : {}),
          ...(loanId ? { 'loan.id': loanId } : {}),
          ...getFilter(),
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setNotifications(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, applicationId, loanId, refreshedAt, page, perPage, filter]);

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(notifications)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Toolbar className={classes.toolbar}>
        <FormControl component="fieldset">
          <RadioGroup row name="transmitter" value={filter} onChange={handleFilterChange}>
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="push" control={<Radio />} label="Push" />
            <FormControlLabel value="sms" control={<Radio />} label="SMS" />
            <FormControlLabel value="email" control={<Radio />} label="Email" />
          </RadioGroup>
        </FormControl>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Delivery</TableCell>
            <TableCell>Transmitter</TableCell>
            <TableCell>Issuer</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Sent at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.delivery_status} />
              </TableCell>
              <TableCell>{row.transmitter_id}</TableCell>
              <TableCell>{row.issuer_id !== null ? 'manual' : 'system'}</TableCell>
              <TableCell className={classes.breakableCell}>
                {/^\d{10,11}$/.test(row.destination)
                  ? formatPhoneNumber(row.destination)
                  : row.transmitter_id.includes('cloud_messaging')
                    ? `Client's smartphone`
                    : row.destination}
              </TableCell>
              <TableCell>{row.template_key}</TableCell>
              <TableCell className={classes.breakableCell}>{row.message}</TableCell>
              <TableCell>{row.sent_at && formatDatetime(row.sent_at)}</TableCell>
              <TableCell align="right">
                <ButtonGroup
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={permissions.indexOf('CAN_NOTIFICATION_EDIT') === -1}>
                  {row.enabled_transitions.indexOf('resend') !== -1 && (
                    <Button disabled={inactive} onClick={() => onTransition(row.id, 'resend')}>
                      Resend
                    </Button>
                  )}
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

NotificationTable.propTypes = {
  userId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
  inactive: PropTypes.bool,
};
