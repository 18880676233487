import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import FlagIcon from '@material-ui/icons/Flag';
import { DANGER_PRINCIPAL_VALUE, WARNING_PRINCIPAL_VALUE } from '../../../constants';
import { formatCurrency } from '../../../utils';

const ComparePrincipalsBox = ({ principalValue }) => (
  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
    {principalValue >= WARNING_PRINCIPAL_VALUE && principalValue < DANGER_PRINCIPAL_VALUE ? (
      <WarningIcon fontSize="small" htmlColor="#d46e00" />
    ) : principalValue >= DANGER_PRINCIPAL_VALUE ? (
      <FlagIcon fontSize="small" htmlColor="red" />
    ) : null}
    {principalValue < WARNING_PRINCIPAL_VALUE ? (
      formatCurrency(principalValue)
    ) : (
      <b>{formatCurrency(principalValue)}</b>
    )}
  </Box>
);

ComparePrincipalsBox.propTypes = {
  principalValue: PropTypes.number,
};

export default ComparePrincipalsBox;
