import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useStyles } from '../styles';
import { formatCurrency } from '../../../utils';

const FeeAccordion = ({ record }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [discountCalculations, setDiscountCalculations] = useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query(`loans/${record.id}/discount_calculations`, { method: 'GET' })
      .then(({ data }) => setDiscountCalculations(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, record]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  return discountCalculations.map(calc => (
    <Accordion key={calc.title} square>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="subtitle2">{calc.title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.pxy0}>
        <Table size="small">
          <TableBody>
            {calc.items.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="left">
                    {item.title} - {item.percentage}%
                  </TableCell>
                  <TableCell align="right">{formatCurrency(item.amount)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  ));
};

FeeAccordion.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
  }),
};

export default FeeAccordion;
