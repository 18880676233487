import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DebounceButton } from '../../../components';

const TableActionButtonGroup = ({ onRefresh, children, refreshBtnName = 'Refresh' }) => {
  return (
    <Box p={1} display="flex" justifyContent="flex-end">
      <ButtonGroup variant="contained" size="small" color="primary">
        <DebounceButton onClick={onRefresh}>{refreshBtnName}</DebounceButton>
        {children}
      </ButtonGroup>
    </Box>
  );
};

TableActionButtonGroup.propTypes = {
  onRefresh: PropTypes.func,
  children: PropTypes.node,
  refreshBtnName: PropTypes.string,
};

export default TableActionButtonGroup;
