import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { formatCurrency, formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const RelatedProjectLoanTable = ({ userId, refreshedAt }) => {
  const [loans, setLoans] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('related_project_loans', {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { 'current_user.id': userId },
      })
      .then(({ data, total }) => {
        setLoans(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(loans)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Loan id</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Principal</TableCell>
            <TableCell>Tenor</TableCell>
            <TableCell>Last income amount</TableCell>
            <TableCell>Disbursed at</TableCell>
            <TableCell>Closed at </TableCell>
            <TableCell>Sold at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loans.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.loan_id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>{row.principal && formatCurrency(row.principal)}</TableCell>
              <TableCell>{row.tenor}</TableCell>
              <TableCell>{row.last_income_amount && formatCurrency(row.last_income_amount)}</TableCell>
              <TableCell>{row.disbursed_at && formatDatetime(row.disbursed_at)}</TableCell>
              <TableCell>{row.closed_at && formatDatetime(row.closed_at)}</TableCell>
              <TableCell>{row.sold_at && formatDatetime(row.sold_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

RelatedProjectLoanTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
