import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, usePermissions } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ImageZoom, findImage } from '../../../components';
import { useStyles } from '../styles';

const UserIdCardBack = ({ record }) => {
  const [totalIdBack, setTotalIdBack] = useState(0);
  const [lastUploadTimeIdCardBack, setLastUploadTimeIdCardBack] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_USER_EDIT');

  const buildHandleUploadFile = (path, refreshHandler) => {
    return e => dataProvider.postImage(path, e.target.files[0]).then(() => refreshHandler(Date.now()));
  };

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper}>
        <Box className={classes.flexCenteredSpaced}>
          <Typography variant="h6" gutterBottom={false}>
            <label htmlFor="id_card_back_side">
              <input
                disabled={!isEditable}
                className={classes.hidden}
                type="file"
                id="id_card_back_side"
                onChange={buildHandleUploadFile(`users/${record.id}/id_card_back_side`, setLastUploadTimeIdCardBack)}
              />

              <IconButton
                disabled={!isEditable}
                color="primary"
                aria-label="upload picture"
                className={classes.uploadIcon}
                component="span"
                edge="start">
                <CloudUploadIcon />
              </IconButton>
            </label>
            Id card back
          </Typography>
          {totalIdBack > 0 && (
            <Typography variant="caption" color="textSecondary">
              {`${totalIdBack} ${totalIdBack === 1 ? 'file' : 'files'}`}
            </Typography>
          )}
        </Box>
        <Box pb={1}>
          <Divider />
        </Box>
        <ImageZoom
          isExist={findImage(record.uploaded_files, 'id_card_back_side')}
          userId={record.id}
          label="id_card_back_side"
          lastUploadTime={lastUploadTimeIdCardBack}
          handleSetTotal={setTotalIdBack}
        />
      </Paper>
    </Grid>
  );
};

UserIdCardBack.propTypes = {
  record: PropTypes.object,
};

export default UserIdCardBack;
