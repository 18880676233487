import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export const WazzupMessagesTable = ({ userId = null, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [messages, setMessages] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('cm_messages', {
        filter: { 'user.id': userId, channel: 'WhatsApp' },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setMessages(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Date time</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map(row => {
            let message;
            if (typeof row.message === 'string') {
              message = row.message;
            } else if (typeof row.message === 'object' && row.message !== null) {
              if (row.message?.custom?.message_type === 'text') {
                message = row.message.text;
              } else if (row.message?.custom?.message_type === 'image') {
                message = (
                  <Link
                    href={row.message.media.mediaUri}
                    className={classes.flexBox}
                    underline="none"
                    target="_blank"
                    rel="noreferrer">
                    <AttachmentIcon fontSize="small" />
                    Open image
                  </Link>
                );
              } else {
                message = row.message.text;
              }
            } else {
              message = row.message;
            }

            return (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{formatDatetime(row.created_at)}</TableCell>
                <TableCell>
                  <Chip size="small" label={row.direction} />
                </TableCell>
                <TableCell>{row.status.toLowerCase()}</TableCell>
                <TableCell>{message}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

WazzupMessagesTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
