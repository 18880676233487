import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { DateTimePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import { timezone } from '../../../utils';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';

const SecurityReportParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('security_report');

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Calculation date"
            format="MMMM dd, yyyy hh:mm"
            className={classes.fullWidth}
            value={value && value.calculation_date ? timezone.shift(value.calculation_date) : null}
            onChange={v => onChange({ ...(value || {}), calculation_date: timezone.unshift(v) })}
            required
            error={!!(touched && error && error.calculation_date)}
            helperText={touched && error && error.calculation_date}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default SecurityReportParamsInput;
