import { ListFilterHOC } from '../../components';
import LoanListDefault from './LoanListDefault';
import ListFilters from './components/ListFilters';

const LoanListMoneyTransferFailed = ListFilterHOC(
  LoanListDefault,
  { has_failed_money_transfer: true },
  [
    { id: 'active', name: 'Active' },
    { id: 'closed', name: 'Closed' },
    { id: 'defaulted', name: 'Defaulted' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'pending', name: 'Pending' },
    { id: 'cep_fail', name: 'CEP fail' },
    { id: 'transfer_fail', name: 'Transfer fail' },
  ],
  ListFilters,
);

export default LoanListMoneyTransferFailed;
