import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Filter } from 'react-admin';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      {children}
    </Filter>
  );
};

ListFilter.propTypes = {
  children: PropTypes.node,
};

export default ListFilter;
