import React from 'react';
import PropTypes from 'prop-types';
import { usePermissions, SelectInput, TextInput } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Call } from '../../../components';
import { useStyles } from '../styles';
import { useHandbook } from '../../../hooks';
import { phoneNumber } from '../../../utils';

const UserContactPerson = ({ record }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');

  const isEditable = permissions.includes('CAN_USER_EDIT');

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" gutterBottom={false}>
        Contact person
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput disabled={!isEditable} label="Name" source="contact_name" fullWidth helperText={false} />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            key={`contact-relationship-input-${contactRelationshipChoices.length}`}
            disabled={!isEditable}
            label="Relationship"
            source="contact_relationship"
            fullWidth
            helperText={false}
            choices={contactRelationshipChoices}
          />
        </Grid>
        <Grid item xs={10}>
          <TextInput
            disabled={!isEditable}
            label="Phone number"
            source="contact_phone_number"
            fullWidth
            helperText={false}
            placeholder={phoneNumber.placeholder}
            parse={phoneNumber.parser}
            format={value => phoneNumber.formatter(value)}
          />
        </Grid>
        <Grid item xs={2} className={classes.call}>
          <Call
            inactive={record.has_sold_loans}
            userId={record.id}
            userPhone={record.contact_phone_number}
            hidden
            edge={'start'}>
            {'+52' + record.contact_phone_number}
          </Call>
        </Grid>
      </Grid>
    </Paper>
  );
};

UserContactPerson.propTypes = {
  record: PropTypes.object,
};

export default UserContactPerson;
