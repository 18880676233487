import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { debounce } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import { LOAN_STATISTICS_FILTER_CHOICES } from '../../constants';
import { getUserId } from '../../utils';
import { generateActionsQuery, useReportStyles, getCellColorByPercentage } from './utils';
import { AutocompleteWithoutForm } from '../../components';

const AssignedBucketActionsReport = ({ collectionGroups }) => {
  const userId = getUserId();

  const [filter, setFilter] = React.useState('active_no_ptp');
  const [collectionGroupsSelected, setCollectionGroupsSelected] = React.useState([]);
  const [collectors, setCollectors] = React.useState([userId]);
  const [maxDpd, setMaxDpd] = React.useState('');
  const [minDpd, setMinDpd] = React.useState('');
  const [maxPrincipal, setMaxPrincipal] = React.useState('');
  const [minPrincipal, setMinPrincipal] = React.useState('');
  const [statistics, setStatistics] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [collectorsFilter, setCollectorsFilter] = React.useState({
    role_permission: 'CAN_BE_COLLECTION_SPECIALIST',
    is_blocked: false,
  });

  const classes = useReportStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const fetchOptions = () => {
    const query = generateActionsQuery(
      collectionGroupsSelected,
      collectors,
      'loans/statistic_with_action_by_dpd',
      'loan_statistic_filter',
      filter,
      maxDpd,
      minDpd,
      maxPrincipal,
      minPrincipal,
    );
    setLoading(true);
    dataProvider
      .query(query, { method: 'GET' })
      .then(res => setStatistics(res.data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  };

  const debouncedFetchData = React.useMemo(
    () => debounce(fetchOptions, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, collectors, collectionGroupsSelected, maxDpd, minDpd, maxPrincipal, minPrincipal],
  );

  React.useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const setCellColor = percentageStr => {
    return getCellColorByPercentage(percentageStr, classes);
  };

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(statistics)) return null;

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <FormControl fullWidth className={classes.spacing} variant="filled">
              <InputLabel id="filter-select-label">Filter</InputLabel>
              <Select
                labelId="filter-select-label"
                onChange={e => setFilter(e.target.value)}
                variant="filled"
                value={filter}>
                {LOAN_STATISTICS_FILTER_CHOICES.map(i => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {collectionGroups.length > 0 && (
              <FormControl fullWidth className={classes.spacing} variant="filled">
                <InputLabel>Collection groups</InputLabel>
                <Select
                  multiple
                  value={collectionGroupsSelected}
                  onChange={e => {
                    setCollectionGroupsSelected(e.target.value);
                    setCollectors(e.target.value.length ? [] : [userId]);
                    const collectionGroupsCodes = collectionGroups
                      .filter(g => e.target.value.includes(g.id))
                      .map(g => g.code);
                    setCollectorsFilter(prev => ({ ...prev, 'collection_groups.code': collectionGroupsCodes }));
                  }}
                  renderValue={selected =>
                    selected.length
                      ? selected.map(id => collectionGroups.find(group => group.id === id)?.code).join(', ')
                      : null
                  }>
                  {collectionGroups.map(group => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth className={classes.spacing}>
              <AutocompleteWithoutForm
                size="medium"
                margin="none"
                resource="admins"
                optionValueProp="id"
                optionLabelProp="username"
                label="Collectors"
                multiple
                filter={collectorsFilter}
                defaultValue={[userId]}
                value={collectors}
                onChange={value => {
                  setCollectors(value);
                  setCollectionGroupsSelected([]);
                  setCollectorsFilter({ role_permission: 'CAN_BE_COLLECTION_SPECIALIST', is_blocked: false });
                }}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <TextField
              type="number"
              variant="filled"
              margin="none"
              label="Min days past due"
              value={minDpd}
              onChange={e => setMinDpd(e.target.value)}
              onKeyPress={event => (event?.key === '.' || event?.key === ',') && event.preventDefault()}
              className={[classes.spacing, classes.maxWidth200].join(' ')}
              fullWidth
            />
            <TextField
              type="number"
              variant="filled"
              margin="none"
              label="Max days past due"
              value={maxDpd}
              onChange={e => setMaxDpd(e.target.value)}
              onKeyPress={event => (event?.key === '.' || event?.key === ',') && event.preventDefault()}
              className={[classes.spacing, classes.maxWidth200].join(' ')}
              fullWidth
            />
            <TextField
              type="number"
              variant="filled"
              margin="none"
              label="Min principal"
              value={minPrincipal}
              onChange={e => setMinPrincipal(e.target.value)}
              onKeyPress={event => (event?.key === '.' || event?.key === ',') && event.preventDefault()}
              className={[classes.spacing, classes.maxWidth200].join(' ')}
              fullWidth
            />
            <TextField
              type="number"
              variant="filled"
              margin="none"
              label="Max principal"
              value={maxPrincipal}
              onChange={e => setMaxPrincipal(e.target.value)}
              onKeyPress={event => (event?.key === '.' || event?.key === ',') && event.preventDefault()}
              className={[classes.spacing, classes.maxWidth200].join(' ')}
              fullWidth
            />
          </Box>
        </Grid>
        {(collectors.length > 0 || collectionGroupsSelected.length > 0 || maxDpd || minDpd) && (
          <Grid item xs={1} alignContent="center">
            <Button
              startIcon={<ClearIcon />}
              variant="text"
              color="primary"
              onClick={() => {
                setCollectors([userId]);
                setCollectionGroupsSelected([]);
                setFilter('active_no_ptp');
                setMaxDpd('');
                setMinDpd('');
                setMinPrincipal('');
                setMaxPrincipal('');
              }}>
              Reset
            </Button>
          </Grid>
        )}
      </Grid>
      {loading ? (
        <Box mt={5} display="flex" justifyContent="center" alignItems="center" sx={{ gap: '8px' }}>
          <CircularProgress size={20} />
          <Typography variant="body2">Loading report ...</Typography>
        </Box>
      ) : statistics.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow className={classes.fontBold}>
                <TableCell align="center">DPD</TableCell>
                <TableCell align="center">{LOAN_STATISTICS_FILTER_CHOICES.find(i => i.id === filter)?.name}</TableCell>
                <TableCell align="center">Not added action</TableCell>
                <TableCell align="center">Loans with added action</TableCell>
                <TableCell align="center">% Loans with action</TableCell>
                <TableCell align="center">Call</TableCell>
                <TableCell align="center">% Call</TableCell>
                <TableCell align="center">WhatsApp</TableCell>
                <TableCell align="center">% WhatsApp</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">% Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statistics.map((row, index) => {
                const total = row.loans_with_action ? ((row.loans_with_action / row.loans_count) * 100).toFixed(0) : 0;
                const callPercentage = row.loans_with_call
                  ? ((row.loans_with_call / row.loans_count) * 100).toFixed(0)
                  : 0;
                const whatsappPercentage = row.loans_with_whatsapp
                  ? ((row.loans_with_whatsapp / row.loans_count) * 100).toFixed(0)
                  : 0;
                const mailPercentage = row.loans_with_email
                  ? ((row.loans_with_email / row.loans_count) * 100).toFixed(0)
                  : 0;
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {`${row.group} (${row.dpd})`}
                    </TableCell>
                    <TableCell align="right">{row.loans_count}</TableCell>
                    <TableCell align="right">{row.loans_count - row.loans_with_action}</TableCell>
                    <TableCell align="right">{row.loans_with_action}</TableCell>
                    <TableCell align="right" className={setCellColor(total)}>{`${total}%`}</TableCell>
                    <TableCell align="right">{row.loans_with_call}</TableCell>
                    <TableCell align="right" className={setCellColor(callPercentage)}>{`${callPercentage}%`}</TableCell>
                    <TableCell align="right">{row.loans_with_whatsapp}</TableCell>
                    <TableCell
                      align="right"
                      className={setCellColor(whatsappPercentage)}>{`${whatsappPercentage}%`}</TableCell>
                    <TableCell align="right">{row.loans_with_email}</TableCell>
                    <TableCell align="right" className={setCellColor(mailPercentage)}>{`${mailPercentage}%`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box mt={5} display="flex" justifyContent="center" alignItems="center" sx={{ gap: '8px' }}>
          <Typography variant="body2">No data found</Typography>
        </Box>
      )}
    </>
  );
};

AssignedBucketActionsReport.propTypes = {
  collectionGroups: PropTypes.array,
};

AssignedBucketActionsReport.defaultProps = {
  collectionGroups: [],
};

export default AssignedBucketActionsReport;
