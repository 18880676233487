import { AUDIENCE_FILTERS_IDS } from '../../../constants';

const ERROR_MESSAGES = {
  valueRequired: 'Value fields can not be empty',
  optionRequired: 'Please select an option',
  rangeInvalid: (start, end) => `Allowed values range is between ${start} and ${end}`,
  allOptionsRequired: 'Please select all options',
};

export const audienceFormValidator = (filters, params) => {
  const errors = {};
  Object.entries(params).forEach(([key, value]) => {
    const type = AUDIENCE_FILTERS_IDS.find(i => i.id === key)?.type;
    const allowedValues = filters.find(filter => filter.id === key)?.description?.allowed_values;
    if (type === 'sign' && value?.values?.some(val => val.number === '')) {
      errors[key] = ERROR_MESSAGES.valueRequired;
    }
    if (
      type === 'sign' &&
      key === 'extension_percentage_of_principal' &&
      value?.values?.some(val => val.number < 0 || val.number > 1000)
    ) {
      errors[key] = ERROR_MESSAGES.rangeInvalid(0, 1000);
    }
    if (
      type === 'last_digits' &&
      allowedValues?.length > 0 &&
      allowedValues.includes(value?.values?.number) === false
    ) {
      const fieldValues = value?.values?.map(item => item.number);

      if (fieldValues.some(val => val === '') || !fieldValues.every(val => allowedValues.includes(+val))) {
        errors[key] = ERROR_MESSAGES.rangeInvalid(allowedValues[0], allowedValues[allowedValues.length - 1]);
      }
    }
    if (type === 'list' && value?.values?.some(val => val.value === '')) {
      errors[key] = ERROR_MESSAGES.optionRequired;
    }
    if (type === 'boolean' && !('values' in value)) {
      errors[key] = ERROR_MESSAGES.optionRequired;
    }
    if (
      type === 'date_range' &&
      !(value?.values && Object.keys(value?.values).includes('from') && Object.keys(value?.values).includes('to'))
    ) {
      errors[key] = ERROR_MESSAGES.allOptionsRequired;
    }
  });
  return errors;
};
