import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

export const setRowStyles = (i, theme) => {
  let background = theme.palette.background.paper;

  const smsTime = new Date(i.resolved_at);
  const nowTime = new Date();
  const operator = i.last_phone_calls_by_context?.application_unsigned_applications?.operator;
  const result = i.last_phone_calls_by_context?.application_unsigned_applications?.result;
  const min = Math.floor((nowTime - smsTime) / 60000);

  if (min > 15 && operator === null) {
    background = red[300];
  }
  if (min > 60 && (result === 'Is thinking' || result === 'Not answered' || result === 'Going to sign')) {
    background = red[300];
  }
  if (result === 'Is not interested') {
    background = orange[300];
  }
  if (min < 60 && result === 'Is thinking') {
    background = yellow[300];
  }
  if (min < 60 && result === 'Going to sign') {
    background = green[300];
  }

  return {
    backgroundColor: background,
  };
};
