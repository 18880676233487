import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  NumberInput,
  useDataProvider,
  useNotify,
  useRefresh,
  usePermissions,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { formatPhoneNumber } from '../../utils';
import { Pagination } from '../../components';
import { ListActions } from '../user/UserList';
import ListFilter from './components/ListFilter';

const DuplicatedRegistrationsListFilter = props => {
  return (
    <ListFilter {...props}>
      <NumberInput label="Duplicate client ID" source="id" alwaysOn />
    </ListFilter>
  );
};

const DuplicatedRegistrationsList = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const showFullPhone = !permissions.includes('CAN_SEE_FULL_PHONE');

  const mergeDuplicateUser = userId => {
    dataProvider
      .query('duplicate_user/merge', {
        method: 'POST',
        body: JSON.stringify({ userId }),
      })
      .then(() => notify(`Success: duplicated user #${userId} merged`, 'success'))
      .catch(error => notify(`Error: ${error.message || 'merging user error'}`, 'error'))
      .finally(() => refresh());
  };

  const deleteDuplicateUser = userId => {
    dataProvider
      .query(`duplicate_user/${userId}`, {
        method: 'DELETE',
        body: JSON.stringify({ userId }),
      })
      .then(() => notify(`Success: duplicated user #${userId} deleted`, 'success'))
      .catch(error => notify(`Error: ${error.message || 'deleting user error'}`, 'error'))
      .finally(() => refresh());
  };

  return (
    <List
      title="Duplicated registrations"
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<DuplicatedRegistrationsListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick={id => `/users/${id}`}>
        <TextField label="Duplicate client ID" source="id" />
        <FunctionField
          label="Duplicate phone number"
          source="username"
          render={({ username }) => formatPhoneNumber(username, showFullPhone)}
          sortable={false}
        />
        <FunctionField
          label="Old client ID"
          render={({ matched_user_id }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + matched_user_id} target="_blank">
              {`#${matched_user_id}`}
            </Link>
          )}
          sortable={false}
        />
        <FunctionField
          label="Old number"
          source="matched_user_name"
          render={({ matched_user_name }) => formatPhoneNumber(matched_user_name, showFullPhone)}
          sortable={false}
        />
        <TextField label="Reason auto-merging is not possible" source="reason_not_auto_merging" sortable={false} />
        <FunctionField
          onClick={e => e.stopPropagation()}
          label="Actions"
          render={({ id, reason_not_auto_merging }) => {
            return (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => mergeDuplicateUser(id)}
                  disabled={reason_not_auto_merging === 'moratorium' || reason_not_auto_merging === 'black_list'}>
                  Merge duplicate
                </Button>
                <Button variant="contained" color="primary" onClick={() => deleteDuplicateUser(id)}>
                  Delete duplicate
                </Button>
              </Box>
            );
          }}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default DuplicatedRegistrationsList;
