import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Chip from '@material-ui/core/Chip';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { formatDatetime } from '../../utils';
import { TableCellSort } from './TableCellSort';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const MatchesTable = ({ recordId, refreshedAt, perPageCustom }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const [matches, setMatches] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageCustom ?? 30);
  const [activeFilter, setActiveFilter] = useState('id');
  const [rejectionReasons, setRejectionReasons] = useState(null);

  const startPageItem = (page > 0 ? page - 1 : 0) * (perPage - 1);
  const endPageItem = page * (perPage - 1);

  const getRejectReason = (errors, id) => {
    if (!Array.isArray(errors) || !id) {
      return null;
    }

    return errors.find(i => i.id === id);
  };

  useEffect(() => {
    dataProvider
      .query(`applications/${recordId}/user-field-matches`, {})
      .then(({ data }) => {
        const sort = data.slice().sort((a, b) => b.id - a.id);
        setMatches(sort);
        setLoading(false);
        setTotal(data.length);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
    dataProvider
      .query('rejection_reasons?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setRejectionReasons(data))
      .catch(({ error }) => {
        setRejectionReasons(null);
        setError(error);
      });
  }, [recordId, dataProvider, refreshedAt]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(matches)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellSort
              changeArray={setMatches}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filter={'id'}>
              Application ID
            </TableCellSort>
            <TableCellSort
              changeArray={setMatches}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filter={'user_id'}>
              User id
            </TableCellSort>
            {[
              'Created at',
              'Name',
              'Approved principal',
              'Rejection reason code',
              'State',
              'Max paid day',
              'Similar value',
              'Type value',
            ].map(i => (
              <TableCell key={i}>{i}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.slice(startPageItem, endPageItem).map((row, id) => {
            const reject = getRejectReason(rejectionReasons, row.rejection_reason_code);
            return (
              <TableRow key={`${id}match`}>
                <TableCell>
                  {permissions.includes('CAN_APPLICATION_VIEW') ? (
                    <Link to={`/applications/${row.id}/show`}>{row.id}</Link>
                  ) : (
                    row.id
                  )}
                </TableCell>
                <TableCell>
                  {permissions.includes('CAN_USER_VIEW') ? (
                    <Link to={`/users/${row.user_id}/show`}>{row.user_id}</Link>
                  ) : (
                    row.user_id
                  )}
                </TableCell>
                <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.approved_principal}</TableCell>
                <TableCell>{reject ? reject.name : row.rejection_reason_code}</TableCell>
                <TableCell>
                  <Chip size="small" label={row.state} />
                </TableCell>
                <TableCell>{row.max_paid_day}</TableCell>
                <TableCell>{row.similar_value}</TableCell>
                <TableCell>{row.type_value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

MatchesTable.propTypes = {
  recordId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
  perPageCustom: PropTypes.number,
};

MatchesTable.defaultProps = {
  recordId: null,
};
