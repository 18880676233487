import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  fixedWidth: {
    width: '200px',
  },
}));

export const BankAccountsTable = ({ userId = null, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .query(`users/${userId}/payment-details`, { method: 'GET' })
      .then(({ data }) => {
        setBankAccounts(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(bankAccounts)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>CLABE Number</TableCell>
            <TableCell>Is default</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bankAccounts.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.number}</TableCell>
              <TableCell>{row.is_default && <DoneIcon color="secondary" />}</TableCell>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

BankAccountsTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
