import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },
}));

const FormWrapper = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = React.useState(record.name);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChangeName = e => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => name !== 'name')));
    setName(e.target.value || null);
  };

  const validate = () => {
    const formErrors = {};

    if (!name) {
      formErrors.name = 'Field is required';
    } else if (name.length > 255) {
      formErrors.name = 'Maximum field length is 255 symbols';
    }

    const isValid = Object.keys(formErrors).length === 0;
    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      dataProvider
        .query(`product_rate_strategies/${record.id}`, { method: 'PATCH', body: JSON.stringify({ name }) })
        .then(() => {
          setLoading(false);
          redirect('/product_rate_strategies');
        })
        .catch(error => {
          setLoading(false);
          if (error.message.includes('product_rate_strategy_name_unique_idx')) {
            const formErrors = { ...errors };
            formErrors.name = 'Such a name already exists. Try another name';
            setErrors(formErrors);
          } else {
            notify(`Error: ${error.message}`, 'error');
          }
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>
                  Update product rate strategy
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.my2} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Name"
                    multiline
                    value={name || ''}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  {record.product_rate_strategy_settings.map((s, i) => (
                    <>
                      <Grid key={i} container spacing={1} flexDirection="row">
                        <Grid item xs={12} sm={3}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Rate"
                            value={parseFloat((s.rate * 100).toFixed(10))}
                            helperText="% per day"
                            size="small"
                            className={classes.formControl}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Tenor from"
                            value={s.tenor_from}
                            size="small"
                            className={classes.formControl}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Tenor to"
                            value={s.tenor_to}
                            size="small"
                            className={classes.formControl}
                            disabled
                          />
                        </Grid>
                        {i !== 0 && (
                          <Grid item xs={12} sm={1}>
                            <IconButton disabled color="primary">
                              <RemoveCircleOutlineTwoTone />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                      {errors?.settings?.[i]?.other && (
                        <Typography variant="caption" color="error">
                          {errors?.settings?.[i]?.other}
                        </Typography>
                      )}
                    </>
                  ))}
                </Grid>
                {errors.range?.length > 0 && (
                  <Grid item xs={12}>
                    {errors.range.map((error, i) => (
                      <Typography component="p" key={i} variant="caption" color="error">
                        {error}
                      </Typography>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button variant="text" color="primary" startIcon={<AddCircleOutlineTwoTone />} disabled>
                    Add new row
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider className={classes.my2} />
            <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={() => redirect('/product_rate_strategies')}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                type="submit">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

FormWrapper.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    product_rate_strategy_settings: PropTypes.array,
  }),
};

const ProductRateStrategiesEdit = ({ ...props }) => (
  <Edit component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default ProductRateStrategiesEdit;
