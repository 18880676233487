import React, { useState } from 'react';
import { Toolbar, required, useNotify, useDataProvider } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Autocomplete } from '../../components';

const useStyles = makeStyles(theme => ({
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
}));

const UserTagsMassManagement = () => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleSubmit = (values, form) => {
    setLoading(true);
    const { action, tag_id, audience_id } = values;
    dataProvider
      .query(`mass_sending_audiences/${audience_id}/tags_action`, {
        method: 'POST',
        body: JSON.stringify({ action, tag_id }),
      })
      .then(() =>
        notify(`User tag successfully ${action === 'add' ? 'added to audience' : 'deleted from audience'}`, 'success'),
      )
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        form.restart();
      });
  };

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} sm={8} md={4}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" gutterBottom={false}>
              User tags management
            </Typography>
            <Divider className={classes.my2} />
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Field name="action" validate={required('This field is required')}>
                        {({ input, meta }) => (
                          <FormControl fullWidth variant="filled">
                            <InputLabel error={meta.touched && meta.error} required id="action-select-label">
                              Action type
                            </InputLabel>
                            <Select
                              id="action-select-label"
                              margin="dense"
                              value={input.value}
                              error={meta.touched && meta.error}
                              onChange={e => {
                                input.onChange(e.target.value);
                              }}
                              fullWidth>
                              <MenuItem value="add">Add</MenuItem>
                              <MenuItem value="delete">Delete</MenuItem>
                            </Select>
                            {meta.touched && meta.error && (
                              <FormHelperText error={meta.touched && meta.error}>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="tag_id" validate={required('This field is required')}>
                        {({ input, meta }) => (
                          <Autocomplete
                            resource="user_tags"
                            label="Choose tag"
                            source="tag_id"
                            optionValueProp="id"
                            optionLabelProp="name"
                            withCreateNew
                            required
                            requiredMessage="This field is required"
                            margin="none"
                            {...input}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="audience_id" validate={required('This field is required')}>
                        {({ input, meta }) => (
                          <Autocomplete
                            resource="mass_sending_audiences"
                            label="Choose audience"
                            source="audience_id"
                            optionValueProp="id"
                            optionLabelProp="name"
                            required
                            requiredMessage="This field is required"
                            margin="none"
                            {...input}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Toolbar className={classes.toolbar}>
                    <Button
                      onClick={handleSubmit}
                      startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : <SaveIcon />}
                      color="primary"
                      variant="contained"
                      disabled={loading}>
                      Submit
                    </Button>
                  </Toolbar>
                </form>
              )}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserTagsMassManagement;
