import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  ChipField,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Pagination, AudioPlayerDialog, DateFilterInput } from '../../components';
import { formatDate, formatPhoneNumber, formatTime } from '../../utils';
import { marginZeroStyles } from '../../constants';
import { directionChoices, causeChoices } from './constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const WebitelCallsListFilter = props => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <SelectInput source="direction" choices={directionChoices} />
      <TextInput label="Destination" source="distination" />
      <SelectInput source="cause" choices={causeChoices} />
      <TextInput label="Queue name" source="queue_name" />
      <DateFilterInput label="Created before" source="created|before" before />
      <DateFilterInput label="Created after" source="created|after" after />
      <DateFilterInput label="Answered before" source="answered|before" before />
      <DateFilterInput label="Answered after" source="answered|after" after />
      <DateFilterInput label="Hangup before" source="hangup|before" before />
      <DateFilterInput label="Hangup after" source="hangup|after" after />
      <NumberInput label="Max talk time, sec." source="bill_sec|lte" />
      <NumberInput label="Min talk time, sec." source="bill_sec|gte" />
    </Filter>
  );
};

const WebitelCallsListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

WebitelCallsListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const WebitelCallsList = props => {
  const [record, setRecord] = React.useState(null);
  const [openPlayer, setOpenPlayer] = React.useState(false);

  const handleOpenPlayer = recordId => {
    setRecord(recordId);
    setOpenPlayer(true);
  };

  const handleClosePlayer = () => {
    setOpenPlayer(false);
    setRecord(null);
  };

  return (
    <>
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<WebitelCallsListFilter />}
        actions={<WebitelCallsListActions />}
        {...props}>
        <Datagrid>
          <TextField source="id" />
          <FunctionField
            label="User"
            render={({ user_id }) =>
              user_id ? (
                <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>{`#${user_id}`}</Link>
              ) : (
                '---'
              )
            }
          />
          <ChipField size="small" source="direction" sortable={false} />
          <FunctionField
            label="Destination"
            source="destination"
            render={record => (
              <div>
                {/^\d{10,11}$/.test(record.destination) ? formatPhoneNumber(record.destination) : record.destination}
              </div>
            )}
            sortable={false}
          />
          <FunctionField
            label="From user"
            sortable={false}
            render={record => (
              <>
                <div>
                  <b>Type:</b>
                  <br />
                  {record.from_user?.type ?? '---'}
                </div>
                <div>
                  <b>Number:</b>
                  <br />
                  {formatPhoneNumber(record.from_user?.number)}
                </div>
                <div>
                  <b>Name:</b>
                  <br />
                  {record.from_user?.name ?? '---'}
                </div>
              </>
            )}
          />
          <FunctionField
            label="To user"
            sortable={false}
            render={record => (
              <>
                <div>
                  <b>Type:</b>
                  <br />
                  {record.to_user?.type ?? '---'}
                </div>
                <div>
                  <b>Number:</b>
                  <br />
                  {formatPhoneNumber(record.to_user?.number)}
                </div>
                <div>
                  <b>Name:</b>
                  <br />
                  {record.to_user?.name ?? '---'}
                </div>
              </>
            )}
          />
          <TextField label="Queue" source="queue_name" sortable={false} />
          <FunctionField
            label="Created at"
            source="created"
            render={({ created }) =>
              created ? (
                <>
                  {formatDate(created)}
                  <br />
                  {formatTime(created)}
                </>
              ) : null
            }
          />
          <FunctionField
            label="Answered at"
            source="answered"
            render={({ answered }) =>
              answered ? (
                <>
                  {formatDate(answered)}
                  <br />
                  {formatTime(answered)}
                </>
              ) : null
            }
          />
          <FunctionField
            label="Hangup"
            sortBy="hangup"
            render={record => (
              <>
                <div>
                  <b>Hangup at:</b>
                  <br />
                  {formatDate(record.hangup)}
                  <br />
                  {formatTime(record.hangup)}
                </div>
                <div>
                  <b>Hangup by:</b>
                  <br />
                  {record.hangup_by}
                </div>
              </>
            )}
          />
          <FunctionField
            label="Cause"
            render={record => (
              <>
                <b>#{record.sip_code}</b>
                <br />
                {record.cause}
              </>
            )}
          />
          <TextField label="Dur." source="duration" />
          <TextField label="Wait time" source="wait_sec" />
          <TextField label="Talk time" source="bill_sec" />
          <FunctionField
            label="Record"
            render={record => (
              <IconButton
                disabled={record.user_files.length === 0}
                onClick={() => handleOpenPlayer(record.user_files[0])}
                color="secondary">
                <PlayCircleOutlineIcon />
              </IconButton>
            )}
          />
        </Datagrid>
      </List>
      {openPlayer && <AudioPlayerDialog onClose={handleClosePlayer} recordId={record} />}
    </>
  );
};

export default WebitelCallsList;
