import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, FormWithRedirect, SaveButton, useNotify, useRedirect, useRefresh, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ListIcon from '@material-ui/icons/List';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MuiAlert from '@material-ui/lab/Alert';
import UserEditTransitions from './components/UserEditTransitions';
import UserEditAdditionalInfo from './components/UserEditAdditionalInfo';
import UserProfile from './components/UserProfile';
import UserResidence from './components/UserResidence';
import UserSelfie from './components/UserSelfie';
import UserIdCardFront from './components/UserIdCardFront';
import UserIdCardBack from './components/UserIdCardBack';
import UserContactPerson from './components/UserContactPerson';
import UserPaymentInfo from './components/UserPaymentInfo';
import { UserLoanApplicationDialog, BlackList, BlackListMatch } from '../../components';
import { validateCardNumber } from '../../utils';
import { useStyles } from './styles';

const UserEditForm = props => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const [isLoanApplicationDialogOpened, setIsLoanApplicationDialogOpened] = useState(false);

  const isEditable = permissions.includes('CAN_USER_EDIT');
  const canApplicationEdit = permissions.includes('CAN_APPLICATION_EDIT');

  const validate = values => {
    const errors = {};

    if (values.curp && values.curp.length !== 18) {
      errors.curp = 'CURP length is 18 symbols';
    }

    if (values.card_number) {
      const cardNumberDigits = `${values.card_number}`.replace(/\D/, '');

      if (cardNumberDigits.length < 12 || cardNumberDigits.length > 19) {
        errors.card_number = 'Card number length should be 12-19 symbols';
      } else if (validateCardNumber(cardNumberDigits) === false) {
        errors.card_number = 'Invalid card number';
      }
    }

    return errors;
  };

  return (
    <>
      <FormWithRedirect
        {...props}
        validate={validate}
        render={formProps => (
          <>
            <UserEditTransitions record={formProps.record} refresh={refresh} />
            <UserEditAdditionalInfo record={formProps.record} />
            <Divider className={classes.my20} />
            <BlackListMatch record={formProps.record} />
            <BlackList blackList={formProps.record.black_list} isInBlackList={formProps.record.is_in_black_list} />
            {formProps.record.state === 'blocked' && (
              <MuiAlert elevation={6} variant="filled" severity="error" className={classes.mb3}>
                User is blocked!
              </MuiAlert>
            )}
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <UserProfile record={formProps.record} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UserResidence record={formProps.record} />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <UserSelfie record={formProps.record} />
                    <UserIdCardFront record={formProps.record} />
                    <UserIdCardBack record={formProps.record} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <UserContactPerson record={formProps.record} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <UserPaymentInfo record={formProps.record} />
                </Grid>
              </Grid>
              <Divider className={classes.my20} />
              <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button startIcon={<ListIcon />} onClick={() => redirect('/users')}>
                    Back to list
                  </Button>
                  {(canApplicationEdit &&
                    !formProps.record.is_in_black_list &&
                    !formProps.record.is_find_in_black_list) ||
                  (canApplicationEdit && !formProps.record.is_in_black_list) ? (
                    <Button startIcon={<AddBoxIcon />} onClick={() => setIsLoanApplicationDialogOpened(true)}>
                      Create application
                    </Button>
                  ) : null}
                  {isEditable ? (
                    <SaveButton
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                      onSuccess={() => notify('Record updated.')}
                    />
                  ) : null}
                </ButtonGroup>
              </Grid>
            </form>
          </>
        )}
      />
      {canApplicationEdit && isLoanApplicationDialogOpened ? (
        <UserLoanApplicationDialog onClose={() => setIsLoanApplicationDialogOpened(false)} record={props.record} />
      ) : null}
    </>
  );
};

UserEditForm.propTypes = {
  record: PropTypes.object,
};

const UserEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <UserEditForm />
  </Edit>
);

export default UserEdit;
