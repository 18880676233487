import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import { timezone } from '../../../utils';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';

const AccountingUnpaidReportParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('accounting_unpaid_report');

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="End date"
            format="MMMM dd, yyyy"
            className={classes.fullWidth}
            value={value && value.end_date ? timezone.shift(value.end_date) : null}
            onChange={v => onChange({ ...(value || {}), end_date: timezone.unshift(v) })}
            required
            error={!!(touched && error && error.end_date)}
            helperText={touched && error && error.end_date}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default AccountingUnpaidReportParamsInput;
