import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import ReportDate from '../components/ReportDate';
import { timezone } from '../../../utils';
import { CALL_CENTER_USER_STATUS_UNCOMPLETED_REG } from '../../../constants';

const UnfinishedRegistrationReportParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('unfinished_registration_report');

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required={false}
        />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Call assigned before"
            format="MMMM dd, yyyy"
            className={classes.fullWidth}
            value={value && value.phone_call_assigned_before ? timezone.shift(value.phone_call_assigned_before) : null}
            onChange={v =>
              onChange({ ...(value || {}), phone_call_assigned_before: timezone.setTime(timezone.unshift(v), 0, 0, 0) })
            }
            error={!!(touched && error && error.phone_call_assigned_before)}
            helperText={touched && error && error.phone_call_assigned_before}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Call assigned after"
            format="MMMM dd, yyyy"
            className={classes.fullWidth}
            value={value && value.phone_call_assigned_after ? timezone.shift(value.phone_call_assigned_after) : null}
            onChange={v =>
              onChange({ ...(value || {}), phone_call_assigned_after: timezone.setTime(timezone.unshift(v), 0, 0, 0) })
            }
            error={!!(touched && error && error.phone_call_assigned_after)}
            helperText={touched && error && error.phone_call_assigned_after}
          />
        </Grid>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
        <ReportDateFormat classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.last_call_result)}>
            <InputLabel id="last-call-result-select-label">Last call result</InputLabel>
            <Select
              labelId="last-call-result-select-label"
              value={value && value.last_call_result ? value.last_call_result : ''}
              onChange={e => onChange({ ...(value || {}), last_call_result: e.target.value })}>
              {CALL_CENTER_USER_STATUS_UNCOMPLETED_REG.map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.last_call_result && <FormHelperText>{error.last_call_result}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Last call date"
            format="MMMM dd, yyyy hh:mm"
            className={classes.fullWidth}
            value={value && value.date_last_call_result ? timezone.shift(value.date_last_call_result) : null}
            onChange={v => onChange({ ...(value || {}), date_last_call_result: timezone.unshift(v) })}
            error={!!(touched && error && error.date_last_call_result)}
            helperText={touched && error && error.date_last_call_result}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="registration-step-number"
            label="Registration step"
            type="number"
            InputProps={{
              inputProps: { min: 1, max: 12 },
            }}
            onChange={e => onChange({ ...(value || {}), registration_step: e.target.value })}
            fullWidth
            error={!!(touched && error && error.registration_step)}
            helperText={touched && error && error.registration_step}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UnfinishedRegistrationReportParamsInput;
