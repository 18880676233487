import React from 'react';
import PropTypes from 'prop-types';
import { TitleCloseDialog } from '../../../components';

const CallCenterLogDialog = ({ open, close, children, title }) => (
  <TitleCloseDialog open={open || false} close={close} title={title} maxWidth={'md'}>
    {children}
  </TitleCloseDialog>
);

CallCenterLogDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
};

export default CallCenterLogDialog;
