import React from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AdminNotification } from './AdminNotification';
import { usePageVisibility } from '../../hooks';

const useStyles = makeStyles(theme => ({
  mr16: {
    marginRight: theme.spacing(2),
  },
  button: {
    '&:hover': {
      background: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',
    borderBottom: `1px solid ${grey[300]}`,
  },
}));

const StyledMenu = withStyles({
  paper: { border: '1px solid #d3d4d5' },
  list: { padding: 0 },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const getOperatorId = () => {
  const userJson = localStorage.getItem('admin_fields');
  if (!userJson) {
    return 'Unknown';
  }
  const userObject = JSON.parse(userJson);
  return userObject?.id ?? null;
};

export const AdminNotifications = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [allNotifications, setAllNotifications] = React.useState([]);
  const [showUnread, setShowUnread] = React.useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const isTabActive = usePageVisibility();

  const notifications = showUnread
    ? allNotifications.filter(item => item.read_at === null).slice(0, 9)
    : allNotifications.slice(0, 9);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleGoToAllNotifications = () => {
    redirect('/admin_notifications');
    setAnchorEl(null);
  };

  const toggleShowUnread = e => {
    e.preventDefault();
    setShowUnread(prev => !prev);
  };

  const getNewNotifications = () => {
    dataProvider
      .getList('admin_notifications', {
        filter: { 'admin.id': getOperatorId() },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data }) => {
        setTotal(data.filter(item => item.read_at === null).length);
        setAllNotifications(data);
      })
      .catch(error => {
        setError(error);
      });
  };

  const readNotification = async id => {
    await dataProvider
      .query(`admin_notifications/${id}/read`, {
        method: 'POST',
      })
      .catch(error => notify(`Notification #${id}: ${error.message}`, 'error'));
    await getNewNotifications();
  };

  const readAllNotifications = async () => {
    await dataProvider
      .query('admin_notifications/read_all', { method: 'POST' })
      .then(() => setAnchorEl(null))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    await getNewNotifications();
  };

  React.useEffect(() => {
    const onCheckNotifications = () => getNewNotifications();
    document.addEventListener('check-admin-notifications', onCheckNotifications);
    return () => {
      document.removeEventListener('check-admin-notifications', onCheckNotifications);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isTabActive) {
      getNewNotifications();
    }
    // eslint-disable-next-line
  }, [isTabActive]);

  React.useEffect(() => {
    let interval;
    if (isTabActive) {
      interval = setInterval(() => {
        getNewNotifications();
      }, 120000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, [isTabActive]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Box className={classes.mr16}>
      <IconButton
        className={classes.button}
        aria-label={`show ${total} new notifications`}
        aria-controls="notifications-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenuOpen}
        disableFocusRipple={true}
        disableRipple={true}>
        <Badge badgeContent={total > 99 ? '99+' : total} color="error" overlap="circular">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        id="notifications-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        classes={classes.paper}>
        <MenuItem className={classes.menuItem} onClick={readAllNotifications}>
          <DoneAllIcon fontSize="inherit" color="secondary" />
          <Typography variant="body2">Read all notifications</Typography>
        </MenuItem>
        {notifications.length ? (
          notifications.map(i => (
            <AdminNotification key={i.id} notification={i} onClose={handleMenuClose} onRead={readNotification} />
          ))
        ) : (
          <MenuItem className={classes.menuItem} onClick={handleGoToAllNotifications}>
            <Typography variant="body2">
              <i>No unread notifications</i>
            </Typography>
          </MenuItem>
        )}
        <MenuItem className={classes.menuItem} onClick={handleGoToAllNotifications}>
          <Typography variant="body2">Show all notifications</Typography>
          <ArrowForwardIcon fontSize="inherit" color="secondary" />
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={toggleShowUnread}>
          <FormControlLabel
            control={<Switch size="small" checked={showUnread} />}
            label={<Typography variant="body2">Show unread only</Typography>}
          />
        </MenuItem>
      </StyledMenu>
    </Box>
  );
};
