import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Filter, TextInput, NumberInput, SelectInput } from 'react-admin';
import { DateFilterInput, QuickFilterInput, DateTimeFilterInput } from '../../../components';
import { marginZeroStyles, REQUEST_TYPE_CHOICES } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFiltersPending = ({ statefilterchoices, ...props }) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <NumberInput label="Phone number" source="user->phoneBooks->phoneNumber" />
      {statefilterchoices.length ? <SelectInput label="Status" source="state" choices={statefilterchoices} /> : null}
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateTimeFilterInput label="Created before" source="created_at|before" before />
      <DateTimeFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <SelectInput label="Loan type" source="is_repeat" choices={REQUEST_TYPE_CHOICES} />
      <QuickFilterInput source="is_direct_debit_registered" label="Direct debit registered" defaultValue={true} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
    </Filter>
  );
};

ListFiltersPending.propTypes = {
  statefilterchoices: PropTypes.array,
};

export default ListFiltersPending;
