import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  invisible: {
    display: 'none',
  },
}));

export const CheckboxListInput = ({ isEditable = true, permissions = [], ...props }) => {
  const input = useInput(props).input;
  const classes = useStyles();

  const { value = [], onChange } = typeof props.onChange === 'function' ? props : input;
  const choices = Array.isArray(props.choices) ? props.choices : [];
  const canAssignSecured = permissions.includes('CAN_ASSIGN_SECURE_ROLES');
  const canAssignRegular = permissions.includes('CAN_ASSIGN_REGULAR_ROLES');

  const handleChange = v => {
    if (value.indexOf(v) === -1) {
      onChange([...value, v]);
    } else {
      onChange(value.filter(item => item !== v));
    }
  };

  return (
    <List>
      {choices.map((choice, idx) => {
        let className = null;
        if (choice.level) {
          if (choice.level === 'secure' && !canAssignSecured) {
            className = classes.invisible;
          }
          if (choice.level === 'regular' && !canAssignRegular) {
            className = classes.invisible;
          }
        }

        return (
          <ListItem
            className={className}
            key={idx}
            dense
            button
            onClick={() => (isEditable ? handleChange(choice.id) : null)}>
            <ListItemText primary={choice.name} />
            <ListItemSecondaryAction>
              <Checkbox
                disabled={!isEditable}
                edge="end"
                checked={value.indexOf(choice.id) !== -1}
                onClick={() => (isEditable ? handleChange(choice.id) : null)}
                className={className}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

CheckboxListInput.propTypes = {
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  choices: PropTypes.array,
  permissions: PropTypes.array,
};
