const convertArray = arr => {
  return arr.map(convertIdentifiersToNumbers);
};
const convertObject = obj => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, convertIdentifiersToNumbers(value)]));
};
const convertString = str => {
  if (/^\/api\/.+\/\d+$/.test(str)) {
    return parseInt(str.replace(/^\/api\/.+\/(\d+)$/, '$1'));
  }

  return str;
};

export const convertIdentifiersToNumbers = data => {
  if (data === null || data === undefined) {
    return null;
  }

  if (Array.isArray(data)) {
    return convertArray(data);
  }

  if (typeof data === 'object') {
    return convertObject(data);
  }

  if (typeof data === 'string') {
    return convertString(data);
  }

  return data;
};

export const convertMinutesToDelay = minutes => {
  const delay_days = Math.floor(minutes / (24 * 60));
  const delay_hours = Math.floor((minutes % (24 * 60)) / 60);
  const delay_minutes = minutes % 60;

  return {
    delay_days,
    delay_hours,
    delay_minutes,
  };
};
