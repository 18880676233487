import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { green } from '@material-ui/core/colors';
import { timezone, formatCurrency } from '../../../utils';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '50%',
    marginRight: theme.spacing(2),
  },
  green: {
    color: green[500],
  },
}));

const FuturePaymentAmount = ({ record }) => {
  const [promiseDatetime, setPromiseDatetime] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [promiseAmount, setPromiseAmount] = useState('');
  const [showWithExtensionCheckbox, setShowWithExtensionCheckbox] = useState(false);
  const [extensionTenor, setExtensionTenor] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const extensionPaymentIndex = paymentDetails?.payments.findIndex(p => p.type === 'extension_payment');
  const interestAfterExtensionIndex = paymentDetails?.payments.findIndex(p => p.type === 'interest_after_extension');

  const getPaymentDetails = withExtension => {
    if (promiseDatetime) {
      const date = timezone.shift(promiseDatetime);
      const endpoint = withExtension
        ? `loans/calculate_promise_payments?loan_id=${record.id}&promise_date=${date.toJSON()}&extension_tenor=${extensionTenor}`
        : `loans/calculate_promise_payments?loan_id=${record.id}&promise_date=${date.toJSON()}`;

      dataProvider
        .query(endpoint, { method: 'GET' })
        .then(({ data }) => {
          setPaymentDetails(withExtension ? data.data.extension_with_full_payment : data.data.full_payment);
          setPromiseAmount(withExtension ? data.data.extension_with_full_payment.total : data.data.full_payment.total);
        })
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
  };

  const handleChangePromiseDatetime = date => {
    if (date) {
      setPromiseDatetime(timezone.unshift(date));
      const endpoint = extensionTenor
        ? `loans/calculate_promise_payments?loan_id=${record.id}&promise_date=${date.toJSON()}&extension_tenor=${extensionTenor}`
        : `loans/calculate_promise_payments?loan_id=${record.id}&promise_date=${date.toJSON()}`;

      dataProvider
        .query(endpoint, { method: 'GET' })
        .then(({ data }) => {
          setPaymentDetails(extensionTenor ? data.data.extension_with_full_payment : data.data.full_payment);
          setPromiseAmount(extensionTenor ? data.data.extension_with_full_payment.total : data.data.full_payment.total);
        })
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    } else {
      setPromiseDatetime(null);
      setPaymentDetails(null);
      setPromiseAmount(null);
    }
  };

  const getDiff = payment => {
    const diff = payment.amount - record[`accrued_${payment.type}`];
    if (diff > 0) {
      return (
        <Typography className={classes.green} variant="caption">
          <b>{`+${diff.toFixed(2)}`}</b>
        </Typography>
      );
    }
  };

  const getExtensionPaymentDiff = payment => {
    const diff = payment.amount - record.allowed_extension_tenors_price[extensionTenor];
    if (diff > 0) {
      return (
        <Typography className={classes.green} variant="caption">
          <b>{`+${diff.toFixed(2)}`}</b>
        </Typography>
      );
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom={false} className={classes.paper}>
            Future payment amount
          </Typography>
          <div className={classes.formControl}>
            <DatePicker
              label="Payment date"
              inputVariant="filled"
              format="MMMM dd, yyyy"
              closeOnSelect
              disablePast
              clearable
              value={promiseDatetime ? timezone.shift(promiseDatetime) : null}
              onChange={handleChangePromiseDatetime}
              size="small"
              fullWidth
            />
          </div>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" pl={2} pr={2} pt={1} pb={2}>
          <Box flexGrow={1}>
            <FormControlLabel
              label="With extension"
              control={
                <Checkbox
                  checked={showWithExtensionCheckbox}
                  onChange={e => {
                    setShowWithExtensionCheckbox(e.target.checked);
                    setExtensionTenor(null);
                    !e.target.checked && getPaymentDetails(false);
                  }}
                />
              }
            />
          </Box>
          {showWithExtensionCheckbox && (
            <>
              <Box flexGrow={1} mr={2}>
                <FormControl className={classes.fullWidth} fullWidth>
                  <InputLabel id="label-select-extension-tenor">Extension tenor</InputLabel>
                  <Select
                    labelId="label-select-extension-tenor"
                    value={extensionTenor ?? ''}
                    onChange={e => setExtensionTenor(e.target.value)}>
                    {record.allowed_extension_tenors.map(i => (
                      <MenuItem key={i + '_days'} value={i}>
                        {i} days
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <ButtonGroup size="small" variant="contained" color="primary">
                <Button
                  disabled={!extensionTenor}
                  onClick={() => {
                    setExtensionTenor(null);
                    getPaymentDetails(false);
                  }}>
                  Clear
                </Button>
                <Button disabled={!promiseDatetime || !extensionTenor} onClick={() => getPaymentDetails(true)}>
                  Apply
                </Button>
              </ButtonGroup>
            </>
          )}
        </Box>
      </Box>
      <Divider />
      {promiseAmount && paymentDetails && (
        <Table className={classes.table} size="small">
          <TableBody>
            {paymentDetails.payments.map(p => {
              const paymentType = p.type.split('_').join(' ');
              if (['extension_payment', 'interest_after_extension'].includes(p.type)) {
                return null;
              }
              return (
                <TableRow key={p.type}>
                  <TableCell align="left">
                    {paymentType.charAt(0).toUpperCase() + paymentType.slice(1)} {getDiff(p)}
                  </TableCell>
                  <TableCell align="right">
                    {p.type.includes('percent') ? `${p.amount} %` : formatCurrency(p.amount)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow key="total">
              <TableCell align="left">
                <b>Total</b> {getDiff({ type: 'total', amount: promiseAmount })}
              </TableCell>
              <TableCell align="right">
                <b>{formatCurrency(promiseAmount)}</b>
              </TableCell>
            </TableRow>
            {extensionPaymentIndex !== -1 && (
              <TableRow key="extension_payment">
                <TableCell align="left">
                  <b>Extension payment</b> {getExtensionPaymentDiff(paymentDetails.payments[extensionPaymentIndex])}
                </TableCell>
                <TableCell align="right">
                  <b>{formatCurrency(paymentDetails.payments[extensionPaymentIndex].amount)}</b>
                </TableCell>
              </TableRow>
            )}
            {interestAfterExtensionIndex !== -1 && (
              <TableRow key="interest_after_extension">
                <TableCell align="left">Interest after extension</TableCell>
                <TableCell align="right">
                  {formatCurrency(paymentDetails.payments[interestAfterExtensionIndex].amount)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

FuturePaymentAmount.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    allowed_extension_tenors: PropTypes.array,
    allowed_extension_tenors_price: PropTypes.object,
  }),
};

export default FuturePaymentAmount;
