import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { red } from '@material-ui/core/colors';
import { sortBy } from 'lodash';
import { formatDatetime, getCatalogValue } from '../../utils';
import {
  CIRCULADECREDITO_RESIDENCIA,
  CIRCULADECREDITO_ESTADOS,
  CIRCULADECREDITO_TIPO_DOMICILIO,
  CIRCULADECREDITO_TIPO_ASENTAMIENTO,
  CIRCULADECREDITO_SERVICIOS,
} from '../../constants';
import CirculoCreditosTable from './CirculoCreditosTable';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    width: '30%',
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  red: {
    backgroundColor: red[300],
  },
}));

export const CirculodecreditoReport = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showWork, setShowWork] = useState(false);

  const classes = useRowStyles();

  const credits = data.response?.creditos ? sortBy(data.response.creditos, ['fechaAperturaCuenta']).reverse() : [];
  const consultas = data.response?.consultas ? sortBy(data.response.consultas, ['fechaConsulta']).reverse() : [];

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.id}</TableCell>
        <TableCell>
          <Chip size="small" label={data.service} />
        </TableCell>
        <TableCell>
          <Chip size="small" label={data.state} />
        </TableCell>
        <TableCell width="40%">{data.error}</TableCell>
        <TableCell>{formatDatetime(data.created_at)}</TableCell>
      </TableRow>
      {data.response && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Persona
                </Typography>
                <Box className={classes.list}>
                  <Box>
                    <Typography variant="body2">
                      <b>Apellido Paterno:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Apellido Materno:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Apellido Adicional:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Nombres:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Fecha Nacimiento:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>RFC:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>CURP:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Numero Seguridad Social:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Nacionalidad:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Residencia:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Estado Civil:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Sexo:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Clave Elector IFE:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Numero Dependientes:</b>
                    </Typography>
                    <Typography variant="body2">
                      <b>Fecha Defuncion:</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">{data.response?.persona?.apellidoPaterno ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.apellidoMaterno ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.apellidoAdicional ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.nombres ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.fechaNacimiento ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.RFC ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.CURP ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.numeroSeguridadSocial ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.nacionalidad ?? '-'}</Typography>
                    <Typography variant="body2">
                      {getCatalogValue(CIRCULADECREDITO_RESIDENCIA, data.response?.persona?.residencia)}
                    </Typography>
                    <Typography variant="body2">{data.response?.persona?.estadoCivil ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.sexo ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.claveElectorIFE ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.numeroDependientes ?? '-'}</Typography>
                    <Typography variant="body2">{data.response?.persona?.fechaDefuncion ?? '-'}</Typography>
                  </Box>
                </Box>
                <Box className={classes.list}>
                  <Typography variant="body2">
                    <b>Domicilios</b>
                  </Typography>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setShowAddress(!showAddress)}
                    endIcon={showAddress ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                    {showAddress ? 'Ocultar' : 'Mostrar'}
                  </Button>
                </Box>
                {showAddress && (
                  <Table size="small" padding="checkbox" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Direccion</TableCell>
                        <TableCell>Colonia Poblacion</TableCell>
                        <TableCell>Delegacion Municipio</TableCell>
                        <TableCell>Ciudad</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Cp</TableCell>
                        <TableCell>Fecha Residencia</TableCell>
                        <TableCell>Numero Telefono</TableCell>
                        <TableCell>Tipo Domicilio</TableCell>
                        <TableCell>Tipo Asentamiento</TableCell>
                        <TableCell>Fecha Registro Domicilio</TableCell>
                        <TableCell>Tipo Alta Domicilio</TableCell>
                        <TableCell>Numero Otorgantes Carga</TableCell>
                        <TableCell>Numero Otorgantes Consulta</TableCell>
                        <TableCell>Id Domicilio</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.response?.domicilios.map((row, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{row.direccion ?? '-'}</TableCell>
                          <TableCell>{row.coloniaPoblacion ?? '-'}</TableCell>
                          <TableCell>{row.delegacionMunicipio ?? '-'}</TableCell>
                          <TableCell>{row.ciudad ?? '-'}</TableCell>
                          <TableCell>{getCatalogValue(CIRCULADECREDITO_ESTADOS, row.estado)}</TableCell>
                          <TableCell>{row.CP ?? '-'}</TableCell>
                          <TableCell>{row.fechaResidencia ?? '-'}</TableCell>
                          <TableCell>{row.numeroTelefono ?? '-'}</TableCell>
                          <TableCell>{getCatalogValue(CIRCULADECREDITO_TIPO_DOMICILIO, row.tipoDomicilio)}</TableCell>
                          <TableCell>
                            {getCatalogValue(CIRCULADECREDITO_TIPO_ASENTAMIENTO, row.tipoAsentamiento)}
                          </TableCell>
                          <TableCell>{row.fechaRegistroDomicilio ?? '-'}</TableCell>
                          <TableCell>{row.tipoAltaDomicilio ?? '-'}</TableCell>
                          <TableCell>{row.numeroOtorgantesCarga ?? '-'}</TableCell>
                          <TableCell>{row.numeroOtorgantesConsulta ?? '-'}</TableCell>
                          <TableCell>{row.idDomicilio ?? '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <Box className={classes.list}>
                  <Typography variant="body2">
                    <b>Empleos</b>
                  </Typography>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setShowWork(!showWork)}
                    endIcon={showWork ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                    {showWork ? 'Ocultar' : 'Mostrar'}
                  </Button>
                </Box>
                {showWork && (
                  <Table size="small" padding="checkbox" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre Empresa</TableCell>
                        <TableCell>Direccion</TableCell>
                        <TableCell>Colonia Poblacion</TableCell>
                        <TableCell>Delegacion Municipio</TableCell>
                        <TableCell>Ciudad</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Cp</TableCell>
                        <TableCell>Numero Telefono</TableCell>
                        <TableCell>Extension</TableCell>
                        <TableCell>Fax</TableCell>
                        <TableCell>Puesto</TableCell>
                        <TableCell>Fecha Contratacion</TableCell>
                        <TableCell>Clave Moneda</TableCell>
                        <TableCell>Salario Mensual</TableCell>
                        <TableCell>Fecha Ultimo Dia Empleo</TableCell>
                        <TableCell>Fecha Verificacion Empleo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.response?.empleos.map((row, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{row.nombreEmpresa ?? '-'}</TableCell>
                          <TableCell>{row.direccion ?? '-'}</TableCell>
                          <TableCell>{row.coloniaPoblacion ?? '-'}</TableCell>
                          <TableCell>{row.delegacionMunicipio ?? '-'}</TableCell>
                          <TableCell>{row.ciudad ?? '-'}</TableCell>
                          <TableCell>{getCatalogValue(CIRCULADECREDITO_ESTADOS, row.estado)}</TableCell>
                          <TableCell>{row.CP ?? '-'}</TableCell>
                          <TableCell>{row.numeroTelefono ?? '-'}</TableCell>
                          <TableCell>{row.extension ?? '-'}</TableCell>
                          <TableCell>{row.fax ?? '-'}</TableCell>
                          <TableCell>{row.puesto ?? '-'}</TableCell>
                          <TableCell>{row.fechaContratacion ?? '-'}</TableCell>
                          <TableCell>{row.claveMoneda ?? '-'}</TableCell>
                          <TableCell>{row.salarioMensual ?? '-'}</TableCell>
                          <TableCell>{row.fechaUltimoDiaEmpleo ?? '-'}</TableCell>
                          <TableCell>{row.fechaVerificacionEmpleo ?? '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                  Сonsultas
                </Typography>
                <Table size="small" padding="checkbox" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha Consulta</TableCell>
                      <TableCell>Clave Otorgante</TableCell>
                      <TableCell>Nombre Otorgante</TableCell>
                      <TableCell>Direccion Otorgante</TableCell>
                      <TableCell>Telefono Otorgante</TableCell>
                      <TableCell>Tipo Credito</TableCell>
                      <TableCell>Clave Unidad Monetaria</TableCell>
                      <TableCell>Importe Credito</TableCell>
                      <TableCell>Tipo Responsabilidad</TableCell>
                      <TableCell>Id Domicilio</TableCell>
                      <TableCell>Servicios</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {consultas.map((row, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{row.fechaConsulta ?? '-'}</TableCell>
                        <TableCell>{row.claveOtorgante ?? '-'}</TableCell>
                        <TableCell>{row.nombreOtorgante ?? '-'}</TableCell>
                        <TableCell>{row.direccionOtorgante ?? '-'}</TableCell>
                        <TableCell>{row.telefonoOtorgante ?? '-'}</TableCell>
                        <TableCell>{row.tipoCredito ?? '-'}</TableCell>
                        <TableCell>{row.claveUnidadMonetaria ?? '-'}</TableCell>
                        <TableCell>{row.importeCredito ?? '-'}</TableCell>
                        <TableCell>{row.tipoResponsabilidad ?? '-'}</TableCell>
                        <TableCell>{row.idDomicilio ?? '-'}</TableCell>
                        <TableCell>{getCatalogValue(CIRCULADECREDITO_SERVICIOS, row.servicios)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Typography variant="h6" gutterBottom component="div" className={classes.mt2}>
                  Creditos
                </Typography>
                <CirculoCreditosTable data={credits} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {!data.response && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Typography variant="body2" gutterBottom component="div">
                Informe no encontrado
              </Typography>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

CirculodecreditoReport.propTypes = {
  data: PropTypes.object,
};
