import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Show, useNotify, useRefresh, useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  UserApiData,
  AddUserApiDataDialog,
  AventusDecisionEngineDataTable,
  DebounceButton,
  Call,
  ImageZoom,
  findImage,
  MatchesTable,
  SelfieMatchesTable,
  IdCardMatchesTable,
  DocumentVerification,
} from '../../components';
import { formatCurrency, formatPhoneNumber, bankNameByAccountNumber } from '../../utils';
import ApplicationInfo from './components/ApplicationInfo';
import ApplicationTransitions from './components/ApplicationTransitions';
import Actions from './components/Actions';
import AdditionalInfo from './components/AdditionalInfo';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  mt20: {
    marginTop: theme.spacing(2),
  },
  redChip: {
    color: 'white',
    backgroundColor: red[500],
  },
  tagsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
}));

const VerificationLayout = ({ permissions, record }) => {
  const classes = useStyles();
  const onRefresh = useRefresh();
  const notify = useNotify();

  const [refreshedAt, setRefreshedAt] = useState();
  const [appCanBeRestarted, setAppCanBeRestarted] = useState(false);
  const [isUserApiDataDialogOpened, setIsUserApiDataDialogOpened] = useState(false);

  const refresh = () => {
    setRefreshedAt(Date.now());
    onRefresh();
  };

  useEffect(() => {
    ['pending', 'processing', 'error'].includes(record.state) &&
      [null, 'aventus-de'].includes(record.decision_engine_id) &&
      record.aventus_decision_engine_state !== 'processed' &&
      setAppCanBeRestarted(true);
  }, [record]);

  const {
    data: user,
    loading,
    error,
  } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id: record.user_id },
  });

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const rowData = (name, value, style) => ({ name, value, style });

  const profileRows = [
    rowData('ID', <Link href={'#users/' + user.id}>{user.id}</Link>),
    rowData('First name', user.first_name),
    rowData('Last name', user.last_name),
    rowData('Father surname', user.father_surname),
    rowData('Mother surname', user.mother_surname),
    rowData(
      'Phone number',
      <Call userId={user.id} userPhone={user.phone_number} edge="end">
        {formatPhoneNumber(user.phone_number)}
      </Call>,
      { display: 'flex' },
    ),
    rowData('Is data processing consent given', user.is_data_processing_consent_given ? 'Yes' : 'No'),
    rowData('CURP', user.curp),
    rowData('RFC', user.rfc),
    rowData('Gender', user.gender),
    rowData('Birthday', user.birthday),
    rowData('Email', user.email),
    rowData('Education', user.education),
    rowData('Main source of income', user.main_source_of_income),
    rowData('Monthly income', user.monthly_income && formatCurrency(user.monthly_income)),
    rowData('Marital status', user.marital_status),
    rowData('In blacklist', user.is_in_black_list ? 'true' : 'false'),
    rowData(
      'User tags',
      user.tags.length ? (
        <Box className={classes.tagsGrid}>
          {user.tags.map(tag => {
            if (tag.name.includes('not disturb')) {
              return (
                <Chip
                  className={classes.redChip}
                  icon={<ErrorOutlineIcon style={{ color: 'white' }} />}
                  key={tag.id}
                  size="small"
                  label={tag.name}
                />
              );
            } else {
              return <Chip key={tag.id} size="small" label={tag.name} color="primary" />;
            }
          })}
        </Box>
      ) : null,
    ),
  ];

  const residenceRows = [
    rowData('Postal code', user.residence_postal_code),
    rowData('Region', user.residence_region),
    rowData('Municipality', user.residence_municipality),
    rowData('Colony', user.residence_colony),
    rowData('Street', user.residence_street),
    rowData('External house', user.residence_external_house),
    rowData('Internal house', user.residence_internal_house),
    rowData('Flat', user.residence_flat),
    rowData('Duration', user.residence_duration),
  ];

  const contactRows = [
    rowData('Name', user.contact_name),
    rowData('Relationship', user.contact_relationship),
    rowData(
      'Phone number',
      user.contact_phone_number && (
        <Call userId={user.id} userPhone={user.contact_phone_number} edge="end">
          {formatPhoneNumber(user.contact_phone_number)}
        </Call>
      ),
      { display: 'flex' },
    ),
  ];

  const bankRows = [
    rowData(
      'Bank name',
      user.bank_account_number ? bankNameByAccountNumber(user.bank_account_number, 'Unknown') : 'N/A',
    ),
    rowData('Bank account number', user.bank_account_number),
  ];

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} justifyContent="space-between">
        {permissions.includes('CAN_APPLICATION_EDIT') ? (
          <ApplicationTransitions
            permissions={permissions}
            record={record}
            refreshedAt={refreshedAt}
            refresh={refresh}
            appCanBeRestarted={appCanBeRestarted}
          />
        ) : (
          <>&nbsp;</>
        )}
        <Actions record={record} permissions={permissions} appView={false} />
      </Grid>
      <Grid item xs={12}>
        <AdditionalInfo permissions={permissions} record={record} refreshedAt={refreshedAt} refresh={refresh} />
      </Grid>
      {/* Photos */}
      <Grid item xs={12} sm={6}>
        {permissions.includes('CAN_USER_VIEW') ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {findImage(user.uploaded_files, 'selfie') && (
                <Paper className={classes.paper}>
                  <Typography variant="h6" gutterBottom={false}>
                    Selfie
                  </Typography>
                  {user.similarity_card_and_selfie !== null ? (
                    <Typography variant="body1">{`Similarity ${user.similarity_card_and_selfie}%`}</Typography>
                  ) : (
                    findImage(user.uploaded_files, 'id_card') &&
                    findImage(user.uploaded_files, 'selfie') && (
                      <Typography variant="caption" color="error">
                        No face is recognized on the ID card
                      </Typography>
                    )
                  )}
                  <Divider />
                  <ImageZoom isExist userId={user.id} label="selfie" />
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {findImage(user.uploaded_files, 'id_card') && (
                <Paper className={classes.paper}>
                  <Typography variant="h6" gutterBottom={false}>
                    Id card front
                  </Typography>
                  <Divider />
                  <ImageZoom isExist userId={user.id} label="id_card" />
                </Paper>
              )}
              {findImage(user.uploaded_files, 'id_card_back_side') && (
                <Paper className={[classes.paper, classes.mt20].join(' ')}>
                  <Typography variant="h6" gutterBottom={false}>
                    Id card back
                  </Typography>
                  <Divider />
                  <ImageZoom isExist userId={user.id} label="id_card_back_side" />
                </Paper>
              )}
            </Grid>
          </Grid>
        ) : (
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom={true}>
              User info
            </Typography>
            <Typography variant="body1">-- You do not have sufficient rights to view customer details --</Typography>
          </Paper>
        )}
        <Grid container spacing={2}>
          {/* Document verivication */}
          <Grid item xs={12} className={classes.mt20}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom={false}>
                Document verification
              </Typography>
              <Divider />
              <DocumentVerification userId={user.id} />
            </Paper>
          </Grid>

          {/* Residence */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom={false}>
                Residence
              </Typography>
              <Divider />
              <Table size="small">
                <TableBody>
                  {residenceRows.map(row => (
                    <TableRow key={row.name}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ApplicationInfo record={record} />

        {/* User profile */}
        <Paper className={[classes.paper, classes.mt20].join(' ')}>
          <Typography variant="h6" gutterBottom={false}>
            User profile
          </Typography>
          <Divider />
          <Table size="small">
            <TableBody>
              {profileRows.map(row => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="right" style={row.style}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {/* Contact person */}
        <Paper className={[classes.paper, classes.mt20].join(' ')}>
          <Typography variant="h6" gutterBottom={false}>
            Contact person
          </Typography>
          <Divider />
          <Table size="small">
            <TableBody>
              {contactRows.map(row => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="right" style={row.style}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {/* Bank */}
        <Paper className={[classes.paper, classes.mt20].join(' ')}>
          <Typography variant="h6" gutterBottom={false}>
            Bank
          </Typography>
          <Divider />
          <Table size="small">
            <TableBody>
              {bankRows.map(row => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {/* STP */}
        <Paper className={[classes.paper, classes.mt20].join(' ')}>
          <Typography variant="h6" gutterBottom={false}>
            STP
          </Typography>
          <Divider />
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left">Incoming transactions account number</TableCell>
                <TableCell align="right">{user.stp_number}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {/* All matches Accordion */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="matches-panel-content"
            id="matches-panel-header">
            <Typography variant="h6" gutterBottom={false}>
              All matches (by application)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <MatchesTable
                permissions={permissions}
                recordId={record.id}
                refreshedAt={refreshedAt}
                perPageCustom={10}
              />
              <Box p={1} display="flex" justifyContent="flex-end">
                <ButtonGroup variant="contained" size="small" color="primary">
                  <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                </ButtonGroup>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Selfie matches Accordion */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="selfie-matches-panel-content"
            id="selfie-matches-panel-header">
            <Typography variant="h6" gutterBottom={false}>
              Selfie matches
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <SelfieMatchesTable userId={record.user_id} refreshedAt={refreshedAt} perPageCustom={10} />
              <Box p={1} display="flex" justifyContent="flex-end">
                <ButtonGroup variant="contained" size="small" color="primary">
                  <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                </ButtonGroup>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* ID card matches Accordion */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="id-card-matches-panel-content"
            id="id-card-matches-panel-header">
            <Typography variant="h6" gutterBottom={false}>
              ID card matches
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <IdCardMatchesTable userId={record.user_id} refreshedAt={refreshedAt} perPageCustom={10} />
              <Box p={1} display="flex" justifyContent="flex-end">
                <ButtonGroup variant="contained" size="small" color="primary">
                  <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                </ButtonGroup>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* API Data Accordion */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="api-data-panel-content"
            id="api-data-panel-header">
            <Typography variant="h6" gutterBottom={false}>
              API Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <UserApiData userId={record.user_id} refreshedAt={refreshedAt} />
              <Box p={1} display="flex" justifyContent="flex-end">
                <ButtonGroup variant="contained" size="small" color="primary">
                  <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                  {permissions.includes('CAN_USER_API_DATA_EDIT') && (
                    <Button onClick={() => setIsUserApiDataDialogOpened(true)}>Add</Button>
                  )}
                </ButtonGroup>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* ADE Data Accordion */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ade-data-panel-content"
            id="ade-data-panel-header">
            <Typography variant="h6" gutterBottom={false}>
              ADE Data (by application)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <AventusDecisionEngineDataTable recordId={record.id} refreshedAt={refreshedAt} perPageCustom={10} />
              <Box p={1} display="flex" justifyContent="flex-end">
                <ButtonGroup variant="contained" size="small" color="primary">
                  <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                </ButtonGroup>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {permissions.includes('CAN_USER_API_DATA_EDIT') && (
          <AddUserApiDataDialog
            userId={record.user_id}
            applicationId={record.id}
            isOpened={isUserApiDataDialogOpened}
            onClose={() => setIsUserApiDataDialogOpened(false)}
            onSubmit={() => {
              setIsUserApiDataDialogOpened(false);
              refresh();
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

VerificationLayout.propTypes = {
  permissions: PropTypes.array,
  record: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    state: PropTypes.string,
    decision_engine_id: PropTypes.string || null,
    aventus_decision_engine_state: PropTypes.string,
  }),
};

const ApplicationVerificationShow = ({ permissions = [], ...props }) => (
  <Show component="div" {...props}>
    <VerificationLayout permissions={permissions} />
  </Show>
);

ApplicationVerificationShow.propTypes = {
  permissions: PropTypes.array,
};

export default ApplicationVerificationShow;
