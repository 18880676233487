import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Chip from '@material-ui/core/Chip';
import { green, yellow, red } from '@material-ui/core/colors';
import { formatDatetime, formatCurrency } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    maxWidth: '250px',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordWrap: 'break',
  },
  yellowChip: {
    backgroundColor: yellow[500],
  },
  greenChip: {
    backgroundColor: green[500],
  },
  redChip: {
    backgroundColor: red[500],
  },
}));

export const DiscountOfferTable = ({ loanId, refreshedAt, onRefresh }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const [discountOffers, setDiscountOffers] = useState(null);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [cancellableOffer, setCancellableOffer] = useState({});
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const getStatusColor = status => {
    switch (status) {
      case 'active':
      case 'pending':
        return classes.yellowChip;
      case 'paid':
        return classes.greenChip;
      case 'cancelled':
      case 'expired':
      case 'failed':
        return classes.redChip;
    }
  };

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('discount_offers', {
        filter: { 'loan.id': loanId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setDiscountOffers(data);
        setTotal(total);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  const handleSubmitCancel = (_e, discountOffer) => {
    setIsDialogOpened(false);
    const offer = { ...discountOffer, state: 'cancelled' };

    dataProvider
      .query(`discount_offers/${discountOffer.id}`, {
        method: 'PATCH',
        body: JSON.stringify(offer),
      })
      .then(() => notify('The offer has been successfully canceled', 'success'))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        onRefresh();
        setCancellableOffer({});
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(discountOffers)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Discount campaign ID</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Category of discount</TableCell>
            <TableCell>Payments</TableCell>
            <TableCell>Number of payments</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Valid</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {discountOffers.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.discount_campaign_id}</TableCell>
              <TableCell className={classes.breakableCell}>{row.created_by_name}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>
                <b>Amount to pay: </b>
                {formatCurrency(row.amount_to_pay)}
                <br />
                <b>Total paid: </b>
                {formatCurrency(row.total_paid)}
              </TableCell>
              <TableCell>{row.max_payments}</TableCell>
              <TableCell>{formatCurrency(row.discount)}</TableCell>
              <TableCell>
                <b>From: </b>
                {row.valid_from && formatDatetime(row.valid_from)}
                <br />
                <b>To: </b>
                {row.valid_to && formatDatetime(row.valid_to)}
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.state} className={getStatusColor(row.state)} />
              </TableCell>
              <TableCell align="right">
                <ButtonGroup
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={permissions.indexOf('CAN_NOTIFICATION_EDIT') === -1}>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.m2}
                    onClick={() => {
                      setCancellableOffer(row);
                      setIsDialogOpened(true);
                    }}
                    color="primary"
                    disabled={['cancelled', 'expired', 'paid', 'failed'].includes(row.state)}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      <Dialog
        open={isDialogOpened}
        onClose={() => {
          setIsDialogOpened(false);
          setCancellableOffer({});
        }}>
        <DialogContent>
          <DialogContentText>Do you want to cancel discount?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDialogOpened(false);
              setCancellableOffer({});
            }}
            color="primary">
            No
          </Button>
          <Button onClick={event => handleSubmitCancel(event, cancellableOffer)} variant="contained" color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DiscountOfferTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onRefresh: PropTypes.func,
};
