import React, { useEffect, useState, useCallback } from 'react';
import { Box, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Confirm, Title, useDataProvider } from 'react-admin';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useCollectionGroups, useHandbook } from '../../hooks';
import { convertIdentifiersToNumbers } from '../../utils';
import { CollectionActionTreeListContactTree, CollectionContactFormDialog } from '../../components';

const findParent = (array, id) => {
  for (const item of array) {
    if (item.id === id) {
      return item;
    } else {
      if (item.children?.length > 0) {
        const res = findParent(item.children, id);
        if (res) {
          return res;
        }
      }
    }
  }
};

const CollectionActionTreeListGroupsToolbar = ({ selectedGroupId, onSelectGroup, groups }) => {
  return (
    <Toolbar variant="dense" disableGutters>
      <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
        Groups:
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          marginLeft: 12,
        }}>
        {groups.map(item => (
          <Button
            key={item.id}
            sx={{ color: '#fff' }}
            variant={selectedGroupId === item.id ? 'contained' : 'text'}
            color="secondary"
            onClick={() => onSelectGroup(item.id)}>
            {item.code}
          </Button>
        ))}
      </Box>
    </Toolbar>
  );
};

CollectionActionTreeListGroupsToolbar.propTypes = {
  selectedGroupId: PropTypes.number,
  onSelectGroup: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
    }),
  ),
};

const CollectionActionTreeListRootContactsToolbar = ({
  typeValues,
  selectedRootContactId,
  onSelectRootContact,
  rootContacts,
  onClickCreate,
}) => {
  const handleClickCreateRoot = () => {
    onClickCreate();
  };

  const buildHandleClickCreateUnderRoot = parentId => {
    return () => {
      onClickCreate(parentId);
    };
  };

  return (
    <Toolbar variant="dense" disableGutters>
      <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
        Call types:
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          marginLeft: 12,
        }}>
        {rootContacts.map(item => (
          <ButtonGroup key={item.id} color="secondary" size="small" variant="contained">
            <Button
              sx={{ color: '#fff' }}
              variant={selectedRootContactId === item.id ? 'contained' : 'outlined'}
              onClick={() => onSelectRootContact(item.id)}>
              {typeValues.find(({ id }) => id === item.type_value)?.value}
            </Button>
            <Button
              variant="text"
              style={{ padding: 0, minWidth: 32 }}
              onClick={buildHandleClickCreateUnderRoot(item.id)}
              disabled={selectedRootContactId !== item.id}>
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        ))}

        <Button color="primary" variant="contained" size="small" onClick={handleClickCreateRoot}>
          Add new call type
        </Button>
      </Box>
    </Toolbar>
  );
};

CollectionActionTreeListRootContactsToolbar.propTypes = {
  selectedRootContactId: PropTypes.number,
  onSelectRootContact: PropTypes.func,
  onClickCreate: PropTypes.func,
  typeValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  rootContacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type_value: PropTypes.number,
    }),
  ),
};

const CollectionActionTreeList = () => {
  const dataProvider = useDataProvider();

  const [expandedContacts, setExpandedContacts] = useState([]);

  const toggleExpand = id => {
    if (expandedContacts.includes(id)) {
      setExpandedContacts(expandedContacts.filter(expandedId => id !== expandedId));
    } else {
      setExpandedContacts([...expandedContacts, id]);
    }
  };

  const { data: groups } = useCollectionGroups();

  const { isLoading: isTypesLoading, data: types } = useHandbook('collection_contact_types', { items_per_page: 1000 });

  const { isLoading: isTypeValuesLoading, data: typeValues } = useHandbook('collection_contact_type_values', {
    items_per_page: 1000,
  });

  const [rootContacts, setRootContacts] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedRootContactId, setSelectedRootContactId] = useState(null);
  const [contactsTree, setContactsTree] = useState([]);

  const [formDialogParent, setFormDialogParent] = useState(null);
  const [formDialogEditId, setFormDialogEditId] = useState(null);
  const [isFormDialogOpened, setIsFormDialogOpened] = useState(false);

  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [deletingContactId, setDeletingContactId] = useState(null);
  const [currentLevelTree, setCurrentLevelTree] = useState([]);

  const fetchRootContacts = useCallback(() => {
    setRootContacts([]);

    if (selectedGroupId) {
      dataProvider
        .getList('collection_contacts', {
          filter: { collectionGroup: selectedGroupId, 'exists[parent]': false },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
        })
        .then(({ data }) => {
          setRootContacts(convertIdentifiersToNumbers(data));
        });
    }
  }, [selectedGroupId, dataProvider]);

  const fetchContactsTree = useCallback(() => {
    if (selectedRootContactId) {
      dataProvider
        .query(`collection_contacts/${selectedRootContactId}/tree`, {
          method: 'GET',
        })
        .then(({ data }) => {
          setContactsTree(data.children);
          setCurrentLevelTree(data.children);
        });
    }
  }, [selectedRootContactId, dataProvider]);

  useEffect(() => {
    setSelectedGroupId(groups[0]?.id || null);
  }, [groups]);

  useEffect(() => {
    fetchRootContacts();
  }, [selectedGroupId, fetchRootContacts]);

  useEffect(() => {
    fetchContactsTree();
  }, [selectedRootContactId, fetchContactsTree]);

  useEffect(() => {
    setSelectedRootContactId(rootContacts[0]?.id);
  }, [rootContacts]);

  const handleSelectGroup = groupId => {
    setSelectedGroupId(groupId);
    setExpandedContacts([]);
  };

  const handleRootContact = contactId => {
    setSelectedRootContactId(contactId);
    setExpandedContacts([]);
  };

  const handleFormDialogClose = () => {
    setFormDialogParent(null);
    setFormDialogEditId(null);
    setIsFormDialogOpened(false);
    setCurrentLevelTree(contactsTree);
  };

  const handleFormDialogSubmit = () => {
    setFormDialogParent(null);
    setFormDialogEditId(null);
    setIsFormDialogOpened(false);
    setCurrentLevelTree(contactsTree);
    fetchRootContacts();
    fetchContactsTree();
  };

  const handleFormDialogCreateOpen = (parentId, tree) => {
    setFormDialogParent(parentId);
    setFormDialogEditId(null);
    setIsFormDialogOpened(true);
    setCurrentLevelTree(tree || contactsTree);
  };

  const handleFormDialogEditOpen = (id, parentId) => {
    setFormDialogParent(null);
    setFormDialogEditId(id);
    setIsFormDialogOpened(true);
    const parent = findParent(contactsTree, parentId);
    setCurrentLevelTree(parent?.children || contactsTree);
  };

  const handleDelete = id => {
    setIsDeleteDialogOpened(true);
    setDeletingContactId(id);
  };

  const handleDeleteConfirm = () => {
    dataProvider.delete('collection_contacts', { id: deletingContactId }).then(() => {
      setIsDeleteDialogOpened(false);
      fetchRootContacts();
      fetchContactsTree();
    });
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpened(false);
  };

  return (
    <>
      <Title title="Action tree" />

      <Box>
        <CollectionActionTreeListGroupsToolbar
          selectedGroupId={selectedGroupId}
          onSelectGroup={handleSelectGroup}
          groups={groups}
        />

        {!!selectedGroupId && (
          <CollectionActionTreeListRootContactsToolbar
            selectedRootContactId={selectedRootContactId}
            onSelectRootContact={handleRootContact}
            typeValues={typeValues}
            rootContacts={rootContacts}
            onClickCreate={handleFormDialogCreateOpen}
          />
        )}

        {!!selectedRootContactId && !isTypesLoading && !isTypeValuesLoading && (
          <CollectionActionTreeListContactTree
            contactsTree={contactsTree}
            types={types}
            typeValues={typeValues}
            onClickCreate={handleFormDialogCreateOpen}
            onClickEdit={handleFormDialogEditOpen}
            onClickDelete={handleDelete}
            toggleExpand={toggleExpand}
            expandedContacts={expandedContacts}
          />
        )}
      </Box>

      <CollectionContactFormDialog
        editId={formDialogEditId}
        parentId={formDialogParent}
        isOpened={isFormDialogOpened}
        collectionGroupId={selectedGroupId}
        contactsTree={currentLevelTree}
        onClose={handleFormDialogClose}
        onSubmit={handleFormDialogSubmit}
      />

      <Confirm
        title="Delete of contact"
        content="Are you sure? This action can not be undone."
        isOpen={isDeleteDialogOpened}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};

export default CollectionActionTreeList;
