export const COMMUNICATION_CATEGORY_GROUP_CHOICES = [
  { id: 'collection', name: 'Collection' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'support', name: 'Support' },
  { id: 'underwriting', name: 'Underwriting' },
  { id: 'compliance', name: 'Compliance' },
];

export const APPLICATION_STATUS_CHOICES = [
  { id: 'pending', name: 'Pending' },
  { id: 'processing', name: 'Processing' },
  { id: 'approved', name: 'Approved' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'confirmed', name: 'Confirmed' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'error', name: 'Error' },
  { id: 'verification_control', name: 'Verification control' },
];

export const LOAN_STATE_CHOICES = [
  { name: 'Pending', key: 'pending' },
  { name: 'Active', key: 'active' },
  { name: 'Closed', key: 'closed' },
  { name: 'Defaulted', key: 'defaulted' },
  { name: 'Cancelled', key: 'cancelled' },
  { name: 'Inactive', key: 'inactive' },
  { name: 'Cep fail', key: 'cep_fail' },
  { name: 'Transfer fail', key: 'transfer_fail' },
  { name: 'Sold', key: 'sold' },
];

export const TEMPLATE_CATEGORY_CHOICES = [
  { id: 'SMS', name: 'SMS' },
  { id: 'Email', name: 'Email' },
  { id: 'Push', name: 'Push' },
  { id: 'PDF', name: 'PDF' },
  { id: 'Calendar', name: 'Calendar' },
  { id: 'Email_layout', name: 'Email layout' },
];

export const SOURCE_OF_INCOME = ['part-time job', 'help of relatives', 'social payments', 'no income'];

export const GENDER = ['male', 'female'];

export const RESIDENCE_DURATION = [
  'up to three month',
  'from 3 to 6 months',
  'from 6 to 12 months',
  'more then a year',
];

export const PAYMENT_TYPE = [
  { id: 'bank', name: 'Bank account' },
  { id: 'card', name: 'Debit card' },
];

export const USER_EVENT = [
  { id: 'user_phone_confirmed', name: 'User phone confirmed' },
  { id: 'user_registration_dropped', name: 'User registration dropped' },
  { id: 'application_created', name: 'Application created' },
  { id: 'application_first_created', name: 'Application first created' },
  { id: 'application_repeat_created', name: 'Application repeat created' },
  { id: 'application_rejected', name: 'Application rejected' },
  { id: 'application_approved', name: 'Application approved' },
  { id: 'application_first_approved', name: 'Application first approved' },
  { id: 'application_repeat_approved', name: 'Application repeat approved' },
  { id: 'application_confirmed', name: 'Application confirmed' },
  { id: 'application_manual_verification', name: 'Application manual verification' },
  { id: 'loan_activated', name: 'Loan activated' },
  { id: 'loan_closed', name: 'Loan closed' },
  { id: 'first_loan_closed', name: 'Loan first closed' },
  { id: 'repeat_loan_closed', name: 'Loan repeat closed' },
  { id: 'loan_defaulted', name: 'Loan defaulted' },
  { id: 'extension_activated', name: 'Extension activated' },
  { id: 'income_processed', name: 'Income processed' },
  { id: 'extension_5th_activated', name: '5th extension activated' },
  { id: 'extension_10th_activated', name: '10th extension activated' },
  { id: 'user_reg_dropped_at_step_1', name: 'User reg. drop, 1th step (user created)' },
  { id: 'user_reg_dropped_at_step_2', name: 'User reg. drop, 2th step (phone confirmed)' },
  { id: 'user_reg_dropped_at_step_3', name: 'User reg. drop, 3th step (username and birthday filled)' },
  { id: 'user_reg_dropped_at_step_4', name: 'User reg. drop, 4th step (address filled)' },
  { id: 'user_reg_dropped_at_step_5', name: 'User reg. drop, 5th step (employment filled)' },
  { id: 'user_reg_dropped_at_step_6', name: 'User reg. drop, 6th step (selfie uploaded)' },
  { id: 'user_reg_dropped_at_step_7', name: 'User reg. drop, 7th step (id_front uploaded)' },
  { id: 'user_reg_dropped_at_step_8', name: 'User reg. drop, 8th step (id_back uploaded)' },
  { id: 'user_reg_dropped_at_step_9', name: 'User reg. drop, 9th step (contact, curp and rfc filled)' },
  { id: 'user_reg_dropped_at_step_10', name: 'User reg. drop, 10th step (clabe filled)' },
  { id: 'user_reg_dropped_at_step_11', name: 'User reg. drop, 11th step (credit bureau confirmed)' },
  { id: 'user_reg_goto_step_2', name: 'User go to 2 step (phone confirmed)' },
  { id: 'user_reg_goto_step_3', name: 'User go to 3 step (username and birthday filled)' },
  { id: 'user_reg_goto_step_4', name: 'User go to 4 step (address filled)' },
  { id: 'user_reg_goto_step_5', name: 'User go to 5 step (employment filled)' },
  { id: 'user_reg_goto_step_6', name: 'User go to 6 step (selfie uploaded)' },
  { id: 'user_reg_goto_step_7', name: 'User go to 7 step (id_front uploaded)' },
  { id: 'user_reg_goto_step_8', name: 'User go to 8 step (id_back uploaded)' },
  { id: 'user_reg_goto_step_9', name: 'User go to 9 step (contact, curp and rfc filled)' },
  { id: 'user_reg_goto_step_10', name: 'User go to 10 step (clabe filled)' },
  { id: 'user_reg_goto_step_11', name: 'User go to 11 step (credit bureau confirmed)' },
  { id: 'user_reg_goto_step_12', name: 'User go to 12 step (application created)' },
  { id: 'loan_transfer_fail', name: 'Loan transfer fail' },
  { id: 'bank_account_used_by_another_client', name: 'Bank account used by another client' },
  { id: 'bank_account_limits_failed', name: 'Bank account limits failed' },
  { id: 'application_first_time_expired', name: 'Application first time expired' },
  { id: 'application_repeat_expired', name: 'Application repeat expired' },
  { id: 'direct_debit_partial_payed', name: 'Direct debit partial payed' },
];

export const TRANSMITTER = [
  { id: 'fake', name: 'Fake' },
  { id: 'amazon_sns', name: 'SMS (Amazon)' },
  { id: 'airmovil', name: 'SMS (Airmovil)' },
  { id: 'firebase_cloud_messaging', name: 'Push (FCM)' },
  { id: 'mailer', name: 'E-mail (Amazon)' },
  { id: 'cm', name: 'WhatsApp (CM)' },
];

export const SMS_TRANSMITTERS = {
  1: 'fake',
  2: 'amazon_sns',
  7: 'airmovil',
};

export const PHONE_CONTACT = [
  { id: 'last_rpc', name: 'The last RPC was received' },
  { id: 'last_ptp', name: 'The last PTP was received' },
  { id: 'was_rpc', name: 'Was RPC' },
  { id: 'was_ptp', name: 'Was PTP' },
];

export const USER_EVENT_NOTIFICATION_TYPES = [
  { id: 'marketing', name: 'Marketing' },
  { id: 'service', name: 'Service' },
];

export const TIMEZONES = ['America/Mexico_City', 'Europe/Minsk', 'Europe/Kiev', 'Local', 'UTC'];

export const TIMEZONE_OFFSET = {
  'America/Mexico_City': () => -360,
  'Europe/Minsk': () => 180,
  'Europe/Kiev': () => {
    const month = new Date().getMonth();
    return month >= 3 && month <= 9 ? 180 : 120;
  },
  Local: () => new Date().getTimezoneOffset() * -1,
  UTC: () => 0,
};

export const AVENTUS_DECISION_ENGINE_STATUSES = [
  { id: 1, name: 'approve' },
  { id: 2, name: 'manual verif' },
  { id: 3, name: 'reject' },
  { id: 5, name: 'in progress' },
  { id: 6, name: 'needs cb circulodecredito' },
  { id: 7, name: 'needs seon' },
  { id: 8, name: 'needs nubarium curp' },
  { id: 9, name: 'needs nubarium jobs' },
  { id: 10, name: 'needs nubarium sec.number' },
  { id: 11, name: 'needs nubarium ine' },
  { id: 12, name: 'needs cb moffin' },
];

export const AUTO_ASSIGNMENT_CONTEXT = [
  { id: 'verification', name: 'Verification' },
  { id: 'telesales', name: 'Telesales' },
];

export const CALL_CENTER_USER_STATUS_UNCOMPLETED_REG = [
  'proceed registration',
  'Sent the app',
  'thinking',
  "not the client's number",
  'reject',
  'hang the phone',
  'call back',
  "can't be reached",
  'Un-Contactable',
  'Temp Hold',
  'Not Intrested',
  'Complete',
  'Attachments are pending',
  'Requested for selfie',
  'Requested for NIC',
  'Requested for Passbook',
  'Pending account Number',
];

export const CALL_CENTER_USER_STATUS_CLOSED_LOANS = [
  'Already Processed - PV',
  'Already Rejected - PV',
  'call back',
  'Complete',
  'Not Interested',
  'Pending Details',
  'Rejected',
  'Temp Hold',
  'Un-Contactable',
];

export const REPORT_PERIOD = ['Daily', 'Weekly', 'Monthly'];

export const CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS = [
  'Not selected',
  'Is not interested',
  'Not answered',
  'Is thinking',
  "Don't like the interest rate",
  "Don't like the approved amount",
  'Going to sign',
];

export const TEMPLATE_TYPES = ['System', 'Marketing', 'Collection'];

export const CHOICES = [
  { id: 'marketing', name: 'Marketing' },
  { id: 'call-center', name: 'Call center' },
  { id: 'collection', name: 'Collection' },
];

export const AUDIENCE_FILTER_CONDITION_CHOICES = [
  { id: '==', name: '==' },
  { id: '<', name: '<' },
  { id: '>', name: '>' },
  { id: '<=', name: '<=' },
  { id: '>=', name: '>=' },
];

export const AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES = [
  { id: 'include', name: 'Include' },
  { id: 'exclude', name: 'Exclude' },
];

export const AUDIENCE_FILTERS_IDS = [
  { id: 'registration_date', title: 'Registration date', type: 'date_range' },
  { id: 'user_is_blocked', title: 'User is blocked', type: 'boolean' },
  { id: 'user_in_blacklist', title: 'User in blacklist', type: 'boolean' },
  { id: 'last_application_rejection_reason', title: 'Last application rejection reason', type: 'list' },
  { id: 'last_application_state', title: 'Last application state', type: 'list' },
  { id: 'last_call_result_promo', title: 'Last call result (Promo)', type: 'list' },
  { id: 'registration_step', title: 'Registration step', type: 'list' },
  { id: 'last_loan_state', title: 'Last loan state', type: 'list' },
  { id: 'last_call_result_unfinished_registration', title: 'Last call result (Unfinished registration)', type: 'list' },
  { id: 'last_call_result_unsigned_request', title: 'Last call result (Unsigned request)', type: 'list' },
  { id: 'user_days_before_birthday', title: 'User days before birthday', type: 'sign' },
  { id: 'gender_filter', type: 'list', title: 'Gender' },
  { id: 'age_filter', type: 'sign', title: 'Age' },
  { id: 'employment_types', type: 'list', title: 'Employment Type' },
  { id: 'education_level', type: 'list', title: 'Education level' },
  { id: 'closed_loan_number', title: 'Closed loan number', type: 'sign' },
  { id: 'days_since_last_rejected_application', title: 'Days since last rejected application', type: 'sign' },
  { id: 'days_since_last_user_unlock', title: 'Days since last user unlock', type: 'sign' },
  { id: 'days_without_loan', title: 'Days without loan', type: 'sign' },
  { id: 'extension_number', title: 'Extension number', type: 'sign' },
  { id: 'last_loan_max_dpd', title: 'Last loan max days past due', type: 'sign' },
  { id: 'last_moratorium_days', title: 'Last moratorium days', type: 'sign' },
  { id: 'last_user_activity', title: 'Last user activity (minutes)', type: 'sign' },
  { id: 'last_sent_sms_template', title: 'Template of the last sent sms', type: 'list' },
  { id: 'last_loan_sequence_number', title: 'Sequence number of last loan', type: 'sign' },
  { id: 'last_loan_has_active_promo_code', title: 'Last loan with active promo code (conditions)', type: 'boolean' },
  { id: 'last_loan_term', title: 'Term of last loan', type: 'sign' },
  { id: 'last_loan_type', title: 'Type of last loan', type: 'list' },
  {
    id: 'registration_date_from_today',
    title: 'Registration date from today(day interval, 0 - today, -1 - yesterday, etc)',
    type: 'sign',
  },
  { id: 'last_loan_collection_score_group', title: 'Last loan collection score group', type: 'list' },
  { id: 'last_loan_collection_score_priority', title: 'Last loan collection score priority', type: 'list' },
  { id: 'dpd', title: 'DPD', type: 'sign' },
  { id: 'collection_group', title: 'Loan Stage', type: 'list' },
  { id: 'last_loan_tenor', title: 'Last loan tenor', type: 'sign' },
  { id: 'collector', title: 'Collector', type: 'list' },
  {
    id: 'days_from_the_last_call_client',
    title: 'Days from last call "Contact type: Client" (phone)',
    type: 'sign',
    withoutNegativeValues: true,
  },
  {
    id: 'days_from_the_last_call_tpc',
    title: 'Days from last call "Contact type: TPC" (phone)',
    type: 'sign',
    withoutNegativeValues: true,
  },
  {
    id: 'days_from_the_last_call',
    title: 'Days from last call (collеction)',
    type: 'sign',
    withoutNegativeValues: true,
  },
  {
    id: 'days_from_the_last_action_client',
    title: 'Days from last action "Contact type: Client"',
    type: 'sign',
    withoutNegativeValues: true,
  },
  {
    id: 'days_from_the_last_action_tpc',
    title: 'Days from last action "Contact type: TPC"',
    type: 'sign',
    withoutNegativeValues: true,
  },
  {
    id: 'days_from_the_last_action',
    title: 'Days from last action (collеction)',
    type: 'sign',
    withoutNegativeValues: true,
  },
  { id: 'days_from_last_ptp_date', title: 'Days from last PTP Date (loan)', type: 'sign', withoutNegativeValues: true },
  {
    id: 'days_from_last_ptp_created',
    title: 'Days from last PTP Created (loan)',
    type: 'sign',
    withoutNegativeValues: true,
  },
  { id: 'ptp_stage', title: 'PTP Stage', type: 'list' },
  { id: 'total', title: 'Total', type: 'sign' },
  { id: 'give_out_principal', title: 'Giveout principal', type: 'sign' },
  { id: 'outstanding_principal', title: 'Outstanding principal', type: 'sign' },
  { id: 'outstanding_commission', title: 'Outstanding commission', type: 'sign' },
  { id: 'outstanding_interest', title: 'Outstanding interest', type: 'sign' },
  { id: 'outstanding_past_due_interest', title: 'Outstanding past due interest', type: 'sign' },
  { id: 'last_contact_type_client_phone', title: 'Contact type: Client', type: 'sign' },
  {
    id: 'last_contact_type_client_promise_phone',
    title: 'Contact type: "Client" and Contact result: "Promise of payment',
    type: 'sign',
  },
  {
    id: 'days_from_last_payment_date',
    title: 'Days from the last Payment  Date (loan)',
    type: 'sign',
    withoutNegativeValues: true,
  },
  { id: 'last_logged_into_personal_account_general', title: 'Logged into personal account ago', type: 'sign' },
  { id: 'last_logged_into_short_personal_account', title: 'Short link to personal account', type: 'sign' },
  { id: 'last_logged_into_short_extension', title: 'Loggert into Short link to extension', type: 'sign' },
  { id: 'external_agency', title: 'External agency', type: 'list' },
  { id: 'user_tag', title: 'User tag', type: 'list' },
  { id: 'last_approved_loan_amount', title: 'Last approved loan amount', type: 'sign' },
  {
    id: 'days_from_last_auto_debt_success_date',
    title: 'Days From AutoDebt success Date',
    type: 'sign',
    withoutNegativeValues: true,
  },
  { id: 'bank_filter', title: 'Bank filter', type: 'list' },
  { id: 'number_of_active_loan_days_filter', title: 'Number of active loan days', type: 'sign' },
  { id: 'last_digits_of_loan_id', title: 'Last 1 digit of loan id', type: 'last_digits' },
  { id: 'last_digits_of_client_id', title: 'Last 1 digit of client id', type: 'last_digits' },
  { id: 'last_2_digits_of_loan_id', title: 'Last 2 digits of loan id', type: 'last_digits' },
  { id: 'last_2_digits_of_client_id', title: 'Last 2 digits of client id', type: 'last_digits' },
  {
    id: 'creation_of_direct_debit',
    title: 'Direct debit allowed to create',
    type: 'boolean',
    onlyValue: true,
    hideInputs: true,
  },
  { id: 'application_risk_score', title: 'Application risk score', type: 'sign' },
  { id: 'application_has_risk_score', title: 'Application has risk score', type: 'boolean' },
  { id: 'user_has_push_available', title: 'User has push available', type: 'boolean' },
  { id: 'active_discount_offer', title: 'Active discount offer', type: 'boolean' },
  { id: 'discount_offer_amount', title: 'Discount offer amount', type: 'sign' },
  { id: 'discount_offer_amount_to_pay', title: 'Amount to pay for discount offer', type: 'sign' },
  { id: 'discount_offer', title: 'Discount offer', type: 'list' },
  { id: 'discount_offer_days_active', title: 'Days of discount offer is active', type: 'sign' },
  { id: 'discount_offer_days_before_end', title: 'Days before end of discount offer', type: 'sign' },
  {
    id: 'discount_offer_ends_at',
    title: 'Discount offer end date',
    type: 'date_range',
    label_after: 'End date after',
    label_before: 'End date before',
  },
  { id: 'discount_offer_state', title: 'Status of discount offer', type: 'list' },
  { id: 'loan_has_direct_debit', title: 'Active direct debit', type: 'boolean' },
  { id: 'client_has_charge_back', title: 'Client has charge-back', type: 'boolean' },
  { id: 'loan_has_charge_back', title: 'Loan has charge-back', type: 'boolean' },
  { id: 'state_of_last_auto_debit_request', title: 'State of last auto debit request', type: 'list' },
  { id: 'last_loan_product_name', title: 'Last loan product name', type: 'list' },
  { id: 'extension_percentage_of_principal', title: 'Extension percentage of principal', type: 'sign' },
  { id: 'bank_account_closed', title: 'Auto debit has error', type: 'boolean' },
];

export const USER_EVENT_NOTIFICATIONS_FILTERS_IDS = [
  { id: 'affiliate', title: 'Affiliates', type: 'list' },
  { id: 'external_agency', title: 'External agencies', type: 'list' },
  { id: 'ip_country', title: 'Country IP address', type: 'list' },
  { id: 'principal', title: 'Approved principal', type: 'sign' },
];

export const CONTACT_STATUS_LIST = [
  { code: 'active', label: 'Active' },
  { code: 'inactive', label: 'Inactive' },
];

export const CONTACT_VALIDATION_STATE_LIST = [
  { code: 'MOBILE', label: 'Mobile' },
  { code: 'LANDLINE', label: 'Landline' },
  { code: 'VOIP', label: 'Voip' },
  { code: 'OTHER', label: 'Other' },
  { code: 'PREPAID', label: 'Prepaid' },
  { code: 'INVALID', label: 'Invalid' },
];

export const USER_API_DATA_SERVICES = [
  { code: 'moffin_credit_bureau', name: 'Credit bureau / Moffin' },
  { code: 'credit_bureau', name: 'Credit bureau / Circulodecredito' },
  { code: 'curp_api', name: 'Curp api' },
  { code: 'hyperverge', name: 'Hyperverge' },
  { code: 'nubarium_curp_api', name: 'Nubarium curp api' },
  { code: 'nubarium_security_number', name: 'Nubarium security number' },
  { code: 'nubarium_job_history', name: 'Nubarium job history' },
  { code: 'nubarium_validate_ine', name: 'Nubarium validate ine' },
  { code: 'nubarium_rfc_name', name: 'Nubarium RFC name' },
  { code: 'stp_cep', name: 'STP CEP' },
  { code: 'moffin_sat_blacklist', name: 'Moffin SAT Blacklist' },
  { code: 'mba', name: 'MBA' },
  { code: 'credolab', name: 'Credolab' },
  { code: 'seon', name: 'Seon' },
];

export const CIRCULADECREDITO_RESIDENCIA = {
  1: 'Propietario',
  2: 'Renta',
  3: 'Vive con Familiares',
  4: 'Vivienda Hipotecada',
  5: 'No Disponible',
};

export const CIRCULADECREDITO_ESTADOS = {
  AGS: 'AGUASCALIENTES',
  BC: 'BAJA CALIFORNIA NORTE',
  BCS: 'BAJA CALIFORNIA SUR',
  CAMP: 'CAMPECHE',
  CDMX: 'CIUDAD DE MÉXICO',
  CHIS: 'CHIAPAS',
  CHIH: 'CHIHUAHUA',
  COAH: 'COHAHUILA',
  COL: 'COLIMA',
  DF: 'DISTRITO FEDERAL',
  DGO: 'DURANGO',
  GTO: 'GUANAJUATO',
  GRO: 'GUERRERO',
  HGO: 'HIDALGO',
  JAL: 'JALISCO',
  MEX: 'MÉXICO',
  MICH: 'MICHOCÁN',
  MOR: 'MORELOS',
  NAY: 'NAYARIT',
  NL: 'NUEVO LEÓN',
  OAX: 'OXACA',
  PUE: 'PUEBLA',
  QRO: 'QUERÉTARO',
  QROO: 'QUINTANA ROO',
  SLP: 'SAN LUIS POTOSÍ',
  SIN: 'SINALOA',
  SON: 'SONORA',
  TAB: 'TABASCO',
  TAMP: 'TAMAULIPAS',
  TLAX: 'TLAXCALA',
  VER: 'VERACRUZ',
  YUC: 'YUCATÁN',
  ZAC: 'ZACATECAS',
};

export const CIRCULADECREDITO_TIPO_DOMICILIO = {
  N: 'Negocio',
  O: 'Domicilio del otorgante',
  C: 'Casa',
  P: 'Apartado Postal',
  E: 'Empleo',
};

export const CIRCULADECREDITO_TIPO_ASENTAMIENTO = {
  0: 'PENDIENTE POR ACTUALIZAR',
  1: 'AEROPUERTO',
  2: 'BARRIO',
  3: 'BASE NAVAL',
  4: 'CAMPAMENTO',
  5: 'CENTRO',
  6: 'CENTRO URBANO',
  7: 'COLONIA',
  8: 'CONDOMINIO',
  9: 'CONGREGACION',
  10: 'CONJUNTO HABITACIONAL',
  11: 'CONJUNTO HABITACIONAL RESIDENCIAL',
  12: 'CONJUNTO HABITACIONAL URBANO',
  13: 'CONJUNTO POPULAR',
  14: 'CONJUNTO RESIDENCIAL',
  15: 'CONJUNTO URBANO',
  16: 'CONJUNTO URBANO POPULAR',
  17: 'EJIDO',
  18: 'ESCUELA',
  19: 'ESTACION DE RADIO',
  20: 'EX-HACIENDA',
  21: 'EX-RANCHO',
  22: 'FABRICA',
  23: 'FINCA',
  24: 'FRACCIONAMIENTO',
  25: 'FRACCIONAMIENTO INDUSTRIAL',
  26: 'FRACCIONAMIENTO RESIDENCIAL',
  27: 'GRANJA',
  28: 'HACIENDA',
  29: 'INGENIO',
  30: 'JUNTA AUXILIA',
  31: 'MODULO HABIT',
  32: 'PARQUE',
  33: 'PARQUE INDUS',
  34: 'CUADRILLA',
  35: 'POBLADO COM',
  36: 'PUEBLO',
  37: 'RANCHO O RAN',
  38: 'RESIDENCIAL',
  39: 'VILLA',
  40: 'UNIDAD HABIT',
  41: 'ZONA FEDERAL',
  42: 'ZONA HABITAC',
  43: 'ZONA INDUSTR',
  44: 'ZONA RESIDEN',
  45: 'ZONA URBANA',
  46: 'ZONA URBANA',
  47: 'CAMPO MILITA',
  48: 'VIVIENDA POPU',
  49: 'CLUB DE GOLF',
  50: 'COOPERATIVA',
  51: 'CIUDAD',
  52: 'OFICINA DE CO',
  53: 'GRAN USUARIO',
  54: 'ZONA COMERC',
  55: 'ZONA RURAL',
};

export const CIRCULADECREDITO_TIPO_RESPONSABILIDAD = {
  I: 'Individual(Titular)',
  M: 'Mancomunado',
  O: 'Obligatorio Solidario',
  A: 'Aval',
  T: 'Titular con Aval',
};

export const CIRCULADECREDITO_TIPO_CUENTA = {
  F: 'Pagos fijos',
  H: 'Hipoteca',
  L: 'Sin límite preestablecido',
  R: 'Revolvente',
  Q: 'Quirografario',
  A: 'Crédito de habilitación o avío',
  E: 'Crédito refaccionario',
  P: 'Crédito prendario',
};

export const CIRCULADECREDITO_TIPO_CREDITO = {
  AA: 'Arrendamiento Automotriz',
  AB: 'Automotriz Bancario',
  AE: 'Física Actividad Empresarial',
  AM: 'Aparatos/Muebles',
  AR: 'Arrendamiento',
  AV: 'Aviación',
  BC: 'Banca Comunal',
  BL: 'Bote/Lancha',
  BR: 'Bienes Raíces',
  CA: 'Compra De Automóvil',
  CC: 'Crédito Al Consumo',
  CF: 'Crédito Fiscal',
  CO: 'Consolidación',
  CP: 'Crédito Personal Al Consumo',
  ED: 'Editorial',
  EQ: 'Equipo',
  FF: 'Fondeo Fira',
  FI: 'Fianza',
  FT: 'Factoraje',
  GS: 'Grupo Solidario',
  HB: 'Hipotecario Bancario',
  HE: 'Préstamo Tipo Home Equity',
  HV: 'Hipotecario ó Vivienda',
  LC: 'Línea de Crédito',
  MC: 'Mejoras a la Casa',
  NG: 'Préstamo No Garantizado',
  PB: 'Préstamo Personal Bancario',
  PC: 'Procampo',
  PE: 'Préstamo Para Estudiante',
  PG: 'Préstamo Garantizado',
  PQ: 'Préstamo Quirografario',
  PM: 'Préstamo Empresarial',
  PN: 'Préstamo de Nómina',
  PP: 'Préstamo Personal',
  SH: 'Segunda Hipoteca',
  TC: 'Tarjeta De Crédito',
  TD: 'Tarjeta Departamental',
  TG: 'Tarjeta Garantizada',
  TS: 'Tarjeta De Servicios',
  VR: 'Vehículo Recreativo',
  OT: 'Otros',
  NC: 'Desconocido',
};

export const CIRCULADECREDITO_FRECUENCIA_PAGO = {
  A: 'Anual',
  B: 'Bimestral',
  C: 'Catorcenal',
  D: 'Deducción del Salario',
  E: 'Semestral',
  M: 'Mensual',
  Q: 'Quincenal',
  R: 'Pago Mínimo Para Cuentas Revolventes',
  S: 'Semanal',
  T: 'Trimestral',
  U: 'Una Sola Exhibición',
};

export const CIRCULADECREDITO_PREVENCION = {
  AD: 'Cuenta o monto en aclaración',
  CA: 'Cartera al Corriente Vendida o cedida a un usuario de una Sociedad',
  CC: 'Cuenta cancelada o cerrada',
  CD: 'Convenio y disminución de pago',
  CL: 'Cuenta cerrada que estuvo en cobranza y fue pagada totalmente sin causar quebranto',
  CO: 'Crédito en Controversia',
  CV: 'Cuenta que no está al corriente vendida o cedida a un usuario de una Sociedad',
  FD: 'Cuenta Fraudulenta',
  FN: 'Fraude no Atribuible al Consumidor',
  FP: 'Fianza pagada',
  FR: 'Adjudicación o aplicación de garantía',
  GP: 'Ejecución de Garantía Prendaria o Fiduciaria en Pago por Crédito',
  IA: 'Cuenta Inactiva',
  IM: 'Integrante causante de mora',
  IS: 'Integrante que fue subsidiado para evitar mora',
  LC: 'Convenio de finiquito o pago menor acordado con el Consumidor',
  LG: 'Pago menor por programa institucional o de gobierno, incluyendo los apoyos a damnificados por catástrofes naturales',
  LO: 'En Localización',
  LS: 'Tarjeta de Crédito Extraviada o Robada',
  NA: 'Cuenta al corriente vendida o cedida a un NO Usuario de una Sociedad',
  NV: 'Cuenta que no está al corriente vendida o cedida a un NO Usuario de una Sociedad',
  PC: 'Cuenta en Cobranza',
  PD: 'Prórroga otorgada debido a un desastre natural',
  PE: 'Prórroga otorgada al acreditado por situaciones especiales',
  PI: 'Prórroga otorgada al acreditado por invalidez, defunción',
  PR: 'Prórroga otorgada debido a una pérdida de relación laboral',
  RA: 'Cuenta reestructurada sin pago menor, por programa institucional o gubernamental, incluyendo los apoyos a damnificados por catástrofes naturales',
  RI: 'Robo de identidad',
  RF: 'Resolución judicial favorable al cliente',
  RN: 'Cuenta reestructurada debido a un proceso judicial',
  RV: 'Cuenta reestructurada sin pago menor por modificación de la situación del cliente, a petición de éste',
  SG: 'Demanda por el Otorgante',
  UP: 'Cuenta que causa quebranto',
  VR: 'Dación en Pagos ó Renta',
};

export const CIRCULADECREDITO_SERVICIOS = {
  0: 'Sin servicios',
  1: 'Servicios al hogar',
};

export const AFFILIATES_FILTER = [{ name: 'without_affiliate' }, { name: 'with_affiliate' }];

export const MARKETING_EVENTS_SEND_FROM_OPTIONS = [
  { id: '00:00', name: '00:00' },
  { id: '00:15', name: '00:15' },
  { id: '00:30', name: '00:30' },
  { id: '00:45', name: '00:45' },
  { id: '01:00', name: '01:00' },
  { id: '01:15', name: '01:15' },
  { id: '01:30', name: '01:30' },
  { id: '01:45', name: '01:45' },
  { id: '02:00', name: '02:00' },
  { id: '02:15', name: '02:15' },
  { id: '02:30', name: '02:30' },
  { id: '02:45', name: '02:45' },
  { id: '03:00', name: '03:00' },
  { id: '03:15', name: '03:15' },
  { id: '03:30', name: '03:30' },
  { id: '03:45', name: '03:45' },
  { id: '04:00', name: '04:00' },
  { id: '04:15', name: '04:15' },
  { id: '04:30', name: '04:30' },
  { id: '04:45', name: '04:45' },
  { id: '05:00', name: '05:00' },
  { id: '05:15', name: '05:15' },
  { id: '05:30', name: '05:30' },
  { id: '05:45', name: '05:45' },
  { id: '06:00', name: '06:00' },
  { id: '06:15', name: '06:15' },
  { id: '06:30', name: '06:30' },
  { id: '06:45', name: '06:45' },
  { id: '07:00', name: '07:00' },
  { id: '07:15', name: '07:15' },
  { id: '07:30', name: '07:30' },
  { id: '07:45', name: '07:45' },
  { id: '08:00', name: '08:00' },
  { id: '08:15', name: '08:15' },
  { id: '08:30', name: '08:30' },
  { id: '08:45', name: '08:45' },
  { id: '09:00', name: '09:00' },
  { id: '09:15', name: '09:15' },
  { id: '09:30', name: '09:30' },
  { id: '09:45', name: '09:45' },
  { id: '10:00', name: '10:00' },
  { id: '10:15', name: '10:15' },
  { id: '10:30', name: '10:30' },
  { id: '10:45', name: '10:45' },
  { id: '11:00', name: '11:00' },
  { id: '11:15', name: '11:15' },
  { id: '11:30', name: '11:30' },
  { id: '11:45', name: '11:45' },
  { id: '12:00', name: '12:00' },
  { id: '12:15', name: '12:15' },
  { id: '12:30', name: '12:30' },
  { id: '12:45', name: '12:45' },
  { id: '13:00', name: '13:00' },
  { id: '13:15', name: '13:15' },
  { id: '13:30', name: '13:30' },
  { id: '13:45', name: '13:45' },
  { id: '14:00', name: '14:00' },
  { id: '14:15', name: '14:15' },
  { id: '14:30', name: '14:30' },
  { id: '14:45', name: '14:45' },
  { id: '15:00', name: '15:00' },
  { id: '15:15', name: '15:15' },
  { id: '15:30', name: '15:30' },
  { id: '15:45', name: '15:45' },
  { id: '16:00', name: '16:00' },
  { id: '16:15', name: '16:15' },
  { id: '16:30', name: '16:30' },
  { id: '16:45', name: '16:45' },
  { id: '17:00', name: '17:00' },
  { id: '17:15', name: '17:15' },
  { id: '17:30', name: '17:30' },
  { id: '17:45', name: '17:45' },
  { id: '18:00', name: '18:00' },
  { id: '18:15', name: '18:15' },
  { id: '18:30', name: '18:30' },
  { id: '18:45', name: '18:45' },
  { id: '19:00', name: '19:00' },
  { id: '19:15', name: '19:15' },
  { id: '19:30', name: '19:30' },
  { id: '19:45', name: '19:45' },
  { id: '20:00', name: '20:00' },
  { id: '20:15', name: '20:15' },
  { id: '20:30', name: '20:30' },
  { id: '20:45', name: '20:45' },
  { id: '21:00', name: '21:00' },
  { id: '21:15', name: '21:15' },
  { id: '21:30', name: '21:30' },
  { id: '21:45', name: '21:45' },
  { id: '22:00', name: '22:00' },
  { id: '22:15', name: '22:15' },
  { id: '22:30', name: '22:30' },
  { id: '22:45', name: '22:45' },
  { id: '23:00', name: '23:00' },
  { id: '23:15', name: '23:15' },
  { id: '23:30', name: '23:30' },
  { id: '23:45', name: '23:45' },
];

export const MARKETING_EVENTS_SEND_TO_OPTIONS = [
  { id: '00:15', name: '00:15' },
  { id: '00:30', name: '00:30' },
  { id: '00:45', name: '00:45' },
  { id: '01:00', name: '01:00' },
  { id: '01:15', name: '01:15' },
  { id: '01:30', name: '01:30' },
  { id: '01:45', name: '01:45' },
  { id: '02:00', name: '02:00' },
  { id: '02:15', name: '02:15' },
  { id: '02:30', name: '02:30' },
  { id: '02:45', name: '02:45' },
  { id: '03:00', name: '03:00' },
  { id: '03:15', name: '03:15' },
  { id: '03:30', name: '03:30' },
  { id: '03:45', name: '03:45' },
  { id: '04:00', name: '04:00' },
  { id: '04:15', name: '04:15' },
  { id: '04:30', name: '04:30' },
  { id: '04:45', name: '04:45' },
  { id: '05:00', name: '05:00' },
  { id: '05:15', name: '05:15' },
  { id: '05:30', name: '05:30' },
  { id: '05:45', name: '05:45' },
  { id: '06:00', name: '06:00' },
  { id: '06:15', name: '06:15' },
  { id: '06:30', name: '06:30' },
  { id: '06:45', name: '06:45' },
  { id: '07:00', name: '07:00' },
  { id: '07:15', name: '07:15' },
  { id: '07:30', name: '07:30' },
  { id: '07:45', name: '07:45' },
  { id: '08:00', name: '08:00' },
  { id: '08:15', name: '08:15' },
  { id: '08:30', name: '08:30' },
  { id: '08:45', name: '08:45' },
  { id: '09:00', name: '09:00' },
  { id: '09:15', name: '09:15' },
  { id: '09:30', name: '09:30' },
  { id: '09:45', name: '09:45' },
  { id: '10:00', name: '10:00' },
  { id: '10:15', name: '10:15' },
  { id: '10:30', name: '10:30' },
  { id: '10:45', name: '10:45' },
  { id: '11:00', name: '11:00' },
  { id: '11:15', name: '11:15' },
  { id: '11:30', name: '11:30' },
  { id: '11:45', name: '11:45' },
  { id: '12:00', name: '12:00' },
  { id: '12:15', name: '12:15' },
  { id: '12:30', name: '12:30' },
  { id: '12:45', name: '12:45' },
  { id: '13:00', name: '13:00' },
  { id: '13:15', name: '13:15' },
  { id: '13:30', name: '13:30' },
  { id: '13:45', name: '13:45' },
  { id: '14:00', name: '14:00' },
  { id: '14:15', name: '14:15' },
  { id: '14:30', name: '14:30' },
  { id: '14:45', name: '14:45' },
  { id: '15:00', name: '15:00' },
  { id: '15:15', name: '15:15' },
  { id: '15:30', name: '15:30' },
  { id: '15:45', name: '15:45' },
  { id: '16:00', name: '16:00' },
  { id: '16:15', name: '16:15' },
  { id: '16:30', name: '16:30' },
  { id: '16:45', name: '16:45' },
  { id: '17:00', name: '17:00' },
  { id: '17:15', name: '17:15' },
  { id: '17:30', name: '17:30' },
  { id: '17:45', name: '17:45' },
  { id: '18:00', name: '18:00' },
  { id: '18:15', name: '18:15' },
  { id: '18:30', name: '18:30' },
  { id: '18:45', name: '18:45' },
  { id: '19:00', name: '19:00' },
  { id: '19:15', name: '19:15' },
  { id: '19:30', name: '19:30' },
  { id: '19:45', name: '19:45' },
  { id: '20:00', name: '20:00' },
  { id: '20:15', name: '20:15' },
  { id: '20:30', name: '20:30' },
  { id: '20:45', name: '20:45' },
  { id: '21:00', name: '21:00' },
  { id: '21:15', name: '21:15' },
  { id: '21:30', name: '21:30' },
  { id: '21:45', name: '21:45' },
  { id: '22:00', name: '22:00' },
  { id: '22:15', name: '22:15' },
  { id: '22:30', name: '22:30' },
  { id: '22:45', name: '22:45' },
  { id: '23:00', name: '23:00' },
  { id: '23:15', name: '23:15' },
  { id: '23:30', name: '23:30' },
  { id: '23:45', name: '23:45' },
];

export const USER_NOTIFICATION_MESSAGE_TYPE_CHOICES = [
  { id: 'success', name: 'Success' },
  { id: 'danger', name: 'Danger' },
  { id: 'warning', name: 'Warning' },
  { id: 'info', name: 'Info' },
];

export const CAMPAIGN_TYPE_CHOICES = [
  { id: 'onetime', name: 'One-time campaign' },
  { id: 'regular', name: 'Regular campaign' },
  { id: 'manual', name: 'Manual campaign' },
];

export const DISCOUNT_TYPE_CHOICES = [
  { id: 'amount', name: 'Discount as absolute value' },
  { id: 'total_percent', name: 'Discount in % from full debt ' },
  { id: 'compound_percent', name: 'Discount in % for debt components' },
];

export const DISCOUNT_EXTENSION_TYPE_CHOICES = [
  { id: 'total_percent', name: 'Discount in % from full payment for extension' },
  { id: 'compound_percent', name: 'Discount in % for debt components' },
];

export const DISCOUNT_CATEGORY_CHOICES = [
  { id: 'loan_closing', name: 'Loan closing' },
  { id: 'loan_extension', name: 'Loan extension' },
];

export const PAYMENT_LIMIT_CHOICES = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
];

export const AUDIENCE_TYPE_CHOICES = [
  { id: 'audience', name: 'Audience' },
  { id: 'file', name: 'Import' },
];

export const LOAN_STATISTICS_FILTER_CHOICES = [
  { id: 'loan_total', name: 'All active loans' },
  { id: 'active_no_ptp', name: 'Loans active (no PTP)' },
  { id: 'active_ptp_in_process', name: 'Loans active (PTP in process)' },
  { id: '1_day_before_ptp', name: 'Loans  1 day before  PTP date' },
  { id: 'due_ptp_date', name: 'Loans  due  PTP date' },
  { id: '1_day_broken_ptp', name: 'Loans  1 day Broken PTP' },
  { id: '2_day_broken_ptp', name: 'Loans 2 days Broken PTP' },
  { id: '3_day_broken_ptp', name: 'Loans 3 days Broken PTP' },
];

export const PHONE_PRIORITY_CHOICES = [
  { id: 10, name: '10' },
  { id: 20, name: '20' },
  { id: 21, name: '21' },
  { id: 22, name: '22' },
  { id: 30, name: '30' },
  { id: 40, name: '40' },
  { id: 50, name: '50' },
  { id: 70, name: '70' },
  { id: 80, name: '80' },
  { id: 90, name: '90' },
  { id: 100, name: '100' },
  { id: 110, name: '110' },
  { id: 120, name: '120' },
  { id: 130, name: '130' },
  { id: 140, name: '140' },
  { id: 150, name: '150' },
  { id: 160, name: '160' },
  { id: 170, name: '170' },
  { id: 180, name: '180' },
  { id: 190, name: '190' },
  { id: 200, name: '200' },
  { id: 210, name: '210' },
  { id: 220, name: '220' },
  { id: 240, name: '240' },
  { id: 250, name: '250' },
  { id: 260, name: '260' },
  { id: 270, name: '270' },
  { id: 280, name: '280' },
  { id: 290, name: '290' },
  { id: 300, name: '300' },
];

export const DEPARTMENTS_CHOICES = [
  { id: 'Marketing', name: 'Marketing' },
  { id: 'Customer support', name: 'Customer support' },
  { id: 'Collection', name: 'Collection' },
];

export const PTP_STATUS_CHOICES = [
  { id: 'in_progress', name: 'In progress' },
  { id: 'kept', name: 'Kept' },
  { id: 'partially_kept', name: 'Partially kept' },
  { id: 'broken', name: 'Broken' },
];

export const TRIGGERS_CHOICES = [
  { id: 'income', name: 'Income' },
  { id: 'login', name: 'Login' },
  { id: 'refresh_token', name: 'Refresh token' },
  { id: 'login_by_contract_number', name: 'Login by contract number' },
  { id: 'login_by_phone', name: 'Login by phone' },
  { id: 'login_by_short_link_personal_account', name: 'Login by short link personal account' },
  { id: 'login_by_short_link_extension', name: 'Login by short link extension' },
  { id: 'promise_payment_date', name: 'Promise payment date' },
  { id: 'repeat_communication_reminder_date', name: 'Repeat communication reminder date' },
  { id: 'WhatsApp_message', name: 'WhatsApp message' },
  { id: 'related_project_loan_closed', name: 'Repaid loan in another company' },
];

export const SENDING_TYPE_CHOICES = [
  { id: 'mass', name: 'Mass' },
  { id: 'manual', name: 'Manual' },
];

export const REQUEST_TYPE_CHOICES = [
  { id: 'false', name: 'New' },
  { id: 'true', name: 'Repeated' },
];
