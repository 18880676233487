import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import { COMMUNICATION_CATEGORY_GROUP_CHOICES } from '../../constants';

const CommunicationCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={[required()]} />
      <SelectInput source="group" validate={[required()]} choices={COMMUNICATION_CATEGORY_GROUP_CHOICES} />
    </SimpleForm>
  </Create>
);

CommunicationCategoryCreate.propTypes = {};

export default CommunicationCategoryCreate;
