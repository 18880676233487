import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DraggableDialog } from '../../../components';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  modalTitle: {
    cursor: 'move',
  },
}));

const AssignVerifierTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [admins, setAdmins] = useState();
  const [verifierId, setVerifierId] = useState();

  useEffect(() => {
    isOpened &&
      dataProvider
        .query('admins?role_permission=CAN_BE_APPLICATION_VERIFIER&is_blocked=false', { method: 'GET' })
        .then(({ data }) => {
          setAdmins(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
  }, [dataProvider, isOpened]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Assign verifier
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please select verifier.</DialogContentText>
        {loading ? (
          <CircularProgress />
        ) : (
          <Select
            id="assign-verifier-select"
            value={verifierId || ''}
            onChange={e => setVerifierId(e.target.value)}
            className={classes.fullWidth}>
            {admins.map(admin => (
              <MenuItem key={admin.id} value={admin.id}>
                {admin.username}
              </MenuItem>
            ))}
          </Select>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(verifierId)} color="primary" disabled={!verifierId}>
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

AssignVerifierTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AssignVerifierTransitionDialog;
