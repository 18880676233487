import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { timezone } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  hiddenOverflow: {
    overflow: 'hidden',
  },
}));

export const PastDueInterestDiscountDialog = ({ onClose, onSubmit, selected = [], transactions = [] }) => {
  const [amount, setAmount] = useState(null);
  const [executedAt, setExecutedAt] = useState(new Date());

  const classes = useStyles();

  useEffect(() => {
    const selectedPDIAmount = () => {
      if (selected.length && transactions.length) {
        const selectedPDITransactions = transactions
          .filter(t => selected.includes(t.id))
          .filter(t => t.direction === 'out' && t.type === 'past_due_interest')
          .map(t => t.amount);
        return selectedPDITransactions.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(2);
      }
      return 0;
    };
    const defaultAmount = parseFloat(selectedPDIAmount());
    setAmount(defaultAmount);
  }, [selected, transactions]);

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">Past due interest discount</DialogTitle>
      <DialogContent className={classes.hiddenOverflow}>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              margin="none"
              fullWidth={true}
              label="Amount"
              value={amount}
              placeholder="0.00"
              inputProps={{ step: '0.01' }}
              onChange={e => {
                if (e.target.value.length > 0) {
                  const value = +Number(e.target.value).toFixed(2);
                  setAmount(value);
                } else if (e.target.value.includes('.') && e.target.value.split('.')[1].length === 2) {
                  return false;
                } else {
                  setAmount(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Executed at"
              className={classes.fullWidth}
              value={timezone.shift(executedAt)}
              onChange={value => setExecutedAt(timezone.unshift(value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(amount, executedAt)}
          color="primary"
          disabled={!amount || !executedAt || amount < 0}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PastDueInterestDiscountDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.number),
  transactions: PropTypes.arrayOf(PropTypes.object),
};
