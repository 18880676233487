import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, usePermissions } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ImageZoom, findImage } from '../../../components';
import { useStyles } from '../styles';

const UserSelfie = ({ record }) => {
  const [totalSelfies, setTotalSelfies] = useState(0);
  const [lastUploadTimeSelfie, setLastUploadTimeSelfie] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_USER_EDIT');

  const buildHandleUploadFile = (path, refreshHandler) => {
    return e => dataProvider.postImage(path, e.target.files[0]).then(() => refreshHandler(Date.now()));
  };

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper}>
        <Box className={classes.flexCenteredSpaced}>
          <Typography variant="h6" gutterBottom={false}>
            <label htmlFor="selfie_upload">
              <input
                disabled={!isEditable}
                className={classes.hidden}
                type="file"
                id="selfie_upload"
                onChange={buildHandleUploadFile(`users/${record.id}/selfie`, setLastUploadTimeSelfie)}
              />

              <IconButton
                disabled={!isEditable}
                color="primary"
                aria-label="upload picture"
                className={classes.uploadIcon}
                component="span"
                edge="start">
                <CloudUploadIcon />
              </IconButton>
            </label>
            Selfie
          </Typography>
          {totalSelfies > 0 && (
            <Typography variant="caption" color="textSecondary">
              {`${totalSelfies} ${totalSelfies === 1 ? 'file' : 'files'}`}
            </Typography>
          )}
        </Box>
        {record.similarity_card_and_selfie !== null ? (
          <Typography variant="body1">{`Similarity ${record.similarity_card_and_selfie}%`}</Typography>
        ) : (
          findImage(record.uploaded_files, 'id_card') &&
          findImage(record.uploaded_files, 'selfie') && (
            <Typography variant="caption" color="error">
              No face is recognized on the ID card
            </Typography>
          )
        )}
        <Box pb={1}>
          <Divider />
        </Box>
        <ImageZoom
          isExist={findImage(record.uploaded_files, 'selfie')}
          userId={record.id}
          label="selfie"
          lastUploadTime={lastUploadTimeSelfie}
          handleSetTotal={setTotalSelfies}
        />
      </Paper>
    </Grid>
  );
};

UserSelfie.propTypes = {
  record: PropTypes.object,
};

export default UserSelfie;
