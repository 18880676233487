import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const STYLES = {
  out: { background: '#dddddd', fontWeight: 'normal' },
  in: { background: '#6db846', fontWeight: 'bold' },
  unknown: { background: '#cccccc', fontWeight: 'normal' },
};

export const TransactionDirection = ({ direction }) => {
  const badgeStyle = STYLES[direction] || STYLES.unknown;

  return <Chip label={direction} size="small" style={badgeStyle} />;
};

TransactionDirection.propTypes = {
  direction: PropTypes.string,
};
