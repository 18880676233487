import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  DateInput,
  NumberInput,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FileCopy } from '@material-ui/icons';
import { ChangePhoneNumberDialog, OldPhonesDialog, WebitelButton, Call } from '../../../components';
import { useStyles } from '../styles';
import { useHandbook } from '../../../hooks';
import { nameTransform, phoneNumber, copyToClipboard, formatChoices } from '../../../utils';
import { GENDER, SOURCE_OF_INCOME } from '../../../constants';

const UserProfile = ({ record }) => {
  const [doNotDisturbUser, setDoNotDisturbUser] = useState(false);
  const [dndApplying, setDndApplying] = useState(false);
  const [oldPhonesDialog, setOldPhonesDialogOpen] = useState(false);
  const [isChangePhoneNumberDialogOpened, setIsChangePhoneNumberDialogOpened] = useState(false);
  const [isChangeSecondPhoneNumberDialogOpened, setIsChangeSecondPhoneNumberDialogOpened] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions = [] } = usePermissions();

  const { choices: maritalStatusChoices } = useHandbook('marital_statuses');
  const { choices: educationChoices } = useHandbook('education');

  const isEditable = permissions.includes('CAN_USER_EDIT');
  const canPhoneEdit = permissions.includes('CAN_PHONE_NUMBER_EDIT');
  const canUserProfileNicEdit = permissions.includes('CAN_USER_PROFILE_NIC_EDIT');

  useEffect(() => {
    record.do_not_disturb && setDoNotDisturbUser(record.do_not_disturb);
  }, [record.do_not_disturb]);

  const handleDNDUser = e => {
    setDoNotDisturbUser(e.target.checked);
    setDndApplying(true);
    dataProvider
      .query(`users/${record.id}`, {
        method: 'PATCH',
        body: JSON.stringify({ do_not_disturb: e.target.checked }),
      })
      .then(({ data }) => {
        setDoNotDisturbUser(data?.do_not_disturb);
        notify('Changes succesfully applied', 'success');
      })
      .catch(error => {
        setDoNotDisturbUser(record.do_not_disturb);
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setDndApplying(false));
  };

  const submitPhone = phone_number => {
    const resource = 'users/' + record.id + '/phone_number';
    dataProvider
      .fetch(resource, {
        body: JSON.stringify(phone_number),
        method: 'PATCH',
      })
      .then(() => {
        notify('Success: Phone number was updated', 'success');
        setIsChangePhoneNumberDialogOpened(false);
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const submitSecondPhone = second_phone_number => {
    const resource = 'users/' + record.id + '/second_phone_number';
    dataProvider
      .fetch(resource, {
        body: JSON.stringify(second_phone_number),
        method: 'PATCH',
      })
      .then(() => {
        notify('Success: Phone number was updated', 'success');
        setIsChangeSecondPhoneNumberDialogOpened(false);
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const copyText = async text => {
    try {
      await copyToClipboard(text);
      notify('Text copied to clipboard!', { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom={false}>
            Profile
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch checked={doNotDisturbUser} onChange={handleDNDUser} name="checkedDndUSer" color="primary" />
            }
            disabled={dndApplying}
            label="Do not disturb this user"
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable}
            format={v => nameTransform(v)}
            source="first_name"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable}
            format={v => nameTransform(v)}
            source="last_name"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable}
            format={v => nameTransform(v)}
            source="father_surname"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable}
            format={v => nameTransform(v)}
            source="mother_surname"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            disabled
            source="phone_number"
            fullWidth
            helperText={false}
            placeholder={phoneNumber.placeholder}
            parse={phoneNumber.parser}
            format={value => phoneNumber.formatter(value)}
          />
          <ButtonGroup>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                setOldPhonesDialogOpen(true);
              }}>
              Old phone numbers
            </Button>
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => {
                setIsChangePhoneNumberDialogOpened(true);
              }}
              disabled={!canPhoneEdit}>
              Change
            </Button>
          </ButtonGroup>
          <OldPhonesDialog userId={record.id} isOpen={oldPhonesDialog} onClose={setOldPhonesDialogOpen} />
          <ChangePhoneNumberDialog
            fieldName="phone_number"
            isOpened={isChangePhoneNumberDialogOpened}
            onClose={() => setIsChangePhoneNumberDialogOpened(false)}
            onSubmit={phone_number => submitPhone(phone_number)}
          />
        </Grid>
        <Grid item xs={1} style={{ paddingTop: 16 }}>
          <Box className={classes.flexCenteredSpaced}>
            <WebitelButton userPhone={record.username} />
            <Call inactive={record.has_sold_loans} userId={record.id} userPhone={record.username} hidden edge={'start'}>
              {'+52' + record.phone_number}
            </Call>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <TextInput
            disabled
            source="second_phone_number"
            fullWidth
            helperText={false}
            placeholder={phoneNumber.placeholder}
            parse={phoneNumber.parser}
            format={value => phoneNumber.formatter(value)}
          />
          <ButtonGroup>
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => {
                setIsChangeSecondPhoneNumberDialogOpened(true);
              }}
              disabled={!canPhoneEdit}>
              Change
            </Button>
          </ButtonGroup>
          <ChangePhoneNumberDialog
            fieldName="second_phone_number"
            isOpened={isChangeSecondPhoneNumberDialogOpened}
            onClose={() => setIsChangeSecondPhoneNumberDialogOpened(false)}
            onSubmit={second_phone_number => submitSecondPhone(second_phone_number)}
          />
        </Grid>
        <Grid item xs={1} style={{ paddingTop: 16 }}>
          <Call
            inactive={record.has_sold_loans}
            userId={record.id}
            userPhone={record.second_phone_number}
            hidden
            edge={'start'}>
            {'+52' + record.second_phone_number}
          </Call>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable}
            label="Email Address"
            source="email"
            type="email"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable || (!canUserProfileNicEdit && !(!record.curp && !isEditable))}
            source="curp"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable || (!canUserProfileNicEdit && !(!record.rfc && !isEditable))}
            source="rfc"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={!isEditable || (!canUserProfileNicEdit && !(!record.nss && !isEditable))}
            source="nss"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            disabled={!isEditable}
            label="Gender"
            source="gender"
            fullWidth
            helperText={false}
            choices={formatChoices(GENDER)}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput disabled={!isEditable} label="Birthday" source="birthday" fullWidth helperText={false} />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            key={`marital-statuses-input-${maritalStatusChoices.length}`}
            disabled={!isEditable}
            label="Marital status"
            source="marital_status"
            fullWidth
            helperText={false}
            choices={maritalStatusChoices}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput disabled source="nationality" fullWidth helperText={false} />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            disabled={!isEditable}
            label="Monthly payments"
            source="external_loan_monthly_payments"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            disabled={!isEditable}
            label="Main source of income"
            source="main_source_of_income"
            fullWidth
            helperText={false}
            choices={formatChoices(SOURCE_OF_INCOME)}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            disabled={!isEditable}
            label="Monthly income"
            source="monthly_income"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            key={`educations-input-${educationChoices.length}`}
            disabled={!isEditable}
            label="Education"
            source="education"
            fullWidth
            helperText={false}
            choices={educationChoices}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput size="small" label="Affiliate" source="affiliate" disabled fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            multiline
            size="small"
            label="Preferred product code"
            source="preferred_product_code"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={11}>
          <TextInput multiline size="small" label="User target URL" source="target_url" disabled fullWidth />
        </Grid>
        <Grid item xs={1}>
          <IconButton disabled={!record.target_url} onClick={() => copyText(record.target_url)}>
            <FileCopy />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <BooleanInput
            disabled={!isEditable}
            label="Consent given?"
            source="is_data_processing_consent_given"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6} style={{ pointerEvents: 'none' }}>
          <BooleanInput
            disabled={false}
            label="Allow to receive marketing email"
            source="is_allow_to_receive_marketing_email"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6} style={{ pointerEvents: 'none' }}>
          <BooleanInput
            disabled={false}
            label="Allow to receive collection email"
            source="is_allow_to_receive_collection_email"
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={6} style={{ pointerEvents: 'none' }}>
          <BooleanInput disabled={false} source="is_charge_back_requested" fullWidth helperText={false} />
        </Grid>
      </Grid>
    </Paper>
  );
};

UserProfile.propTypes = {
  record: PropTypes.object,
};

export default UserProfile;
