import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export const DocumentVerification = ({ userId }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const [ducumentData, setDocumentData] = useState();
  const [loading, setLoading] = useState(false);

  const getCheckOrCloseIcon = value => {
    if (value) {
      return <CheckIcon className={classes.success} />;
    } else {
      return <CloseIcon className={classes.error} />;
    }
  };

  useEffect(() => {
    setLoading(true);
    dataProvider
      .query(`users/${userId}/verified_document`, { method: 'GET' })
      .then(({ data }) => setDocumentData(data))
      .catch(error => notify(`Error: ${error.message || 'verified document not found'}`, 'error'))
      .finally(() => setLoading(false));
  }, [dataProvider, notify, userId]);

  if (loading) return <CircularProgress />;
  if (!ducumentData) return null;

  return (
    <Grid container xs={12} md={12} spacing={2}>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle1" paragraph color="textSecondary">
          Document category
        </Typography>
        <Typography variant="h6" paragraph>
          {ducumentData?.document_category}
        </Typography>
        <Grid container xs={12} md={12} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" paragraph color="textSecondary">
              {ducumentData?.images[0].title}
            </Typography>
            <img
              src={
                ducumentData?.images[0].values.length && 'data:image/jpeg;base64,' + ducumentData?.images[0].values[0]
              }
              className={classes.image}
              alt={ducumentData?.images[0].title}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <Typography variant="subtitle1" paragraph color="textSecondary">
          Document type
        </Typography>
        <Typography variant="h6" paragraph>
          {ducumentData?.document_type}
        </Typography>
        <Typography variant="subtitle1" paragraph color="textSecondary">
          {ducumentData?.images[1].title}
        </Typography>
        <img
          src={ducumentData?.images[1].values.length && 'data:image/jpeg;base64,' + ducumentData?.images[1].values[0]}
          className={classes.image}
          alt={ducumentData?.images[1].title}
        />
      </Grid>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Field type</TableCell>
              <TableCell>Visual zone</TableCell>
              <TableCell>Valid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ducumentData?.text_fields.map((i, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{i.title}</TableCell>
                  <TableCell>{i.value}</TableCell>
                  <TableCell>{getCheckOrCloseIcon(i.is_valid)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>issuing state code</TableCell>
              <TableCell>{ducumentData?.issuing_state_code}</TableCell>
              <TableCell>
                <CheckIcon className={classes.success} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>issuing state name</TableCell>
              <TableCell>{ducumentData?.issuing_state_name}</TableCell>
              <TableCell>
                <CheckIcon className={classes.success} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

DocumentVerification.propTypes = {
  userId: PropTypes.number.isRequired,
};
