import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from '@material-ui/pickers';
import { timezone } from '../../utils';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

export const MarkOverpaymentRefundedDialog = ({ isOpened, onClose, onSubmit }) => {
  const [executedAt, setExecutedAt] = useState(null);
  const [errors, setErrors] = useState({});

  const classes = useStyles();

  const removeError = (...fields) => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => fields.includes(name) === false)));
  };

  const validate = () => {
    const formErrors = {};

    if (!executedAt) {
      formErrors.executedAt = 'Date field is required';
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const handleCloseDialog = () => {
    setExecutedAt(null);
    setErrors({});
    onClose();
  };

  const handleSubmitDialog = () => {
    if (validate()) {
      onSubmit(executedAt);
      setExecutedAt(null);
    }
  };

  useEffect(() => {
    setExecutedAt(new Date());
  }, [isOpened]);

  return (
    <Dialog open={isOpened} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Mark overpayment as refunded</DialogTitle>
      <DialogContent>
        <DateTimePicker
          label="Date"
          className={classes.fullWidth}
          value={executedAt ? timezone.shift(executedAt) : null}
          onChange={value => {
            removeError('executedAt');
            setExecutedAt(timezone.unshift(value));
          }}
          error={!!errors.executedAt}
          helperText={errors.executedAt}
        />
        <Typography className={classes.mt2}>
          The overpayment on this loan will be marked as repaid on the specified date
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmitDialog} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MarkOverpaymentRefundedDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
