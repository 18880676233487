import { useDataProvider, useNotify } from 'react-admin';
import { useEffect, useState } from 'react';

export const useRejectionReasons = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [error, setError] = useState();
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    dataProvider
      .query('rejection_reasons?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setRejectionReasons(data))
      .catch(({ error }) => setError(error));
  }, [dataProvider]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
  }

  return rejectionReasons;
};
