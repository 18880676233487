import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  NumberInput,
  DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import { DAYS_MODE_DOW, DAYS_MODE_DOM, WEEK_DAYS, MONTH_DAYS } from '../../constants';
import { selectedValidator, getUserAttachments } from '../../utils';
import { getFormStyles, ALLOCATION_CHOICES, AUDIENCE_TYPE_CHOICES } from './constants';
import { Autocomplete } from '../../components';

const useStyles = makeStyles(theme => getFormStyles(theme));

const FormWrapper = ({ save, record, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const getAllocationPeriod = () => {
    if (!record?.params.allocated_days && !record?.params.allocated_date) {
      return 1;
    }
    if (record?.params.allocated_days) {
      return 2;
    }
    if (record?.params.allocated_date) {
      return 3;
    }
  };

  const [weekDays, setWeekDays] = useState(record?.params?.week_days || []);
  const [monthDays, setMonthDays] = useState(record?.params?.month_days || []);
  const [daysMode, setDaysMode] = useState(
    Array.isArray(record?.params?.month_days) && record.params.month_days.length > 0
      ? DAYS_MODE_DOM
      : record.params.week_days?.length > 0
        ? DAYS_MODE_DOW
        : 'onetime',
  );
  const [agenciesList, setAgenciesList] = useState([]);
  const [allocationOption, setAllocationOption] = useState(getAllocationPeriod());
  const [params, setParams] = useState(record.params.external_agencies);
  const [audienceType, setAudienceType] = useState(record.audience_type);
  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [errors, setErrors] = useState({});

  const attachments = getUserAttachments(files);

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgenciesList(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  useEffect(() => {
    if (record.audience_type === 'file' && record.file_id !== null) {
      dataProvider
        .getOne('files', { id: record.file_id })
        .then(({ data }) => setFiles(prevState => [...prevState, data]))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
  }, [dataProvider, notify, record]);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  const onAddAgency = () => setParams([...params, { key: Date.now(), id: null, percent: null }]);
  const onRemoveAgency = id => setParams([...params.filter(item => item.id !== id)]);

  const setSelectAgency = (id, type, value) => {
    const itemIndex = params.findIndex(param => param.key === id || param.id === id);
    if (itemIndex !== -1) {
      const newParams = [...params];
      newParams[itemIndex][type] = value;
      setParams(newParams);
    }
  };

  const AGENCIES_CHOICES = [
    ...agenciesList?.filter(agency => agency.status === 'active').map(item => ({ id: item.id, name: item.name })),
  ];

  const validatePercentage = () => {
    const percentage = params.map(param => param.percent);
    const sum = percentage.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    return sum > 100;
  };

  const handleAttachFile = async e => {
    try {
      if (e.target.files[0]?.size > 10240000) {
        return notify('Maximum file size is 10 Mb', 'error');
      }
      setLoadingFile(true);
      const formData = new FormData();
      formData.append('file', e.target.files[0], 'file');
      const { data } = await dataProvider.query('files/users', { method: 'POST', body: formData });
      setFiles(prevState => [...prevState, data]);
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    } finally {
      setLoadingFile(false);
      errors.file && setErrors({});
    }
  };

  const handleUnattachFile = id => setFiles(prevState => [...prevState.filter(i => i.id !== id)]);

  return (
    <FormWithRedirect
      save={(
        { audience_id, audience_type, name, is_enabled, allocated_days, allocated_date, one_time_allocations },
        ...rest
      ) => {
        const validationErrors = {};
        if (audience_type === 'file' && files.length === 0) {
          validationErrors.file = 'Upload a file!';
        }
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        const payload = {
          name,
          is_enabled,
          params: {
            week_days: daysMode === DAYS_MODE_DOW ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
            one_time_allocations: daysMode === 'onetime' ? [one_time_allocations] : null,
            allocated_days: allocationOption === 2 ? allocated_days : null,
            allocated_date: allocationOption === 3 ? allocated_date : null,
            external_agencies: params.map(item => ({ id: item.id, percent: item.percent })),
          },
          _params: { method: 'PATCH' },
        };
        if (audience_type === 'file') {
          payload.file_id = files[0].id;
        }
        if (audience_type === 'audience') {
          payload.audience_id = audience_id;
        }
        save(...[payload, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6" gutterBottom={false}>
                      Update external agency assignment campaign
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput label="Enabled" source="is_enabled" />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} key="allocation_date">
                    <SelectInput
                      label="Allocation period"
                      helperText={false}
                      onChange={e => setAllocationOption(e.target.value)}
                      source="allocation_date_option"
                      validate={[required()]}
                      choices={ALLOCATION_CHOICES}
                      defaultValue={getAllocationPeriod()}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {allocationOption === 2 && (
                      <NumberInput
                        label="Days"
                        source="allocated_days"
                        fullWidth
                        validate={[required()]}
                        defaultValue={formProps.record.params.allocated_days}
                      />
                    )}
                    {allocationOption === 3 && (
                      <DateInput
                        label="Date"
                        source="allocated_date"
                        fullWidth
                        validate={[required()]}
                        defaultValue={formProps.record.params.allocated_date}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      source="audience_type"
                      label="Select loans from"
                      allowEmpty
                      emptyValue={1}
                      emptyText="Not selected"
                      choices={AUDIENCE_TYPE_CHOICES}
                      validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                      disabled={daysMode !== 'onetime'}
                      fullWidth
                      onChange={e => {
                        setAudienceType(e.target.value);
                        if (e.target.value !== 'file') {
                          setFiles([]);
                        }
                      }}
                    />
                  </Grid>
                  {audienceType === 'audience' && (
                    <Grid item xs={12} sm={6} key="audience_id">
                      <Autocomplete
                        resource="mass_sending_audiences"
                        source="audience_id"
                        optionValueProp="id"
                        optionLabelProp="name"
                      />
                    </Grid>
                  )}
                  {audienceType === 'file' && (
                    <Grid item xs={12} sm={6}>
                      <>
                        {files.length === 0 ? (
                          <Box className={classes.mt2}>
                            <label htmlFor="upload_file">
                              <input
                                className={classes.hidden}
                                type="file"
                                id="upload_file"
                                accept="text/csv"
                                onChange={handleAttachFile}
                                onClick={e => {
                                  e.target.value = null;
                                }}
                              />
                              <Button
                                color="primary"
                                aria-label="upload file"
                                className={classes.uploadIcon}
                                startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                                component="span">
                                Attach file
                              </Button>
                            </label>
                          </Box>
                        ) : (
                          <Box className={classes.mt3}>
                            {attachments.map(file => (
                              <Box key={file.src} className={classes.flex}>
                                <Box className={classes.flex}>
                                  <AttachmentIcon color="primary" fontSize="small" />
                                  <Link target="_blank" href={file.src}>
                                    {file.file_name}
                                  </Link>
                                </Box>
                                <IconButton
                                  color="primary"
                                  aria-label="unattach file"
                                  className={classes.removeIcon}
                                  component="span"
                                  edge="start"
                                  onClick={() => handleUnattachFile(file.id)}>
                                  <CloseIcon color="primary" fontSize="small" />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </>
                      <div>
                        {errors.file && (
                          <Typography color="error" variant="caption">
                            {errors.file}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <Typography variant="body1" className={classes.mb2}>
                  Agencies list
                </Typography>
                {params.map(param => (
                  <Grid container alignItems="center" spacing={2} key={param.id} className={classes.mb2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        label="Agency"
                        helperText={false}
                        source={`agency_id->${param.id}`}
                        validate={[required()]}
                        choices={AGENCIES_CHOICES}
                        value={param.id}
                        defaultValue={param.id}
                        onChange={e => setSelectAgency(param.id, 'id', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberInput
                        label="Percent"
                        helperText={false}
                        source={`percent->${param.id}`}
                        validate={[required()]}
                        value={param.percent}
                        defaultValue={param.percent}
                        onChange={e => setSelectAgency(param.id, 'percent', +e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton onClick={() => onRemoveAgency(param.id)} color="primary" variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button onClick={onAddAgency} color="primary" variant="contained">
                  Add agency
                </Button>
                <Divider className={classes.my2} />
                <Grid item xs={12}>
                  <RadioGroup row className={classes.mb2}>
                    <FormControlLabel
                      value="onetime"
                      control={<Radio />}
                      label="One-time"
                      checked={daysMode === 'onetime'}
                      onChange={() => {
                        setDaysMode('onetime');
                        setAudienceType(1);
                        formProps.form.change('audience_type', 1);
                        setFiles([]);
                      }}
                    />
                    <FormControlLabel
                      value={DAYS_MODE_DOW}
                      control={<Radio />}
                      label="Days of week"
                      checked={daysMode === DAYS_MODE_DOW}
                      onChange={() => {
                        setDaysMode(DAYS_MODE_DOW);
                        setAudienceType('audience');
                        formProps.form.change('audience_type', 'audience');
                        setFiles([]);
                      }}
                    />
                    <FormControlLabel
                      value={DAYS_MODE_DOM}
                      control={<Radio />}
                      label="Days of month"
                      checked={daysMode === DAYS_MODE_DOM}
                      onChange={() => {
                        setDaysMode(DAYS_MODE_DOM);
                        setAudienceType('audience');
                        formProps.form.change('audience_type', 'audience');
                        setFiles([]);
                      }}
                    />
                  </RadioGroup>
                  {daysMode === 'onetime' && (
                    <Grid item xs={12} sm={6}>
                      <DateInput
                        label="One-time assignment date"
                        source="one_time_allocations"
                        fullWidth
                        validate={[required()]}
                        defaultValue={
                          formProps.record.params.one_time_allocations
                            ? formProps.record.params.one_time_allocations[0]
                            : null
                        }
                      />
                    </Grid>
                  )}
                  {daysMode === DAYS_MODE_DOW && (
                    <div>
                      {WEEK_DAYS.map((day, idx) => (
                        <FormControlLabel
                          key={day}
                          control={
                            <Checkbox onChange={() => onChangeWeekDay(idx + 1)} checked={weekDays.includes(idx + 1)} />
                          }
                          label={day}
                        />
                      ))}
                    </div>
                  )}
                  {daysMode === DAYS_MODE_DOM && (
                    <div className={classes.domList}>
                      {MONTH_DAYS.map(({ value, label }) => (
                        <div key={value} className={classes.domItem}>
                          <FormControlLabel
                            control={
                              <Checkbox onChange={() => onChangeMonthDay(value)} checked={monthDays.includes(value)} />
                            }
                            label={label}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
                <SaveButton
                  disabled={validatePercentage()}
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                {validatePercentage() && (
                  <FormHelperText error>The amount of percent across all agencies is more than 100</FormHelperText>
                )}
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    params: PropTypes.shape({
      week_days: PropTypes.array,
      month_days: PropTypes.array,
      allocated_days: PropTypes.number,
      allocated_date: PropTypes.string,
      one_time_allocations: PropTypes.array,
      external_agencies: PropTypes.array,
    }),
    audience_type: PropTypes.string,
    file_id: PropTypes.number,
  }),
};

const ExternalAgencyCampaignEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default ExternalAgencyCampaignEdit;
