import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  TextInput,
  Toolbar,
  BooleanInput,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      save={({ id, name, enabled }, ...rest) => {
        save(...[{ id, name, enabled, _params: { method: 'PATCH' } }, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit no payment reason
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <NumberInput helperText={false} fullWidth disabled source="id" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput helperText={false} fullWidth label="Name" source="name" validate={[required()]} />
                    </Grid>
                    <Grid item xs={12}>
                      <BooleanInput helperText={false} label="Is enabled" source="enabled" />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={false}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const CollectionNoPaymentReasonEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default CollectionNoPaymentReasonEdit;
