import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { snakeToHuman } from '../../utils';

const TRANSACTION_COLORS = {
  principal: '#00FFFF',
  commission: '#f8c912',
  interest: '#00f',
  past_due_interest: '#f00',
  service_fee: '#3CB371',
  consulting_fee: '#9ACD32',
  late_payment_fee: '#FF0000',
  service_fee_add: '#B22222',
  consulting_fee_add: '#CD5C5C',
  unknown: '#999999',
};

const TRANSACTION_TYPES = {
  principal: TRANSACTION_COLORS.principal,
  principal_discount: TRANSACTION_COLORS.principal,
  principal_revert: TRANSACTION_COLORS.principal,
  commission: TRANSACTION_COLORS.commission,
  commission_discount: TRANSACTION_COLORS.commission,
  commission_revert: TRANSACTION_COLORS.commission,
  interest: TRANSACTION_COLORS.interest,
  interest_discount: TRANSACTION_COLORS.interest,
  interest_revert: TRANSACTION_COLORS.interest,
  past_due_interest: TRANSACTION_COLORS.past_due_interest,
  past_due_interest_discount: TRANSACTION_COLORS.past_due_interest,
  past_due_interest_revert: TRANSACTION_COLORS.past_due_interest,
  consulting_fee: TRANSACTION_COLORS.consulting_fee,
  consulting_fee_discount: TRANSACTION_COLORS.consulting_fee,
  consulting_fee_revert: TRANSACTION_COLORS.consulting_fee,
  service_fee: TRANSACTION_COLORS.service_fee,
  service_fee_discount: TRANSACTION_COLORS.service_fee,
  service_fee_revert: TRANSACTION_COLORS.service_fee,
  late_payment_fee: TRANSACTION_COLORS.late_payment_fee,
  late_payment_fee_discount: TRANSACTION_COLORS.late_payment_fee,
  late_payment_fee_revert: TRANSACTION_COLORS.late_payment_fee,
  overpayment: TRANSACTION_COLORS.late_payment_fee,
  overpayment_discount: TRANSACTION_COLORS.late_payment_fee,
  overpayment_revert: TRANSACTION_COLORS.late_payment_fee,
  service_fee_add: TRANSACTION_COLORS.service_fee_add,
  service_fee_add_discount: TRANSACTION_COLORS.service_fee_add,
  service_fee_add_revert: TRANSACTION_COLORS.service_fee_add,
  consulting_fee_add: TRANSACTION_COLORS.consulting_fee_add,
  consulting_fee_add_discount: TRANSACTION_COLORS.consulting_fee_add,
  consulting_fee_add_revert: TRANSACTION_COLORS.consulting_fee_add,
};

const useStyles = makeStyles(() => ({
  circle: {
    display: 'inline-block',
    width: '0.6em',
    height: '0.6em',
    borderRadius: '50%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));

export const TransactionType = ({ type }) => {
  const styles = useStyles();
  const circle_color = TRANSACTION_TYPES[type] || TRANSACTION_COLORS.unknown;
  const transactionType = type.includes('past_due_interest') ? type.replace('past_due_interest', 'PDI') : type;

  return (
    <div className={styles.container}>
      <span className={styles.circle} style={{ backgroundColor: circle_color }} />
      &nbsp;
      {snakeToHuman(transactionType)}
    </div>
  );
};

TransactionType.propTypes = {
  type: PropTypes.string,
};
