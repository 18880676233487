import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { formatPhoneNumber } from '../../utils';

const UserPhonesDialog = ({ isOpened, onClose, previewList }) => {
  const [listToShow, setListToShow] = useState(20);

  const handleShowMore = () => {
    setListToShow(previewList?.length);
  };

  return (
    <Dialog open={isOpened} onClose={onClose} maxWidth="md">
      <DialogTitle>Campaign clients list</DialogTitle>
      <DialogContent>
        {previewList.length ? (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="left">Loan ID</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Phone number</TableCell>
                  <TableCell align="right">Phone status</TableCell>
                  <TableCell align="right">Phone priority</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previewList?.slice(0, listToShow)?.map(row => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">
                      {row.loan_id ? (
                        <Link
                          onClick={e => e.stopPropagation()}
                          href={`#loans/${row.loan_id}/show`}
                          target="_blank"
                          rel="noreferrer">
                          {` #${row.loan_id}`}
                        </Link>
                      ) : (
                        '---'
                      )}
                    </TableCell>
                    <TableCell align="right">{`${row.first_name} ${row.middle_name} ${row.last_name}`}</TableCell>
                    <TableCell align="right">{row.phone_number && formatPhoneNumber(row.phone_number)}</TableCell>
                    <TableCell align="right">{row.phone_status}</TableCell>
                    <TableCell align="right">{row.weight}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {listToShow < previewList?.length && (
              <Button onClick={handleShowMore} color="primary">
                + {previewList?.length - listToShow} more users
              </Button>
            )}
          </>
        ) : (
          <Typography variant="body1">No users found for this campaign</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserPhonesDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  previewList: PropTypes.array,
};

UserPhonesDialog.defaultProps = {
  previewList: [],
};

export default UserPhonesDialog;
